import React , { Fragment, useState, useEffect } from 'react';
import Toolbar from '../Navigation/Toolbar/Toolbar'
import AsideNavigation from '../Navigation/AsideNavigation/AsideNavigation';
import AsideNavCandidate from '../Navigation/AsideNavigation/AsideNavCandidate';
import  { withStyles, Hidden, Modal } from '@material-ui/core';

const styles = (theme) => ({
    container: {
        height: '100%',
        paddingTop: '78px',
        overflowX: "hidden"
    },
    mainContent: {
      position: 'relative',
      height: '100%',
        [theme.breakpoints.up('md')]: {
            paddingLeft: '84px'
        }
    }
  });


const Layout = (props) => {

    const { classes } = props;
    return (
        <Fragment>
            <Toolbar 
                logout={props.logout} 
                userData={props.userData} 
                logo={props.logo}
                uuid={props.uuid} 
                token={props.token} 
                organizations={props.organizations}
                setLogo={props.setLogo}
                orgID={props.orgID}
                orgActive={props.orgActive}
                appBarColor = {props.appBarColor}
                setAppBarColor={props.setAppBarColor}
                setOrgNid = {props.setOrgNid} 
            />
            <div className={classes.container}>
                <Hidden smDown>
                    {props.role === "employer" ? (
                        <AsideNavigation orgID={props.orgID}/>
                    ):(
                        <AsideNavCandidate/> 
                    )}
                </Hidden>
                <main className={classes.mainContent}>{props.children}</main>
            </div>
        </Fragment>
    )
}

export default withStyles(styles)(Layout);

