import React, {useEffect} from 'react';
import queryString from 'query-string';
import axios from 'axios';
import oauthConfig from '../../oauthConfig';

const Contact = (props) => {
    
    useEffect (()=>{
        const values = queryString.parse(props.location.search)
        axios.post(`${oauthConfig.baseUrl}/api/candidate_access_process`, values)
        .then(res => {
            window.location.replace("https://www.womentech.net/intro-request");
            // props.history.push('/dashboard')
            // props.contactOpen(res.data, "success")
        })
        .catch(err => {
            props.history.push('/dashboard')
            props.contactOpen(err.response.data, "error") 
        })
    }, [])

    return null
    
}

export default Contact;