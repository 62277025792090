import React, { Fragment, useEffect } from "react";
import { makeStyles, Container, Box } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(3),
    width: "100%",
    textAlign: "center",
    color: theme.palette.grey[600],
  },
  navLink: {
    margin: theme.spacing(1),
    color: theme.palette.primary.main,
    fontWeight: "bold",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
}));

const Footer = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      <Container maxWidth="md">
        <Box display="flex" justifyContent="center">
          <a className={classes.navLink} href="https://www.facebook.com/womentech.net/" target="_blank">
            <FacebookIcon />
          </a>
          <a className={classes.navLink} href="https://www.linkedin.com/company/womentech-network" target="_blank">
            <LinkedInIcon />
          </a>
          <a className={classes.navLink} href="https://www.instagram.com/womentechnet/" target="_blank">
            <InstagramIcon />
          </a>
          <a className={classes.navLink} href="https://twitter.com/WomenTechNet" target="_blank">
            <TwitterIcon />
          </a>
          <a className={classes.navLink} href="https://www.youtube.com/c/WomeninTech-Network" target="_blank">
            <YouTubeIcon />
          </a>
        </Box>
        <Box display="flex" justifyContent="center">
          <a className={classes.navLink} href="https://www.womentech.net/privacy-policy-imprint" target="_blank">
            {"Privacy Policy"}
          </a>
          <a className={classes.navLink} href="https://www.womentech.net/privacy-policy-imprint" target="_blank">
            {"Imprint"}
          </a>
          <a className={classes.navLink} href="https://www.womentech.net/terms" target="_blank">
            {"Terms & Conditions"}
          </a>
        </Box>
      </Container>
    </div>
  );
};

export default Footer;
