import React from 'react';
import { Card, makeStyles, Grid } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles(theme => ({
    errorCard: {
        textAlign: "center",
        padding: theme.spacing(2),
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.error.contrastText
    },
    icon: {
        marginRight: theme.spacing(1),
    }
}));

const AlertError = (props) => {
    const classes = useStyles();
    return (
        <Card className={classes.errorCard}>
            <Grid container justify="center" alignItems="center">
                <ErrorOutlineIcon className={classes.icon}/>
                <h3>{props.children}</h3>
            </Grid>
        </Card>
    )
}

export default AlertError;