import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import {Menu, MenuItem, Fade, Typography, Divider, Grid, Button,  withStyles, Box } from '@material-ui/core';
import OrganizationItems from '../../Organization/ListOrganization/OrganizationItems';


const CustomButton = withStyles(theme => ({
  root: {
    width: "200px",
    margin: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px 0 `,
    color: theme.palette.grey[700],
    backgroundColor: 'transperant',
    borderColor: theme.palette.grey[700],
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.grey[500],
      borderColor: theme.palette.grey[500],
    }
  }
}))(Button);

const styles = (theme) => ({ 
    root: {
        width: '370px',
        [theme.breakpoints.up('md')]: {
            width: "500px",
        }
    },
    menuItemContent: {
        width: "100%",
        textAlign: "center",
        whiteSpace: "normal"
    }
  });

const MenuPopup = (props) => {
    const { classes } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    
    return (
        <Fragment>
            <div onClick={handleClick} >{props.children}</div>
            <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                keepMounted
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
                classes={{
                    paper: classes.root,
                }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.menuItemContent}
                > 
                    <Typography variant="subtitle2">
                        {`${props.userData.attributes.field_first_name} ${props.userData.attributes.field_last_name}`}
                    </Typography>
                    <Typography variant="body2">
                        {`${props.userData.attributes.field_contact_email}`}
                        
                    </Typography>
                    <CustomButton  onClick={handleClose} component={Link} to="/profile/view" variant="outlined" size="small">
                        View Profile
                    </CustomButton>
                    <CustomButton  onClick={handleClose} component={Link} to="/profile/step/0" variant="outlined" size="small">
                        Edit Profile
                    </CustomButton>
                </Box>
                { props.orgID !== "candidate" && (
                    <div>
                        <Divider />
                            <MenuItem onClick={handleClose}> 
                                <div className={classes.menuItemContent}>
                                    <OrganizationItems
                                        organizations={props.organizations}
                                        uuid={props.uuid} 
                                        token={props.token} 
                                        menu={true} 
                                        setLogo={props.setLogo} 
                                        orgID = {props.orgID}
                                        orgActive={props.orgActive}
                                        setOrgNid = {props.setOrgNid}
                                        setAppBarColor={props.setAppBarColor}
                                    />
                                </div>
                            </MenuItem> 
                        <Divider />
                    </div>
                )}
                <Box 
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.menuItemContent}
                >
                    <CustomButton  variant="outlined" size="small" onClick={props.logout}>
                        Logout
                    </CustomButton> 
                </Box>
            </Menu>
        </Fragment>
    )
}

export default withStyles(styles)(MenuPopup)