import React, { Component, Fragment } from "react";
import JobsTable from "./JobsTable";
import JobStats from "./JobStats";
import Loader from "../../../Utilities/Loader/Loader";
import ErrorText from "../../../Utilities/Error/ErrorText";
import axios from "axios";
import oauthConfig from "../../../../oauthConfig";

class DashboardOverview extends Component {
  state = {
    jobs: {
      jobsList: null,
      included: null,
      totalViews: null,
    },
    currentOrg: this.props.organizations ? this.props.organizations.find((el) => el.id === this.props.orgID) : null,
  };

  componentDidMount() {
    this.fetchData().catch((err) => {
      if (err.response) {
        if (err.response.status === 401) {
          this.props.logout();
        }
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.token !== prevProps.token) {
      this.fetchData().catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            this.props.logout();
          }
        }
      });
    }

    if (this.props.orgID !== prevProps.orgID) {
      this.fetchData();
    }
  }

  setHeadrs = () => {
    let token = this.props.token;

    let headers = {
      headers: {
        Accept: "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
        Authorization: `${token.token_type} ${token.access_token}`,
      },
    };
    return headers;
  };

  fetchData = async () => {
    this.setState({
      jobs: {
        jobsList: null,
      },
    });

    const headers = this.setHeadrs();
    const jobsList = await axios.get(
      `${oauthConfig.baseUrl}/api/node/job_posting?filter[field_hiring_organization.id][value]=${this.props.orgID}&include=field_hiring_organization.field_logo`,
      headers
    );
    let totalViews;
    if (this.props.nid) {
      totalViews = await axios.get(`${oauthConfig.baseUrl}/api/organization/${this.props.nid}/job_analytics`, headers).catch((err) => {
        return 0;
      });
    }

    this.setState({
      jobs: {
        jobsList: jobsList.data.data.reverse(),
        included: jobsList.data.included,
        totalViews: totalViews.data ? totalViews.data.length : 0,
      },
    });
  };

  handleDelete = (id) => {
    let headers = this.setHeadrs();
    axios
      .delete(`${oauthConfig.baseUrl}/api/node/job_posting/${id}`, headers)
      .then((response) => {
        this.fetchData();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          this.props.logout();
        }
      });
  };

  render() {
    let perMonth;
    if (this.props.organizations && this.props.orgID) {
      const currentOrg = this.props.organizations.find((el) => el.uuid === this.props.orgID);
      const created = new Date(Number(currentOrg.created));
      const currentDate = new Date(Date.now());

      function monthDiff(d1, d2) {
        let months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 1 : months;
      }

      const passedMonths = monthDiff(created, currentDate);
      if (this.state.jobs.jobsList) {
        perMonth = this.state.jobs.jobsList.length / passedMonths;
      } else {
        perMonth = 0;
      }
      perMonth = Number.isInteger(perMonth) ? perMonth : perMonth.toFixed(2);
    }

    let content;
    if (!this.props.orgID) {
      content = <ErrorText>Please Select Active Organization</ErrorText>;
    } else {
      content = (
        <Fragment>
          {this.state.jobs.jobsList ? (
            <Fragment>
              <JobStats perMonth={perMonth} number={this.state.jobs.jobsList.length} totalViews={this.state.jobs.totalViews} />
              <JobsTable {...this.props} jobs={this.state.jobs} handleDelete={this.handleDelete} />
            </Fragment>
          ) : (
            <Loader />
          )}
        </Fragment>
      );
    }
    return content;
  }
}

export default DashboardOverview;
