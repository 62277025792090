import { makeStyles } from '@material-ui/core/styles';
const drawerWidth = 0;
const drawerWidthMobile = 80;
const drawerStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      // overflow: 'hidden',
      // height: '100%',
      [theme.breakpoints.down('xs')]: {
        display: 'block'
      }
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    asideMobileNav: {
      width: '80px',
      height: '100%',
      overflow: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    asideMobileNavClose:{
      width: 0,
    },
    drawerMenus: {
      // display: 'flex',
      height: '100%'
    },
    drawer: {
      width: window.innerWidth > theme.breakpoints.values.md ? drawerWidth : drawerWidthMobile,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerContent: {
      overflow: 'hidden' 
    },
    drawerOpen: {
      width: window.innerWidth > theme.breakpoints.values.md ? drawerWidth : drawerWidthMobile,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    paperOpen: {
      overflowY:'visible', 
      width: window.innerWidth > theme.breakpoints.values.md ? drawerWidth : drawerWidthMobile,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    paperClose: {
      overflowY:'visible',
      width: '20px',
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(0, 1),
      // ...theme.mixins.toolbar,
    },
    content: {
      width: '100%',
      overflow: 'hidden',
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(7) + 1,
        marginLeft: 0
      }
    },
    contentOpen: {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0,
        marginLeft: window.innerWidth > theme.breakpoints.values.lg ? drawerWidth : drawerWidthMobile
      }
    },
    link: {
      textDecoration: 'none',
      color: 'inherit',
      display: 'block'
    },
    chevron: {
      display: window.innerWidth > theme.breakpoints.values.md ? 'none' : 'block',
      position: 'absolute',
      top: '20px',
      right: '-16px',
      zIndex: 100,
      color: '#ffffff',
      padding: '4px',
      backgroundColor: theme.palette.primary.main,
      '&:focus': {backgroundColor: theme.palette.primary.main},
      '&:active': {backgroundColor: theme.palette.primary.main}
    },
    expansionPannel: { 
      '&.MuiExpansionPanel-root': {
        margin: '0 !important',
        boxShadow: 'none',
        borderBottom: ' 1px solid rgba(0, 0, 0, 0.12)',
      }
    },
    expansionDetails: {
      "&.MuiExpansionPanelDetails-root": {
        padding: 0
      }
    },
    expansionPannel: { 
      '&.MuiExpansionPanel-root': {
        margin: '0 !important',
        boxShadow: 'none',
        borderBottom: ' 1px solid rgba(0, 0, 0, 0.12)',
        // borderLeft: '5px solid rgba(0, 0, 0, 0.09)'
      }
    },
    expansionSummary: {
      "&.MuiExpansionPanelSummary-root": {
        padding: `0 ${theme.spacing(2)}px`
      },
      "& .MuiExpansionPanelSummary-expandIcon":{  
        padding: 0,
        margin: 0
      },
      "&.summaryClosed": {
        flexDirection: 'column'
      }
    },
    list: {
      width: '100%',
      padding: 0
    },
    logo: {
      width: '50px',
      height: '20px',
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      "& > img" : {
          width: "100%",
      }
    },
    avatar: {
      width: '30px',
      height: '30px',
      margin: '0 auto' 
    },
    panelTitle: {
      width: '120px',
      marginLeft: `${theme.spacing(1)}px`,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      transition: 'all 0.2s ease',
      '&.panelTitleClosed': {
        width: 0,
        margin: 0
      }
    },
    expand: { 
      margin: 0
    },
    icon: { 
      justifyContent: 'center'
    },
    iconDrawerOpen: {
      width: '100%',
      justifyContent: 'center',
      minWidth: 'auto',
      marginRight: '16px'
    }
  }));

export default drawerStyles;