import React, { Fragment } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import Script from 'react-load-script';
import config from '../../../oauthConfig';
import { withStyles, Checkbox, FormControlLabel, Typography } from '@material-ui/core';

const styles = theme => ({
    wrapper: {
        position: 'relative',
        '& .location-search-input' : {
            width: '100%',
            padding: '2px 8px',
            border: '1px solid hsl(0,0%,80%)',
            borderRadius: '4px',
            minHeight: '38px',
            outline: '0 !important'
        },
        '& .suggestion-item': {
            fontSize: '14px',
            padding: '5px'
        },
        '& .autocomplete-dropdown-container': {
            position: 'absolute',
            top: '100%',
            width: '100%',
            zIndex: '100',
            border: '1px solid hsl(0,0%,80%)',
            borderRadius: '4px',
            backgroundColor: '#fff'
        }
    }
});


const searchOptions = {
    types: ['(cities)'],
}

class SearchLocation extends React.Component {
    state = { 
        address: '',
        scriptLoaded: false
    }

    handleScriptLoad = () => this.setState({scriptLoaded: true})
  
    handleChange = address => {
      this.setState({ address });
    };
  
    handleSelect = address => {
        const selectedOption = {label: address, value: address}
        const selectedOptionArr = [...this.props.values, selectedOption]
        this.props.handleSelect(selectedOptionArr, this.props.checked, this.props.statePropName)
    };
  
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.wrapper}>
                <Script
                    url={`https://maps.googleapis.com/maps/api/js?key=${config.googleMapKey}&libraries=places&language=en`}
                    onLoad={this.handleScriptLoad}
                />
                {this.state.scriptLoaded && (
                    <Fragment>
                        <Typography variant="body1" gutterBottom={true}>{this.props.title}</Typography>
                        <PlacesAutocomplete
                        value={this.state.address}
                        onChange={this.handleChange}
                        onSelect={this.handleSelect}
                        searchOptions={searchOptions}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                                let result;
                                if(suggestions.length > 0){
                                result = suggestions.filter(suggestion => suggestion.types[0] != "administrative_area_level_2" && suggestion.types[0] != "administrative_area_level_1" && suggestion.types[0] != "sublocality_level_1");
                                }
                                return (
                                    <div>
                                        <input
                                            className={classes.input}
                                            {...getInputProps({
                                            placeholder: 'Search Location',
                                            className: 'location-search-input',
                                            })}
                                        />
                                        
                                        {/* {loading && <div>Loading...</div>} */}
                                        { suggestions.length > 0 && (
                                            <div className="autocomplete-dropdown-container">
                                            {result.map(suggestion => {
                                            const className = suggestion.active
                                                ? 'suggestion-item suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div 
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                    key={suggestion.placeId}
                                                    >
                                                    <div className="suggestion-item">{suggestion.description}</div>
                                                </div>
                                            );
                                            })}
                                            </div>

                                        )}
                                    </div>
                                )
                            }}
                        </PlacesAutocomplete>
                        {this.props.values && ( 
                            this.props.values.map(el => {
                                return   (
                                    <div key={el.value}> 
                                        <FormControlLabel
                                            control={<Checkbox checked={this.props.checkedObj[el.value]} onChange={(e)=> this.props.handleChange(e.target.name, e.target.checked, this.props.checked)} name={el.value} />}
                                            label={el.label}
                                        />
                                    </div>
                                )
                            })
                        )}
                    </Fragment>
                )}
            </div>
        );
    }
}

export default withStyles(styles)(SearchLocation);