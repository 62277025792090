import React, { Component } from "react";
import { withStyles, Modal, Button, Grid, IconButton, Hidden } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Popover from "../Popover/Popover";
import SelectCheckbox from "../SelectCheckbox/SelectCheckbox";
import CheckboxFilter from "../CheckboxFilter/CheckboxFilter";
import RadioFilter from "../RadioFilter/RadioFilter";
import SearchLocation from "../SearchLocation/SearchLocation";
import RadioOptions from "../SelectCheckbox/RadioOptions";

const styles = (theme) => ({
  modal: {
    "&:focus": {
      outline: "none",
    },
  },
  modalContent: {
    padding: `${theme.spacing(2)}px`,
    backgroundColor: "#ffffff",
    height: "100%",
    overflowY: "scroll",
    "&:focus": {
      outline: "none",
    },
  },
  filter: {
    padding: `${theme.spacing(2)}px 0`,
  },
  allFilterContainer: {
    width: "100%",
    maxWidth: "920px",
    margin: "auto",
    paddingTop: `70px`,
  },
  filterHeader: {
    top: 0,
    left: 0,
    position: "absolute",
    width: "100%",
    background: "#ffff",
    zIndex: 2,
    padding: `${theme.spacing(2)}px 0`,
    boxShadow: "0px 6px 9px 0px rgb(163 163 163 / 75%)",
    position: "absolute",
  },
  close: {
    position: "absolute",
    top: 10,
    right: 30,
  },
  allBtn: {
    width: "100px",
  },
  allBtnMobile: {
    margin: "20px 0",
  },
  clear: {
    cursor: "pointer",
  },
});

const predefinedSelectCheckbox = (predefinedVals, allList, checkedProps, selectedProps, callBack) => {
  if (predefinedVals && selectedProps !== "location") {
    let preDefined;
    if (predefinedVals.includes(",")) {
      preDefined = predefinedVals.split(",");
    } else {
      preDefined = predefinedVals.split(" ");
    }
    const result = allList.filter((el) => preDefined.indexOf(el.value) >= 0);
    callBack(result, checkedProps, selectedProps);
  }
  if (predefinedVals && selectedProps == "location") {
    const preDefined = predefinedVals.split(" ");
    const result = preDefined.map((el) => {
      const replace = el.replaceAll("--", ", ").replaceAll("-", " ").replaceAll("--", " - ");
      return { label: replace, value: replace };
    });
    callBack(result, checkedProps, selectedProps);
  }
};

const predefinedCheckbox = (predefinedVals, allList, checkedProps, callBack) => {
  if (predefinedVals) {
    const preDefined = predefinedVals.split(" ");
    const result = allList.filter((el) => preDefined.indexOf(el.value) >= 0);
    result.forEach((el) => {
      callBack(el.value, true, checkedProps);
    });
  }
};

const predefinedRadio = (predefinedVals, serachProp, property, callBack) => {
  if (predefinedVals) {
    const preDefined = serachProp + predefinedVals;
    callBack(preDefined, property);
  }
};

const selectOperator = [
  { label: "Any", id: 0, value: "+" },
  { label: "All", id: 1, value: "," },
];

const defaultState = (id, initialData) => {
  //Experience
  const experienceRaw = { ...initialData.experience };

  const experienceList = [];
  for (const property in experienceRaw) {
    experienceList.push({ label: experienceRaw[property], value: property });
  }
  const checkedExperience = {};
  experienceList.forEach((el) => {
    Object.assign(checkedExperience, { [el.value]: false });
  });

  const cvList = [{ label: "Has CV Uploaded", value: "not%20empty" }];
  const openProfileList = [{ label: "Open Profiles", value: "1" }];

  //Skills
  const skillsList = initialData.techSkills.map((el) => ({
    label: el.label,
    value: `${el.tid}`,
  }));
  //country
  const countriesList = initialData.country.map((el) => ({
    label: el.label,
    value: `${el.tid}`,
  }));
  //source
  const sourceList = initialData.source.map((el) => ({
    label: el.label,
    value: `${el.tid}`,
  }));

  return {
    open: false,
    skills: null,
    location: [],
    countries: null,
    selectStatus: [
      // { label: "All", value: "&connection_status_all="},
      { label: "All (excl. contacted)", value: `&connection_status_rejected_negate=${id}&connection_status_requested_negate=${id}&connection_status_connected_negate=${id}` },
      { label: "Requested", value: `&connection_status_requested=${id}` },
      { label: "Connected", value: `&connection_status_connected=${id}` },
    ],
    selectMonth: [
      { label: "Last 1 month", value: "&created=-1month" },
      { label: "Last 3 months", value: "&created=-3months" },
      { label: "Last 6 months", value: "&created=-6onths" },
      { label: "Last 9 months", value: "&created=-9months" },
      { label: "Last 12 months", value: "&created=-12months" },
    ],
    skillsList,
    countriesList,
    experienceList,
    sourceList,
    cvList,
    openProfileList,
    checked: {
      checkedCompany: {},
      checkedType: {},
      checkedSkills: {},
      checkedSource: {},
      checkedLocation: {},
      checkedCountries: {},
      checkedExperience,
      checkedCV: {},
      checkedOpenProfile: {},
    },
    radio: {
      checkedStatus: {
        checkedStatus: "",
        checkedStatusDefault: "",
      },
      checkedOperator: {
        checkedOperator: "+",
        checkedOperatorDefault: "+",
      },
      checkedCreated: {
        checkedCreated: "",
        checkedCreatedDefault: "",
      },
    },
  };
};

class CandidateJobFilter extends Component {
  state = defaultState(this.props.currentOrgId, this.props.initialData);

  componentDidUpdate(prevProps) {
    if (this.props.predefinedVals !== prevProps.predefinedVals) {
      const skills = this.props.predefinedVals["skills"];
      const source = this.props.predefinedVals["source"];
      const location = this.props.predefinedVals["location"];
      const experience = this.props.predefinedVals["experience"];
      const country = this.props.predefinedVals["country"];
      const cvRaw = this.props.predefinedVals["field_curriculum_vitae_op"];
      const openProfile = this.props.predefinedVals["field_third_party_communications"];
      let cv = cvRaw ? cvRaw.replace(" ", "%20") : undefined;
      const created = this.props.predefinedVals["created"];
      //Preselecting status radio
      const objKeys = Object.keys(this.props.predefinedVals);
      objKeys.forEach((el) => {
        if (el === "connection_status_requested") {
          this.handleChangeRadio(`&connection_status_requested=${this.props.currentOrgId}`, "checkedStatus");
        } else if (el === "connection_status_connected") {
          this.handleChangeRadio(`&connection_status_connected=${this.props.currentOrgId}`, "checkedStatus");
        } else if (el === "connection_status_connected_negate") {
          this.handleChangeRadio(
            `&connection_status_rejected_negate=${this.props.currentOrgId}&connection_status_requested_negate=${this.props.currentOrgId}&connection_status_connected_negate=${this.props.currentOrgId}`,
            "checkedStatus"
          );
        }
      });
      //Preselect Skills operator
      if (this.props.predefinedVals["skills"] && this.props.predefinedVals["skills"].includes(",")) {
        this.handleChangeRadio(",", "checkedOperator");
      }
      if (created) {
        this.handleChangeRadio(`&created=${created}`, "checkedCreated");
      }
      predefinedSelectCheckbox(skills, this.state.skillsList, "checkedSkills", "skills", this.handleSelect);
      predefinedSelectCheckbox(source, this.state.sourceList, "checkedSource", "source", this.handleSelect);
      predefinedSelectCheckbox(location, null, "checkedLocation", "location", this.handleSelect);
      predefinedSelectCheckbox(country, this.state.countriesList, "checkedCountries", "countries", this.handleSelect);
      predefinedCheckbox(experience, this.state.experienceList, "checkedExperience", this.handleChange);
      predefinedCheckbox(cv, this.state.cvList, "checkedCV", this.handleChange);
      predefinedCheckbox(openProfile, this.state.openProfileList, "checkedOpenProfile", this.handleChange);
    }
  }

  handleOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleSelect = (selectedOption, checkedObj, statePropName) => {
    let obj = {};
    let elVal;
    selectedOption.forEach((el) => {
      if (this.state.checked[checkedObj][el.value] === undefined || this.state.checked[checkedObj][el.value] === false) {
        elVal = el.value;
        Object.assign(obj, { [el.value]: true });
      }
    });

    //If value already exist
    if (Object.keys(obj).length === 0) {
      return;
    }

    if (this.state.checked[checkedObj][elVal] === undefined || this.state.checked[checkedObj][elVal] === false) {
      this.setState((prevState) => ({
        checked: {
          ...prevState.checked,
          [checkedObj]: {
            ...prevState.checked[checkedObj],
            ...obj,
          },
        },
      }));
    }

    this.setState({
      [statePropName]: selectedOption,
    });
  };

  handleChange = (name, checked, checkedObj) => {
    this.setState((prevState) => ({
      checked: {
        ...prevState.checked,
        [checkedObj]: {
          ...prevState.checked[checkedObj],
          [name]: checked,
        },
      },
    }));
  };

  handleChangeRadio = (value, property) => {
    this.setState((prevState) => ({
      radio: {
        ...prevState.radio,
        [property]: {
          ...prevState.radio[property],
          [property]: value,
        },
      },
    }));
  };

  handleSingleApply = (type, queryString) => {
    const checkedObj = { ...this.state.checked[type] };
    const arrOfKeys = Object.keys(checkedObj);
    if (arrOfKeys.length === 0) {
      return;
    }
    const queryArr = [];
    for (const property in checkedObj) {
      if (checkedObj[property]) {
        queryArr.push(property);
      }
    }
    if (queryString !== "&location=") {
      if (queryString === "&skills=") {
        this.props.handleSearch(`${queryString}${queryArr.join(`${this.state.radio.checkedOperator.checkedOperator}`)}`, 0);
      } else {
        this.props.handleSearch(`${queryString}${queryArr.join("+")}`, 0);
      }
    } else if (queryString === "&location=") {
      const replaced = queryArr.map((el) => {
        return el.replaceAll(", ", "--").replaceAll(" - ", "--").replaceAll(" ", "-");
      });
      this.props.handleSearch(`${queryString}${replaced.join("+")}`, 0);
    }
  };

  handleApplyRadio = (type) => {
    const radioObj = { ...this.state.radio };
    let radioValue = radioObj[type][type];
    this.props.handleSearch(radioValue, 0);
  };

  handleApplyAll = () => {
    //Merge & Apply Checkboxes
    const checkedObj = { ...this.state.checked };
    let innerObj;
    let arr = [];
    let queryString = "";
    for (const checkedObjProp in checkedObj) {
      innerObj = checkedObj[checkedObjProp];
      let innerArr = [];
      for (const innerObjProp in innerObj) {
        if (innerObj[innerObjProp]) {
          innerArr.push(innerObjProp);
        }
      }
      if (checkedObjProp === "checkedSkills") {
        if (innerArr.length > 0) {
          queryString = "&skills=";
          arr.push([`${queryString}${innerArr.join(`${this.state.radio.checkedOperator.checkedOperator}`)}`]);
        }
      }
      if (checkedObjProp === "checkedLocation") {
        if (innerArr.length > 0) {
          queryString = "&location=";
          const replaced = innerArr.map((el) => {
            return el.replace(", ", "--").replace(" ", "-");
          });
          arr.push([`${queryString}${replaced.join("+")}`]);
        }
      }
      if (checkedObjProp === "checkedExperience") {
        if (innerArr.length > 0) {
          queryString = "&experience=";
          arr.push([`${queryString}${innerArr.join("+")}`]);
        }
      }
      if (checkedObjProp === "checkedCV") {
        if (innerArr.length > 0) {
          queryString = "&field_curriculum_vitae_op=";
          arr.push([`${queryString}${innerArr.join("+")}`]);
        }
      }
      if (checkedObjProp === "checkedCountries") {
        if (innerArr.length > 0) {
          queryString = "&country=";
          arr.push([`${queryString}${innerArr.join("+")}`]);
        }
      }
      if (checkedObjProp === "checkedSource") {
        if (innerArr.length > 0) {
          queryString = "&source=";
          arr.push([`${queryString}${innerArr.join("+")}`]);
        }
      }
      if (checkedObjProp === "checkedOpenProfile") {
        if (innerArr.length > 0) {
          queryString = "&field_third_party_communications=";
          arr.push([`${queryString}${innerArr.join("+")}`]);
        }
      }
    }
    //Apply Radio
    const radioObj = { ...this.state.radio };
    let status = radioObj.checkedStatus.checkedStatus;
    let created = radioObj.checkedCreated.checkedCreated;
    if (status) {
      arr.push([status]);
    } else if (created) {
      arr.push([created]);
    }
    //Apply Filter all
    this.props.handleSearch(arr.join(""), 0, "allFilter");
    this.handleClose();
  };

  handleClear = (checkedObj, defaultVal, clearContent, query) => {
    const queryArr = this.props.query.split("&");
    const remove = queryArr.filter((el) => !`&${el}`.includes(query));
    const mergeArr = remove.join("&");
    let checkedVals = { ...this.state.checked[checkedObj] };
    for (const property in checkedVals) {
      checkedVals[property] = false;
    }
    this.setState((prevState) => ({
      [clearContent]: [],
      checked: {
        ...prevState.checked,
        [checkedObj]: checkedVals,
      },
    }));
    this.props.handleSearch(mergeArr, this.props.page, "allFilter");
  };

  handleClearRadio = (property, defaultVal) => {
    let mergeArr;
    const checkedVal = this.state.radio[property][property];
    let queryArr = this.props.query.split("&");
    const isStatusAll = queryArr.includes(`connection_status_rejected_negate=${this.props.currentOrgId}`);
    if (isStatusAll) {
      const arrStatusAll = this.state.selectStatus[0].value.split("&");
      const result = queryArr.filter((el) => arrStatusAll.indexOf(el) === -1);
      result.splice(0, 0, "");
      mergeArr = result.join("&");
    } else {
      mergeArr = queryArr.filter((el) => !`&${el}`.includes(checkedVal)).join("&");
    }

    this.setState((prevState) => ({
      radio: {
        ...prevState.radio,
        [property]: {
          ...prevState.radio[property],
          [property]: defaultVal,
        },
      },
    }));
    this.props.handleSearch(mergeArr, this.props.page, "allFilter");
  };

  handleClearAll = () => {
    localStorage.removeItem("candidateFilterQuery");
    this.setState(defaultState(this.props.currentOrgId, this.props.initialData));
    this.props.handleSearch("&search=+", 0, "allFilter");
  };

  handleReset = () => {
    this.handleClearAll();
  };

  findActive = (checkedFilters) => {
    if (checkedFilters === "&connection_status_all=") {
      return false;
    }
    for (const item in checkedFilters) {
      if (checkedFilters[item]) {
        return true;
      }
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div style={{ width: "100%", position: "relative" }}>
        <Hidden xsDown>
          <Grid container={true} wrap="wrap" spacing={1} className={classes.filter}>
            <Grid item>
              <Popover
                id={"Skills"}
                type="checkedSkills"
                query={"&skills="}
                clearContent={"skills"}
                handleSingleApply={this.handleSingleApply}
                handleClear={this.handleClear}
                active={this.findActive(this.state.checked.checkedSkills)}
              >
                <SelectCheckbox
                  checked="checkedSkills"
                  statePropName="skills"
                  placeholder="Add Skills"
                  values={this.state.skills}
                  selectOptions={this.state.skillsList}
                  checkedObj={this.state.checked.checkedSkills}
                  handleSelect={this.handleSelect}
                  handleChange={this.handleChange}
                >
                  <RadioOptions values={selectOperator} value={this.state.radio.checkedOperator.checkedOperator} checked="checkedOperator" handleChangeRadio={this.handleChangeRadio} />
                </SelectCheckbox>
              </Popover>
            </Grid>
            <Grid item>
              <Popover
                id={"Countries"}
                type="checkedCountries"
                clearContent={"countries"}
                query={"&country="}
                handleSingleApply={this.handleSingleApply}
                handleClear={this.handleClear}
                active={this.findActive(this.state.checked.checkedCountries)}
              >
                <SelectCheckbox
                  checked="checkedCountries"
                  statePropName="countries"
                  placeholder="Add Countries"
                  values={this.state.countries}
                  selectOptions={this.state.countriesList}
                  checkedObj={this.state.checked.checkedCountries}
                  handleSelect={this.handleSelect}
                  handleChange={this.handleChange}
                />
              </Popover>
            </Grid>
            <Grid item>
              <Popover
                id={"Location"}
                type="checkedLocation"
                clearContent={"location"}
                query={"&location="}
                handleSingleApply={this.handleSingleApply}
                handleClear={this.handleClear}
                active={this.findActive(this.state.checked.checkedLocation)}
              >
                <SearchLocation
                  statePropName="location"
                  checked="checkedLocation"
                  values={this.state.location}
                  checkedObj={this.state.checked.checkedLocation}
                  handleSelect={this.handleSelect}
                  handleChange={this.handleChange}
                />
              </Popover>
            </Grid>
            <Grid item>
              <Popover
                id={"Experience"}
                type="checkedExperience"
                query={"&experience="}
                handleSingleApply={this.handleSingleApply}
                handleClear={this.handleClear}
                active={this.findActive(this.state.checked.checkedExperience)}
              >
                <CheckboxFilter
                  title="Experience"
                  checked="checkedExperience"
                  values={this.state.experienceList}
                  checkedObj={this.state.checked.checkedExperience}
                  handleChange={this.handleChange}
                  handleClear={this.handleClear}
                />
              </Popover>
            </Grid>
            <Grid item>
              <Popover
                id={"Status"}
                type="checkedStatus"
                default={this.state.radio.checkedStatus.checkedStatusDefault}
                handleSingleApply={() => this.handleApplyRadio("checkedStatus")}
                handleClear={this.handleClearRadio}
                active={this.findActive(this.state.radio.checkedStatus.checkedStatus)}
              >
                <RadioFilter values={this.state.selectStatus} checked="checkedStatus" value={this.state.radio.checkedStatus.checkedStatus} handleChangeRadio={this.handleChangeRadio} />
              </Popover>
            </Grid>
            <Grid item>
              <Popover
                id={"New Profiles"}
                type="checkedCreated"
                default={this.state.radio.checkedCreated.checkedCreatedDefault}
                handleSingleApply={this.handleApplyRadio}
                handleClear={this.handleClearRadio}
                active={this.findActive(this.state.radio.checkedCreated.checkedCreated)}
              >
                <RadioFilter values={this.state.selectMonth} checked="checkedCreated" value={this.state.radio.checkedCreated.checkedCreated} handleChangeRadio={this.handleChangeRadio} />
              </Popover>
            </Grid>
            <Grid item>
              <Popover
                id={"CV"}
                type="checkedCV"
                query={"&field_curriculum_vitae_op="}
                handleSingleApply={this.handleSingleApply}
                handleClear={this.handleClear}
                active={this.findActive(this.state.checked.checkedCV)}
              >
                <CheckboxFilter title="" checked="checkedCV" values={this.state.cvList} checkedObj={this.state.checked.checkedCV} handleChange={this.handleChange} handleClear={this.handleClear} />
              </Popover>
            </Grid>
            {/* 
            <Grid item>
              <Popover
                id={"Source"}
                type="checkedSource"
                query={"&source="}
                clearContent={"source"}
                handleSingleApply={this.handleSingleApply}
                handleClear={this.handleClear}
                active={this.findActive(this.state.checked.checkedSource)}
              >
                <SelectCheckbox
                  checked="checkedSource"
                  statePropName="source"
                  placeholder="Add Source"
                  values={this.state.source}
                  selectOptions={this.state.sourceList}
                  checkedObj={this.state.checked.checkedSource}
                  handleSelect={this.handleSelect}
                  handleChange={this.handleChange}
                ></SelectCheckbox>
              </Popover>
            </Grid>
             */}
            <Grid item>
              <Popover
                id={"Open Profiles"}
                type="checkedOpenProfile"
                query={"&field_third_party_communications="}
                handleSingleApply={this.handleSingleApply}
                handleClear={this.handleClear}
                active={this.findActive(this.state.checked.checkedOpenProfile)}
              >
                <CheckboxFilter
                  title=""
                  checked="checkedOpenProfile"
                  values={this.state.openProfileList}
                  checkedObj={this.state.checked.checkedOpenProfile}
                  handleChange={this.handleChange}
                  handleClear={this.handleClear}
                />
              </Popover>
            </Grid>
            <Grid item>
              <Button className={classes.allBtn} onClick={this.handleOpen}>
                All Filters
              </Button>
            </Grid>
          </Grid>
          <Grid container={true} wrap="nowrap" spacing={1} className={classes.filter}></Grid>
        </Hidden>
        <Hidden smUp>
          <Button className={classes.allBtnMobile} onClick={this.handleOpen} variant="contained" color="primary" fullWidth>
            All Filters
          </Button>
        </Hidden>
        {this.props.candidates && this.props.candidates.length >= 0 ? (
          <Grid container item xs={12}>
            <Grid item container justify="space-between" alignItems="center" xs={12}>
              {this.props.candidates.length === 0 && `No result found`}
              {this.props.candidates.length > 0 && `${this.props.totalCandidates} ${this.props.totalCandidates > 1 ? "results" : "result"} found`}
              {this.props.query && (
                <Button
                  onClick={() => {
                    this.handleReset();
                  }}
                >
                  Reset filters{" "}
                </Button>
              )}
            </Grid>
          </Grid>
        ) : null}
        <Modal open={this.state.open} onClose={this.handleClose} className={classes.modal}>
          <div className={classes.modalContent}>
            <div className={classes.filterHeader}>
              <Grid container justify="center" spacing={2}>
                <Grid item>
                  <Button variant="contained" color="primary" onClick={this.handleApplyAll}>
                    Apply
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={this.handleClearAll}>Clear</Button>
                </Grid>
              </Grid>
              <IconButton className={classes.close} onClick={this.handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <Grid container spacing={2} className={classes.allFilterContainer}>
              <Grid item xs={12} md={6} lg={4}>
                <SelectCheckbox
                  title="Countries"
                  checked="checkedCountries"
                  statePropName="countries"
                  placeholder="Add Countries"
                  values={this.state.countries}
                  selectOptions={this.state.countriesList}
                  checkedObj={this.state.checked.checkedCountries}
                  handleSelect={this.handleSelect}
                  handleChange={this.handleChange}
                  handleClear={this.handleClear}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <SearchLocation
                  title="Location"
                  statePropName="location"
                  checked="checkedLocation"
                  stateArr={this.state.location}
                  values={this.state.location}
                  checkedObj={this.state.checked.checkedLocation}
                  handleSelect={this.handleSelect}
                  handleChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <SelectCheckbox
                  title="Skills"
                  checked="checkedSkills"
                  statePropName="skills"
                  placeholder="Add Skills"
                  values={this.state.skills}
                  selectOptions={this.state.skillsList}
                  checkedObj={this.state.checked.checkedSkills}
                  handleSelect={this.handleSelect}
                  handleChange={this.handleChange}
                  handleClear={this.handleClear}
                >
                  <RadioOptions values={selectOperator} value={this.state.radio.checkedOperator.checkedOperator} checked="checkedOperator" handleChangeRadio={this.handleChangeRadio} />
                </SelectCheckbox>
              </Grid>
              <Grid item xs={12} md={6}>
                <RadioFilter title="Status" values={this.state.selectStatus} checked="checkedStatus" value={this.state.radio.checkedStatus.checkedStatus} handleChangeRadio={this.handleChangeRadio} />
              </Grid>
              <Grid item xs={12} md={6}>
                <CheckboxFilter
                  title="Experience"
                  checked="checkedExperience"
                  values={this.state.experienceList}
                  checkedObj={this.state.checked.checkedExperience}
                  handleChange={this.handleChange}
                  handleClear={this.handleClear}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CheckboxFilter title="CV" checked="checkedCV" values={this.state.cvList} checkedObj={this.state.checked.checkedCV} handleChange={this.handleChange} handleClear={this.handleClear} />
              </Grid>
              <Grid item xs={12} md={6}>
                <RadioFilter
                  title="New Profiles"
                  values={this.state.selectMonth}
                  checked="checkedCreated"
                  value={this.state.radio.checkedCreated.checkedCreated}
                  handleChangeRadio={this.handleChangeRadio}
                />
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <SelectCheckbox
                  title="Candidate Source"
                  checked="checkedSource"
                  statePropName="source"
                  placeholder="Add Source"
                  values={this.state.source}
                  selectOptions={this.state.sourceList}
                  checkedObj={this.state.checked.checkedSource}
                  handleSelect={this.handleSelect}
                  handleChange={this.handleChange}
                ></SelectCheckbox>
              </Grid> */}
              <Grid item xs={12} md={6}>
                <CheckboxFilter
                  title="Open profiles"
                  checked="checkedOpenProfile"
                  values={this.state.openProfileList}
                  checkedObj={this.state.checked.checkedOpenProfile}
                  handleChange={this.handleChange}
                  handleClear={this.handleClear}
                />
              </Grid>
            </Grid>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(CandidateJobFilter);
