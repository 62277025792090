import React, { useState, Fragment } from "react";
import { withFormik } from "formik";
import DropUploader from '../../../Utilities/DropUploader';
import ImageUploader from '../../../Utilities/ImageUploader';
import * as Yup from "yup";
import { 
  withStyles, 
  Card,
  TextField, 
  Button,
  Grid,
  Typography,
  Tooltip,
  Radio,
  FormControlLabel
} from "@material-ui/core";
import RichTextEditor from '../../../RichTextEditor/RichTextEditor';
import LocationTextField from '../../../Filters/SearchLocation/LocationTextField';
import RadioButtons from '../FormItems/RadioButtons';
import HelpIcon from '@material-ui/icons/Help'; 

const styles = (theme) => ({
  card: {
    marginBottom: 50,
    padding: 20
  },
  container: {
    display: "Flex",
    justifyContent: "center"
  },
  tooltip: {
    marginLeft: '5px'
  },
  actions: {
    padding: `${theme.spacing(2)}px 0`
  }
});

const tooltipText = "Companies will be able to view your profile and contact information only after they request an introduction which has to be confirmed by you. We will send you requests to your email address where you can decide to accept or decline";

const FormContent = props => {
  const { 
    classes,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    profileImage,
    selectedCountry,
    handleSelected,
    countries,
    createImageEntity,
    deleteImageEntity,
    initialData
  } = props;
  
  const uploadFile = (file, type) => {
    createImageEntity(file, type)
  }

  const removeFile = (type) => {
    deleteImageEntity(type)
  }

  const [mbLimit, setMbLimit] = useState(false);
  const [sizeLimitLogo, setSizeLimitLogo] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const onOpenTooltip = () => {
    setTooltipOpen(true)
  }

  const onCloseTooltip = () => {
    setTooltipOpen(false)
  }


  const createRadio = (data, tooltip) => {
    const result = Object.keys(data).map((el,index) => {
      let found;
      let tooltipText; 
      if(tooltip){
        found =  tooltip.find(el => el.index === index)
        if(found){
          tooltipText = tooltip[index]['text']
        }
      }
      return (
        <Fragment key={index}>
        <FormControlLabel 
          value={el} 
          control={<Radio />} 
          label={
              <Typography variant="caption" color="textPrimary">{data[el]}</Typography>
          } 
        />
        {tooltipText && (
          <Tooltip title={tooltipText} open={tooltipOpen} onClose={onCloseTooltip} onOpen={onOpenTooltip}>
            <HelpIcon onClick={onOpenTooltip} style={{ fontSize: 15 }} />
          </Tooltip>
        )}
        </Fragment>
      ) 
    })
    return result;
  }
  const thirdParty = createRadio(initialData.thirdParty, [{text: tooltipText, index: 0}])
  const visible = createRadio(initialData.visible);

  const setLocationValues = (city, state, country, countryString) => {
    let attributes = {};
    let relationships = {field_candidate_country: {data: null}};
    if(state){
      attributes['field_city_text'] = city
      attributes['field_state'] = state
      relationships['field_candidate_country']['data'] = [country]
    }else{
      attributes['field_city_text'] = city
      attributes['field_state'] = ""
      relationships['field_candidate_country']['data'] = [country]
    }
    setFieldValue('field_city_text' , city);
    handleSelected("selectedCountry", countryString)
    if(props.isEdit){
      props.mergeAndSubmitLocation(attributes, relationships)
    }else{
      props.mergeFormData( "attributes", attributes);
      props.mergeFormData( "relationships", relationships);
    }
  }

  const handleRemoveLocation = () => {
    setFieldValue('field_city_text' , "");
  }

  const setTextValues = (e) => {
    const field = e.target.id;
    const value = e.target.value;
    handleBlur(e)
    if(Object.keys(errors).length && props.isEdit){
      return
    }
    props.mergeFormData( "attributes", {[field]: value});
  }

  const rteChangeExperience = (event, editor, id) => {
    const data = editor.getData();
    setFieldValue(`field_short_bio` , data);
    if(!data){
      return
    }
    props.mergeFormData( "attributes", {[id]: data})
  }
  
  const handleRadio = (e, field) => {
    const data = isNaN(e.target.value) ? e.target.value : Number(e.target.value);
    setFieldValue(field , data);
    props.mergeFormData( "attributes", {[field]: data});
  }

  return (
      <form onSubmit={handleSubmit} className={classes.container}>
        <Card className={classes.card}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ImageUploader
                title={"User Profile Picture"}
                caption={"Please upload profile picture in .jpg .png or .gif format (min 200X200px and less than 15MB)"}
                color={mbLimit || sizeLimitLogo ?  "error" : "textPrimary"}
                setSizeLimit={{func: setSizeLimitLogo, width: 200, height: 200 }}
                setMbLimit={{func: setMbLimit, mbLimit: 15 }}   
                uploadFile={uploadFile}
                removeFile={removeFile}
                type="field_profile_picture"
                initialData={profileImage}
                removeInitialData={removeFile}  
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                  id="field_first_name"
                  label="First Name*"
                  value={values.field_first_name}
                  onChange={handleChange}
                  onBlur={(e) => setTextValues(e)}
                  helperText={touched.field_first_name ? errors.field_first_name : ""}
                  error={touched.field_first_name && Boolean(errors.field_first_name)}
                  margin="dense"
                  variant="outlined"
                  fullWidth
                />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="field_last_name"
                label="Last Name*"
                value={values.field_last_name}
                onChange={handleChange}
                onBlur={(e) => setTextValues(e)}
                helperText={touched.field_last_name ? errors.field_last_name : ""}
                error={touched.field_last_name && Boolean(errors.field_last_name)}
                margin="dense"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="field_phone_number"
                label="Phone"
                value={values.field_phone_number}
                onChange={handleChange}
                onBlur={(e) => setTextValues(e)}
                helperText={touched.field_phone_number ? errors.field_phone_number : ""}
                error={touched.field_phone_number && Boolean(errors.field_phone_number)}
                margin="dense"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}> 
              <LocationTextField
                placeholder = "Your Location*"
                id="field_city_text"
                onBlur={handleBlur}
                helperText = {touched.field_city_text ? errors.field_city_text : ""}
                error={touched.field_city_text && Boolean(errors.field_city_text)}
                handleLocation={setLocationValues}
                handleRemoveLocation={handleRemoveLocation} 
                initialData={countries}
                initialCountry={selectedCountry}
                initialState={props.defaultVal.attributes.field_state}
                initialCity={props.defaultVal.attributes.field_city_text}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">Short Bio*</Typography>
              <RichTextEditor
                  id="field_short_bio"
                  onBlur={handleBlur}
                  helperText = {touched.field_short_bio ? errors.field_short_bio : ""}
                  error={touched.field_short_bio && Boolean(errors.field_short_bio)} 
                  data={props.defaultVal.attributes.field_short_bio} 
                  rteChange={(event, editor, id) => rteChangeExperience(event, editor, id) }
              />
            </Grid>
            <Grid item xs={12}>
              <RadioButtons 
                title="Companies can view my profile data & CV *" 
                handleChange={handleRadio} 
                field="field_third_party_communications"
                value={(values.field_third_party_communications).toString()}
                error={(touched.field_third_party_communications && errors.field_third_party_communications)}
              >
              {thirdParty.reverse()}
              </RadioButtons>
            </Grid>
            <Grid item xs={12}>
              <RadioButtons 
                title="Profile Visibility in Search (Name & Picture)" 
                handleChange={handleRadio} 
                field="field_name_visible_to_companies"
                value={(values.field_name_visible_to_companies).toString()}
                error={(touched.field_name_visible_to_companies && errors.field_name_visible_to_companies)}
              >
              {visible.reverse()}
              </RadioButtons>
            </Grid>
          </Grid>
          {!props.isEdit ? (
              <div className={classes.actions}>
                <Button type="submit" variant="contained" fullWidth>Continue</Button>
              </div>
            ):(
              <div className={classes.actions}>
                <Button variant="contained" fullWidth onClick={() => props.next()}>Next</Button>
              </div>
          )}
        </Card>
      </form>
  );
};

const Form = withFormik({
  mapPropsToValues: ({ 
    profile_picture_preview,
    field_profile_picture,
    defaultVal
  }) => {
    return {
      field_first_name: defaultVal.attributes.field_first_name ? defaultVal.attributes.field_first_name : "",
      field_last_name: defaultVal.attributes.field_last_name ? defaultVal.attributes.field_last_name : "",
      field_phone_number: defaultVal.attributes.field_phone_number ? defaultVal.attributes.field_phone_number : "",
      profile_picture_preview: profile_picture_preview || "",
      field_profile_picture: defaultVal.relationships.field_profile_picture.data ?  defaultVal.relationships.field_profile_picture.data : "",
      field_short_bio: defaultVal.attributes.field_short_bio	 ? defaultVal.attributes.field_short_bio	 : "",
      field_city_text: defaultVal.attributes.field_city_text ? defaultVal.attributes.field_city_text : "",
      field_state: defaultVal.attributes.field_state ? defaultVal.attributes.field_state : "",
      country: defaultVal.relationships.field_candidate_country.data[0] ? defaultVal.relationships.field_candidate_country.data[0] : "",
      field_third_party_communications: defaultVal.attributes.field_third_party_communications === 0 || defaultVal.attributes.field_third_party_communications === 1 ? defaultVal.attributes.field_third_party_communications : "",
      field_name_visible_to_companies: defaultVal.attributes.field_name_visible_to_companies ?  1 : 0 
    };
  },

  validationSchema: Yup.object().shape({
    field_first_name: Yup.string().required("Required"),
    field_last_name: Yup.string().required("Required"),
    field_phone_number: Yup.number().typeError('Phone must be a number'),
    field_city_text: Yup.string().required("Required. Please select a location from the autocomplete dropdown"),
    field_short_bio: Yup.string().required("Required"),
    field_third_party_communications: Yup.number().required("Required")
  }),

  handleSubmit: (values, { props, setSubmitting, setValues, setFieldValue }) => {

    const email = localStorage.getItem("email");
    let attributes = {
      title: `${values.field_first_name} ${values.field_last_name}`,
      field_contact_email: email,
    }
    if(!props.isEdit) {
      props.mergeFormData('attributes', attributes);
    }
    props.next()
  }
})(FormContent);

export default withStyles(styles)(Form);
