import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid, CircularProgress } from "@material-ui/core";
import OrganizationForm from "../AddOrganization/OrganizationForm";
import oauthConfig from "../../../oauthConfig";
import axios from "axios";
import parse from "html-react-parser";

class EditOrganization extends Component {
  state = {
    loading: true,
    field_logo: null,
    field_page_title_background: null,
    field_culture_images: null,
    ids_delete_files: [],
    selectedTechnicalSkills: null,
    selectedBenefits: null,
    selectedIndustry: null,
    selectedCountry: null,
    initialData: {
      country: this.props.initialData.country,
      benefits: this.props.initialData.benefits,
      techSkills: this.props.initialData.techSkills,
      industry: this.props.initialData.industry,
      size: this.props.initialData.size,
      currency: this.props.initialData.currency,
    },
    orgData: {},
  };

  componentDidMount() {
    this.fetchData().then(() => this.setState({ loading: false }));
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState({ loading: true });
      this.fetchData().then(() => this.setState({ loading: false }));
    }
  }

  setStringFields = (fields, orgData) => {
    return fields.reduce(
      (data, field) => ({
        ...data,
        [field]: orgData[field] && orgData[field],
      }),
      {}
    );
  };

  setRichTextFields = (fields, orgData) => {
    return fields.reduce(
      (data, field) => ({
        ...data,
        [field]: orgData[field] && { value: orgData[field], format: "basic_html" },
      }),
      {}
    );
  };

  setSelectedFields = (field, orgData, item) => {
    if (!orgData[field]) {
      return;
    }
    if (orgData[field].includes("&amp;")) {
      orgData[field] = parse(orgData[field]);
    }
    const data = orgData[field].split(";");
    return this.state.initialData[item].filter((el) => data.includes(el.label));
  };

  fetchData = async () => {
    const organizations = [...this.props.organizations];
    const item = organizations.find((el) => el.nid == this.props.match.params.id);
    const currentOrg = { ...item };
    const stringFileds = [
      "title",
      "field_contact_email",
      "field_hires_needed",
      "field_phone_number",
      "field_website_url",
      "field_linkedin_profile",
      "field_twitter_profile",
      "field_facebook_profile",
      "field_video",
      "field_company_size",
    ];
    const richTextFileds = ["field_company_description", "field_what_makes_your_company_un", "field_video_text", "field_tech_challenges", "field_culture_description"];
    const stringAttributes = this.setStringFields(stringFileds, currentOrg);
    const richTextAttributes = this.setRichTextFields(richTextFileds, currentOrg);
    let videosAttributes = [];
    if (currentOrg.field_video.length) {
      const videosArr = currentOrg.field_video.split(", ");
      const videoData = videosArr.map((input) => {
        const video_id = input.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?([a-zA-Z0-9_-]{11})(?![a-zA-Z0-9_-])/);
        return {
          input,
          video_id: !video_id ? "" : video_id[1],
        };
      });
      videosAttributes = videoData;
    }
    const relationships = {};
    let field_logo = item.field_logo ? { path: item.field_logo } : { path: null };
    let field_page_title_background = item.field_page_title_background ? { path: item.field_page_title_background } : { path: null };
    let field_culture_images = [{ path: null }];
    if (item.field_culture_images) {
      const images = JSON.parse(item.field_culture_images);
      field_culture_images = images.map((image) => {
        return { type: "file--file", path: image.url, id: image.uuid };
      });
      field_culture_images.push({ path: null, id: null });
      const files = { data: field_culture_images.filter((file) => file.id) };
      relationships.field_culture_images = files;
    }
    const techSkillsSelected = this.setSelectedFields("field_technical_skills", currentOrg, "techSkills");
    const benefitsSelected = this.setSelectedFields("field_benefits_name", currentOrg, "benefits");
    const industrySelected = this.setSelectedFields("field_industry", currentOrg, "industry");
    const selectedCountry = this.setSelectedFields("field_hiring_location", currentOrg, "country");
    const selectedCurrency = this.state.initialData.currency.find((el) => el.value === item.field_currency);

    const attributes = { ...stringAttributes, ...richTextAttributes };
    attributes.field_video = videosAttributes;
    currentOrg.attributes = attributes;
    currentOrg.relationships = relationships;

    this.setState({
      orgData: currentOrg,
      field_logo,
      field_page_title_background,
      field_culture_images,
      techSkillsSelected,
      benefitsSelected,
      industrySelected,
      selectedCountry,
      selectedCurrency,
    });
  };

  setHeaders = () => {
    let token = this.props.token;

    let headers = {
      headers: {
        Accept: "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
        Authorization: `${token.token_type} ${token.access_token}`,
      },
    };

    return headers;
  };

  handleProfilePreview = (path, field) => {
    this.setState({
      [field]: { path },
    });
  };

  handleMultiplePreview = (path, field, index) => {
    const fileArr = [...this.state[field]];
    fileArr[index].path = path;
    if (path !== "loading") {
      fileArr.push({ path: null });
    }
    const newState = {
      ...this.state,
      [field]: fileArr,
    };
    this.setState(newState);
  };

  handleMultipleDelete = (path, field, index) => {
    const fileArr = [...this.state[field]];
    fileArr.splice(index, 1);
    const newState = {
      ...this.state,
      [field]: fileArr,
    };
    this.setState(newState);
  };

  handleData = (key, data) => {
    this.setState((prevState) => ({
      orgData: {
        ...prevState.orgData,
        [key]: {
          ...prevState.orgData[key],
          ...data,
        },
      },
    }));
  };

  createImageEntity = (file, field, index) => {
    index >= 0 ? this.handleMultiplePreview("loading", field, index) : this.handleProfilePreview("loading", field);
    var imagefile = file;
    let token = this.props.token;
    fetch(`${oauthConfig.baseUrl}/api/node/organization_profile/${field}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/octet-stream",
        Accept: "application/vnd.api+json",
        "Content-Disposition": `file; filename="${imagefile.name}"`,
        Authorization: `${token.token_type} ${token.access_token}`,
      },
      body: imagefile,
    })
      .then((response) => response.json())
      .then((success) => {
        index >= 0 ? this.handleMultiplePreview(success.data.attributes.uri.url, field, index) : this.handleProfilePreview(success.data.attributes.uri.url, field);
        let selectedID = { type: success.data.type, id: success.data.id };
        let data = index >= 0 ? { [field]: { data: [selectedID] } } : { [field]: { data: selectedID } };
        if (this.state.orgData.relationships[field] && index >= 0) {
          data = { [field]: { data: [...this.state.orgData.relationships[field].data, selectedID] } };
        }
        this.handleData("relationships", data);
      })
      .catch((error) => console.log(error, "error"));
  };

  deleteImageEntity = (field, index) => {
    index >= 0 ? this.handleMultipleDelete(null, field, index) : this.handleProfilePreview(null, field);
    let data = { [field]: { data: null } };
    if (this.state.orgData.relationships[field] && index >= 0) {
      const files = [...this.state.orgData.relationships[field].data];
      files.splice(index, 1);
      const filesWithId = files.filter((file) => file.id);
      data = { [field]: { data: filesWithId } };
      //   data = {[field]: {data:  [...this.state.orgData.relationships[field].data.splice(index, 1)] }}
    }
    this.handleData("relationships", data);
  };

  handleFormSubmit = () => {
    const orgData = {
      id: this.state.orgData.uuid,
      type: "node--employer_profile",
      attributes: { ...this.state.orgData.attributes },
      relationships: { ...this.state.orgData.relationships },
    };
    this.setState({ orgData });
    this.saveProfile();
  };

  saveProfile = () => {
    //if filed video is a string do not pacth it and keep it as it is on BE
    if (typeof this.state.orgData.attributes.field_video === "string") {
      delete this.state.orgData.attributes.field_video;
    }

    let headers = this.setHeaders();
    axios
      .patch(`${oauthConfig.baseUrl}/api/node/organization_profile/${this.state.orgData.id}`, { data: this.state.orgData }, headers)
      .then((res) => {
        if (this.props.all) {
          this.props.fetchOrg();
          this.props.history.push(`/organization/all`);
        } else {
          this.props.orgActive(this.state.orgData.id);
          // this.props.fetchOrg()
          this.props.history.push(`/organization/edit/${this.props.match.params.id}`);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          this.props.logout();
        }
      });
  };

  render() {
    return this.state.loading ? (
      <Grid container direction="row" justify="center" alignItems="center">
        <CircularProgress color="secondary" />
      </Grid>
    ) : (
      <OrganizationForm
        formEdit
        defaultValues={this.state.orgData}
        initialData={this.state.initialData}
        techSkillsSelected={this.state.techSkillsSelected}
        benefitsSelected={this.state.benefitsSelected}
        industrySelected={this.state.industrySelected}
        selectedCountry={this.state.selectedCountry}
        selectedCurrency={this.state.selectedCurrency}
        logo={this.state.field_logo}
        background={this.state.field_page_title_background}
        culture={this.state.field_culture_images}
        handleData={this.handleData}
        createImageEntity={this.createImageEntity}
        deleteImageEntity={this.deleteImageEntity}
        handleFormSubmit={this.handleFormSubmit}
      />
    );
  }
}

export default withRouter(EditOrganization);
