import React , { Component } from 'react';
import Layout from '../../Layout/Layout'
import { Route, Switch, Redirect } from 'react-router-dom';
// import Jobs from '../../JobsCandidate/Jobs';
import JobsWrapper from '../../JobsCandidate/JobsWrapper';
import EditProfile from '../../Settings/Profile/EditProfile';
import Profile from '../../Profile/Candidate/CandidateProfile';
import ChangePassword from '../../Settings/Profile/ChangePassword/ChangePassword';
import Loader from '../../Utilities/Loader/Loader';
import oauthConfig from '../../../oauthConfig';
import axios from 'axios';
import Organizations from '../../OrganizationsCandidate/Organizations';

class Candidate extends Component {
    state = {
        userData: null
    }

    componentDidMount(){
        this.fetchProfileData()
    }

    setHeaders = () => {
        if(!this.props.token){
            this.props.logout();
            return
        }
        const token = this.props.token;
    
        const headers = {
          headers: {
          'Accept': 'application/vnd.api+json',
          'Content-Type': 'application/vnd.api+json',
          'Authorization': `${token.token_type} ${token.access_token}`
          }
        }
        return headers
    }

    fetchProfileData = async () => {
        const headers = this.setHeaders();
        const result = await axios( 
            `${oauthConfig.baseUrl}/api/node/candidate_profile?filter[uid.id][value]=${this.props.uuid}&include=field_candidate_country,field_curriculum_vitae,field_profile_picture,field_personal_skills,field_technical_skills`, headers
        );
        
        let includesObj = {};
        let technicalArr = [];
        let personalArr = [];
        result.data.included.forEach(el => {
            if(el.type === "taxonomy_term--country"){
                includesObj["country"] = el.attributes.name
            }
            if(el.type === "file--file"){
                if(el.attributes.filemime === "application/pdf"){
                    includesObj["pdf"] = el.attributes.uri.url
                }else{
                    includesObj["picture"] = el.attributes.uri.url
                }
            }
            if(el.type === "taxonomy_term--personal_skills"){
                personalArr.push(el.attributes.name)
                includesObj["personal"] = personalArr
            }
            if(el.type === "taxonomy_term--technical_skills"){ 
                technicalArr.push(el.attributes.name)
                includesObj["technical"] = technicalArr
            }  
        });
        this.setState({
            userData: {
                attributes: {...result.data.data[0].attributes},
                includes: {...includesObj}
            }
        })
    };

    render(){
        return (
            <Layout 
                {...this.props}
                role="candidate"
                logo={null}
                appBarColor = {'#ffff'}
                organizations={[]}
                userData={this.state.userData}
                orgID={"candidate"}
            >
                <Switch>
                    <Route path="/profile" render={(props) => (
                        this.props.initialData && this.state.userData ? <Profile {...props}{...this.props} {...this.state} fetchProfileData={this.fetchProfileData}  role="candidate"/> : <Loader/>
                        )} 
                    /> 
                    <Route path="/organizations" render={(props) => <Organizations {...props} {...this.props}/>} />
                    <Route path="/jobs" render={(props) => this.props.initialData ? <JobsWrapper {...props} {...this.props}/> : <Loader/> }/>
                    <Route path="/user/edit" render={(props) => this.props.initialData ? <EditProfile {...props}{...this.props} fetchProfileData={this.fetchProfileData}  role="candidate"/> : <Loader/>} />
                    <Route path="/password" render={(props) => <ChangePassword {...this.props} {...props} /> } />
                    <Redirect to="/jobs"/>
                </Switch> 
            </Layout>
        )
    }
}

export default Candidate;
