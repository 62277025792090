import React from 'react';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

const styles = (theme) => ({
    link: {
        color: theme.palette.common.white,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        textDecoration: "none",
        fontSize: "11px",
        '&:hover': {
            background: theme.palette.background.darkBlueGreyWhiter,
        },
        '&.active': {
            background: theme.palette.primary.main,
        }
    },

    disabled : {
        color: theme.palette.grey[600]
    }

});

// const linkActive = {
//     background
// }

const asideNavigationItem = (props) => {

const { classes } = props;

const handleClick = (e) => {
    if(props.disabled){
        e.preventDefault()
    }
}

return (
        <NavLink 
            to={props.to} 
            className={clsx(classes.link, {[classes.disabled]: props.disabled })}
            activeClassName="active"
            onClick={handleClick} 
            >
            {props.children}
        </NavLink>
    )
}

export default withStyles(styles)(asideNavigationItem);