import React, { Component, Fragment } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import Drawer from '../../Drawer/Drawer';
import Greenhouse from './Greenhouse/Greenhouse';

const styles = theme => ({
    root: {
        padding: `0 ${theme.spacing(4)}px`,
    }
});

class Settings extends Component {
    state = {
    
    }
    
    render(){
        const { classes, ...other } = this.props;

        const drawerItems = [
            {
                text: "Greenhouse",
                icon: "sync",
                pathTo: "/settings/greenhouse" 
            }
        ]

        return (
            <Drawer drawerItems={drawerItems}>
                <div className={classes.root}>
                        <Switch>
                            <Route exact path="/settings/greenhouse" render={()=> <Greenhouse currentOrg = {this.props.currentOrg.attributes.drupal_internal__nid} token={this.props.token}/>}/>
                            <Redirect to="/settings/greenhouse" /> 
                        </Switch>
                </div>
            </Drawer>
        )
    } 
}

export default withStyles(styles)(Settings);