import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Profile from './Profile';
import TrialProfile from './TrialProfile';
import Loader from '../Utilities/Loader/Loader';
import oauthConfig from '../../oauthConfig';
import axios from 'axios';
import queryString from 'query-string';

class CandidateProfile extends Component {
    state = {
        userData : null,
        page: 0
    }

    componentDidMount(){
        this.fetchProfile().catch( err => console.log(err))
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.fetchProfile()
        }
      }

    fetchProfile = async() => {
        this.setState({userData : null})
        const token = this.props.token;
        const nid = this.props.currentOrg.nid;
        let uuid
        if(this.props.match.params.id){
            uuid = this.props.match.params.id
        }else{
            uuid = this.props.uuid
        }
        
        const headers = { 
          headers: {
          'Accept': 'application/vnd.api+json',
          'Content-Type': 'application/vnd.api+json',
          'Authorization': `${token.token_type} ${token.access_token}` 
          }
        }
        let exportView = this.props.candidateDB ? 'export' : 'demo_export' ;
        let path = this.props.candidateDB ? '/api/node/candidate_profile/' : `/api/candidates/${exportView}/`;

        let candidates;
        let uuids;
        let currentIndex;
        let search;
        if(this.props.candidateDB){
            const parsed = queryString.parse(this.props.location.search);
            parsed.page = this.state.page;
            delete parsed.search;
            search = `&search=+&${queryString.stringify(parsed)}`;
        }else{
            search = this.props.location.search.replace("?", "");
        }
        if(this.props.match.params.id){
            candidates = await axios.get(`${oauthConfig.baseUrl}/api/candidates/${exportView}/?_format=json${search}&org_id=${nid}&connection_status_connected=${nid}`, headers);
            uuids = candidates.data.map(el => el.node_uuid)
            currentIndex = uuids.findIndex(el => el === uuid)
            //recursion until finding a specific uuid
            if(currentIndex === -1){
                this.setState(prevState => ({
                    page: prevState.page + 1
                }))
                this.fetchProfile()
                return
            }
        }

        const result = await axios( 
            `${oauthConfig.baseUrl}${path}${uuid}?org_id=${nid}`, headers
        ).catch(err => {
            if(err.response.status === 403){
                return null
            }
        })
        
        const include = await axios( 
            `${oauthConfig.baseUrl}${path}${uuid}?org_id=${nid}&include=field_candidate_country,field_curriculum_vitae,field_profile_picture,field_personal_skills,field_technical_skills`, headers
        ).catch(err => {
            if(err.response.status === 403){
                return null
            }
        })
        
        if(result && include && this.props.candidateDB){

            let includesObj = {};
            let technicalArr = [];
            let personalArr = [];
        
            include.data.included.forEach(el => {
                if(el.type === "taxonomy_term--country"){
                    includesObj["country"] = el.attributes.name
                }
                if(el.type === "file--file"){
                    if(el.attributes.filemime === "application/pdf"){
                        includesObj["pdf"] = el.attributes.uri.url
                    }else{
                        includesObj["picture"] = el.attributes.uri.url
                    }
                }
                if(el.type === "taxonomy_term--personal_skills"){
                    personalArr.push(el.attributes.name)
                    includesObj["personal"] = personalArr
                }
                if(el.type === "taxonomy_term--technical_skills"){ 
                    technicalArr.push(el.attributes.name)
                    includesObj["technical"] = technicalArr
                }  
            });

            this.setState({
                uuids,
                currentIndex,
                userData: {
                    attributes: result && {...result.data.data.attributes},
                    includes: {...includesObj}
                }
            })

        }else{
            this.setState({
                uuids,
                currentIndex,
                userData: result && {...result.data[0]}
            })
            // this.props.history.push('/dashboard')
        }
    }

    goToProfile = (direction) => {
        const search = this.props.location.search;
        if(direction === "next"){
            let nextIndex;
            if(this.state.currentIndex === (this.state.uuids.length - 1)){
                nextIndex = this.state.uuids[0]
            }else{
                nextIndex = this.state.uuids[this.state.currentIndex + 1]
            }
            this.props.history.push(`/candidates/filter/${nextIndex}${search}`)
        }
        if(direction === "prev"){
            let prevIndex;
            if(this.state.currentIndex === 0){
                prevIndex = this.state.uuids[this.state.uuids.length - 1]
            }else{
                prevIndex = this.state.uuids[this.state.currentIndex - 1]
            }
            this.props.history.push(`/candidates/filter/${prevIndex}${search}`)
        }
    }
    
    goBack = () => {
        const search = this.props.location.search;
        this.props.history.push(`/candidates/filter${search}`);
    }

    render(){
        const { candidateDB,  initialData } = this.props
        let profile = null;
        if(this.state.userData) {
            profile = candidateDB ? (
                <Profile
                    goBack={this.goBack}
                    initialData={initialData} 
                    userData={this.state.userData} 
                    goToProfile={this.goToProfile} 
                    arrows={Boolean(this.props.match.params.id) && this.state.uuids.length > 3} 
                /> 
            ):(
                <TrialProfile
                    goBack={this.goBack}
                    userData={this.state.userData} 
                    goToProfile={this.goToProfile} 
                    arrows={Boolean(this.props.match.params.id) && this.state.uuids.length > 3}
                />
            )
        }
        return (
            profile ? profile : <Loader />
        )
    }
} 

export default withRouter(CandidateProfile);
