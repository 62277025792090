import React, { Fragment } from 'react';
import BillingForm from './BillingForm';

export default function Billing(props) {
    const item = props.organizations.filter(el => el.nid == props.match.params.id); 
    const itemAttributes = item[0];

    const { token, fetchOrg, initialData } = props;
    const country = [...initialData.country]
    const selectedCountry = country.filter(el => el.label === itemAttributes.field_company_data_country)

  return (
    <BillingForm defaultValues={itemAttributes} token={token} fetchOrg={fetchOrg} country={country} selectedCountry={selectedCountry}/>
  );
}