import React, { useState, Fragment } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import { 
  withStyles, 
  Card,
  Grid, 
  TextField, 
  Button,
  Typography,
  CardActions
} from "@material-ui/core";
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import InputAdornment from '@material-ui/core/InputAdornment';
import TwitterIcon from '@material-ui/icons/Twitter';
import DropPdf from '../../../Utilities/DropPdf';

const styles = (theme) => ({
  card: {
    marginBottom: 50,
    padding: 20
  },
  icon: {
    color: theme.palette.grey[500]
  },
  container: {
    display: "Flex",
    justifyContent: "center"
  },
  actions: {
    '& > * + *' : {
      marginLeft: `${theme.spacing(1)}px`
    }
  }
});

const FormContent = props => {
  const { 
    classes,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    handleBack,
    pdfPreview,
    deleteImageEntity,
    createImageEntity
  } = props;
  
  const [mbLimit, setMbLimit] = useState(false);
  
  const uploadFile = (file, type) => { 
    createImageEntity(file, type)
  }

  const removeFile = (type) => {
    deleteImageEntity(type)
  }

  const setTextValues = (e) => {
    const field = e.target.id;
    const value = e.target.value;
    handleBlur(e)
    if(Object.keys(errors).length && props.isEdit){
      return
    }
    props.mergeFormData( "attributes", {[field]: value});    
  }

  const socialError = () => {
    return errors.field_linkedin_profile && (touched.field_linkedin_profile || touched.field_github_profile || touched.field_linkedin_profile || touched.field_twitter_profile)
  }
  return (
    <form onSubmit={handleSubmit} className={classes.container}>
      <Card className={classes.card}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DropPdf 
                uploadFile={uploadFile}
                removeFile={removeFile}
                setMbLimit={{func: setMbLimit, mbLimit: 25 }}
                color={ mbLimit ?  "error" : "textPrimary" }  
                type="field_curriculum_vitae"
                initialData={pdfPreview}
                removeInitialData={removeFile}  
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
                id="field_portfolio_link"
                label="Portfolio Link"
                value={values.field_portfolio_link.toLowerCase()}
                onChange={handleChange}
                onBlur={(e) => setTextValues(e)}
                helperText={touched.field_portfolio_link ? errors.field_portfolio_link : ""}
                error={touched.field_portfolio_link && Boolean(errors.field_portfolio_link)}
                margin="dense"
                variant="outlined"
                fullWidth
              />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" color={ socialError() ? "error" : "inherit"}>Social Media Profiles (please provide at least one URL)*</Typography>
            <TextField
                id="field_linkedin_profile"
                label="LinkedIn"
                value={values.field_linkedin_profile.toLowerCase()}
                onChange={handleChange}
                onBlur={(e) => setTextValues(e)}
                helperText={Boolean(errors.field_linkedin_profile) && Object.keys(errors).length === 1 ? errors.field_linkedin_profile : ""}
                error={touched.field_linkedin_profile && Boolean(errors.field_linkedin_profile)}
                margin="dense"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LinkedInIcon className={classes.icon} />
                    </InputAdornment>
                  ),
                }}
              />
          </Grid>
          <Grid item xs={12}>
            <TextField
                id="field_github_profile"
                label="GitHub"
                value={values.field_github_profile.toLowerCase()}
                onChange={handleChange}
                onBlur={(e) => setTextValues(e)}
                helperText={Boolean(errors.field_github_profile) && Object.keys(errors).length === 1 ? errors.field_github_profile : ""}
                error={touched.field_github_profile && Boolean(errors.field_github_profile)}
                margin="dense"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <GitHubIcon className={classes.icon} />
                    </InputAdornment>
                  ),
                }}
              />
          </Grid>
          <Grid item xs={12}>
            <TextField
                id="field_twitter_profile"
                label="Twitter"
                value={values.field_twitter_profile.toLowerCase()}
                onChange={handleChange}
                onBlur={(e) => setTextValues(e)}
                helperText={Boolean(errors.field_twitter_profile) && Object.keys(errors).length === 1 ? errors.field_twitter_profile : ""}
                error={touched.field_twitter_profile && Boolean(errors.field_twitter_profile)}
                margin="dense"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <TwitterIcon className={classes.icon} />
                    </InputAdornment>
                  ),
                }}
              />
          </Grid>
        </Grid>
          {!props.isEdit ? (
            <CardActions className={classes.actions}>
              <Button onClick={handleBack} variant="contained"> Back </Button>
              <Button type="submit" variant="contained" fullWidth >Continue</Button>
            </CardActions>
          ) : ( 
            <CardActions className={classes.actions}>
              <Button onClick={handleBack} variant="contained"> Back </Button>
              <Button variant="contained" fullWidth onClick={() => props.next()}>Next</Button>
            </CardActions>
          )}
      </Card>
    </form>
  );
};

const Form = withFormik({
  mapPropsToValues: ({
    file_preview,
    defaultVal,
    pdfPreview
  }) => {
    return {
    field_linkedin_profile: defaultVal.attributes.field_linkedin_profile ? defaultVal.attributes.field_linkedin_profile : "",
    field_github_profile:  defaultVal.attributes.field_github_profile ? defaultVal.attributes.field_github_profile : "",
    field_twitter_profile: defaultVal.attributes.field_twitter_profile ? defaultVal.attributes.field_twitter_profile : "",
    field_portfolio_link: defaultVal.attributes.field_portfolio_link ? defaultVal.attributes.field_portfolio_link : "",
    file_preview: file_preview || "",
    pdfPreview: pdfPreview || ""
    };
  },

  validationSchema: Yup.object().shape({
    field_linkedin_profile: Yup.string().url('Please provide a valid url').test('oneOfRequired', ' ', function(item){
      return this.parent.field_linkedin_profile || this.parent.field_github_profile || this.parent.field_twitter_profile
    }),
    field_github_profile: Yup.string().url('Please provide a valid url').test('oneOfRequired', ' ', function(item){
      return this.parent.field_linkedin_profile || this.parent.field_github_profile || this.parent.field_twitter_profile
    }),
    field_twitter_profile: Yup.string().url('Please provide a valid url').test('oneOfRequired', ' ', function(item){
      return this.parent.field_linkedin_profile || this.parent.field_github_profile || this.parent.field_twitter_profile
    }),
    field_portfolio_link: Yup.string().url('Please provide a valid url')
  }),
    
  handleSubmit: (values, { props, setSubmitting, setValues, setFieldValue }) => {
    props.next()
  }
})(FormContent);

export default withStyles(styles)(Form);
