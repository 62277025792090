import React , { Component, Fragment } from 'react';
import { Route } from 'react-router-dom';
import { withStyles, Grid, Modal, Paper, Typography, TextField, InputAdornment, Box, Button  } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import JobSelected from './JobSelected';
import oauthConfig from '../../oauthConfig';
import axios from 'axios';
import Loader from '../Utilities/Loader/Loader';
import CandidateJobFilter from '../Filters/CandidateJobFilter/CandidateJobFilter';
import JobMenu from '../JobsCandidate/JobsMenu';
import ReactPaginate from 'react-paginate';
import SearchIcon from '@material-ui/icons/Search';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { Scrollbars } from 'react-custom-scrollbars';
import queryString from 'query-string';

const styles = theme => ({
    root: {
        maxWidth: '1280px',
        margin: '0 auto',
        padding: '0 10px',
        [theme.breakpoints.up('md')]: {
            padding: `0 ${theme.spacing(4)}px`,
        }
    },
    menu: {
        borderRight: "1px solid #D3D3D3"
    },
    titleLink: {
        color: "black"
    },
    navLink: {
        textDecoration: "none",
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
        color: "#505050",
        borderBottom: "1px solid #D3D3D3",
        display: "block"
    },
    logoLink: {
        width: '40px',
        height: '40px',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        marginRight: `${theme.spacing(2)}px`
    },
    pagination: {
        display: "flex",
        listStyle: "none",
        justifyContent: "center",
        alignItems: 'center',
        [theme.breakpoints.between('xs', 'md')]: {
            paddingBottom: `100px`,
        },
        '& li':{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 5px',
            width: '30px',
            height: '30px',
            cursor: 'pointer'
        },
        '& a': {
            height: '20px',
            '&:focus': {
                outline: "0"
            }
        }
    },
    active: {
        background: '#eeeeee', 
        borderRadius: '50%' 
    },
    modal: {
        position: "absolute !important",
        width: '100%',
        heigth: '100%',
        overflow: "scroll"
    },
    result: {
        padding: `${theme.spacing(1)}px 0`
    },
    search: {
        marginTop: `${theme.spacing(2)}px`, 
        background: '#ffffff',
    }
});

let totalJobs = 0;
let pageCount = null;
const perPage = 50;

let values;

class Jobs extends Component {
    
    state = {
        search: "" ,
        jobs: null,
        companies: null,
        pageCount: 0,
        query: null,
        page: 0,
        loading: false
    }

    componentDidMount(){
        const saved = localStorage.getItem('jobFilterQuery')
        if(saved){
            values = {...queryString.parse(saved)}
        }else{
            values = {...queryString.parse(this.props.location.search)}
        }
        const page = parseInt(values.page)
        const isSearch = values.hasOwnProperty("q")
        const arrSearchQuery = []
        for(const prop in values){
            if(prop !== "page" && prop !== "q"){
                if(prop !== "created"){
                    arrSearchQuery.push(`&${prop}=${values[prop].split(" ").join("+")}`)
                }else{
                    arrSearchQuery.push(`&${prop}=${values[prop]}`)
                }
            }
        }
        const seacrhString = arrSearchQuery.join("")

        this.setState({
            page
        })

        this.fetchCompanies()
        if(isSearch){
            if(page > 0){
                this.handleSearch(seacrhString, page)
            }else{
                this.handleSearch(seacrhString, 0)
            }
        }else {
            if(page > 0){
                this.fetchJobsPaging(page)
            }else{
                this.fetchJobsPaging(0)
            }
        }
    }

    componentDidUpdate(prevState){
        const page = queryString.parse(this.props.location.search).page
        const prevPage = queryString.parse(prevState.location.search).page
        const isMobile = document.body.clientWidth < 960;
        if(page !== prevPage && this.state.jobs && !isMobile){
            this.refs.scrollbarsMenu.scrollToTop()
        }
        if(prevState.location.pathname !== this.props.location.pathname && this.state.jobs && !isMobile){
            this.refs.scrollbarsContent.scrollToTop()
        }
    }

    fetchCompanies = async() => {
        let token = this.props.token;
        let headers = {
            headers: {
              'Accept': 'application/vnd.api+json',
              'Content-Type': 'application/vnd.api+json',
              //   'Authorization': `${token.token_type} ${token.access_token}`
            }
        }
        let allCompanies = [];
        let offset = 50;
        await axios.get(`${oauthConfig.baseUrl}/api/organizations/export?page[limit]=50&page[offset]=${0}`, headers)
        .then(async (response) => {
            const countCompanies = response.data.length > 0 ? response.data[0].total_rows : 0;
            allCompanies = [...allCompanies, ...response.data] 
            while (countCompanies >= offset){
                await axios.get(`${oauthConfig.baseUrl}/api/organizations/export?page[limit]=50&page[offset]=${offset}`, headers)
                .then(companies => {
                    allCompanies = [...allCompanies, ...companies.data];
                    offset = offset + 50;
                })
            }
            this.setState({companies: allCompanies})
        })
    }

    fetchJobsPaging = async(page, isPageClick) => {
        this.setState({
            loading: true
        })
        let token = this.props.token;

        let headers = {
            headers: {
              'Accept': 'application/vnd.api+json',
              'Content-Type': 'application/vnd.api+json',
            //   'Authorization': `${token.token_type} ${token.access_token}`
            }
        }
        const isMobile = document.body.clientWidth < 960;
        const jobs = await axios.get(`${oauthConfig.baseUrl}/api/jobs/search?_format=json&page=${page}`, headers);
        let firstJob;
        if(jobs.data.length > 0){
            firstJob = jobs.data[0].nid
            totalJobs = jobs.data[0].total_rows;
            pageCount = Math.ceil(totalJobs / perPage);
        }else{
            totalJobs = "No";
        }   
        
        if(isMobile){
            this.props.history.push({
                pathname: `/jobs`,
                search: `?page=${page}`
            })
        }
        
        if(!isMobile && firstJob){
            const pathId = this.props.location.pathname.split("/")[2];
            let currentJobId;
            if(isPageClick){
                currentJobId = firstJob
            }else{
                currentJobId = pathId ? pathId : firstJob
            }
            this.props.history.push({
                pathname: `/jobs/${currentJobId}`,
                search: `?page=${page}`
            })
        }

        this.setState({
            jobs: jobs.data,
            totalJobs,
            page,
            pageCount,
            loading: false
        }) 
    }

    handleChange = (e) => {
        this.setState({search: e.target.value});
    }

    handleKeyPress = (e) => {
        if(e.key === 'Enter'){
            this.handleSearch(`&search=${this.state.search.replace(/\s+/g, '+')}` , 0)
        }
    }

    handleSearch = async(query, page, isAllFilter, isPageClick) => {
        this.setState({
            loading: true
        })
        let queryMerge = "";
        if(this.state.query && !isAllFilter){
            let newQuery = query.split("&").slice(1);
            let newQueryProp = newQuery[0].split("=");
            let exist = false;
            let prevQuery = this.state.query.split("&").slice(1)
            let prevQueryProps = prevQuery.map(el => {
                return el.split("=")
            })
            prevQueryProps.forEach(el=>{
                if(el[0] === newQueryProp[0]){ 
                  exist = true
                } 
            })
            if(exist){
                let replaceQuery = prevQueryProps.map( el => {
                    if(el[0] === newQueryProp[0]){
                        el[1] = newQueryProp[1]
                        if(!newQueryProp[1]){
                            el[0] = null
                            el[1] = null  
                        } 
                    }
                    return el.join("=")
                })
                const filtered = replaceQuery.filter(el => el != "=");
                if(filtered.length > 0){
                    queryMerge = `&${filtered.join("&")}`
                }else{
                    queryMerge = ""
                }
            }else{
                queryMerge = this.state.query + query
            }
        }else{
            if(query){
                const availableProps = query.split("=")[1]
                queryMerge = availableProps.length === 0 ? "" : query
            }
        }

        let token = this.props.token;

        let headers = {
            headers: {
              'Accept': 'application/vnd.api+json',
              'Content-Type': 'application/vnd.api+json',
            //   'Authorization': `${token.token_type} ${token.access_token}`
            }
        }
        const isMobile = document.body.clientWidth < 960; 
        const jobs = await axios.get(`${oauthConfig.baseUrl}/api/jobs/search?_format=json&page=${page}${queryMerge}`, headers);
        let selectedJob;
        const pathId = this.props.location.pathname.split("/")[2];
        if(jobs.data.length > 0){
            const foundJob = jobs.data.find(el => el.nid === pathId);
            selectedJob = foundJob ? foundJob.nid : jobs.data[0].nid;
            totalJobs = jobs.data[0].total_rows;
            pageCount = Math.ceil(totalJobs / perPage);
        }else{
            totalJobs = "No";
            this.props.history.push({
                pathname: `/jobs`,
                search: `?page=${page}&q=1${queryMerge}`
            })
        }

        if(isMobile){
            this.props.history.push({
                pathname: `/jobs`,
                search: `?page=${page}&q=1${queryMerge}`
            })
        }

        if(!isMobile && selectedJob){
            this.props.history.push({
                pathname: `/jobs/${selectedJob}`,
                search: `?page=${page}&q=1${queryMerge}`
            })
        }

        localStorage.setItem('jobFilterQuery', `?page=${page}&q=1${queryMerge}`);
        this.setState({
            jobs: jobs.data,
            totalJobs,
            query: queryMerge,
            pageCount,
            page,
            loading: false
        })
    }

    handlePageClick = (e) => {
        if(this.state.query){
            let query = this.state.query
            this.handleSearch(query, e.selected, true, true)
        }else{
            this.fetchJobsPaging(e.selected, true)
        }
    }

    render(){
        const { classes, ...other } = this.props;
        return (
            <div className={classes.root}> 
                { this.state.jobs && this.state.companies ? (
                    <Fragment>
                        <TextField 
                            className={classes.search} 
                            fullWidth={true} 
                            variant="outlined"
                            onChange={this.handleChange}
                            value={this.state.search}
                            onKeyPress={this.handleKeyPress}
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                        <Box color="#757575" display="flex">
                                            <SearchIcon fontSize="small"/><Box fontSize={12}>Search</Box>
                                        </Box>
                                </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                            <Button 
                                                type="submit" 
                                                variant="contained" 
                                                fullWidth
                                                disabled = { !this.state.search } 
                                                onClick={() => this.handleSearch(`&search=${this.state.search.replace(/\s+/g, '+')}` ,0)}
                                            >
                                                Search
                                            </Button>
                                    </InputAdornment>
                                )
                            }} 
                        />
                        <CandidateJobFilter 
                            {...other}
                            jobs = {this.state.jobs}
                            totalJobs= {this.state.totalJobs}
                            query={this.state.query}
                            page={this.state.page} 
                            companies={this.state.companies} 
                            predefinedVals={values && values} 
                            handleSearch={this.handleSearch}
                        />
                        <Hidden only={['md','lg','xl']}>
                            {this.state.jobs.length > 0 && (
                                 !this.state.loading ? (
                                    <Fragment>
                                        <JobMenu 
                                            jobs={this.state.jobs} 
                                            page={this.state.page}
                                            query={this.state.query} 
                                            pageCount={this.state.pageCount} 
                                            handlePageClick={this.handlePageClick}
                                        />
                                        <Route path="/jobs/:id" render = { 
                                            (props) => {
                                            return <Modal className={classes.modal} open={true}><Paper><JobSelected {...props} jobs={this.state.jobs} companies={this.state.companies} mobile/></Paper></Modal>
                                            }
                                        }/> 
                                        <ReactPaginate
                                            previousLabel={<NavigateBeforeIcon />}
                                            nextLabel={<NavigateNextIcon />}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={this.state.pageCount}
                                            initialPage={this.state.page}
                                            disableInitialCallback={ true }
                                            marginPagesDisplayed={1}
                                            pageRangeDisplayed={4}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={classes.pagination}
                                            activeClassName={classes.active}
                                        />
                                    </Fragment>
                                ) : (
                                    <Loader/>
                                )
                            )} 
                        </Hidden>
                        <Hidden only={['xs','sm']}>
                            <Grid container>
                                <Grid container item xs={12} md={5} className={classes.menu}>
                                    {this.state.jobs.length > 0 && (
                                        <Scrollbars style={{ height: `80vh` }} ref="scrollbarsMenu">
                                            { !this.state.loading ? (
                                                <Fragment>
                                                    <JobMenu 
                                                        jobs={this.state.jobs} 
                                                        page={this.state.page}
                                                        query={this.state.query} 
                                                        pageCount={this.state.pageCount} 
                                                        handlePageClick={this.handlePageClick}
                                                    />
                                                
                                                    <ReactPaginate
                                                        previousLabel={<NavigateBeforeIcon />}
                                                        nextLabel={<NavigateNextIcon />}
                                                        breakLabel={"..."}
                                                        breakClassName={"break-me"}
                                                        pageCount={this.state.pageCount}
                                                        initialPage={this.state.page}
                                                        disableInitialCallback={ true }
                                                        marginPagesDisplayed={1}
                                                        pageRangeDisplayed={4}
                                                        onPageChange={this.handlePageClick}
                                                        containerClassName={classes.pagination}
                                                        activeClassName={classes.active}
                                                    />
                                                </Fragment> 
                                                ) : (
                                                <Loader/>
                                            )}
                                        </Scrollbars>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <Scrollbars style={{ height: `80vh` }} ref="scrollbarsContent">
                                        <Route path="/jobs/:id" render={(props)=> <JobSelected {...props} jobs={this.state.jobs} companies={this.state.companies}/>}/>
                                    </Scrollbars>
                                </Grid>
                            </Grid>
                        </Hidden>
                    </Fragment>
                ):(
                    <Loader/> 
                )}
            </div>
        )
    }
}

export default withStyles(styles)(Jobs);
