import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import EditProfile from '../../Settings/Profile/EditProfile';
import ProfileView from './EmployerProfileView';
import ChangePassword from '../../Settings/Profile/ChangePassword/ChangePassword';
import { withStyles } from '@material-ui/core';
import Drawer from '../../Drawer/Drawer';

const styles = theme => ({
    root: {
        maxWidth: '940px',
        margin:  `0 auto`,
        [theme.breakpoints.up('md')]: {
            margin:  `0 auto ${theme.spacing(1)}px auto`,
            padding: `30px ${theme.spacing(4)}px`,
        },
    }
});

const accordionMenu = [
    {
        title: "Edit profile", 
        logo: null,
        icon: "settings",
        items: [
            {
                text: "Personal info",
                icon: "info",
                pathTo: `/profile/step/0`
            },
            {
                text: "Responsibilities",
                icon: "info",
                pathTo: `/profile/step/1`
            },
            {
                text: "Change Password",
                icon: "info",
                pathTo: `/profile/password`
            }
        ]  
    }
]

class EmployerProfile extends Component {
    state = {
        drawerItems: [
            {
                text: "View Profile",
                icon: "list_alt",
                pathTo: "/profile/view", 
            },
            {
                accordionMenu
            }
        ] 
    }

    render(){
        const { classes, ...otherProps } = this.props;
        return (
            <Drawer drawerItems={this.state.drawerItems} expanded="Edit profile" orgID={otherProps.orgID}>
                <div className={classes.root}>
                    <Switch>
                        <Route path="/profile/view" render={(props) => <ProfileView {...otherProps} /> } />
                        <Route path="/profile/password" render={(props) => <ChangePassword {...otherProps} /> } />
                        <Route path="/profile/step/:id" render={ () => <EditProfile {...otherProps} />} />
                        <Redirect to='/profile/step/0'/>
                    </Switch>
                </div>
            </Drawer>
        )
    } 
}


export default withStyles(styles)(EmployerProfile);