import React, { useState, Fragment, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { Drawer, List, ListItem, ListItemIcon, Divider, Avatar, IconButton, Icon, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, Backdrop, Hidden } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BusinessIcon from '@material-ui/icons/Business';
import oauthConfig from '../../oauthConfig';
import drawerStyles from './DrawerStyles';
import drawerStylesNoSubNav from './drawerStylesNoSubNav';
import { Scrollbars } from 'react-custom-scrollbars';
import AsideNavigation from '../Navigation/AsideNavigation/AsideNavigation';
import AsideNavCandidate from '../Navigation/AsideNavigation/AsideNavCandidate';

export default function MiniDrawer(props) {
  const classes = props.noSubnav ? drawerStylesNoSubNav() : drawerStyles();
  const [open, setOpen] = useState(true);
  const [openExpansion, setOpenExpansion] = useState(true);
  const [touchEnd, setTouchEnd] = useState(0);
  
  const handleTouchMove = (e) => {
      setTouchEnd(e.targetTouches[0].clientX);
  }

  const handleTouchEnd = () => {
    if(touchEnd == 0 ){
      return
    }
    handleToggle();
    setTouchEnd(0);
  }
  
  const handleToggle = () => {
    setOpen(!open);
  }
  
  const handleExpand = (title) => {
    if(title === openExpansion){
      setOpenExpansion(false)
    }else{
      setOpenExpansion(title)
    }
  }

  useEffect(() => {
    if(props.expanded){
      setOpenExpansion(props.expanded)
    }
  },[props.expanded]);

  let content = (
    <div className={classes.drawerContent}>
      {props.drawerItems.map((el) => {
          if(el.hasOwnProperty('accordionMenu')){
              return (
                el.accordionMenu.map((panels) => ( 
                  <ExpansionPanel  key={panels.title} className={classes.expansionPannel} expanded={panels.title === openExpansion}> 
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon className={classes.expand}/>}
                    className={clsx(classes.expansionSummary, {['summaryClosed']: !open})}
                    onClick={() => handleExpand(panels.title)}
                  >
                  
                    {
                      panels.logo ? (
                        <Fragment>
                          <div className={classes.logo} style={{backgroundImage: `url(${oauthConfig.baseUrl}${panels.logo})`}}></div>
                          <Typography variant="subtitle2" className={clsx(classes.panelTitle,{['panelTitleClosed']: !open})}>
                            {panels.title}
                          </Typography>
                        </Fragment>
                      ):(
                        panels.icon ? (
                          <Fragment>
                            <ListItemIcon className={classes.icon}><Icon>{panels.icon}</Icon></ListItemIcon>
                            <Typography variant="subtitle2" className={clsx(classes.panelTitle,{['panelTitleClosed']: !open})}>{panels.title}</Typography>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <div className={classes.logo}><Avatar className={classes.avatar}> <BusinessIcon style={{fontSize: '15px'}}/></Avatar></div>
                            <Typography variant="subtitle2" className={clsx(classes.panelTitle,{['panelTitleClosed']: !open})}>
                              {panels.title}
                            </Typography> 
                          </Fragment>
                        )
                      )
                    }
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className={classes.expansionDetails}>
                    <List className={classes.list}>
                      {panels.items.map(nestedEl => (
                        <NavLink
                          to={nestedEl.pathTo}  
                          key={nestedEl.text} 
                          className={classes.link} 
                          activeStyle={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}   
                        >
                          <ListItem button>
                            <ListItemIcon className={clsx(classes.icon, {[classes.iconDrawerOpen]:!open})}><Icon>{nestedEl.icon}</Icon></ListItemIcon>
                            <Typography variant="subtitle2" className={clsx(classes.panelTitle,{['panelTitleClosed']: !open})}>{ nestedEl.text }</Typography>
                          </ListItem>
                        </NavLink>
                      ))}
                    </List>
                  </ExpansionPanelDetails>
                  </ExpansionPanel>
                )
              )
            )
          }else{
            return (
              <NavLink
                to={el.pathTo}  
                key={el.text}
                className={classes.link} 
                activeStyle={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}>
                <ListItem button >
                    <ListItemIcon className={clsx(classes.icon, {[classes.iconDrawerOpen]:!open})}><Icon>{el.icon}</Icon></ListItemIcon>
                    <Typography variant="subtitle2">{el.jobCount ?  `${el.text} (${el.jobCount})` : el.text}</Typography>
                </ListItem>
              </NavLink>
            )
          }
      })}
      <Divider />
    </div>
  );

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      display: 'none'
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  return (
    <div className={classes.root}>
      <Drawer
        onTouchMove={handleTouchMove}
        onTouchEnd={() => handleTouchEnd()}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open, 
        })}
        classes={{
          paper: clsx({
            [classes.paperOpen]: open, 
            [classes.paperClose]: !open, 
          }),
        }}
      >
        <div className={classes.drawerMenus}>
        <IconButton disableRipple disableFocusRipple onClick={handleToggle} className={classes.chevron}> 
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon /> }
        </IconButton>
          <Hidden mdUp>
            <div className={clsx(classes.asideMobileNav, {
                [classes.asideMobileNavClose]: !open, 
              })}
            >
              {props.orgID ? <AsideNavigation orgID={props.orgID}/> : <AsideNavCandidate/>}
            </div>
          </Hidden>
          <Scrollbars style={{height: '85vh'}} renderThumbVertical={renderThumb}> 
            { content }
          </Scrollbars>
        </div>
      </Drawer>
        <Hidden smUp><Backdrop open={open} style={{zIndex: 2}} onClick={handleToggle}></Backdrop></Hidden>
        <div 
        className={clsx(classes.content, {
          [classes.contentOpen]: open,
          [classes.contentClose]: !open, 
        })}
      >
        {props.children}
        </div>
    </div>
  );
}