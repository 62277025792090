import React, { useState } from 'react';
import { withFormik } from "formik";
import { makeStyles, CircularProgress, Grid, Button, TextField, FormControlLabel, Radio,  Typography, Card } from "@material-ui/core";
import RadioButtons from '../../../Authentication/SignUp/FormItems/RadioButtons';
import * as Yup from "yup";
import oauthConfig from '../../../../oauthConfig';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
    container: {
        padding: `${theme.spacing(2)}px`,
        margin: `${theme.spacing(2)}px 0`
    },
    submitBtn: {
        display: 'block',
        margin: "10px auto",
        minWidth: "150px"
    }
}));

const FormContent = props => { 
    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur, 
        handleSubmit,
        isSubmitting,
        setFieldValue,
        positionData,
    } = props;

    const classes = useStyles(); 

    const field_company_position = Object.keys(positionData).map((el,index) => {
        return  <FormControlLabel
                  key={index} 
                  value={el} 
                  control={<Radio />} 
                  label={<Typography variant="caption" color="textPrimary">{positionData[el]}</Typography>} 
                />
    });

    const handleRadio = (e, field) => {
        const data = isNaN(e.target.value) ? e.target.value : Number(e.target.value);
        setFieldValue(field , data);
    }

    return (
        <Card className={classes.container}>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h6" color="textPrimary">Add admin access to user</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="first_name"
                            label="First Name*"
                            value={values.first_name || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.first_name ? errors.first_name : ""}
                            error={touched.first_name && Boolean(errors.first_name)}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="last_name"
                            label="Last Name*"
                            value={values.last_name || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.last_name ? errors.last_name : ""}
                            error={touched.last_name && Boolean(errors.last_name)}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="email"
                            label="Email*"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.email ? errors.email : ""}
                            error={touched.email && Boolean(errors.email)}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <RadioButtons 
                            title="Company Position*" 
                            handleChange={handleRadio} 
                            field="field_company_position"
                            value={values.field_company_position}
                            error={(touched.field_company_position && errors.field_company_position)}
                            >
                            {field_company_position}
                        </RadioButtons>
                    </Grid>
                </Grid>

                <Button className={classes.submitBtn} type="submit" variant="contained" disabled={isSubmitting}>
                    {isSubmitting ? <CircularProgress color="secondary" size={25} /> :  "Add access" }
                </Button>
            </form>
        </Card>
    )
}

const Form = withFormik({
    mapPropsToValues: ({
        first_name,
        last_name,
        field_company_position,
        email
      }) => {
        return {
            first_name: first_name || "",
            last_name: last_name || "",
            email: email || "",
            field_company_position: field_company_position || "" 
        };
    },

    validationSchema: Yup.object().shape({
        first_name: Yup.string().required("Required"),
        last_name: Yup.string().required("Required"),
        email: Yup.string().email("Enter a valid email").required("Required"),
        field_company_position: Yup.string().required("Required")
    }),

    handleSubmit: (values, {props, setSubmitting} ) => {
        let token = props.token;
        let headers = {
            headers: {
                'Accept': 'application/vnd.api+json',
                'Content-Type': 'application/vnd.api+json',
                'Authorization': `${token.token_type} ${token.access_token}`
            }
        }
        const id = props.currentOrg.nid;
        axios.patch(`${oauthConfig.baseUrl}/api/organization/${id}/add_shared_access_user`, values, headers)
        .then(res => {
            setSubmitting(false)
            props.fetchOrg();
        })
        .catch(err => {
            setSubmitting(false)
            props.error(err.response.data)
        });
    }
  })(FormContent);

export default Form;
