import React, { Component, Fragment } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import Drawer from '../Drawer/Drawer';
import JobNew from './JobsComponents/JobNew/JobNew';
import JobList from './JobsComponents/JobItems/jobList';
import axios from 'axios';
import oauthConfig from '../../oauthConfig';
import queryString from 'query-string';

const styles = theme => ({
    wrapper: {
        padding: `${theme.spacing(2)}px`, 
        [theme.breakpoints.up('md')]: {
            padding: `${theme.spacing(4)}px`,
        }
    }
});

class Jobs extends Component {
    state = {
        uuid: '',
        jobList: null,
        jobsCount: null,
        page: null,
        values: localStorage.getItem('jobPostsFilterQuery') ? {...queryString.parse(localStorage.getItem('jobPostsFilterQuery'))} : {...queryString.parse(this.props.location.search)}
    }

    componentDidMount(){
        const values = this.state.values;
        const page = parseInt(values.page)
        const isSeatch = values.hasOwnProperty("q")
        const arrSearchQuery = []
        for(const prop in values){
            if(prop !== "page" && prop !== "q"){
                arrSearchQuery.push(`&${prop}=${values[prop].split(" ").join("+")}`)
            }
            if(prop === "q"){
                const search = values[prop]
                this.setState({ search })
            }
        }
            
        const seacrhString = arrSearchQuery.join("")
        
        this.setState({ 
            page,
            search: seacrhString
        })

        if(isSeatch){
            if(page > 0){
                this.handleSearch(seacrhString, page)
            }else{
                this.handleSearch(seacrhString, 0)
            }
        }else{
            if(page > 0){
                this.handleFetchData(page)
            }else{
                this.handleFetchData(0)
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentOrg !== prevProps.currentOrg) {
            this.props.history.push({ pathname:'/job/all' , search: `?page=0` })
            this.handleFetchData(0);
        }
    }

    setHeadrs = () => {
        let token = this.props.token;

        let headers = {
            headers: {
              'Accept': 'application/vnd.api+json',
              'Content-Type': 'application/vnd.api+json',
              'Authorization': `${token.token_type} ${token.access_token}`
            }
        }

        return headers
    }

    handleSearch = async(query, page, isAllFilter) => {
        this.setState({
            jobList: null,
            page: null
        })
        let queryMerge = "";
        if(this.state.query && !isAllFilter && query){ 
            let newQuery = query.split("&").slice(1);
            let newQueryProp = newQuery[0].split("=");
            let exist = false;
            let prevQuery = this.state.query.split("&").slice(1)
            let prevQueryProps = prevQuery.map(el => {
                return el.split("=")
            })
            prevQueryProps.forEach(el=>{
                if(el[0] === newQueryProp[0]){ 
                  exist = true
                } 
            })
            if(exist){
                let replaceQuery = prevQueryProps.map( el => {
                    if(el[0] === newQueryProp[0]){
                        el[1] = newQueryProp[1]
                        if(!newQueryProp[1]){
                            el[0] = null
                            el[1] = null  
                        } 
                    }
                    return el.join("=")
                })
                const filtered = replaceQuery.filter(el => el != "=");
                if(filtered.length > 0){
                    queryMerge = `&${filtered.join("&")}`
                }else{
                    queryMerge = ""
                }
            }else{
                queryMerge = this.state.query + query
            }
        }else{
            if(query){
                const availableProps = query.split("=")[1]
                queryMerge = availableProps.length === 0 ? "" : query
            }
        }

        let headers = this.setHeadrs();
        const nid = this.props.currentOrg.nid;
        const currentUserJobs = await axios.get(`${oauthConfig.baseUrl}/api/jobs/export/${nid}?_format=json&page=${page}${queryMerge}`);

        this.props.history.push({
            search: `?page=${page}&q=1${queryMerge}`
        })
        const jobQuota = await axios.get(`${oauthConfig.baseUrl}/api/organization/${nid}/job_quota_left?_format=json`, headers);
        const feturedQuota = await axios.get(`${oauthConfig.baseUrl}/api/organization/${nid}/featured_job_quota_left`, headers);
        const values = {...queryString.parse(queryMerge)}
        localStorage.setItem('jobPostsFilterQuery', `?page=${page}&q=1${queryMerge}`);
        this.setState({
            jobList: currentUserJobs.data,
            jobsCount: currentUserJobs.data.length > 0 ? currentUserJobs.data[0].total_rows : "0",
            jobQuota: jobQuota.data,
            feturedQuota: feturedQuota.data,
            query: queryMerge,
            page,
            values
        })
    }

    handleReset = () => {
        this.setState({
            jobList: null,
            jobsCount: "0",
            jobQuota: null,
            query: null,
            page: null
        })
        this.props.history.push({
            pathname: '/job/all',
        })
    }

    handleFetchData = (page) => {
        if(this.state.query){
            this.handleSearch(this.state.query, page)
            .catch(err => {
                if(err.response === 401){
                    if(err.response.status === 401){
                        this.props.logout()
                    }
                }
            });
        }else{
            this.handleSearch('', page)
            .catch(err => {
                if(err.response === 401){
                    if(err.response.status === 401){
                        this.props.logout()
                    }
                }
            });
        }
    }

    handleSetPage = (page) => {
        let query = this.state.query
        this.handleSearch(query, page)
    }

    handleDlete = (id) => {
        this.props.history.push('/job/all')
        this.setState({jobList: null})
        let headers = this.setHeadrs();
        axios.delete(`${oauthConfig.baseUrl}/api/node/job_posting/${id}`, headers)
        .then(response => {
            let query = this.state.query ? this.state.query : ''; 
            let page = this.state.page ?  this.state.page : 0;
            this.handleSearch(query, page)
        })
        .catch(err => {
            if(err.response.status === 401){
              this.props.logout()
            }
        });
    }

    render(){
        const { classes, ...other } = this.props;
        const drawerItems = [
            {
                text: "Job Postings",
                icon: "assignment",
                pathTo: "/job/all",
                jobCount: this.state.jobsCount && this.state.jobsCount 
            },
            {
                text: "Create Job",
                icon: "create",
                pathTo: "/job/new",
                jobCount: this.state.jobQuota && this.state.jobQuota 
            }
        ]
        
        return (
            <Drawer drawerItems={drawerItems} orgID={other.currentOrg.uuid}>
                <div className={classes.wrapper}>
                        <Switch>
                            <Route exact path="/job/new" render={()=> <JobNew {...other} page={this.state.page} jobQuota={this.state.jobQuota} fetchAll={this.handleFetchData} handleSetPage={this.handleSetPage}/>}/>
                            <Route path="/job/all" render={
                                (props)=> (
                                    <Fragment>
                                        <JobList 
                                            {...other} 
                                            {...props}
                                            predefinedVals={this.state.values} 
                                            jobList={this.state.jobList}
                                            jobsCount={this.state.jobsCount}
                                            feturedQuota = {this.state.feturedQuota}
                                            handleSetPage={this.handleSetPage} 
                                            fetchAll={this.handleFetchData} 
                                            handleDlete={this.handleDlete} 
                                            handleSearch={this.handleSearch}
                                            handleReset={() => {this.handleReset(this)}}
                                            page={this.state.page}
                                            query={this.state.query}
                                        />
                                    </Fragment>
                                )}
                            />
                            <Redirect to="/job/all" /> 
                        </Switch>
                </div>
            </Drawer>
        )
    } 
}

export default withStyles(styles)(Jobs);