import React, { Component, Fragment } from 'react';
import AccessForm from './AccessForm';
import AccessList from './AccessList';
import AlertErrorAuto  from '../../../Utilities/Alert/AlertErrorAuto';
import Loader from '../../../Utilities/Loader/Loader';
import oauthConfig from '../../../../oauthConfig';
import axios from 'axios';


class Access extends Component {

    state = {
        accessUsers : [],
        loadings: {
            loadingRemove: null,
            loadingOwner: null
        },
        errorMsg: null,
        ownerName: null 
    }

    componentDidMount(){
        this.getOwnerData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentOrg !== prevProps.currentOrg) {
            this.props.fetchOrg()
        }
    }

    setHeaders = () => {
        let token = this.props.token;
        let headers = {
            headers: {
              'Accept': 'application/vnd.api+json',
              'Content-Type': 'application/vnd.api+json',
              'Authorization': `${token.token_type} ${token.access_token}`
            }
        }
        return headers
    }

    error = (errorMsg) => {
        this.setState({ errorMsg })
    }

    getOwnerData = async() => {
        let headers = this.setHeaders();
        const id = this.props.currentOrg.nid;
        const result = await axios(`${oauthConfig.baseUrl}/api/organization/${id}/get_shared_access_users`, headers);
        const users = result.data;
        const accessUsers = [];
        let owner;

        for (const id in users) {
            if(users[id].owner){
                owner = users[id];
            }
            accessUsers.push(users[id]);
        }

        this.setState({
            owner,
            accessUsers
        });
    }
    
    removeAccess = (userId) => {
        this.setState({ loadings: { loadingRemove: userId} })
        const currentOrg = {...this.props.currentOrg};
        const orgId = currentOrg.nid;
        let headers = this.setHeaders();
        axios.delete(`${oauthConfig.baseUrl}/api/organization/${orgId}/remove_shared_access_user/${userId}`, headers)
        .then(res => { this.props.fetchOrg() })
        .catch(err => {
            this.setState({ 
                errorMsg: err.response.data,
                loadings: { loadingRemove: null} 
            })
        })
    }

    makeOwner = (userId) => {
        this.setState({ loadings: { loadingOwner: userId} })
        const orgId = this.props.currentOrg.nid;
        let headers = this.setHeaders();
        axios.patch(`${oauthConfig.baseUrl}/api/organization/${orgId}/shared_access_switch_owner/${userId}`, {} , headers)
        .then(res => { 
            this.props.refreshToken() 
        })
        .catch(err => {
            this.setState({ 
                errorMsg: err.response.data,
                loadings: { loadingOwner: null} 
            })
        })
    }

    render(){
        const { accessUsers, loadings,  errorMsg, ownerName, owner } = this.state;
        const { currentOrg, currentUser } = this.props;
        if(accessUsers.length){
            let index = accessUsers.findIndex((el) => el.owner);
            accessUsers.unshift(accessUsers.splice(index, 1)[0]);
        }
        let content;
        if(owner){
            content = (
                <Fragment>
                    <AccessList
                        currentOrg = {currentOrg.title}
                        isOwner = { owner.email === currentUser }
                        owner={owner} 
                        accessUsers={accessUsers} 
                        loadings={loadings} 
                        makeOwner={this.makeOwner} 
                        removeAccess={this.removeAccess}
                    />
                    { owner.email === currentUser && <AccessForm {...this.props} error={this.error}/> } 
                </Fragment>
            )
        }else{
            content = <Loader />
        }
        return ( 
            <Fragment>
                { errorMsg && <AlertErrorAuto clear={ () => this.setState({ errorMsg: null }) }>{ errorMsg }</AlertErrorAuto> }
                { content }
            </Fragment>
        )
   }
}

export default Access;