import React from 'react';
import Select from 'react-select';
import { withFormik } from "formik";
import * as Yup from "yup";
import { InputAdornment, Grid, FormHelperText } from '@material-ui/core';
// import 'date-fns';
import RadioButtons from '../../../Authentication/SignUp/FormItems/RadioButtons';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { 
    makeStyles, 
    FormControlLabel,
    Radio,
    Typography,
    TextField, 
    Button,
    CircularProgress 
} from "@material-ui/core";
import RichTextEditor from '../../../RichTextEditor/RichTextEditor';
import LocationTextField from '../../../Filters/SearchLocation/LocationTextField';
import GenderBias from '../../../GenderBiasReview';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: '700px',
        margin: '0 auto'
    },
    input: {
        backgroundColor: "white"
    },
    inputNimber: {
        backgroundColor: "white",
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0
        },
        "& input[type=number]" : {
            "-moz-appearance" : "textfield" 
        }
    }
}));

const JobNew = (props) => {
    const { 
        values,
        touched,
        errors,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        initialData,
        defaultValues,
        selected,
        currency,
        formEdit
    } = props;
    
    const classes = useStyles();
    const inputStyle = {
        className: classes.input
    }

    const inputNumberStyle = {
        className: classes.inputNimber
    }

    const inputNumberProps = {
        step : "0.01"
    }

    const selectStyles = {
        menu: base => ({
          ...base,
          zIndex: 100
        }),
        container: (base, state) => ({
            ...base,
            margin: '8px 0 4px 0'
        }),
        control: (base, state) => ({
            ...base,
            minHeight: '40px',
            boxShadow: 'none',
            '&:hover': {
                border: '1px solid',
                borderColor: touched.field_technical_skills && Boolean(errors.field_technical_skills) ? "red" : `hsl(0,0%,70%)`
            },
            borderColor: touched.field_technical_skills && Boolean(errors.field_technical_skills) ? "red" : `hsl(0,0%,70%)`
        })
    };
    
    const currencyIcon = {
            startAdornment: (
                <InputAdornment position="start">
                    {values.field_currency ? values.field_currency : "USD"}
                </InputAdornment>
            )
    }

    const createRadio = (data) => {
        const result = Object.keys(data).map((el,index) => {
          return (
            <FormControlLabel
              key={index} 
              value={el} 
              control={<Radio />} 
              label={<Typography variant="caption" color="textPrimary">{data[el]}</Typography>} 
            />
          ) 
        })
        return result;
    }

    const type = createRadio(initialData.type)
    const remote = createRadio(initialData.remote)

    const handleRadio = (e, field) => {
        setFieldValue(field , e.target.value);
        props.mergingUserData( "attributes", {[field]: e.target.value})
    }

    const handleLocation = (city, state, country) => {
        let attributes = {};
        let relationships = {field_job_country: {data: null}};
        if(state){
          attributes['field_job_city_name'] = city
          attributes['field_job_state'] = state
          relationships['field_job_country']['data'] = [country]
        }else{
          attributes['field_job_city_name'] = city
          attributes['field_job_state'] = ""
          relationships['field_job_country']['data'] = [country]
        }
        setFieldValue('field_job_city_name' , city);
        props.mergingUserData( "attributes", attributes);
        props.mergingUserData( "relationships", relationships);
    }

    const handleRemoveLocation = () => {
        setFieldValue('field_job_city_name' , "");
        let relationships = {field_job_country: {data: null}};
        props.mergingUserData( "relationships", relationships);
    } 
    
    const handleSelect = (selectedOption, field, type ) => {
        let options;
        if(selectedOption){
            options = selectedOption.map( el => {
                return {type: `taxonomy_term--${type}` , id: el.id, value: el.value}
            })
            setFieldValue(field , options);
            let relationships = {[field]: {data: options}};
            props.mergingUserData( "relationships", relationships);
        }else {
            setFieldValue(field , options);
            let relationships = {[field]: {data: []}};
            props.mergingUserData( "relationships", relationships);
        }
    }

    const rteChange = (event, editor, id) => {
        const data = editor.getData();
        setFieldValue(id , data);
        // props.mergingUserData( "attributes", {[id]: data})
        
    }

    const setTextValues = (e) => {
        const field = e.target.id;
        const value = e.target.value;
        props.mergingUserData( "attributes", {[field]: value});
        handleBlur(e)    
    }

    const onChangeTrim = (e) => {
        e.target.value = e.target.value.trim()
        handleChange(e)
    }

    const handleDateChange = (date, dateString, field) => {
        setFieldValue(field , date);
        props.mergingUserData( "attributes", {[field]: date.toISOString().split('T')[0]});
    };

    const handleSalary = (e) => {
        e.target.value = e.target.value.replace(/[^0-9]*/g,'');
        handleChange(e)
    }

    const handleListSelect = (selectedOption, field) => {
        setFieldValue(field , selectedOption.value);
        props.mergingUserData( "attributes", {[field]: selectedOption.value})
    }
    
    return (
        <div className={classes.container}>
            <form onSubmit={handleSubmit} className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField 
                            id="title"
                            label="Job Title*"
                            type="text"
                            value={values.title} 
                            onChange={handleChange}
                            onBlur={(e) => setTextValues(e)}
                            helperText={touched.title ? errors.title : ""}
                            error={touched.title && Boolean(errors.title)}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            InputProps={{ ...inputStyle }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                    <RadioButtons 
                        title="Remote Job?" 
                        handleChange={(e)=> handleRadio(e,'field_remote_job')} 
                        id="field_remote_job" 
                        value={values.field_remote_job}
                        error={(touched.field_remote_job && errors.field_remote_job)}
                    >
                        {remote}
                    </RadioButtons>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="subtitle2">Salary currency</Typography>
                        <Select 
                            placeholder="Currency"
                            defaultValue={selected ? selected.currency : {label: "United States Dollar", value: "USD"}}
                            options={initialData.currency}  
                            onChange={ (selectedOption) => handleListSelect(selectedOption , 'field_currency') } 
                            styles={selectStyles} 
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="subtitle2">Net Base Salary (Annual)</Typography>
                        <TextField
                            id="field_base_salary_year_from"
                            label="From"
                            onChange={(e) => handleSalary(e)}
                            value={values.field_base_salary_year_from}
                            onBlur={handleBlur}
                            helperText={errors.field_base_salary_year ? errors.field_base_salary_year : ""}
                            error={errors.field_base_salary_year && Boolean(errors.field_base_salary_year)}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            InputProps={{ ...inputNumberProps, ...inputNumberStyle, ...currencyIcon  }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} >
                        <Typography variant="subtitle2">Net Base Salary (Annual)</Typography>
                        <TextField
                            id="field_base_salary_year_to"
                            label="To"
                            onChange={(e) => handleSalary(e)}
                            value={values.field_base_salary_year_to}
                            onBlur={handleBlur}
                            helperText={errors.field_base_salary_year ? errors.field_base_salary_year : ""}
                            error={errors.field_base_salary_year  && Boolean(errors.field_base_salary_year)}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            InputProps={{ ...inputNumberProps, ...inputNumberStyle,...currencyIcon  }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}> 
                        <LocationTextField
                            autocompleteFix
                            placeholder = {values.field_remote_job !== "remote" ?  'Job Location*' : 'Job Location'}
                            id="field_job_city_name"
                            onBlur={handleBlur}
                            helperText = {touched.field_job_city_name ? errors.field_job_city_name : ""}
                            error={touched.field_job_city_name && Boolean(errors.field_job_city_name) && values.field_remote_job !== "remote"}
                            handleLocation={handleLocation}
                            handleRemoveLocation={handleRemoveLocation} 
                            initialData={initialData.country}
                            initialCountry={selected && selected.country}
                            initialState={defaultValues.attributes.field_job_state}
                            initialCity={defaultValues.attributes.field_job_city_name}
                        /> 
                    </Grid>
                    <Grid item xs={12} >
                        <Typography variant="subtitle2">Description*</Typography>
                        <RichTextEditor
                            id="field_description"
                            helperText = {touched.field_description ? errors.field_description : ""}
                            error={touched.field_description && Boolean(errors.field_description)} 
                            data={values.field_description} 
                            rteChange={(event, editor, id) => rteChange(event, editor, id) }
                            onBlur={handleBlur}
                        />
                        <GenderBias id="description" style={{"float": "right"}}/>
                    </Grid>
                    <Grid item xs={12} >
                        <Typography variant="subtitle2">Experience Requirements</Typography>
                        <RichTextEditor
                            id="field_experience_requirements" 
                            data={values.field_experience_requirements} 
                            rteChange={(event, editor, id) => rteChange(event, editor, id) }
                        />
                        <GenderBias id="experience" style={{"float": "right"}}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Select 
                            isMulti
                            defaultValue={selected ? selected.techSkills : null} 
                            placeholder="Tech skills*" 
                            options={initialData.techSkills} 
                            onChange={ (selectedOption) => handleSelect(selectedOption , 'field_technical_skills', 'technical_skills') }
                            onBlur={ () => handleBlur({target:{id: "field_technical_skills"}}) }
                            styles={selectStyles} 
                        />
                        {touched.field_technical_skills && Boolean(errors.field_technical_skills) && <FormHelperText error={true} variant="filled">{errors.field_technical_skills}</FormHelperText>}
                    </Grid>
                    <Grid item xs={12}>
                        <RadioButtons 
                            title="Employment Type*" 
                            handleChange={(e)=> handleRadio(e,'field_employment_type')} 
                            field="field_employment_type"
                            value={values.field_employment_type}
                            error={(touched.field_employment_type && errors.field_employment_type)}
                        >
                            {type}
                        </RadioButtons>
                    </Grid>
                    <Grid item xs={12} > 
                        <TextField
                            id="field_apply_url"
                            label="Apply URL"
                            type="text"
                            value={values.field_apply_url}
                            helperText={touched.field_apply_url ? errors.field_apply_url : ""}
                            error={touched.field_apply_url && Boolean(errors.field_apply_url)}
                            onChange={handleChange}
                            onBlur={(e) => setTextValues(e)}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            InputProps={inputStyle}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                // disableToolbar
                                label="Valid Until*"
                                fullWidth
                                inputVariant="outlined" 
                                format="dd/MM/yyyy"
                                margin="dense"
                                value={values.field_valid_until}
                                InputProps={inputStyle}
                                onChange={(date, dateString) => handleDateChange(date, dateString, "field_valid_until")}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} md={6} > 
                        <TextField
                            id="field_identifier"
                            label="Internal Job Identifier"
                            type="text"
                            value={values.field_identifier}
                            onChange={ e => onChangeTrim(e) }
                            onBlur={ e => setTextValues(e) }
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            InputProps={inputStyle}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Button className={classes.submitBtn} type="submit" variant="contained" disabled={props.orgMandatoryFields || props.error}>
                                {isSubmitting ? <CircularProgress color="secondary" size={25} /> : formEdit ? "Save" : "Save and Create"}
                            </Button>
                        </Grid> 
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

const FormJobNew = withFormik({
    mapPropsToValues: ({
        defaultValues,
        currency,
        date,
        uuid
    }) => {
        return {
            title: defaultValues.attributes.title ? defaultValues.attributes.title : "",
            field_currency: defaultValues.attributes.field_currency ? defaultValues.attributes.field_currency : (currency ? currency :  "USD"),
            field_base_salary_year_to: defaultValues.attributes.field_base_salary_year ? defaultValues.attributes.field_base_salary_year.to : "", 
            field_base_salary_year_from: defaultValues.attributes.field_base_salary_year ? defaultValues.attributes.field_base_salary_year.from : "",
            field_description: defaultValues.attributes.field_description ? defaultValues.attributes.field_description : "",
            field_job_city_name: defaultValues.attributes.field_job_city_name ? defaultValues.attributes.field_job_city_name : "", 
            field_job_state: defaultValues.attributes.field_job_state ? defaultValues.attributes.field_job_state: "",
            field_remote_job: defaultValues.attributes.field_remote_job ? defaultValues.attributes.field_remote_job : "no",
            field_employment_type: defaultValues.attributes.field_employment_type ? defaultValues.attributes.field_employment_type : "",
            field_apply_url: defaultValues.attributes.field_apply_url ? defaultValues.attributes.field_apply_url : "",
            field_experience_requirements: defaultValues.attributes.field_experience_requirements  ? defaultValues.attributes.field_experience_requirements : "",
            field_identifier: defaultValues.attributes.field_identifier ? defaultValues.attributes.field_identifier : "",
            field_technical_skills: defaultValues.relationships.field_technical_skills ? defaultValues.relationships.field_technical_skills.data : "", 
            field_valid_until: defaultValues.attributes.field_valid_until ? new Date(defaultValues.attributes.field_valid_until) : date,
            field_hiring_organization: localStorage.getItem('userOrgData') ? JSON.parse(localStorage.getItem('userOrgData'))[uuid]  ? JSON.parse(localStorage.getItem('userOrgData'))[uuid].organization : "" : ""
          };
    },
    validationSchema: Yup.object().shape({ 
        title: Yup.string().trim().required("Required"),
        field_description: Yup.string().required("Required"),
        field_remote_job: Yup.string(),
        field_job_city_name: Yup.string()
        .when('field_remote_job', {
            is: (value) => value !== "remote" ,
            then: Yup.string().required("Required")
        }),
        field_base_salary_year: Yup.string()
        .test("Salary", "Both values are required ( from > to )", function(code) {
            const { field_base_salary_year_from, field_base_salary_year_to } = this.parent;
            if(field_base_salary_year_from !== undefined || field_base_salary_year_to !== undefined){
                return parseInt(field_base_salary_year_from) <= parseInt(field_base_salary_year_to)
            }
            return true
            
        }),
        field_employment_type: Yup.string().required("Required"),
        field_technical_skills: Yup.array().required("Required"),
        field_apply_url: Yup.string().url('Please provide valid url')
    }),
    
    handleSubmit: (values, { props, setFieldValue ,resetForm }) => {
        let field_hiring_organization = {data: {type: "node--organization_profile",  id: values.field_hiring_organization}};
        props.mergingUserData( "attributes", {['field_base_salary_year']: {from: values.field_base_salary_year_from, to: values.field_base_salary_year_to}})
        props.mergingUserData( "attributes", {['field_description']: {value: values.field_description, format: 'basic_html'}})
        props.mergingUserData( "attributes", {['field_experience_requirements']: {value: values.field_experience_requirements, format: 'basic_html'}})
        props.mergingUserData( "relationships", {'field_hiring_organization': field_hiring_organization});
        props.handleJobSubmit()
    }
  })(JobNew);

export default FormJobNew;
