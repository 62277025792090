import React , { Component } from 'react';
import { withStyles, Modal, Button, Grid, Typography, IconButton, Hidden } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Popover from '../Popover/Popover';
import SelectCheckbox from '../SelectCheckbox/SelectCheckbox';
import RadioFilter from '../RadioFilter/RadioFilter';
import CheckboxFilter from '../CheckboxFilter/CheckboxFilter';
import SearchLocation from '../SearchLocation/SearchLocation';
import RadioOptions from '../SelectCheckbox/RadioOptions';

const styles = theme => ({
    modal: {
        '&:focus':{
            outline: "none" 
        }
    },
    modalContent: {
        padding: `${theme.spacing(2)}px`,
        backgroundColor: '#ffffff',
        height: "100%",
        overflowY: "scroll",
        '&:focus':{
            outline: "none"
        }
    },
    filter: {
        padding: `${theme.spacing(2)}px 0`
    },
    allFilterContainer: {
        width: '100%',
        maxWidth: '920px',
        margin: 'auto',
        paddingTop: `70px`,
    },
    filterHeader: {
        top: 0,
        left: 0,
        position: 'absolute',
        width: '100%',
        background: '#ffff',
        zIndex: 2,
        padding:  `${theme.spacing(2)}px 0`,
        boxShadow: '0px 6px 9px 0px rgb(163 163 163 / 75%)',
        position: 'absolute'
    },
    close: {
        position: 'absolute',
        top: 10,
        right: 30
    },
    allBtn: {
        width: '100px'
    },
    allBtnMobile: {
        margin: '20px 0'
    }
});

const predefinedSelectCheckbox = (predefinedVals, allList, checkedProps, selectedProps, callBack) => {
    if(predefinedVals && selectedProps !== "location"){
        let preDefined;
        if(predefinedVals.includes(",")){
            preDefined = predefinedVals.split(",")

        } else{
            preDefined = predefinedVals.split(" ")
        }
        const result = allList.filter(el => preDefined.indexOf(el.value) >= 0);
        callBack(result, checkedProps, selectedProps)
    }
    if(predefinedVals && selectedProps == "location"){
        const preDefined = predefinedVals.split(" ")
        const result = preDefined.map(el => {
            const replace = el.replaceAll("--", ", ").replaceAll( "-", " ").replaceAll("--", " - " )
            return {label: replace, value: replace}
        })
        callBack(result, checkedProps, selectedProps)
    }
}

const predefinedCheckbox = (predefinedVals, allList, checkedProps, callBack) => {
    if(predefinedVals){
        const preDefined = predefinedVals.split(" ")
        const result = allList.filter(el => preDefined.indexOf(el.value) >= 0);
        result.forEach(el => {
            callBack(el.value, true, checkedProps)
        })
    }
}

const predefinedRadio = (predefinedVals, serachProp, property, callBack) => {
    if(predefinedVals){
        const preDefined = serachProp + predefinedVals
        callBack(preDefined, property)
    }
}

const selectOperator = [
    { label: "Any", id: 0, value: "+" },
    { label: "All", id: 1, value: "," }
]

const expiered = [
    { label: "Expired", value: "&field_valid_until_op=<&field_valid_until[value]=now"},
    { label: "Not Expired", value: "&field_valid_until_op=>&field_valid_until[value]=now"},
    { label: "All", value: ""} 
]

const featured = [
    { label: "Featured jobs", value: "&sticky=1"},
    { label: "All jobs", value: ""} 
]

const defaultState = (initialData) => {
    //Remote
    const remoteRaw = {...initialData.remote}
    const remoteList = []
    for (const property in remoteRaw) {
        remoteList.push({label: remoteRaw[property] , value: property})
    }
    const checkedRemote = {}
    remoteList.forEach(el => {
        Object.assign(checkedRemote, {[el.value]: false});
    });

    //Type
    const type = {...initialData.type}
    const employmentType = []
    for (const property in type) {
        employmentType.push({label: type[property] , value: property})
    }
    const checkedType = {}
    employmentType.forEach(el => {
        Object.assign(checkedType, {[el.value]: false});
    });
    //Skills
    const skillsList = initialData.techSkills.map(el => (
        {
            label: el.label,
            value: `${el.tid}` 
        }
    ))
    //Country
    const countriesList = initialData.country.map(el => (
        {
            label: el.label, 
            value: `${el.tid}`
        }
    ))
     
    return {
        open: false,
        skills: null,
        location: [],
        countries: null,
        employmentType: null,
        remoteList,
        skillsList,
        employmentType,
        countriesList, 
        checked: {
            checkedType,
            checkedRemote,
            checkedSkills: {},
            checkedLocation: {},
            checkedCountries: {}
        },
        radio: {
            checkedExpired: {
                checkedExpired: "",
                checkedExpiredDefault: ""
            },
            checkedFeatured: {
                checkedFeatured: "",
                checkedFeaturedDefault: ""
            },
            checkedOperator: {
                checkedOperator: "+",
                checkedOperatorDefault: "+"
            }
        }
    }
 }

class JobItemsFilter extends Component {

    state = defaultState(this.props.initialData); 
    
    componentDidMount(){
        const skills = this.props.predefinedVals['field_technical_skills'];
        const remote = this.props.predefinedVals['field_remote_job'];
        const employment_type = this.props.predefinedVals['field_employment_type'];
        const location = this.props.predefinedVals['field_location'];
        const country = this.props.predefinedVals['country'];
        const expiered = this.props.predefinedVals['field_valid_until_op'];
        const featured = this.props.predefinedVals['sticky'];
        //Preselect Skills operator
        if(skills && skills.includes(",")){
            this.handleChangeRadio(",", "checkedOperator")
        }
        //Predefined SelectCheckBox
        predefinedSelectCheckbox(skills, this.state.skillsList, "checkedSkills", "skills", this.handleSelect)
        predefinedSelectCheckbox(location, null, "checkedLocation", "location", this.handleSelect)
        predefinedSelectCheckbox(country, this.state.countriesList, "checkedCountries", "countries", this.handleSelect)
        //Predefined CheckBox
        predefinedCheckbox(employment_type, this.state.employmentType, "checkedType", this.handleChange)
        predefinedCheckbox(remote, this.state.remoteList, "checkedRemote", this.handleChange)
        //Predefined Radio
        if(expiered === '>'){
            this.handleChangeRadio("&field_valid_until_op=>&field_valid_until[value]=now", 'checkedExpired')
        }else if(expiered === '<'){
            this.handleChangeRadio("&field_valid_until_op=<&field_valid_until[value]=now", 'checkedExpired')
        }
        if(featured){
            this.handleChangeRadio("&sticky=1", 'checkedFeatured')
        }
    }

    handleOpen = () => {
        this.setState({
            open: true
        })
    };

    handleClose = () => {
        this.setState({
            open: false
        })
    };

    handleSelect = (selectedOption, checkedObj, statePropName) => {
        let obj = {}
        let elVal;
        selectedOption.forEach(el => {
            if(this.state.checked[checkedObj][el.value] === undefined || this.state.checked[checkedObj][el.value] === false){ 
                elVal = el.value
                Object.assign(obj, {[el.value]: true});
            }
        });
        
        //If value already exist
        if(Object.keys(obj).length === 0){
            return
        }
        
        if(this.state.checked[checkedObj][elVal] === undefined || this.state.checked[checkedObj][elVal] === false){
            this.setState(prevState => ({
                checked: {
                    ...prevState.checked,
                    [checkedObj]: {
                        ...prevState.checked[checkedObj],
                        ...obj
                    } 
                }
            }))
        }
        
        this.setState({
            [statePropName]: selectedOption
        })
    }

    handleChange = (name, checked, checkedObj) => {
        this.setState(prevState => ({
            checked:{
                ...prevState.checked,
                [checkedObj]: {
                    ...prevState.checked[checkedObj],
                    [name]: checked 
                }
            }
        }))
    }

    handleChangeRadio = (value, property) => {
        this.setState(prevState => ({
            radio: {
                ...prevState.radio,
                [property]: {
                    ...prevState.radio[property],
                    [property] : value
                }
            }
        }))
    }

    handleSingleApply = (type, queryString) => {
        const checkedObj = {...this.state.checked[type]}
        const arrOfKeys = Object.keys(checkedObj)
        if (arrOfKeys.length === 0){
            return;
        }
        const queryArr = [];
        for (const property in checkedObj) {
            if(checkedObj[property]){
                queryArr.push(property)
            } 
        }
        if(queryString !== "&field_location="){
            if(queryString === "&field_technical_skills="){
                this.props.handleSearch(`${queryString}${queryArr.join(`${this.state.radio.checkedOperator.checkedOperator}`)}`, 0)
            }else{
                this.props.handleSearch(`${queryString}${queryArr.join("+")}`, 0)
            }
        }else if(queryString === "&field_location="){
            const replaced = queryArr.map(el => {
                return el.replaceAll(", ", "--").replaceAll(" - ", "--").replaceAll(" ", "-")
            })
            this.props.handleSearch(`${queryString}${replaced.join("+")}`, 0)
        }
    }

    handleApplyRadio = (type, query) => {
        const radioObj = {...this.state.radio}
        let status = radioObj[type][type];
        this.props.handleSearch(status, 0);
    }

    handleApplyAll = () => {
        //Merge & Apply Checkboxes
        const checkedObj = {...this.state.checked}
        let innerObj;
        let arr = [];
        let queryString = "";
        for (const checkedObjProp in checkedObj) {
            innerObj = checkedObj[checkedObjProp]
            let innerArr = []
            for(const innerObjProp in innerObj){
                if(innerObj[innerObjProp]){
                    innerArr.push(innerObjProp)
                }
            }
            if(checkedObjProp === "checkedSkills"){
                if(innerArr.length > 0){
                    queryString = "&field_technical_skills="
                    arr.push([`${queryString}${innerArr.join(`${this.state.radio.checkedOperator.checkedOperator}`)}`])
                }
            }
            if(checkedObjProp === "checkedLocation"){
                if(innerArr.length > 0){
                    queryString = "&field_location="
                    const replaced = innerArr.map(el => {
                        return el.replace(", ", "--").replace(" ", "-")
                    })
                    arr.push([`${queryString}${replaced.join('+')}`])
                }
            }
            if(checkedObjProp === "checkedType"){
                if(innerArr.length > 0){
                    queryString = "&field_employment_type="
                    arr.push([`${queryString}${innerArr.join('+')}`])
                }
            }
            if(checkedObjProp === "checkedRemote"){
                if(innerArr.length > 0){
                    queryString = "&field_remote_job="
                    arr.push([`${queryString}${innerArr.join('+')}`])
                }
            }
        }
        //Apply Radio
        const radioObj = {...this.state.radio}
        let radioExpired = radioObj.checkedExpired.checkedExpired;
        let radioFeatured = radioObj.checkedFeatured.checkedFeatured;
        arr.push([radioExpired])
        arr.push([radioFeatured])
        //Apply Filter all
        this.props.handleSearch(arr.join(""), 0, "allFilter")
        this.handleClose()
    }

    handleClear = (checkedObj, defaultVal, clearContent, query) => {
        if(this.props.query === null){
            return
        } 
        const queryArr = this.props.query.split("&");
        const remove = queryArr.filter(el =>  (!`&${el}`.includes(query)));
        const mergeArr = remove.join('&');
        let checkedVals = {...this.state.checked[checkedObj]}
        for (const property in checkedVals) {
            checkedVals[property] = false 
        }
        
        this.setState(prevState => ({
            [clearContent]: [],
            checked: {
                ...prevState.checked,
                [checkedObj]: checkedVals
            } 
        }))
        this.props.handleSearch(mergeArr, this.props.page, true)
    }

    handleClearRadio = (property , defaultVal) => {
        let mergeArr;
        const checkedVal = this.state.radio[property][property];
        let queryArr = this.props.query.split("&");
        mergeArr = queryArr.filter(el =>  (!`&${el}`.includes(checkedVal))).join('&');
        
        this.setState(prevState => ({
            radio: {
                ...prevState.radio,
                [property]: {
                    ...prevState.radio[property],
                    [property] : defaultVal
                }
            }
        }))
        this.props.handleSearch(mergeArr, this.props.page, true)
    }

    handleClearAll = () => {
        localStorage.removeItem('jobPostsFilterQuery');
        this.setState(defaultState(this.props.initialData))
        this.props.handleSearch("", 0, true)
    }

    findActive = (checkedFilters) => {
        if(checkedFilters === ""){
            return false
        }
        for (const item in checkedFilters) {
            if(checkedFilters[item]){
                return true
            }
        }
    }

    render(){
        const { classes } = this.props;
        return (
            <div style={{width: '100%', position: 'relative'}}>
                <Hidden xsDown>
                    <Grid container={true} wrap="nowrap" spacing={1} className={classes.filter}>
                        <Grid item> 
                            <Popover 
                                id={"Skills"} 
                                type="checkedSkills" 
                                query={"&field_technical_skills="}
                                clearContent = {"skills"} 
                                handleSingleApply={this.handleSingleApply} 
                                handleClear={this.handleClear}
                                active={this.findActive(this.state.checked.checkedSkills)}
                            >
                                <SelectCheckbox 
                                    checked="checkedSkills"
                                    statePropName="skills"
                                    placeholder="Add Skills"
                                    values = {this.state.skills}
                                    selectOptions = {this.state.skillsList}
                                    checkedObj = {this.state.checked.checkedSkills} 
                                    handleSelect={this.handleSelect}
                                    handleChange={this.handleChange}
                                    handleClear={this.handleClear}
                                >
                                <RadioOptions 
                                    values= {selectOperator}
                                    value= {this.state.radio.checkedOperator.checkedOperator}
                                    checked="checkedOperator"
                                    handleChangeRadio={this.handleChangeRadio}
                                />
                                </SelectCheckbox>
                            </Popover>
                        </Grid>
                        <Grid item>
                            <Popover 
                                id={"Remote"}
                                type="checkedRemote" 
                                query={"&field_remote_job="}
                                handleSingleApply={this.handleSingleApply} 
                                handleClear={this.handleClear}
                                active={this.findActive(this.state.checked.checkedRemote)}
                            >
                                <CheckboxFilter
                                    title="Remote" 
                                    checked="checkedRemote"
                                    values={this.state.remoteList}
                                    checkedObj = {this.state.checked.checkedRemote}
                                    handleChange={this.handleChange} 
                                    handleClear={this.handleClear} 
                                />
                            </Popover>
                        </Grid>
                        <Grid item>
                            <Popover 
                                id={"Location"} 
                                type="checkedLocation" 
                                query={"&field_location="}
                                clearContent = {"location"} 
                                handleSingleApply={this.handleSingleApply} 
                                handleClear={this.handleClear}
                                active={this.findActive(this.state.checked.checkedLocation)}
                            >
                                <SearchLocation
                                    statePropName="location"
                                    checked="checkedLocation"
                                    values = {this.state.location}
                                    checkedObj = {this.state.checked.checkedLocation}
                                    handleSelect={this.handleSelect}
                                    handleChange={this.handleChange}
                                />
                            </Popover>
                        </Grid>
                        <Grid item>
                            <Popover 
                                id={"Expired"} 
                                type="checkedExpired" 
                                default={this.state.radio.checkedExpired.checkedExpiredDefault}
                                handleSingleApply={this.handleApplyRadio} 
                                handleClear={this.handleClearRadio}
                                active={this.findActive(this.state.radio.checkedExpired.checkedExpired)}
                            >
                                <RadioFilter
                                    values={expiered}
                                    checked="checkedExpired"
                                    value={this.state.radio.checkedExpired.checkedExpired}
                                    handleChangeRadio={this.handleChangeRadio}
                                />
                            </Popover>
                        </Grid>
                        <Grid item>
                            <Popover 
                                id={"Employment"}
                                type="checkedType" 
                                query={"&field_employment_type="}
                                handleSingleApply={this.handleSingleApply} 
                                handleClear={this.handleClear}
                                active={this.findActive(this.state.checked.checkedType)}
                            >
                                    <CheckboxFilter 
                                        checked="checkedType"
                                        values={this.state.employmentType}
                                        checkedObj = {this.state.checked.checkedType} 
                                        handleSelect={this.handleSelect}
                                        handleChange={this.handleChange} 
                                        handleClear={this.handleClear} 
                                    />
                            </Popover>
                        </Grid>
                        <Grid item>
                            <Button  className={classes.allBtn} onClick={this.handleOpen}> 
                                All Filters
                            </Button>
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden smUp>
                    <Button className={classes.allBtnMobile} onClick={this.handleOpen} variant="contained" color="primary" fullWidth> 
                        All Filters
                    </Button>
                </Hidden>
                <Grid container item xs={12}>
                    <Grid item container justify="space-between" alignItems="center" xs={12}>
                        {this.props.jobsCount == 0 && `No result found`}
                        {this.props.jobsCount > 0 && `${this.props.jobsCount} ${this.props.jobsCount > 1 ?  "results" : "result"} found`}
                        {this.props.query && <Button  onClick={()=>{ this.handleClearAll()}}>Reset filters </Button>}
                    </Grid>
                </Grid>
                <Modal
                    open={this.state.open}
                    onClose={this.handleClose}
                    className={classes.modal}
                >
                    <div className={classes.modalContent}>
                        <div className={classes.filterHeader}>
                            <Grid container justify="center" spacing={2}>
                                <Grid item>
                                    <Button variant="contained" color="primary" onClick={this.handleApplyAll}>
                                        Apply
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={this.handleClearAll}>
                                        Clear
                                    </Button>
                                </Grid>
                            </Grid>
                            <IconButton  className={classes.close} onClick={this.handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <Grid container spacing={2} className={classes.allFilterContainer}>
                            <Grid item xs={12} sm={6}>
                                <SelectCheckbox 
                                    title="Skills"
                                    checked="checkedSkills"
                                    statePropName="skills"
                                    placeholder="Add Skills"
                                    values = {this.state.skills}
                                    selectOptions = {this.state.skillsList}
                                    checkedObj = {this.state.checked.checkedSkills} 
                                    handleSelect={this.handleSelect}
                                    handleChange={this.handleChange}
                                    handleClear={this.handleClear}
                                >
                                <RadioOptions 
                                    values= {selectOperator}
                                    value= {this.state.radio.checkedOperator.checkedOperator}
                                    checked="checkedOperator"
                                    handleChangeRadio={this.handleChangeRadio}
                                />
                                </SelectCheckbox>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SearchLocation
                                    title="Location"
                                    statePropName="location"
                                    checked="checkedLocation"
                                    stateArr = {this.state.location}
                                    values = {this.state.location}
                                    checkedObj = {this.state.checked.checkedLocation}
                                    handleSelect={this.handleSelect}
                                    handleChange={this.handleChange}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <RadioFilter
                                    title="Expired"
                                    values={expiered}
                                    checked="checkedExpired"
                                    value={this.state.radio.checkedExpired.checkedExpired}
                                    handleChangeRadio={this.handleChangeRadio}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <RadioFilter
                                    title="Featured Job"
                                    values={featured}
                                    checked="checkedFeatured"
                                    value={this.state.radio.checkedFeatured.checkedFeatured}
                                    handleChangeRadio={this.handleChangeRadio}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CheckboxFilter
                                    title="Remote" 
                                    checked="checkedRemote"
                                    values={this.state.remoteList}
                                    checkedObj = {this.state.checked.checkedRemote}
                                    handleChange={this.handleChange} 
                                    handleClear={this.handleClear} 
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CheckboxFilter
                                    title="Employment type" 
                                    checked="checkedType"
                                    values={this.state.employmentType}
                                    checkedObj = {this.state.checked.checkedType}
                                    handleChange={this.handleChange} 
                                    handleCompanyApply={this.handleCompanyApply}
                                    handleClear={this.handleClear} 
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Modal>
                
            </div>
        )
    }
}

export default withStyles(styles)(JobItemsFilter);