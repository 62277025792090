import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import OrganizationItem from '../OrganizationsCandidate/OrganizationItem';
import ReactPaginate from 'react-paginate';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

const useStyles = makeStyles(theme => ({
    root: {
        margin:`${theme.spacing(4)}px auto 0 auto`,
        maxWidth: '1280px',
        padding: '0 10px',
        [theme.breakpoints.up('md')]: {
            padding: `0 ${theme.spacing(4)}px`,
        }
    },
    pagination: {
        display: "flex",
        listStyle: "none",
        justifyContent: "center",
        alignItems: 'center',
        [theme.breakpoints.between('xs', 'md')]: {
            paddingBottom: `100px`,
        },
        '& li':{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 5px',
            width: '30px',
            height: '30px',
            cursor: 'pointer'
        },
        '& a': {
            height: '20px',
            '&:focus': {
                outline: "0"
            }
        }
    },
    active: {
        background: '#eeeeee', 
        borderRadius: '50%' 
    }
}))

const OrganizationsList = (props) => {
    const classes = useStyles();
    let content;
    const goTo = (id) => {
        props.history.push(`/organizations/${id}`)
    }

    content = (
        props.items.map( el => {
            return (
                <Grid 
                className={classes.item} 
                item xs={12} sm={6} md={4} 
                key={el.nid}
                onClick={() => goTo(el.nid)}
                >
                    <OrganizationItem data={el}/>
                </Grid>
            )
        })
    )
    
    return (
        <Grid container className={classes.root}> 
            <Grid container spacing={2}>{content}</Grid>
            <Grid item xs={12}>
                <ReactPaginate
                    previousLabel={<NavigateBeforeIcon fontSize="small"/>}
                    nextLabel={<NavigateNextIcon fontSize="small"/>}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    disableInitialCallback={true} 	
                    pageCount={props.pageCount}
                    initialPage={props.initialPage}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={4}
                    onPageChange={props.onPageChange}
                    containerClassName={classes.pagination}
                    activeClassName={classes.active}
                />
            </Grid>
        </Grid>
    )
} 

export default OrganizationsList;