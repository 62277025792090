import React, { Component } from 'react';
import Drawer from '../Drawer/Drawer';
import Jobs from './Jobs';

class JobsWrapper extends Component {
    render(){
        const { classes, ...otherProps } = this.props;
        return (
            <Drawer drawerItems={[]} noSubnav>
                <Jobs {...otherProps} />
            </Drawer>
        )
    } 
}


export default JobsWrapper;