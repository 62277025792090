import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Drawer from '../Drawer/Drawer';
import AddOrganization from './AddOrganization/AddOrganization';
import ListOrganization from './ListOrganization/ListOrganization';
import Billing from './Billing/Billing';
import OrganizationItem from './ListOrganization/OrganizationItem';
import EditOrganization from './EditOrganization/EditOrganization';
import Settings from './Settings/Settings'; 

class Organization extends Component {
    state = {
        expanded: null,
        drawerItems: [
            {
                text: "Organizations",
                icon: "list_alt",
                pathTo: "/organization/all",
            },
            {
                accordionMenu: []
            }, 
            {
                text: "Add",
                icon: "post_add",
                pathTo: "/organization/add",
            }
        ] 
    }

    componentDidMount(){
        const accordionMenu = this.props.organizations.map(org => { 
            return {
                title: org.title, 
                logo: org.field_logo ? org.field_logo : null,
                items: [
                    {
                        text: "View Profile",
                        icon: "visibility",
                        pathTo: `/organization/view_profile/${org.nid}`,
                    },
                    {
                        text: "Edit Profile",
                        icon: "edit",
                        pathTo: `/organization/edit/${org.nid}`
                    },
                    {
                        text: "Edit Billing Details",
                        icon: "receipt",
                        pathTo: `/organization/billing/${org.nid}`
                    },
                    {
                        text: "Settings",  
                        icon: "settings",
                        pathTo: `/organization/settings/${org.nid}`,
                    }
                ]  
            }
        })

        if(this.props.orgID){
            const drawerItems = [...this.state.drawerItems]
            const item = drawerItems[1]
            item.accordionMenu = accordionMenu
            const expanded = accordionMenu[0].title;
            this.setState({
                expanded, 
                drawerItems
            })
        }
    }
    render(){
        return (
            <Drawer drawerItems={this.state.drawerItems} expanded={this.state.expanded} orgID={this.props.orgID}>
                <Switch>
                    <Route exact path="/organization/add" render={()=> <AddOrganization {...this.props}/>} />
                    <Route path="/organization/all" render={() => <ListOrganization  {...this.props}/>} />
                    <Route exact path="/organization/billing/:id" render={(props) => <Billing {...this.props} {...props} />} />
                    <Route exact path="/organization/view_profile/:id" render={(props)=> <OrganizationItem organizations={this.props.organizations} {...props}/> }/>
                    <Route exact path="/organization/edit/:id" render={(props)=> <EditOrganization organizations={this.props.organizations}  fetchOrg = {this.props.fetchOrg} {...this.props} {...props}/> }/>
                    <Route exact path="/organization/settings/:id" render={(props) => <Settings {...this.props} {...props} />} />
                    <Redirect to="/organization/all" /> 
                </Switch>
            </Drawer>
        )
    } 
}


export default Organization;