import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import JobItems from '../JobItems/JobItems';
import JobItem from '../JobItem/JobItem';
import JobEdit from '../JobEdit/JobEdit';
import Loader from '../../../Utilities/Loader/Loader';

class jobList extends Component {
    render(){
        return(
                <Switch>
                    <Route exact path="/job/all" render={(props)=> <JobItems {...this.props} {...props} />} />
                    <Route exact path="/job/all/:id" render={(props)=> <JobItem {...this.props} {...props} />} />
                    <Route exact path="/job/all/:id/edit" render={(props)=> (
                        this.props.jobList ? <JobEdit {...this.props} {...props} /> : <Loader />
                    )} />
                </Switch>
        )
    }
}

export default jobList;