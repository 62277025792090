import React from 'react';
import { Card, makeStyles, Grid } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles(theme => ({
    successCard: {
        textAlign: "center",
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.error.contrastText
    },
    icon: {
        marginRight: theme.spacing(1),
    }
}));

const AlertError = (props) => {
    const classes = useStyles();
    return (
        <Card className={classes.successCard}>
            <Grid container justify="center" alignItems="center">
                <CheckCircleOutlineIcon className={classes.icon}/>
                <h3>{props.children}</h3>
            </Grid>
        </Card>
    )
}

export default AlertError;