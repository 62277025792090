import React , { Component } from 'react';
import { Button, Popover, withStyles } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const styles = theme => ({
    content: {
        width: '400px', 
        minHeight: "200px",  
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px 40px ${theme.spacing(2)}px`,
        position: "relative",
        [theme.breakpoints.between('xs', 'sm')]: {
            width: '90vw',
        }
    },
    bottomButtons: {
        position: "absolute",
        bottom: `${theme.spacing(2)}px`,
        right: `${theme.spacing(2)}px`
    }
});

class FilterPopover extends Component {
    state = {
        open: false,
        anchorEl: null
    }


    handleClick = (e) => {
        this.setState({
            anchorEl: e.currentTarget,
            id: e.currentTarget.id
        })
    }

    handleClose = () => {
        this.setState({
            anchorEl: null
        })
    }

    handleApply = () => {
        this.handleClose()
        this.props.handleSingleApply(this.props.type, this.props.query)
    }

    handleSingleClear = () => {
        this.handleClose()
        this.props.handleClear(this.props.type, this.props.default, this.props.clearContent, this.props.query)
    }

    render(){
        const { classes } = this.props;
        return (
            <div>
                <Button id={this.props.id} variant={this.props.active ? "contained" : "outlined"} color={this.props.active ? "secondary" : "primary"} onClick={(e) => this.handleClick(e)}>
                    {this.props.id}
                    <ArrowDropDownIcon />
                </Button>
                <Popover
                    PaperProps={{
                        style: {
                            overflowX: 'visible',
                            overflowY: 'visible'
                        }
                    }}
                    open={Boolean(this.state.anchorEl) && this.state.id === this.props.id}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                    }}
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                    }}
                >
                    <div className={classes.content}>
                        {this.props.children}
                        <div className={classes.bottomButtons}>
                                <Button onClick={this.handleSingleClear}>  
                                    Clear
                                </Button>
                                <Button variant="contained" color="primary" onClick={this.handleApply}>
                                    Apply
                                </Button>
                        </div>
                    </div>
                </Popover>
            </div>
        )
    }
}

export default withStyles(styles)(FilterPopover);