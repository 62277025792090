import React, { Fragment } from 'react';
import { Grid, Box, Avatar, Paper, makeStyles, Typography, Button } from '@material-ui/core';
import dummyPicture from '../../images/dummy450x450.jpg';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import WebIcon from '@material-ui/icons/Web';
import MailIcon from '@material-ui/icons/Mail';
import DescriptionIcon from '@material-ui/icons/Description';
import PhoneIcon from '@material-ui/icons/Phone';
import abstract from '../../images/abstract.jpg';
import oauthConfig from '../../oauthConfig';

const useStyles = makeStyles(theme => ({
        avatar: {
            width: "150px",
            height: "150px",
            '&.trial': {
                filter: 'blur(3px)'
            }
        },
        wrapper:{
            // padding: `${theme.spacing(2)}px`,
        },
        container: {
            padding: `${theme.spacing(2)}px`,
            marginBottom: `${theme.spacing(2)}px`,
            position: "relative"
        },
        bg: {
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundSize: "cover",
            opacity: "0.1",
            backgroundRepeat: "no-repeat",
            top: "0",
            left: "0",
            backgroundPosition: "bottom center",
            zIndex: "0",  
            backgroundImage: `url(${abstract})` 
        },
        skill: {
            padding: `${theme.spacing(1)}px ${theme.spacing(2)}px` ,
            background: theme.palette.grey[400],
            color: '#fff',
            margin: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
        },
        socials: {
            color: theme.palette.grey[800],
            marginRight: `${theme.spacing(1)}px`
        },
        links: {
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
            color: theme.palette.grey[800],
            transition: " 0.5s ease",
            marginBottom: `${theme.spacing(1)}px`,
            '&:hover': {
                color: theme.palette.grey[600],
            }
        },
        buttons: {
            paddingBottom: `${theme.spacing(2)}px`,
        }
}))

const Profile = (props) => {
    const classes = useStyles();
    let avatar = () => {

        if(props.userData.includes.picture){
            return <Avatar className={classes.avatar} src={`${oauthConfig.baseUrl}/${props.userData.includes.picture}`}></Avatar>
        }else{
            return <Avatar className={classes.avatar} src={dummyPicture}/>
        }
    }

    let role = () => {
        if(props.userData.attributes.field_current_role){
            return <Typography variant="subtitle1">{props.userData.attributes.field_current_role}</Typography>
        }
    }

    let bio = () => {
        if(props.userData.attributes.field_short_bio){
            return (
                <Paper className={classes.container}>
                    <Typography variant="h5">
                        Short Bio
                    </Typography>
                    <div dangerouslySetInnerHTML={{__html: props.userData.attributes.field_short_bio.value}} />
                </Paper>
            )
        }
    }

    let personalSkills = () => {
        if(props.userData.includes.personal){
            const personalExperience = props.userData.attributes.field_personal_skills_exp;
            return (
                <Paper className={classes.container}>
                <Typography variant="h5" gutterBottom>
                    Personal Skills
                </Typography>
                <Box display="flex">
                    {props.userData.includes.personal.map((el, index) => <div className={classes.skill} key={el}>{`${el} (${personalExperience[index] ? props.initialData.experience[personalExperience[index]] : "-"})`}</div>)}
                </Box>
                </Paper>
            )
        }
    }

    let technicalSkills = () => {
        if(props.userData.includes.technical){
            const skillsExperience = props.userData.attributes.field_technical_skills_exp;
            return (
                <Paper className={classes.container}>
                    <Typography variant="h5">
                        Technical Skills
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>Professional Experience: {props.userData.attributes.field_professional_experience} years</Typography>
                    <Box display="flex" flexWrap="wrap"> 
                        {props.userData.includes.technical.map((el, index) => <div className={classes.skill} key={el}>{`${el} (${skillsExperience[index] ? props.initialData.experience[skillsExperience[index]] : "-"})`}</div>)}
                    </Box>
                </Paper>
            )
        }
    }

    let github = () => {
        if(props.userData.attributes.field_github_profile){
            return (
                <Typography variant="subtitle1">
                    <a className={classes.links} target="_blank" href={props.userData.attributes.field_github_profile.uri} ><GitHubIcon /><Box ml={1}>GitHub</Box></a>
                </Typography>
            )
        }
    }

    let linked = () => {
        if(props.userData.attributes.field_linkedin_profile){
            return (
                <Typography variant="subtitle1">
                    <a className={classes.links} target="_blank" href={props.userData.attributes.field_linkedin_profile.uri} ><LinkedInIcon /><Box ml={1}>LinkedIn</Box></a>
                </Typography>
            )
        }
    }

    let twitter = () => {
        if(props.userData.attributes.field_twitter_profile){
            return (
                <Typography variant="subtitle1">
                    <a className={classes.links} target="_blank" href={props.userData.attributes.field_twitter_profile.uri} ><TwitterIcon /><Box ml={1}>Twitter</Box></a>
                </Typography>
            )
        }
    }

    let cv = () => {
        if(props.userData.includes.pdf){
            return (
                <Typography variant="subtitle1">
                    <a className={classes.links} target="_blank" href={`${oauthConfig.baseUrl}${props.userData.includes.pdf}`} ><DescriptionIcon/><Box ml={1}>Curriculum Vitae</Box></a>
                </Typography>
            )
        }
    }

    let portfolio = () => {
        if(props.userData.attributes.field_portfolio_link){
            return (
                <Typography variant="subtitle1">
                    <a className={classes.links} target="_blank" href={`${props.userData.attributes.field_portfolio_link.uri}`} ><WebIcon/><Box ml={1}>Portfolio</Box></a>
                </Typography>
            )
        }
    }

    let email = () => {
        if(props.userData.attributes.field_contact_email){
            return (
                <Typography variant="subtitle1">
                    <a className={classes.links} target="_blank" href={`mailto:${props.userData.attributes.field_contact_email}`} ><MailIcon/><Box ml={1}>{props.userData.attributes.field_contact_email}</Box></a>
                </Typography>
            )
        }
    }

    let phone = () => {
        if(props.userData.attributes.field_phone_number){
            return (
                <Typography variant="subtitle1">
                    <a className={classes.links} target="_blank" href={`tel:${props.userData.attributes.field_phone_number}`} ><PhoneIcon/><Box ml={1}>{props.userData.attributes.field_phone_number}</Box></a>
                </Typography>
            )
        }
    }

    let arrows = () => {
        if(props.arrows){
            return (
                <Box justifyContent="space-between" display="flex" className={classes.buttons}>
                    <Button variant="outlined" color="primary" onClick={() => props.goToProfile("prev")}><NavigateBeforeIcon /> Prev</Button>
                    <Button variant="outlined" color="primary" onClick={() => props.goToProfile("next")}>Next <NavigateNextIcon /></Button>
                </Box>
            )
        }
    }

    let language = () => {
        if(props.userData.attributes.field_languages.length > 0){
            const languages = props.initialData.languages.filter(element => props.userData.attributes.field_languages.indexOf(element.value) !== -1);
            const proficiencies = props.initialData.languageProficiencies.filter(element => props.userData.attributes.field_language_proficiencies.indexOf(element.value) !== -1);
            return (
                <Paper className={classes.container}>
                    <Typography variant="h5" gutterBottom>
                        Languages
                    </Typography> 
                        {languages.map((el,i) => <Box display="flex" flexDirection="column" key={el.value}><Typography variant="subtitle2">{el.label}:</Typography> <Typography variant="body2" gutterBottom>{ proficiencies[i] && proficiencies[i].label }</Typography></Box>)}
                </Paper>
            )
        }
    }

    let salary = () => {
        if(props.userData.attributes.field_desired_annual_salary){
            return (
                <Paper className={classes.container}>
                    <Typography variant="h5" gutterBottom>
                        Desired annual salary
                    </Typography> 
                    <Typography variant="body2">
                        {`From ${props.userData.attributes.field_desired_annual_salary.from} ${props.userData.attributes.field_currency} to ${props.userData.attributes.field_desired_annual_salary.to} ${props.userData.attributes.field_currency}`}
                    </Typography>
                    <Typography variant="body2"></Typography>  
                </Paper>
            )
        }
    }

    let back = () => {
        if(!localStorage.getItem('role').includes('candidate')) {
            return (
                <Box display="flex" justifyContent="center" mb={2}>
                    <Button variant="outlined" color="primary" onClick={() => props.goBack()}>Back to candidates</Button>
                </Box>
            )
        }
    }

    return (
        <div className={classes.wrapper}>
            { back() }
            <Paper className={classes.container}>
                <div className={classes.bg}></div>
                <Grid container style={{zIndex: "1", position: "relative"}}>
                    <Grid item container direction="column" justify="center" alignItems="center" xs={12} md={7}>
                        { avatar() }
                        <Typography variant="h4"> 
                                {props.userData.attributes.field_first_name} {props.userData.attributes.field_last_name}
                        </Typography>
                        { role() }
                        <Typography variant="h6">
                            {props.userData.includes.country}
                            {props.userData.includes.country && props.userData.attributes.field_city_text ? ", " : null}
                            {props.userData.attributes.field_city_text}
                        </Typography>
                    </Grid>
                    <Grid item container direction="column" justify="center" alignItems="flex-start" xs={12} md={5}>
                        <div>
                            { github() }
                            { linked() }
                            { twitter()}
                            { cv() }
                            { phone() }
                            { portfolio() }
                            { email() }
                        </div>
                    </Grid>
                </Grid>
            </Paper>
            { bio() }
            { technicalSkills() }
            { personalSkills() }
            { language() }
            { salary() }
            { arrows() }
        </div>
    )
}

export default Profile;
