import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { withFormik } from "formik";
import * as Yup from "yup";
import { withStyles, Card, CardContent, CardActions, TextField, Button, Typography } from "@material-ui/core";
import Footer from "../Footer/Footer";

const styles = (theme) => ({
  card: {
    maxWidth: 420,
  },
  container: {
    display: "Flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 50,
    padding: 10,
  },
  linkText: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    color: theme.palette.grey[800],
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
});

const loginForm = (props) => {
  const { classes, values, touched, errors, handleChange, handleBlur, handleSubmit } = props;

  const preventSpace = (e) => {
    e.target.value = e.target.value.trim();
    handleChange(e);
  };

  return (
    <Fragment>
      <div className={classes.container}>
        <form onSubmit={handleSubmit}>
          <Card className={classes.card}>
            <CardContent>
              <TextField
                id="email"
                label="Email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.email ? errors.email : ""}
                error={touched.email && Boolean(errors.email)}
                margin="dense"
                variant="outlined"
                fullWidth
              />
              <TextField
                id="pass"
                label="Password"
                type="password"
                onChange={(e) => preventSpace(e)}
                onBlur={handleBlur}
                helperText={touched.pass ? errors.pass : ""}
                error={touched.pass && Boolean(errors.pass)}
                margin="dense"
                variant="outlined"
                fullWidth
              />
            </CardContent>
            <CardActions>
              <Button type="submit" variant="contained" fullWidth={true}>
                Save and Continue
              </Button>
            </CardActions>
          </Card>
        </form>
        <Typography variant="body2" className={classes.linkText}>
          <Link className={classes.link} to="/forgotten-password">
            Forgot your password?
          </Link>
        </Typography>
        <Typography variant="body2" className={classes.linkText}>
          No account yet?&nbsp;&nbsp;
          <Link className={classes.link} to="/sign-up">
            Sign up!
          </Link>
        </Typography>
      </div>
      <Footer />
    </Fragment>
  );
};

const Form = withFormik({
  mapPropsToValues: ({ email, pass }) => {
    return {
      email: email || "",
      pass: pass || "",
    };
  },

  validationSchema: Yup.object().shape({
    email: Yup.string().email("Enter a valid email").required("Email is required"),
    pass: Yup.string().min(8, "Password must contain at least 8 characters").required("Enter your password"),
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    props.loginFunction("login", values.email, values.pass);
  },
})(loginForm);

export default withStyles(styles)(Form);
