import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Typography, Tab, Tabs, Card, CardContent, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import SignUpTalent from "./SignUpTalent/SignUpTalent";
import SignUpEmployer from "./SignUpEmployer/SignUpEmployer";
import Footer from "../../Footer/Footer";

function TabContainer(props) {
  return <div>{props.children}</div>;
}

const styles = (theme) => ({
  root: {
    width: "100%",
    padding: "10px",
  },
  linkText: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    color: theme.palette.grey[800],
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  card: {
    maxWidth: 420,
    marginTop: 50,
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },
  buttons: {
    fontSize: "18px",
  },
});

class ScrollableTabsButtonAuto extends React.Component {
  state = {
    value: sessionStorage.getItem("tab") ? parseInt(sessionStorage.getItem("tab")) : null,
  };

  handleChange = (event, value) => {
    sessionStorage.setItem("tab", value);
    this.setState({ value });

    this.props.history.push({
      search: `?tab=${value}`,
    });
  };

  componentDidMount() {
    this.props.cc();
    const tab = this.props.history.location.search;
    if (tab) {
      const value = parseInt(tab.split("=")[1]);
      this.handleChange(null, value);
    }
    if (this.state.value !== null) {
      this.handleChange(null, this.state.value);
    }
  }

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    let content = <SignUpEmployer {...this.props} />;
    // if (this.state.value !== null) {
    //   content = (
    //     <Fragment>
    //       <Tabs value={value} onChange={this.handleChange} indicatorColor="primary" textColor="primary" scrollable="false" scrollButtons="auto" centered>
    //         <Tab label="Talent" className={classes.buttons} />
    //         <Tab label="Employer" className={classes.buttons} />
    //       </Tabs>
    //       {value === 1 && (
    //         <TabContainer>
    //           <SignUpEmployer {...this.props} />
    //         </TabContainer>
    //       )}
    //       {value === 0 && (
    //         <TabContainer>
    //           <SignUpTalent {...this.props} />
    //         </TabContainer>
    //       )}
    //     </Fragment>
    //   );
    // } else {
    //   content = (
    //     <Card className={classes.card}>
    //       <CardContent>
    //         <Typography variant="h4" gutterBottom>
    //           Sign up as
    //         </Typography>
    //         <Button variant="contained" onClick={() => this.handleChange(null, 0)}>
    //           Talent
    //         </Button>{" "}
    //         <Button variant="contained" onClick={() => this.handleChange(null, 1)}>
    //           Employer
    //         </Button>
    //       </CardContent>
    //     </Card>
    //   );
    // }

    return (
      <Fragment>
        <div className={classes.root}>
          {content}
          <Typography variant="body2" className={classes.linkText}>
            Already a member?&nbsp;&nbsp;
            <Link className={classes.link} to="/login">
              Login!
            </Link>
          </Typography>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

export default withStyles(styles)(ScrollableTabsButtonAuto);
