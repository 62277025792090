import React, { Fragment } from 'react';
import { Grid, Box, Avatar, Paper, makeStyles, Typography, Button } from '@material-ui/core';
import dummyPicture from '../../../images/dummy450x450.jpg';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import abstract from '../../../images/abstract.jpg';
import oauthConfig from '../../../oauthConfig';

const useStyles = makeStyles(theme => ({
        avatar: {
            width: "150px",
            height: "150px",
            '&.trial': {
                filter: 'blur(3px)'
            }
        },
        wrapper:{
            padding: `${theme.spacing(2)}px`,
        },
        container: {
            padding: `${theme.spacing(2)}px`,
            marginBottom: `${theme.spacing(2)}px`,
            position: "relative"
        },
        bg: {
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundSize: "cover",
            opacity: "0.1",
            backgroundRepeat: "no-repeat",
            top: "0",
            left: "0",
            backgroundPosition: "bottom center",
            zIndex: "0",  
            backgroundImage: `url(${abstract})` 
        },
        skill: {
            padding: `${theme.spacing(1)}px ${theme.spacing(2)}px` ,
            background: theme.palette.grey[400],
            color: '#fff',
            margin: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
        },
        socials: {
            color: theme.palette.grey[800],
            marginRight: `${theme.spacing(1)}px`
        },
        links: {
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
            color: theme.palette.grey[800],
            transition: " 0.5s ease",
            marginBottom: `${theme.spacing(1)}px`,
            '&:hover': {
                color: theme.palette.grey[600],
            }
        },
        buttons: {
            paddingBottom: `${theme.spacing(2)}px`,
        }
}))

const Profile = (props) => {
    const classes = useStyles();
    let avatar = () => {
        if(props.userData.includes.picture){
            return <Avatar className={classes.avatar} src={`${oauthConfig.baseUrl}/${props.userData.includes.picture}`}></Avatar>
        }else{
            return <Avatar className={classes.avatar} src={dummyPicture}/>
        }
    }

    let role = () => {
        if(props.userData.attributes.field_role_at_company){
            return <Typography variant="subtitle1">{props.userData.attributes.field_role_at_company}</Typography>
        }
    }

    let position = () => {
        if(props.userData.attributes.field_company_position){
            const positions = {...props.initialData.position};
            let match;
            for (const position in positions) {
                if(position === props.userData.attributes.field_company_position ){
                    match = positions[position];
                }
            }
            return <Typography variant="h6">{match}</Typography>
        }
    }

    let email = () => {
        if(props.userData.attributes.field_contact_email){
            return (
                <Typography variant="subtitle1">
                    <a className={classes.links} target="_blank" href={`mailto:${props.userData.attributes.field_contact_email}`} ><MailIcon/><Box ml={1}>{props.userData.attributes.field_contact_email}</Box></a>
                </Typography>
            )
        }
    }

    let phone = () => {
        if(props.userData.attributes.field_phone_number){
            return (
                <Typography variant="subtitle1">
                    <a className={classes.links} target="_blank" href={`tel:${props.userData.attributes.field_phone_number}`} ><PhoneIcon/><Box ml={1}>{props.userData.attributes.field_phone_number}</Box></a>
                </Typography>
            )
        }
    }

    return (
        <div className={classes.wrapper}>
            <Paper className={classes.container}>
                <div className={classes.bg}></div>
                <Grid container style={{zIndex: "1", position: "relative"}}>
                    <Grid item container direction="column" justify="center" alignItems="center" xs={12} md={7}>
                        { avatar() }
                        <Typography variant="h4"> 
                                {props.userData.attributes.field_first_name} {props.userData.attributes.field_last_name}
                        </Typography>
                        { role() }
                    </Grid>
                    <Grid item container direction="column" justify="center" alignItems="flex-start" xs={12} md={5}>
                        <div>
                            { position() }
                            { phone() }
                            { email() }
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

export default Profile;