import React, { useState } from 'react';
import { Card, makeStyles, Grid, Snackbar } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles(theme => ({
    errorCard: {
        width: '400px',
        textAlign: "center",
        padding: theme.spacing(2),
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.error.contrastText
    },
    icon: {
        marginRight: theme.spacing(1),
    }
}));

const AlertErrorAuto = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);

    const close = () => {
        setOpen(false);
        props.clear();
    }
    
    return (
        <Snackbar 
            autoHideDuration={5000}
            onClose={ () => close() } 
            open={open}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
        >
            <Card className={classes.errorCard}>
                <Grid container justify="center" alignItems="center">
                    <ErrorOutlineIcon className={classes.icon}/>
                    <h3>{props.children}</h3>
                </Grid>
            </Card>
        </Snackbar>
    )
}

export default AlertErrorAuto;