import React , { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import oauthConfig from '../../oauthConfig';
import OrganizationList from './OrganizationList';
import OrganizationProfile from './OrganizationProfile';
import Drawer from '../Drawer/Drawer'; 
import Loader from '../Utilities/Loader/Loader';
import queryString from 'query-string';
import axios from 'axios';

let totalCandidates = 0;
let pageCount = null;
const perPage = 30;

class Organizations extends Component {

    state = {
        items: null,
        pageCount,
        page: 0,
        count: 0
    }

    componentDidMount(){
        const query = queryString.parse(this.props.location.search)
        const page = parseInt(query.page)
        if(page && page !== 0){
            const offset = perPage * page
            this.getOrganizations(offset)
            this.setState({ page })
            return
        }
        this.getOrganizations(0)
    }

    getOrganizations = async(offset, count) => {
        if(this.state.items){ 
            this.setState({ items: null})
        }
        const token = this.props.token;
        const headers = { 
          headers: {
          'Accept': 'application/vnd.api+json',
          'Content-Type': 'application/vnd.api+json',
        //   'Authorization': `${token.token_type} ${token.access_token}`
          }
        }
        const currentUserOrganization = await axios.get(`${oauthConfig.baseUrl}/api/organizations/export?page[limit]=${perPage}&page[offset]=${offset}` , headers);
        totalCandidates = currentUserOrganization.data.length > 0 ? currentUserOrganization.data[0].total_rows : 0;
        pageCount = Math.ceil(totalCandidates / perPage);
        let items =  [...currentUserOrganization.data];
        this.setState({
            items,
            pageCount,
            count: count ? count : 0
        })
    }

    handlePaging = (e) => {
        const page = e.selected
        this.props.history.push({
            search: `page=${page}`
        })
        const offset = perPage * page
        this.setState({ page })
        this.getOrganizations(offset)
    }

    findOrg = async() => {
        let count = this.state.count
        count++ 
        const offset = count*perPage
        this.getOrganizations(offset, count)
    }

    render(){
        return (
            <Drawer drawerItems={[]} noSubnav>
                <Switch>
                    <Route path="/organizations/:id" render={(props) => this.state.items ? <OrganizationProfile organizations={this.state.items} findOrg={this.findOrg} {...props} /> : <Loader />}/>
                    <Route path="/organizations" render={(props) => this.state.items ? <OrganizationList items={this.state.items} {...props} {...this.props} pageCount={this.state.pageCount} initialPage={this.state.page} onPageChange={this.handlePaging} /> : <Loader />}/>
                </Switch>
            </Drawer>
        )
    }
} 

export default Organizations;