import React, {Component, Fragment} from 'react';
import { withRouter } from 'react-router-dom';
import { Box, Snackbar, Card, Button } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { withStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import ErrorQuota from '../../../Utilities/Error/ErrorText';
import Loader from '../../../Utilities/Loader/Loader';
import config from '../../../../oauthConfig';
import axios from 'axios';
import JobNewForm from './JobNewForm';

const styles = theme => ({
  errorCard: {
    textAlign: "center",
    padding: theme.spacing(2),
    backgroundColor: green[500],
    color: theme.palette.error.contrastText
  },
});

const getDate = () => {
  var now = new Date();
  const current = new Date(now.getFullYear(), now.getMonth()+1, now.getDate());
  return current
}

class JobNew extends Component {
    state = {
        date: getDate(),
        loading: false,
        feedback: false,
        currency: this.props.currentOrg.field_currency,
        selected: {
          currency: this.props.initialData.currency.find(curr => curr.value === this.props.currentOrg.field_currency),
        },
        userData: {
            type: "node--job_posting",
            attributes: {},
            relationships: {}
        }
    }

    mergingUserData = (dataType, newData) => {
        this.setState( prevState => ({
            userData: {
              ...prevState.userData,
              [dataType]: {
                ...prevState.userData[dataType],
                ...newData
              }
            }
        }));
    }
    
    handleOpenFeedback = () => {
      this.setState({feedback: true}) 
    }

    handleCloseFeedback = () => {
      this.setState({feedback: false})
    }

    handleJobSubmit = () => {
      let token = this.props.token;
      const headers = {
          headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
            'Authorization': `${token.token_type} ${token.access_token}`
          }
      }
      this.setState({loading: true}) 
      axios.post(`${config.baseUrl}/api/node/job_posting`, {data: this.state.userData}, headers)  
      .then(res => {
        this.setState({loading: false})
        let page = this.props.page ?  this.props.page : 0;
        this.props.fetchAll(page); 
        this.props.history.push('/job/all');
      })
      .catch(err => {
        if(err.response){
          if(err.response.status === 401){
            this.props.logout()
          }
          if(err.response.status === 403){
            this.setState({
              loading: false,
              error: true,
              errorText: err.response.data
            })
          }
        }
      });
    }

    render(){
      const { classes } = this.props;
      const orgMandatoryFields = !Boolean(this.props.currentOrg.field_company_description && this.props.currentOrg.field_logo)
      const orgId = this.props.currentOrg.nid;
        return (
            this.state.loading ? (
              <Loader />
            ) : (
                <Fragment>
                    { this.props.jobQuota == 0 && <ErrorQuota>{"Job post quota exceeded. Please contact"} <a href='mailto:sales@womentech.net' target='_blank'>sales@womentech.net</a></ErrorQuota> }
                    { orgMandatoryFields && (
                        <Box textAlign="center" mb={2}>
                          <ErrorQuota>{"Please add the organization description and organization logo to save the job post."}</ErrorQuota>
                          <Button className={classes.submitBtn} type="submit" variant="contained" onClick={() => this.props.history.push(`/organization/all/${orgId}/edit`)}>
                            Go to edit organization
                          </Button>
                        </Box>
                      )
                    }
                    <JobNewForm
                      {...this.props}
                      defaultValues={this.state.userData}
                      formEdit = {false}
                      date={this.state.date}
                      currency={this.state.currency}
                      selected={this.state.selected}
                      initialData={this.props.initialData} 
                      mergingUserData={this.mergingUserData} 
                      handleJobSubmit={this.handleJobSubmit}
                      error={this.props.jobQuota == 0}
                      orgMandatoryFields={orgMandatoryFields}
                    />

                    <Snackbar 
                      autoHideDuration={2000} 
                      open={this.state.feedback} 
                      onClose={this.handleCloseFeedback}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}>
                      <Card className={classes.errorCard}>
                          <div><CheckCircleOutlineIcon fontSize="large"/></div>
                            <p>Job have been added</p>
                      </Card>
                    </Snackbar>

                </Fragment>
            )
        )
    }
}
    

export default withRouter(withStyles(styles)(JobNew));
