import React, { Fragment, useState, forwardRef } from 'react';
import { Grid, Box, Avatar, Paper, makeStyles, Typography, Button, Slide, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import dummyPicture from '../../images/dummy450x450.jpg';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import abstract from '../../images/abstract.jpg';
import oauthConfig from '../../oauthConfig';
import Blur from 'react-blur';

const useStyles = makeStyles(theme => ({
        avatar: {
            width: "150px",
            height: "150px"
        },
        wrapper: {
            padding: `${theme.spacing(2)}px`,
            marginBottom: `${theme.spacing(2)}px`,
            position: "relative"
        },
        bg: {
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundSize: "cover",
            opacity: "0.1",
            backgroundRepeat: "no-repeat",
            top: "0",
            left: "0",
            backgroundPosition: "bottom center",
            zIndex: "0",  
            backgroundImage: `url(${abstract})` 
        },
        skill: {
            padding: `${theme.spacing(1)}px ${theme.spacing(2)}px` ,
            background: theme.palette.grey[400],
            color: '#fff',
            margin: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
        },
        socials: {
            color: theme.palette.grey[800],
            marginRight: `${theme.spacing(1)}px`
        },
        links: {
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
            color: theme.palette.grey[800],
            transition: " 0.5s ease",
            marginBottom: `${theme.spacing(1)}px`,
            '&:hover': {
                color: theme.palette.grey[600],
            }
        }
}))

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Profile = (props) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    let avatar = () => {
        if(props.userData.field_profile_picture){
            return <Avatar className={classes.avatar}><Blur blurRadius={10} img={props.userData.field_profile_picture} className={classes.avatar} /></Avatar>
        }else{
            return <Avatar className={classes.avatar} src={dummyPicture}/>
        }
    }

    let role = () => {
        if(props.userData.field_current_role){
            return <Typography variant="subtitle1">{props.userData.field_current_role}</Typography>
        }
    }

    let technicalSkills = () => {
        if(props.userData.field_technical_skills_name){
            const skills = props.userData.field_technical_skills_name.split(";");
            return (
                <Paper className={classes.wrapper}>
                    <Typography variant="h5">
                        Technical Skills
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>Professional Experience: {props.userData.field_professional_experience} years</Typography>
                    <Box display="flex" flexWrap="wrap"> 
                        {skills.map(el => <div className={classes.skill} key={el}>{el}</div>)}
                    </Box>
                </Paper>
            )
        }
    }

    let arrows = () => {
        if(props.arrows){
            return (
                <Box justifyContent="space-between" display="flex" className={classes.buttons}>
                    <Button variant="outlined" color="primary" onClick={() => props.goToProfile("prev")}><NavigateBeforeIcon /> Prev</Button>
                    <Button variant="outlined" color="primary" onClick={() => props.goToProfile("next")}>Next <NavigateNextIcon /></Button>
                </Box>
            )
        }
    }

    let language = () => {
        // if(props.userData.attributes.field_languages.length > 0){
        //     const languages = props.initialData.languages.filter(element => props.userData.attributes.field_languages.indexOf(element.value) !== -1);
        //     const proficiencies = props.initialData.languageProficiencies.filter(element => props.userData.attributes.field_language_proficiencies.indexOf(element.value) !== -1);
        //     return (
        //         <Paper className={classes.wrapper}>
        //             <Typography variant="h5" gutterBottom>
        //                 Languages
        //             </Typography> 
        //                 {languages.map((el,i) => <Box display="flex" flexDirection="column" key={el.value}><Typography variant="subtitle2">{el.label}:</Typography> <Typography variant="body2" gutterBottom>{proficiencies[i].label}</Typography></Box>)}
        //         </Paper>
        //     )
        // }
    }


    return (
        <Fragment>
            <Box display="flex" justifyContent="center" mb={2}>
                <Button variant="outlined" color="primary" onClick={() => props.goBack()}>Back to candidates</Button>
            </Box>
            <Paper className={classes.wrapper}>
                <div className={classes.bg}></div>
                <Grid container style={{zIndex: "1", position: "relative"}}>
                    <Grid item container direction="column" justify="center" alignItems="center" xs={12} md={7}>
                        { avatar() }
                        <Typography variant="h4"> 
                                {props.userData.field_first_name} {props.userData.field_last_name}
                        </Typography>
                        { role() }
                        <Typography variant="h6">
                            {props.userData.field_candidate_country}
                            {props.userData.field_candidate_country && props.userData.field_city_text ? ", " : null}
                            {props.userData.field_city_text}
                        </Typography>
                    </Grid>
                    <Grid item container direction="column" justify="center" alignItems="flex-start" xs={12} md={5}>
                        <Button onClick={toggle} variant="contained" color="primary" >Subscribe to unlock profile</Button>
                    </Grid>
                </Grid>
            </Paper>
            { technicalSkills() }
            { language() }
            { arrows() }
            <Dialog 
                open={isOpen}
                TransitionComponent={Transition}
                >
                    <DialogContent>
                        <Typography>To enable access to candidate profiles upgrade your account.  Please purchase a network partnership package or contact our sales team.</Typography> 
                    </DialogContent>
                    <DialogActions>
                        <Button target="_blank" rel="noopener" href="https://www.womentech.net/women-tech-conference/partner?product=NetworkHiring" color="primary" variant="contained">Contact Sales</Button>
                        <Button target="_blank" rel="noopener" href="https://shop.womentech.net/products/network-membership" color="primary" variant="contained">Upgrade</Button>
                        <Button onClick={toggle} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
        </Fragment>
    )
}

export default Profile;
