import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import Drawer from '../Drawer/Drawer';
import CandidatesFilter from './CandidatesFilter';
import CandidateProfile from './CandidateProfile';
import Pipelines from './Pipelines/Pipelines';


const styles = theme => ({
    root: {
        maxWidth: '940px',
        margin:  `0 auto ${theme.spacing(1)}px auto`,
        padding: `${theme.spacing(2)}px`, 
        [theme.breakpoints.up('md')]: {
            padding: `${theme.spacing(4)}px`
        }
    }
});



class Candidates extends Component {
    render(){
        const { classes, ...otherProps } = this.props;
        const drawerItems = []
        const search = {
            text: "Candidate Search",
            icon: "search",
            pathTo: "/candidates/filter",
        };
        const board = {
            text: "Candidates Board",
            icon: "table_chart",
            pathTo: "/candidates/board",
        };
        drawerItems.push(search)
        if(otherProps.candidateDB){
            drawerItems.push(board)
        }
        return (
            <Drawer drawerItems={drawerItems} orgID={otherProps.currentOrg.uuid}>
                <div className={classes.root}>
                    <Switch>
                        <Route path="/candidates/board" render={(props) => <Pipelines  {...props} {...otherProps}/>} />
                        <Route path="/candidates/filter/:id" render={(props) => <CandidateProfile  {...props} {...otherProps}/>} />
                        <Route path="/candidates/filter" render={(props) => <CandidatesFilter  {...props} {...otherProps}/>} />
                        <Redirect to="/candidates/filter" /> 
                    </Switch>
                </div>
            </Drawer>
        )
    } 
}


export default withStyles(styles)(Candidates);