import React from 'react';
import { Grid, Typography, Button, makeStyles, IconButton } from '@material-ui/core';
import AlertError from '../Utilities/Alert/AlertError';
import CloseIcon from '@material-ui/icons/Close';
import oauthConfig from '../../oauthConfig';
import placeholder from '../../images/placeholder.png';
import parse from 'html-react-parser';
import { Html5Entities } from 'html-entities';
const htmlEntities = new Html5Entities();

const useStyles = makeStyles(theme => ({ 
    wrapper: {
        padding: `0 ${theme.spacing(1)}px`,
    },
    logoLink: {
        width: '80px',
        height: '80px',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        margin: '0 auto',
        cursor: "pointer"
    },
    header: {
        padding: `${theme.spacing(4)}px 0`,
        // borderBottom: "1px solid #D3D3D3"
    },
    headerDetails: {
        color: "#505050",
        cursor: "pointer"
    },
    details: {
        borderTop: "1px solid #D3D3D3",
        borderBottom: "1px solid #D3D3D3",
        marginBottom: `${theme.spacing(2)}px`
    },
    detailsItems: {
        padding: `${theme.spacing(2)}px`,
        textTransform: "capitalize",
        '&:nth-of-type(2)':{
            borderLeft: "1px solid #D3D3D3",
            borderRight: "1px solid #D3D3D3"
        }
    },
    container: {
        padding: `${theme.spacing(2)}px`,
    }
}));


const JobSelected = (props) => {
    const classes = useStyles();
    
    const selected = props.jobs.filter(el => {
        return el.nid === props.match.params.id
    })
    
    const goTo = (uuid) => { 
        props.history.push({
            pathname: `/organizations/${uuid}`,
        })
    } 
    let logo;
    let content;
    let currency;
    if(!selected[0]){
        // content = <AlertError>Something went wrong!</AlertError>
    }else{
        currency = selected[0].field_currency ? selected[0].field_currency : 'USD'
        if(selected[0].logo){
            logo = `${oauthConfig.baseUrl}/${selected[0].logo}`
        }else {
            logo = placeholder
        }
        content = (
            <div className={classes.wrapper}>
                {props.mobile && <IconButton onClick={() => props.history.goBack()}><CloseIcon /></IconButton>}
                <Grid container className={classes.header}>
                    <Grid item xs={3}>
                        <div className={classes.logoLink} style={{backgroundImage: `url(${logo})`}} onClick={() => goTo(selected[0].field_hiring_organization)}></div>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography 
                        variant="h5"
                        >
                            {parse(selected[0].title)}
                        </Typography>
                        <Typography variant="body1" className={classes.headerDetails} onClick={() => goTo(selected[0].field_hiring_organization)}>
                            {`${parse(selected[0].field_hiring_organization_1)} - ${selected[0].field_job_country}`}
                            {selected[0].field_job_state && ", "}
                            {selected[0].field_job_state} 
                            {selected[0].field_job_city_name && ", "}
                            {selected[0].field_job_city_name}
                        </Typography>
                    </Grid>
                    {selected[0].field_apply_url && (
                        <Grid item container justify="center">
                            <Button variant="contained" color="primary" target="_blank" href={selected[0].field_apply_url}>
                                Apply
                            </Button>
                        </Grid>
                    )}
                </Grid>
                <Grid container className={classes.details}>
                    <Grid item  xs={12} sm={4} className={classes.detailsItems}>
                        <Typography variant="caption">Published</Typography>
                        <Typography variant="subtitle2">{selected[0].created}</Typography>
                    </Grid>
                    <Grid item  xs={12} sm={4} className={classes.detailsItems}>
                        <Typography variant="caption">Employment type</Typography>
                        <Typography variant="subtitle2">{selected[0].field_employment_type}</Typography>
                    </Grid>
                    <Grid item  xs={12} sm={4} className={classes.detailsItems}>
                        <Typography variant="caption">Remote job?</Typography>
                        <Typography variant="subtitle2">{selected[0].field_remote_job}</Typography>
                    </Grid>
                </Grid>
                <div className={classes.container}>
                    <Typography variant="h6">Job description</Typography>
                    {parse(selected[0].field_description)}
                    {/* <div dangerouslySetInnerHTML={{ __html: selected[0].field_description }} /> */}
                
                    {
                        selected[0].field_experience_requirements && (
                            <div>
                                <Typography variant="h6">Experience Requirements</Typography>
                                {parse(selected[0].field_experience_requirements)}
                            </div>
                        )
                    }
                    <Grid container spacing={1}>
                        {
                            selected[0].field_technical_skills && (
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="subtitle2">Technical skills</Typography>
                                    {selected[0].field_technical_skills.split(";").join(", ")}
                                </Grid>
                            )
                        }
                        {
                            selected[0].field_location_requirement && (
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="subtitle2">Location Requirement</Typography>
                                    {selected[0].field_location_requirement.split(";").join(", ")}
                                </Grid>
                            )
                        }
                        {
                            selected[0].field_base_salary_year_from || selected[0].field_base_salary_year_to ? (
                                <Grid item xs={12} sm={6}>
                                    <Typography component="h2" variant="subtitle2">
                                        Net base salary (annual)
                                    </Typography>
                                        <Typography variant="body2">
                                            {`From ${selected[0].field_base_salary_year_from} ${currency}`} {`to ${selected[0].field_base_salary_year_to} ${currency}`}
                                        </Typography>
                                </Grid>
                            ) : null
                        }
                        {
                            selected[0].field_professional_experience && (
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="subtitle2">Professional Experience</Typography>
                                    {selected[0].field_professional_experience}
                                </Grid>
                            )
                        }
                    </Grid>
                </div>
            </div>
        )
    }
    
    return (
        <div>
             { content }
        </div>
    )
}

export default JobSelected;
