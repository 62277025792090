import React, { Fragment, useState } from 'react';
import { Grid, makeStyles, Paper, Avatar, Button, Checkbox, Box } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import BusinessIcon from '@material-ui/icons/Business';
import oauthConfig from '../../../oauthConfig';
import ErrorText from '../../Utilities/Error/ErrorText';
import parse from 'html-react-parser';


const useStyles = makeStyles(theme => ({
    root: {
        width: "90%",
        [theme.breakpoints.up('md')]: {
            width: "70%",
        },
        padding: `${theme.spacing(2)}px 0`,
        margin: `${theme.spacing(4)}px auto ${theme.spacing(2)}px auto`,
        backgroundColor: theme.palette.grey[100],
        position: "relative"
    },
    active: {
        backgroundColor: theme.palette.common.white
    },
    link: {
        margin: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
        color: theme.palette.grey[800],
        textDecoration: 'none',
        cursor: 'pointer',
        "&:hover": {
            color: theme.palette.secondary.main
        }
    },
    logo: {
        width: '150px',
        height: '60px',
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        "& > img" : {
            width: "100%",
        }
    },
    button: {
        margin: `${theme.spacing(1)}px`,
    },
    avatar: {
        marginBottom: `${theme.spacing(2)}px`,
    },
    checkbox: {
        position: 'absolute',
        left: `10px`,
        top: '50%',
        transform: 'translateY(-50%)'
        
    },
    title: {
        width: '70%',
        padding: `0 ${theme.spacing(1)}px`
    }
}));

const OrganizationItems = (props) => { 
    const classes = useStyles();
    const [id, setId] = useState(props.orgID);
    
    const allCheckVal = (id) => { 
        let checkVal = {}
        props.organizations.forEach(el => {
            if(id){
                if(String(id) === el.uuid){
                    checkVal[el.nid] = true
                }else {
                    checkVal[el.nid] = false
                }
            }else {
                checkVal[el.nid] = false
            }
        })
        
        return checkVal
    };
    
    const [checked, setChecked] = useState(allCheckVal(id));


    const goTo = (nid) => {
        if(props.menu){
            props.history.push(`/organization/all/${nid}`)
        }else{
            props.history.push(`${props.match.path}/${nid}`)
        }
    };

    const handleChange = item => event => {
        const userOrgData = {
            [props.uuid]: {}
        }

        userOrgData[props.uuid].organization = item.uuid;
        props.orgActive(item.uuid)
        setChecked({ ...allCheckVal(item.uuid), [item.nid]: event.target.checked });
        if(item.field_logo){
            props.setLogo(item.field_logo);
            // localStorage.setItem('organizationLogo', item.relationships.field_logo.data.attributes.uri.url);
            userOrgData[props.uuid].organizationLogo = item.field_logo
        }else{
            // localStorage.removeItem('organizationLogo');
            props.setLogo(null)
            userOrgData[props.uuid].organizationLogo = null
        }
        if(item.field_color_picker){
            props.setAppBarColor(item.field_color_picker);
            // localStorage.setItem('appBarColor', item.attributes.field_color_picker);
            userOrgData[props.uuid].appBarColor = item.field_color_picker
        }else{
            props.setAppBarColor('#ffffff');
            // localStorage.setItem('appBarColor', '#ffffff');
            userOrgData[props.uuid].appBarColor = '#ffffff'
        }

        userOrgData[props.uuid].nid = item.nid;
        props.setOrgNid(item.nid)
        
        const storageOrgData = JSON.parse(localStorage.getItem('userOrgData'))
        const mergeStorage = {...storageOrgData, ...userOrgData}
        localStorage.setItem('userOrgData', JSON.stringify(mergeStorage));
    };
    
    const organizations = props.organizations.map((el, index) => {
        let avatar ;
        if(el.field_logo){
            avatar = <div className={classes.logo} style={{backgroundImage: `url(${oauthConfig.baseUrl}${el.field_logo})`}}></div>
        }else{
            avatar = <Avatar><BusinessIcon /></Avatar>
        }
        return (
            <Paper className={clsx(classes.root,{[classes.active]: checked[el.nid] })} key={index}>
                    
                    <Checkbox
                        disabled={props.organizations.length <= 1}
                        className={classes.checkbox}
                        checked={checked[el.nid]}
                        onChange={handleChange(el)}
                        value={`${el.nid}`}
                    />
               
                <Grid container justify="center" direction="column" alignItems="center">
                    <div className={classes.avatar}>{avatar}</div>
                    
                    <Box className={classes.title} fontSize={{ xs: 'body2.fontSize', md: 'h6.fontSize' }} textAlign="center">{parse(el.title) || 'Organization'}</Box>
                    <Grid container justify="center">
                        <Button className={classes.button} variant="contained" onClick={() => goTo(el.nid)} children={<VisibilityIcon fontSize="small"/>}></Button>
                        <Button className={classes.button} variant="contained" onClick={() => goTo(`${el.nid}/edit`)} children={<EditIcon fontSize="small"/>}></Button>
                        {(props.organizations.length > 1 && !props.menu)  && <Button className={classes.button} variant="contained" onClick={() => props.handleClickOpen(el.uuid)} children={<DeleteForeverIcon fontSize="small"/>}></Button>} 
                    </Grid>
                </Grid>
            </Paper> 
        )
    })
    return (
        <Fragment>
            {  !props.orgID && <ErrorText>Please Select Active Organization</ErrorText> }
            {  organizations.length > 0 ? organizations : <p>No available organizations</p> }
        </Fragment>
    )

}

export default withRouter(OrganizationItems);