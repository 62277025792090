import React from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import RadioButtons from '../FormItems/RadioButtons';
import { 
  withStyles, 
  Card,
  CardActions,
  Grid, 
  TextField,
  Radio,
  Typography, 
  FormControlLabel, 
  Button,
  CircularProgress 
} from "@material-ui/core";
import axios from 'axios';
import config from '../../../../oauthConfig';

const styles = (theme) => ({
  card: {
    marginBottom: 50,
    padding: 20
  },
  icon: {
    color: theme.palette.grey[500]
  },
  container: {
    display: "Flex",
    justifyContent: "center"
  },
  actions: {
    '& > * + *' : {
      marginLeft: `${theme.spacing(1)}px`
    }
  }
});

const form = props => {
  const { 
    classes,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    handleBack,
    positionData,
    isSubmitting,
    setSubmitting
  } = props;

  const field_company_position = Object.keys(positionData).map((el,index) => {
    return  <FormControlLabel
              key={index} 
              value={el} 
              control={<Radio />} 
              label={<Typography variant="caption" color="textPrimary">{positionData[el]}</Typography>} 
            />
  })

  const handleRadio = (e, field) => {
    const data = isNaN(e.target.value) ? e.target.value : Number(e.target.value);
    setFieldValue(field , data);
    props.mergeFormData( "attributes", {[field]: data});
  }

  const setTextValues = (e) => {
    const field = e.target.id;
    const value = e.target.value;
    handleBlur(e); 
    if(Object.keys(errors).length && props.isEdit){
      return
    }
    props.mergeFormData( "attributes", {[field]: value});
  }
  
  return (
    <form onSubmit={handleSubmit} className={classes.container}>
      <Card className={classes.card}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="field_role_at_company"
              label="Role at Company*"
              value={values.field_role_at_company}
              onChange={handleChange}
              onBlur={(e) => setTextValues(e)}
              helperText={touched.field_role_at_company ? errors.field_role_at_company : ""}
              error={touched.field_role_at_company && Boolean(errors.field_role_at_company)}
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <RadioButtons 
              title="Company Position*" 
              handleChange={handleRadio} 
              field="field_company_position"
              value={values.field_company_position}
              error={(touched.field_company_position && errors.field_company_position)}
            >
              {field_company_position}
            </RadioButtons>
          </Grid>
        </Grid>
        <CardActions className={classes.actions}>
          {props.isEdit &&  <Button onClick={handleBack} variant="contained" fullWidth> Back </Button>}
          {!props.isEdit && <Button onClick={handleBack} variant="contained"> Back </Button>}
          {!props.isEdit && <Button type="submit" variant="contained" fullWidth> {isSubmitting ? <CircularProgress color="secondary" size={25} /> : "Save"}</Button>}
        </CardActions>
      </Card>
    </form>
  );
};

const Form = withFormik({

  mapPropsToValues: ({defaultVal}) => {
    return {
      field_role_at_company: defaultVal.attributes.field_role_at_company ? defaultVal.attributes.field_role_at_company : "",
      field_company_position: defaultVal.attributes.field_company_position ? defaultVal.attributes.field_company_position : "",
    }
  },

  validationSchema: Yup.object().shape({
    field_role_at_company: Yup.string().required("Required"),
    field_company_position: Yup.string().required("Required")
  }),
    
  handleSubmit: (values, { props, setSubmitting, setValues, setFieldValue }) => {
    setSubmitting(true)
    const { token, uuid } = props;
      const headers = {
        headers: {
          'Accept': 'application/vnd.api+json',
          'Content-Type': 'application/vnd.api+json',
          'Authorization': `${token.token_type} ${token.access_token}`
        }
    }
    if(!props.isEdit) {
      //If sign up profile
      axios.post(`${config.baseUrl}/api/node/employer_profile`, {data: props.defaultVal}, headers)
      .then( response => {
        const createProfile = {
          "data": {
            "type": "user--user",
            "id": uuid,
            "relationships": {
              "field_personal_profile_reference": {
              "data": {
                  type: response.data.data.type,
                  id: response.data.data.id
                }
              }  
            }
          }
        }
        
        axios.patch(`${config.baseUrl}/api/user/user/${uuid}`, createProfile , headers)
          .then( response => {
            props.refreshToken()
          })
          .catch(error => {
            console.log(error, "error");
            setSubmitting(false)
          });
      })
      .catch(err => {
          if(err.response.status === 401){
            props.refreshToken()
          }
          setSubmitting(false)
      });
    }
  }
})(form);

export default withStyles(styles)(Form);
