import React, { useEffect } from "react";

const Logout = (props) => {
    useEffect(() => {
        props.logout()
    });
    return <h1>Logging out ...</h1>
}

export default Logout; 
