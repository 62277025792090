import React, { Component } from 'react';
import { Typography, Box, Button } from "@material-ui/core";
import SignUpStepsEmployer from '../../Authentication/SignUp/SignUpEmployer/SignUpStepsEmployer';
import SignUpStepsTalent from '../../Authentication/SignUp/SignUpTalent/SignUpStepsTalent';
import Loader from '../../Utilities/Loader/Loader';
import axios from 'axios';
import oauthConfig from '../../../oauthConfig';

class EditProfile extends Component { 

    state = {
        userData: null,
        profileImage: null,
        base64ProfileImg: null,
        pdfName: null,
        pdfFieldId: null,
        isPdfRemoved: false, 
        role: this.props.role,
        initialData: {
            country: this.props.initialData.country,
            personalSkills: this.props.initialData.personalSkills,
            techSkills: this.props.initialData.techSkills,
            position: this.props.initialData.position,
            interests: this.props.initialData.interests,
            experience: this.props.initialData.experience,
            selectedCountry: null,
            selectedPersonalSkills: null,
            selectedRole: null,
            selectedLanguages: null,
            selectedProgramingLang: null,
            checkedType: null,
            selectedCurrency: null
        }
    }

    componentDidMount(){
        this.fetchData()
        .catch(err => {
            if(err.response){
                if(err.response.status === 401){
                    this.props.logout()
                }
            }
        });
    }

    fetchData = async () => {
        let token = this.props.token;
        let headers = {
            headers: {
              'Accept': 'application/vnd.api+json',
              'Content-Type': 'application/vnd.api+json',
              'Authorization': `${token.token_type} ${token.access_token}`
            }
        }
       
        let role;
        let fileds;
        if(this.props.role === "employer"){
            role = "employer";
            fileds = "field_profile_picture";
        }else{
            role = "candidate";
            fileds = "field_profile_picture,field_curriculum_vitae,field_technical_skills,field_personal_skills";
        }

        const userDataRaw = await axios.get(`${oauthConfig.baseUrl}/api/node/${role}_profile?filter[uid.id][value]=${this.props.uuid}&include=uid,${fileds}`, headers)
        const userData = {...userDataRaw.data.data[0]}
        if(role === "employer"){
            this.setState({ 
                profileImage: userDataRaw.data.included[1] ? userDataRaw.data.included[1].attributes.uri.url : null,
                userData: userData
            })
           
        }else {
            if(userData.attributes.field_short_bio){
                userData.attributes.field_short_bio = userData.attributes.field_short_bio.value
            }
            if(userData.attributes.field_portfolio_link){
                userData.attributes.field_portfolio_link = userData.attributes.field_portfolio_link.uri
            }
            if(userData.attributes.field_github_profile){
                userData.attributes.field_github_profile = userData.attributes.field_github_profile.uri
            }
            if(userData.attributes.field_linkedin_profile){
                userData.attributes.field_linkedin_profile = userData.attributes.field_linkedin_profile.uri
            }
            if(userData.attributes.field_twitter_profile){
                userData.attributes.field_twitter_profile = userData.attributes.field_twitter_profile.uri
            }
            userData.attributes.field_third_party_communications = userData.attributes.field_third_party_communications ? 1 : 0; 
            if(userData.relationships.field_candidate_country.data){
                const countryId = userData.relationships.field_candidate_country.data.id
                const selectedCountry =  await axios.get(`${oauthConfig.baseUrl}/api/taxonomy_term/country/${countryId}`, headers);
                const SelectedCountryString = selectedCountry.data.data.attributes.name
                this.handleSelectedCountry(SelectedCountryString)
            }
            
            //Set up technical skills 
            const techSkillsIncluded = userDataRaw.data.included.filter(el => {
                return el.type === "taxonomy_term--technical_skills"
            })
    
            const arrTechSkills = []
            if(userData.relationships.field_technical_skills.data){
                techSkillsIncluded.forEach(el => { 
                    userData.relationships.field_technical_skills.data.forEach(skill => {
                        if(el.id === skill.id){
                            arrTechSkills.push(el)
                        } 
                    })
                })
            }
            const techSkillsLevel = userData.attributes.field_technical_skills_exp;
            const programingLanguages = arrTechSkills.map((language, index) => ({ lang: { type: `taxonomy_term--technical_skills`, id: language.id, value: language.attributes.name }, level: techSkillsLevel[index] ? techSkillsLevel[index] : [] }))
            let selectedProgramingLanguages;
            if(programingLanguages.length > 0){
                selectedProgramingLanguages = [...programingLanguages]
            }else{
                selectedProgramingLanguages = [{lang:[], level:[]}]
            }

            //Set up personal skills
            const personalSkillsIncluded = userDataRaw.data.included.filter(el => {
                return el.type === "taxonomy_term--personal_skills"
            })

            const arrPersonalSkills = []

            if(userData.relationships.field_personal_skills.data){
                personalSkillsIncluded.forEach(el => { 
                    userData.relationships.field_personal_skills.data.forEach(skill => {
                        if(el.id === skill.id){
                            arrPersonalSkills.push(el)
                        } 
                    })
                })
            }
            const personalSkillsLevel = userData.attributes.field_personal_skills_exp;
            const personalSkills = arrPersonalSkills.map((language, index) => ({ lang: { type: `taxonomy_term--personal_skills`, id: language.id, value: language.attributes.name }, level: personalSkillsLevel[index] ? personalSkillsLevel[index] : [] }))
            let personalSkillsSelected;

            if(personalSkills.length > 0){
                personalSkillsSelected = [...personalSkills]
            }else{
                personalSkillsSelected = [{lang:[], level:[]}]
            }

            const selectedRole = userData.attributes.field_roles_interested.map(selectedRole => this.props.initialData.role.find(role => selectedRole == role.value));
            const selectedLevel = userData.attributes.field_language_proficiencies;
            const availableLanguages = userData.attributes.field_languages.map((language, index) => ({ lang: language, level: selectedLevel[index] ? selectedLevel[index] : [] }))
            let selectedLanguages;
            if(availableLanguages.length > 0){
                selectedLanguages = [...availableLanguages]
            }else{
                selectedLanguages = [{lang:[], level:[]}]
            }
            let selectedCurrency;
            if(userData.attributes.field_currency){
                selectedCurrency = this.props.initialData.currency.find(el => el.value === userData.attributes.field_currency);
            }
            const userType = userData.attributes.field_looking_for_opportunities
            const initialType = {...this.props.initialData.type}
            const checkedType = Object.keys(initialType).reduce((acc, item) => {acc[item] = (userType.indexOf(item) > -1 ? true : false); return acc;}, {})
            const userVisa = userData.attributes.field_visa_sponsorship_required;
            const initialVisa = {...this.props.initialData.visaSponsorship}
            const checkedVisa = Object.keys(initialVisa).reduce((acc, item) => {acc[item] = (userVisa.indexOf(item) > -1 ? true : false); return acc;}, {})
            this.handleInitialData(checkedVisa, 'checkedVisa'); 
            this.handleInitialData(checkedType, 'checkedType'); 
            this.handleInitialData(selectedLanguages, 'selectedLanguages');
            this.handleInitialData(selectedRole, 'selectedRole');
            this.handleInitialData(selectedProgramingLanguages, 'selectedProgramingLang'); 
            this.handleInitialData(personalSkillsSelected, 'selectedPersonalSkills');
            this.handleInitialData(selectedCurrency, 'selectedCurrency');

            const fileImage = userDataRaw.data.included.filter(el => {
                const str = el.attributes.filemime
                if(str){
                    let arr;
                    arr = str.split('/')
                    return arr[0] === "image"
                }
            });

            const filePdf = userDataRaw.data.included.filter(el => {
                const str = el.attributes.filemime
                if(str){
                    let arr;
                    arr = str.split('/')
                    return arr[0] === "application"
                }
            });
            
            this.setState({
                profileImage: fileImage[0] ? fileImage[0].attributes.uri.url : null,
                pdfPreview: filePdf[0] ? filePdf[0].attributes.filename : null,
                userData: userData
            })
        }
        
    }

    handleInitialData = (data, key) => {
        this.setState(prevState => ({
          initialData: {
            ...prevState.initialData,
            [key]: data
          }
        }))
    }

    handleSelectedCountry = (country) => {
        this.setState(prevState => ({
          initialData: {
            ...prevState.initialData,
            selectedCountry: country
          }
        }))
    }

    render(){
        return(
             this.state.userData &&  this.props.initialData ?  (
                <div>
                    {this.state.role === 'employer' ? (
                        <SignUpStepsEmployer
                            isEdit
                            {...this.props}
                            userData={this.state.userData} 
                            profileImage={this.state.profileImage}
                        />
                    ):(
                        <SignUpStepsTalent 
                            isEdit
                            {...this.props}
                            userData={this.state.userData} 
                            profileImage={this.state.profileImage}
                            pdfPreview={this.state.pdfPreview}
                            selectedCountry={this.state.initialData.selectedCountry}
                            selectedPersonal={this.state.initialData.selectedPersonalSkills}
                            selectedRole={this.state.initialData.selectedRole}
                            selectedLanguages={this.state.initialData.selectedLanguages}
                            selectedProgramingLang = {this.state.initialData.selectedProgramingLang}
                            checkedType={this.state.initialData.checkedType}
                            checkedVisa={this.state.initialData.checkedVisa}
                            selectedCurrency={this.state.initialData.selectedCurrency}
                        />
                    ) }
                </div>
            ):(
                <Loader />
            )
        )
    }
}

export default EditProfile;
