import React, { useEffect, useState } from 'react';
import OrganizationView from './OrganizationView';

const OrganizationProfile = (props) => {
    const { match } = props;  
    let item = props.organizations.filter(el => el.nid == props.match.params.id)
    useEffect(() => {
        if(item.length == 0){
            props.findOrg()
        }
    },[props.organizations]);

    return item.length > 0 && <OrganizationView organizations={props.organizations} match={match}/>
}

export default OrganizationProfile;