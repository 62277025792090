import React, { Fragment } from "react";
import Loader from '../Utilities/Loader/Loader';
import { makeStyles, Grid, Typography } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import dummyImage from '../../images/pdf.png';
import BeenhereIcon from '@material-ui/icons/Beenhere';

const useStyles = makeStyles(theme => ({
    initialData: {
        marginBottom: theme.spacing(2),
        width: '200px',
        height: '130px'
    },
    imagePreview: {
      width: '100%',
      height: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'contain'
    },
    customFileUpload: {
        display: 'inline-block',
        backgroundColor: theme.palette.primary.main,
        borderRadius: 3,
        border: 0,
        color: 'white',
        padding: '4px 16px 0 16px',
        fontSize: '0.875rem',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.secondary.main
        },
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    profileImagePreview: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100px',
        height: '100px',
        marginBottom: theme.spacing(1),
        borderRadius: '50%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover'
    },
    container: {
        minHeight: '180px'
    }
}))

const DropUploader = (props) => {

    const classes = useStyles();

    const dropStyles = {
        dropzone: { 
            overflow: 'hidden',
            backgroundImage: `url(${dummyImage})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            border: '3px dashed #e0e0e0',
            minHeight: '180px'
        },
        inputLabel: {
            fontSize: '12px',
            color: "#424242"
        },
        preview: {
            padding: '5px',
            border: 'none',
            minHeight: '180px'
        },
        previewImage: {
            maxWidth: '50%',
            maxHeight: 'none',
            position: 'relative',
            left: '50%',
            transform: 'translateX(-50%)' 
        },
        dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
    }

    const handleChangeStatus = (fileWithMeta, status) => {
        if(status === 'error_validation'){
            fileWithMeta.remove()
        }

        if(status === 'done'){
            props.uploadFile(fileWithMeta.file, props.type)
        }

        if(status === 'removed'){
            if(!fileWithMeta.meta.validationError){
                props.removeFile(props.type)
            }
        }
    };

    const bytesToMegaBytes = bytes => bytes / (1024*1024);

    const handleValidation = (fileWithMeta) => {
        if(props.setMbLimit){
            const mbSize = bytesToMegaBytes(fileWithMeta.file.size)
            if(mbSize > props.setMbLimit.mbLimit){
                console.log(1)
                props.setMbLimit.func(true)
                return true
            }else{
                console.log(2)
                props.setMbLimit.func(false)
                return false
            }
        }
    }

    const handleInputContent = (files, extra) => {
        return extra.reject ? 'PDF files only' : 'Drag CV or Click to Browse'
    }

    let content;

    if(props.initialData && props.initialData === 'loading'){
        content = <Loader />

    }else if(props.initialData){
        content = (
            <Grid container direction="column" justify="center" alignItems="center">
                <BeenhereIcon style={{fontSize: '100px'}} color="primary"/>
                <h5>{props.initialData }</h5>
                <button className={classes.customFileUpload} type="button" onClick={() => props.removeInitialData(props.type)}><DeleteIcon /></button>
            </Grid>
        )

    }else{
        content = (
            <Dropzone 
                maxFiles={1}
                multiple={false} 
                onChangeStatus={handleChangeStatus}
                validate={handleValidation}
                accept='.pdf'
                inputContent={(files, extra) => handleInputContent(files, extra)}  
                styles={dropStyles}
            />
        )
    }

    return (
        <Fragment>
            <Typography variant="subtitle2" align="center">Curriculum Vitae (CV) document</Typography>
            <Grid container direction="column" justify="center" alignItems="center" className={classes.container}>
                {content}
            </Grid>
            {!props.initialData && <Typography variant="caption" color={props.color}>Please upload your CV in .pdf format (less than 30MB)</Typography>}
        </Fragment>
    )
}

export default DropUploader;