import React, {Component} from 'react';
import config from '../../../../oauthConfig';
import axios from 'axios';
import JobNewForm from '../JobNew/JobNewForm';
import Loader from '../../../Utilities/Loader/Loader';

class JobNew extends Component {
    state = {
        loading: true,
        currency : this.props.currentOrg.field_currency
    }

    componentDidMount(){
        this.fetchData()
        .then(()=> this.setState({loading: false}))
        .catch(err => {
            if(err.response){
                if(err.response.status === 401){
                    this.props.logout()
                }
            }
        });
    }

    fetchData = async () => {
        let token = this.props.token;
        const headers = {
          headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
            'Authorization': `${token.token_type} ${token.access_token}`
          }
        }
        const item = this.props.jobList.filter(el => el.nid == this.props.match.params.id)[0];
        const location = await axios.get(`${config.baseUrl}/api/taxonomy_term/country?filter[drupal_internal__tid]=${item.field_job_country_1}`, headers);
        let selectedTechSkills = [];
        const attributes = {};
        const relationships = {};
        attributes["title"] = item.title;
        attributes["field_application_method"] = item.field_application_method_1;
        attributes["field_apply_url"] = item.field_apply_url;
        attributes["field_currency"] = item.field_currency;
        attributes["field_base_salary_year"] = {from: item.field_base_salary_year_from, to: item.field_base_salary_year_to};
        attributes["field_description"] = item.field_description;
        attributes["field_employment_type"] = item.field_employment_type_1;
        attributes["field_experience_requirements"] = item.field_experience_requirements;
        attributes["field_identifier"] = item.field_identifier;
        attributes["field_remote_job"] = item.field_remote_job_1;
        attributes["field_valid_until"] = item.field_valid_until;
        attributes['field_job_state'] = item.field_job_state;
        attributes['field_job_city_name'] = item.field_job_city_name;
        relationships["field_job_country"] = {data: location.data.data.length > 0 ? location.data.data : null}
        //Setup selected tech skills
        if(item.field_technical_skills_1){ 
            const technicalSkillsArr = item.field_technical_skills_1.split(",")
            selectedTechSkills = technicalSkillsArr.map(el => {
                return this.props.initialData.techSkills.find(taxonomy => {
                    return taxonomy.tid == el
                })
            })
            const found = selectedTechSkills.map(obj => ({
                type: "taxonomy_term--technical_skills", 
                label: obj.label,
                id: obj.id,
                value: obj.value
            }));
            
            relationships["field_technical_skills"] = {data: found}
        }
        //Setup selected currency
        const currency = this.props.initialData.currency.find(curr => curr.value === item.field_currency)
        //Setup selected country
        const country = location.data.data.length > 0 && location.data.data[0].attributes.name
        
        const date =  new Date(item.field_valid_until);
        const userData = {
            type: "node--job_posting",
            id: item.uuid,
            attributes,
            relationships
        }
        
        this.setState({ 
            date,
            userData,
            selected: {
                currency,
                country,
                techSkills: selectedTechSkills,
            }
        })
    }

    mergingUserData = (dataType, newData) => {
        this.setState( prevState => ({
            userData: {
              ...prevState.userData,
              [dataType]: {
                ...prevState.userData[dataType],
                ...newData
              }
            }
        }));
    }

    handleJobSubmit = () => {
        let token = this.props.token;
        const headers = {
            headers: {
              'Accept': 'application/vnd.api+json',
              'Content-Type': 'application/vnd.api+json',
              'Authorization': `${token.token_type} ${token.access_token}`
            }
        }

        this.setState({loading: true})
        
        axios.patch(`${config.baseUrl}/api/node/job_posting/${this.state.userData.id}`, {data: this.state.userData}, headers)  
        .then(res => {
            this.setState({loading: false})
            let page = this.props.page ?  this.props.page : 0;
            this.props.fetchAll(page)
            this.props.history.push('/job/all');
        })
        .catch(err => {
            if(err.response){
                if(err.response.status === 401){
                    this.props.logout()
                  }
            }
        });
    }

    render(){
        return (
            this.state.loading ? (
                <Loader />
            ) : (
                <JobNewForm
                    formEdit
                    defaultValues={this.state.userData} 
                    initialData={this.props.initialData}
                    uuid={this.props.uuid}
                    date={this.state.date}
                    selected={this.state.selected}
                    currency={this.state.currency} 
                    mergingUserData={this.mergingUserData} 
                    handleJobSubmit={this.handleJobSubmit}
                />
            )
        )
    }
}
    

export default JobNew;