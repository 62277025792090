import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { 
    Grid, Table, TableBody, TableCell, TableHead, TableRow, Paper, IconButton, Menu, MenuItem, Avatar, Typography, Button, Box, CircularProgress 
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core/styles';
import BusinessIcon from '@material-ui/icons/Business';
import ErrorText from '../../../Utilities/Error/ErrorText';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import clsx from 'clsx';
import oauthConfig from '../../../../oauthConfig';
import { Html5Entities } from 'html-entities';
const htmlEntities = new Html5Entities();

const useStyles = makeStyles(theme => ({
    tableHeader: {
       padding: '0 16px'
    },
    tableTitle: {
        color: theme.palette.grey[600],
    },
    expand: {
        cursor: 'pointer',
        color: "#03a9f4",
        textDecoration: "none"
    },
    table: {
        minWidth: 650,
        // height: '200px',
        overflow: 'hidden' 
    },
    dot: {
        display: 'inline-block',
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        background: theme.palette.grey[600],
    },
    active: {
        background: '#4caf50'
    },
    text: {
        marginLeft: '10px'
    },
    logo: {
        borderRadius: '5px',
        width: '35px',
        height: '35px',
        marginRight: '10px',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat'
    },
    link: {
        color: theme.palette.grey[800]
    },
    collapse: {
        overflowX: 'auto',
    }
}));

const setDate = (date) => {
    const rawDate = new Date(date);
    const buildDate = `${rawDate.getDate()}/${rawDate.getMonth() + 1 }/${rawDate.getFullYear()}`
    return buildDate;
}

const isActive = (date) => {
    let today = new Date()
    let valid = new Date(date)
    return today < valid
}

const JobsTable = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [loading , setLoading ] = useState(false);

    const handleClick = event => {
      setAnchorEl(event.currentTarget);  
    };
  
    const handleClose = () => { 
      setAnchorEl(null);
    };

    const getLogo = (id) => {
        
        const matchOrg = props.jobs.included.find(el => {
            return el.id === id
        });

        if(matchOrg.relationships.field_logo.data){
            const matchLogo = props.jobs.included.find(el => {
                return el.id === matchOrg.relationships.field_logo.data.id
            }); 
            return (
                <div className={classes.logo} style={{backgroundImage: `url( ${oauthConfig.baseUrl}${matchLogo.attributes.uri.url})`}}></div>
            )
        }else{
            return <Avatar className={classes.logo}><BusinessIcon /></Avatar >
        }
    }
    const collapsedHeight = () => {
        let height

        switch(props.jobs.jobsList.length) {
            case 1:
                height = '180px'
              break;
            case 2:
                height = '254px'
              break;
            default:
                height = '328px'
          }

          return height
    }

    const handleDelete = (id) => {
        setLoading(true)
        props.handleDelete(id)
    }
    
    const jobsTable = (
        <Paper style={{height: collapsedHeight(), overflowY:"hidden" }}>
            
            <Grid 
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                className={classes.tableHeader}
            >
                <h2 className={classes.tableTitle}>Job Posts {`(${props.jobs.jobsList.length})`}</h2>
                <Link to={'/job/all'} className={classes.expand}>See All</Link>
                
            </Grid> 
            <Table className={classes.table} aria-label="simple table"> 
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Valid Until</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={classes.tbody}>
                    {props.jobs.jobsList.map(job => (
                        <TableRow key={job.id}>
                            <TableCell component="th" scope="job">
                                <Typography variant="body1">{setDate(job.attributes.created)}</Typography>
                            </TableCell>
                            <TableCell component="th" scope="job">
                                <Typography variant="body1">{setDate(job.attributes.field_valid_until)}</Typography>
                            </TableCell>
                            <TableCell>
                                <Grid 
                                    container
                                    wrap="nowrap"
                                    direction="row"
                                    alignItems="center"
                                    className={classes.tableHeader}
                                >  
                                    { getLogo(job.relationships.field_hiring_organization.data.id) }
                                    <Link className={classes.link} to={`/job/all/${job.attributes.drupal_internal__nid}`}>
                                        {htmlEntities.decode(job.attributes.title)}
                                    </Link>
                                </Grid>
                            </TableCell>
                            <TableCell>
                                <Grid 
                                    container
                                    wrap="nowrap"
                                    direction="row"
                                    alignItems="center"
                                    className={classes.tableHeader}
                                >  
                                    <span className={clsx(classes.dot, {[classes.active]: isActive(job.attributes.field_valid_until) })}></span>
                                    {isActive(job.attributes.field_valid_until) ? <span className={classes.text}>Active</span> : <span className={classes.text}>Expired</span> }
                                </Grid>
                            </TableCell>
                            <TableCell align="center">
                                <IconButton
                                    id={job.id}
                                    aria-label="more"
                                    aria-controls={job.id}
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    getContentAnchorEl={null}
                                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={open && anchorEl.id === job.id}
                                    className={classes.menu}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={handleClose}>
                                        <Link className={classes.link} to={`/job/all/${job.attributes.drupal_internal__nid}`}>
                                            View</Link>
                                    </MenuItem>
                                    <MenuItem onClick={handleClose}>
                                        <Link className={classes.link} to={`/job/all/${job.attributes.drupal_internal__nid}/edit`}>Edit Job</Link>
                                    </MenuItem>
                                    <MenuItem onClick={() => handleDelete(job.id)}>
                                        Delete {loading && <CircularProgress size={12}/> }
                                    </MenuItem>
                                </Menu>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    )

    const withoutJobs = (
        <Grid container direction="column">
            <ErrorText>
                You have not published any job offers yet. Add a new job posting.
            </ErrorText>
            <Box align="center" mt={2}>
                <Button variant="contained" onClick={()=>{props.history.push('/job/new')}}>Add new job<NoteAddIcon fontSize="small"/></Button>
            </Box>
        </Grid>
    )

    return props.jobs.jobsList.length > 0 ? jobsTable : withoutJobs
            
}

export default JobsTable;
