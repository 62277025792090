import React, { Fragment } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import config from '../../../../oauthConfig';
import Select from 'react-select';
import HelpIcon from '@material-ui/icons/Help';
import RadioButtons from '../FormItems/RadioButtons';
import CheckboxButtons from '../FormItems/CheckboxButtons'; 
import {
  withStyles, 
  Card,
  CardActions, 
  Button,
  FormControlLabel,
  Radio,
  Grid,
  Typography,
  TextField,
  CircularProgress,
  Tooltip
} from "@material-ui/core";

const styles = (theme) => ({
  card: {
    marginBottom: 50,
    padding: 20
  },
  container: {
    display: "Flex",
    justifyContent: "center"
  },
  actions: {
    '& > * + *' : {
      marginLeft: `${theme.spacing(1)}px`
    }
  },
  radioBtn: {
    fontSize: ''
  },
  select: {
    paddingBottom: theme.spacing(2)
  },
  inputNimber: {
    backgroundColor: "white",
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0
    },
    "& input[type=number]" : {
        "-moz-appearance" : "textfield" 
    }
  }
});

const form = props => {
  const { 
    classes,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleBack,
    handleSubmit,
    setFieldValue,
    initialData,
    isSubmitting,
    handleSelected
  } = props;
  
  const inputNumberStyle = {
    className: classes.inputNimber
  }

  const inputNumberProps = {
    step : "0.01"
  }

  const createRadio = (data, tooltip) => {
    const result = Object.keys(data).map((el,index) => {
      let found;
      let tooltipText; 
      if(tooltip){
        found =  tooltip.find(el => el.index === index)
        if(found){
          tooltipText = tooltip[index]['text']
        }
      }
      return (
        <FormControlLabel
          key={index} 
          value={el} 
          control={<Radio />} 
          label={<Fragment><Typography variant="caption" color="textPrimary">{data[el]}</Typography>{tooltipText && <Tooltip title={tooltipText}><HelpIcon style={{ fontSize: 15 }} /></Tooltip>}</Fragment>} 
        />
      ) 
    })
    return result;
  }
  const remote = createRadio(initialData.remoteWork)
  const interests = createRadio(initialData.interests)
  const handleRadio = (e, field) => {
    const data = isNaN(e.target.value) ? e.target.value : Number(e.target.value);
    setFieldValue(field , data);
    props.mergeFormData( "attributes", {[field]: data});
  }

  const handleCheckBox = (type, value, field) => {
    const checkedObj = {...initialData[type].checked}
    checkedObj[value] = !checkedObj[value]
    const selectedArr = [];
    for(let property in checkedObj){
      if(checkedObj[property]){
        selectedArr.push(property)
      }
    }
    setFieldValue(field , selectedArr);
    if(type === "employmnetType" && props.isEdit){
      if(selectedArr.length === 0 ){
        return
      }
    }
    props.mergeFormData( "attributes", {[field]: selectedArr});
    props.handleChecked(type, checkedObj);
  }

  const setTextValues = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]*/g,'');
    handleChange(e)    
  }

  const handleSalary = (e) => {
    handleBlur(e)
    if(!Boolean(errors.field_desired_annual_salary)){
      props.mergeFormData( "attributes", {['field_desired_annual_salary']: {from: values.field_desired_annual_salary_from, to: values.field_desired_annual_salary_to }});
    }
  }

  const handleListSelect = (selectedOption, field, selected) => {
    handleSelected(selected, selectedOption)
    props.mergeFormData( "attributes", {[field]: selectedOption.value})
  }
  
  return (
    <form onSubmit={handleSubmit} className={classes.container}>
      <Card className={classes.card}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">Desired net salary (annual)</Typography>
              <TextField
                  id="field_desired_annual_salary_from"
                  label="From"
                  onChange={(e) => setTextValues(e)}
                  value={values.field_desired_annual_salary_from}
                  onBlur={(e) => handleSalary(e)}
                  helperText={errors.field_desired_annual_salary ? errors.field_desired_annual_salary : ""}
                  error={errors.field_desired_annual_salary && Boolean(errors.field_desired_annual_salary)}
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  InputProps={{ ...inputNumberProps, ...inputNumberStyle }}
              />
            </Grid>
            <Grid item xs={12} md={6} >
              <Typography variant="subtitle2">Desired net salary (annual)</Typography>
              <TextField
                  id="field_desired_annual_salary_to"
                  label="To"
                  onChange={(e) => setTextValues(e)}
                  value={values.field_desired_annual_salary_to}
                  onBlur={(e) => handleSalary(e)}
                  helperText={errors.field_desired_annual_salary ? errors.field_desired_annual_salary : ""}
                  error={errors.field_desired_annual_salary && Boolean(errors.field_desired_annual_salary)}
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  InputProps={{ ...inputNumberProps, ...inputNumberStyle }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">Salary currency</Typography>
              <Select 
                  placeholder="Currency"
                  defaultValue={initialData.selectedCurrency ? initialData.selectedCurrency : {label: "United States Dollar", value: "USD"}}
                  options={initialData.currency}  
                  onChange={ (selectedOption) => handleListSelect(selectedOption , 'field_currency', 'selectedCurrency') } 
                  // styles={selectStyles} 
              />
            </Grid>
            <Grid item xs={12}>
              <RadioButtons 
                title="Are you willing to work remotely?" 
                handleChange={handleRadio} 
                field="field_remote_work"
                value={values.field_remote_work}
              >
              {remote}
              </RadioButtons>
            </Grid>
            <Grid item xs={12}>
              <CheckboxButtons 
                title="What kind of job are you looking for?*" 
                handleChange={handleCheckBox} 
                field="field_looking_for_opportunities"
                data={initialData}
                type="employmnetType"
                error={(touched.field_looking_for_opportunities && errors.field_looking_for_opportunities)}
              />
            </Grid>
            <Grid item xs={12}>
              <RadioButtons 
                title="Interests*" 
                handleChange={handleRadio} 
                field="field_involving_interest"
                value={values.field_involving_interest}
                error={(touched.field_involving_interest && errors.field_involving_interest)}
              >
              {interests}
              </RadioButtons>
            </Grid>
            <Grid item xs={12}>
              <CheckboxButtons 
                title="Visa Sponsorship"
                subtitle="Please select visa sponsorship if you require support to apply for a visa in the selected country." 
                handleChange={handleCheckBox} 
                field="field_visa_sponsorship_required"
                data={initialData}
                type="visaSponsorship"
              />
            </Grid>
          </Grid>
          <CardActions  className={classes.actions}>
            {props.isEdit && <Button onClick={handleBack} variant="contained" fullWidth> Back </Button>}
            {!props.isEdit && <Button onClick={handleBack} variant="contained"> Back </Button>}
            {!props.isEdit && <Button type="submit" variant="contained" fullWidth> {isSubmitting ? <CircularProgress color="secondary" size={25} /> : "Save"}</Button>}
          </CardActions>
      </Card>
    </form>
  );
};

const Form = withFormik({

  mapPropsToValues: ({defaultVal}) => {
    return {
      field_remote_work: defaultVal.attributes.field_remote_work ? defaultVal.attributes.field_remote_work : "",
      field_looking_for_opportunities: defaultVal.attributes.field_looking_for_opportunities ? defaultVal.attributes.field_looking_for_opportunities : "",  
      field_visa_sponsorship_required : defaultVal.attributes.field_visa_sponsorship_required ? defaultVal.attributes.field_visa_sponsorship_required : '',
      field_desired_annual_salary_to: defaultVal.attributes.field_desired_annual_salary ? defaultVal.attributes.field_desired_annual_salary.to : "", 
      field_desired_annual_salary_from: defaultVal.attributes.field_desired_annual_salary ? defaultVal.attributes.field_desired_annual_salary.from : "",
      field_currency : defaultVal.attributes.field_currency ? defaultVal.attributes.field_currency : "",
      field_involving_interest:  defaultVal.attributes.field_involving_interest ? defaultVal.attributes.field_involving_interest : "" 
    }
  },

  validationSchema: Yup.object().shape({
    field_looking_for_opportunities: Yup.array().min(1, "Required").required("Required"),
    field_involving_interest: Yup.string().required("Required"),
    field_desired_annual_salary: Yup.string()
    .test("Salary", "Both values are required ( from > to )", function(code) {
      const { field_desired_annual_salary_from, field_desired_annual_salary_to } = this.parent;
      if(field_desired_annual_salary_from !== undefined || field_desired_annual_salary_to !== undefined){
          return parseInt(field_desired_annual_salary_from ) <= parseInt(field_desired_annual_salary_to)
      }
      return true
    }),
  }),
  
  handleSubmit: (values, { props, setSubmitting, setValues, setFieldValue }) => {
    setSubmitting(true)
    const { token, uuid } = props;
      const headers = {
        headers: {
          'Accept': 'application/vnd.api+json',
          'Content-Type': 'application/vnd.api+json',
          'Authorization': `${token.token_type} ${token.access_token}`
        }
    }
    if(!props.isEdit) {
      //If sign up profile
      axios.post(`${config.baseUrl}/api/node/candidate_profile`, {data: props.defaultVal}, headers)
      .then( response => {
        const createProfile = {
          "data": {
            "type": "user--user",
            "id": uuid,
            "relationships": {
              "field_personal_profile_reference": {
              "data": {
                  type: response.data.data.type,
                  id: response.data.data.id
                }
              }  
            }
          }
        }
        axios.patch(`${config.baseUrl}/api/user/user/${uuid}`, createProfile , headers)
          .then( response => {
            props.refreshToken()
          })
          .catch(error => {
            console.log(error, "error");
            setSubmitting(false)
          });
      })
      .catch(err => {
          if(err.response){
            err.response.status === 401 && this.props.refreshToken()
          }
          setSubmitting(false)
      });
    }
  }
})(form);

export default withStyles(styles)(Form);
