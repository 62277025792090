import React from "react";
import { Paper, Grid, Typography, Box } from "@material-ui/core";
import PostAddIcon from "@material-ui/icons/PostAdd";
import DateRangeIcon from "@material-ui/icons/DateRange";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    marginBottom: `${theme.spacing(2)}px`,
  },
  header: {
    padding: `${theme.spacing(2)}px`,
    color: theme.palette.grey[600],
    backgroundColor: theme.palette.grey[200],
  },
  icon: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    color: theme.palette.grey[400],
  },
  content: {
    padding: `${theme.spacing(2)}px 0`,
    color: theme.palette.grey[700],
  },
  title: {
    margin: "0",
  },
  number: {
    fontSize: "30px",
    fontWeight: "bold",
    lineHeight: "1",
  },
}));

const JobStats = (props) => {
  const classes = useStyles();
  const date = new Date();
  const passedMonths = date.getMonth() + 1;
  //JONS / MONTH

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <h2 className={classes.title}>Jobs</h2>
      </div>
      <Grid container className={classes.content}>
        <Grid container item xs={12} md={3}>
          <PostAddIcon className={classes.icon} fontSize="large" />
          <Box flexDirection="column">
            <Typography variant="subtitle2">Total Jobs</Typography>
            <span className={classes.number}>{props.number}</span>
          </Box>
        </Grid>
        <Grid container item xs={12} md={3}>
          <DateRangeIcon className={classes.icon} fontSize="large" />
          <Box flexDirection="column">
            <Typography variant="subtitle2">Average per month</Typography>
            <span className={classes.number}>{props.perMonth < 1 ? "<1" : props.perMonth}</span>
          </Box>
        </Grid>
        {/* <Grid container item xs={12} md={3}>
          <VisibilityIcon className={classes.icon} fontSize="large" />
          <Box flexDirection="column">
            <Typography variant="subtitle2">Total views of jobs</Typography>
            <span className={classes.number}>{props.totalViews}</span>
          </Box>
        </Grid>  */}
      </Grid>
    </Paper>
  );
};

export default JobStats;
