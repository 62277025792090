import React from 'react';
import AsideNavigationItem from './AsideNavigationItem/AsideNavigationItem';
import { withStyles } from '@material-ui/core';
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';
import WorkRoundedIcon from '@material-ui/icons/WorkRounded';
import BusinessIcon from '@material-ui/icons/Business';
import PeopleIcon from '@material-ui/icons/People';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const styles = (theme) => ({
    root: {
        background: theme.palette.background.darkBlueGrey,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        zIndex: '100',
        [theme.breakpoints.up('md')]: {
            position: "fixed",
            width: '84px'
        }
    }
  });

const AsideNavigation = (props) => {
    const { classes } = props;
    return (
        <aside className={classes.root}>
            <AsideNavigationItem to={'/dashboard'} disabled={false}> 
                <DashboardRoundedIcon /> 
                Dashboard 
            </AsideNavigationItem>

            <AsideNavigationItem to={'/job'} disabled={!(typeof props.orgID === 'string')}>
                <WorkRoundedIcon /> 
                Job Posts
            </AsideNavigationItem>

            <AsideNavigationItem to={'/organization'} disabled={false}>
                <BusinessIcon /> 
                Organization 
            </AsideNavigationItem>

            <AsideNavigationItem to={'/candidates'} disabled={!(typeof props.orgID === 'string')}>
                <PeopleIcon /> 
                Candidates 
            </AsideNavigationItem>
            <AsideNavigationItem to={'/profile'}>
                <AccountCircleIcon /> 
                Profile 
            </AsideNavigationItem>
        </aside>
    )
}

export default withStyles(styles)(AsideNavigation);