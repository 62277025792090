import React, {Component, Fragment} from 'react';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import GenderBiasWords from './gender-bias-words.json';
import './index.css';
import Popper from '@material-ui/core/Popper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 15,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: genderBiasWordResources["masculine-highlight-color"],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: genderBiasWordResources["feminine-highlight-color"],
    },
  }))(LinearProgress);

//Gender bias review resources
const genderBiasWordResources = {
    help: '<div style="color: lightgray"> Click here to enable gender bias review </div>',
    "neutral-review": "Your text is <i>netural</i>",
    "feminine-review": "Your text is <i>feminine</i>",
    "masculine-review": "Your text is <i>masculine</i>",
    "masculine-highlight-color": "rgba(105, 141, 241, 0.5)",
    "feminine-highlight-color": "rgba(236, 72, 163, 0.5)",
    // "masculine-highlight-color": "rgba(151, 189, 238, 0.5)",
    // "feminine-highlight-color": "rgba(241, 194, 220, 0.5)",
    "masculine-text": "masculine",
    "feminine-text": "feminine",
    "strongly-feminine-review": "Your text is <i>strongly feminine</i>",
    "strongly-masculine-review": "Your text is <i>strongly masculine</i>"
}

//holds data on each of the gender bias word identified in the text node
class GenderBiasWord {
    constructor(node, text, index, id) {
        this.node = node;
        this.text = text;
        this.gender = genderBiasWordResources["masculine-text"];
        this.index = index;
        this.id = id;
    }
    toString() {
        return "text='" + this.text + "', gender=" + this.gender + ", index=" + this.index;
    }
}


const useStyles = (theme) => ({
    root: {
      display: 'flex',
      
      
    },
    
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
      margin: "0 10px 0 13px",
    //   paddingRight: '3px',
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    img: {
      margin: 'auto',
      display: 'block',
      width: '133px',
      height: '39px',
      'background-color': 'white',
      'border-radius': '25px',
      'margin-left': '13px',
    },
    image: {
      '&:focus': {
        outline: 'none',
      },
    },
    
    
  });

//Performs gender bias review on a given text (that is found under the parent node)
class GenderBiasReview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            highlighters: {},
            genderBiasReviewResult: {__html: genderBiasWordResources.help},
            genderBiasReviewEnabled: false,
            timeoutForScroll: null,
            highlightsCleared: false,
            documentListener: null,
            windowListener: null,
            genderWords: [],
            count: 0,
            fcount: 0,
            mcount: 0,
            detailWords:"",
        }
        this.genderRef = React.createRef();
        this.popupStateRef = React.createRef();
    }

    componentDidMount() {
        this.addListeners();
    }
    componentWillUnmount() {
        this.removeListeners();
        
    }
    removeListeners = () => {
        document.removeEventListener('scroll',this.state.documentListener);
        window.removeEventListener('resize', this.state.windowListener);
    }
   
    //listens to various window and parent node events and performs gender bias review if enabled
    //TODO: need to check on performance for frequent events
    addListeners = () => {
        let self = this;
        var listener = function() {
            if(self.state.genderBiasReviewEnabled == true) {
                self.clearGenderBiasReview(1, self);
                self.doGenderBiasReview(1, self.genderRef.current.parentNode, self.updateText);
            }
          };
        let documentListener = function(){
            if(self.state.genderBiasReviewEnabled == true) {
                if(self.state.highlightsCleared == false) {
                    self.clearGenderBiasReview(1, self, true);
                }
                if(!self.state.timeoutForScroll) {
                    let timeout = setTimeout(function() {
                        self.setState({timeoutForScroll: null});
                        self.clearGenderBiasReview(1, self);
                        self.doGenderBiasReview(1, self.genderRef.current.parentNode, self.updateText);
                    }, 500);
                    self.setState({timeoutForScroll: timeout});
                }
            }
        }
        this.genderRef.current.parentNode.addEventListener('keyup', listener);
        this.genderRef.current.parentNode.addEventListener('paste', listener);
        window.addEventListener('resize', listener);
        document.addEventListener('scroll', documentListener);
        this.setState({
            documentListener: documentListener,
            windowListener: listener,
        })
        
    }

    //creates a highlight div element on each of the gender word identified 
    highlightWords = (id, node, genderWords, resultText, updateText, self) => {
        if(genderWords) {
            let highlightArray = [];
            genderWords.forEach((genderWord) => {
                let r = document.createRange();
                r.setStart(genderWord.node, genderWord.index);
                r.setEnd(genderWord.node, genderWord.index + genderWord.text.length);
                let rect = r.getClientRects()[0];
                highlightArray.push({top: rect.top, left: rect.left, width: rect.width, height: rect.height, gender: genderWord.gender, index: genderWord.index, id: genderWord.id});
                
            });
            if(highlightArray.length > 0) {
                let array = self.state.highlighters;
                array[id] = {node: node, highlights: highlightArray, gender: genderBiasWordResources["masculine-text"]};
                self.setState({highlighters: array});
            }
            updateText(resultText, self);
        };
    }

    //Calculates gender bias review result by going over the total number of feminine and masculine words
    calculateGenderBiasResult = (words) => {
        let genderWords = [];
        
        let result = "";
        let count = 0, fcount = 0, mcount = 0;
        for(let i=0; i<words.length; i++){
            for(let j=0; j< GenderBiasWords.feminine_words.length; j++){
                if(words[i].text === GenderBiasWords.feminine_words[j]){
                    words[i].gender = genderBiasWordResources["feminine-text"];
                    genderWords.push(words[i]);
                    fcount ++;
                }
            }
            for(let j=0; j< GenderBiasWords.masculine_words.length; j++){
                if(words[i].text === GenderBiasWords.masculine_words[j]){
                    words[i].gender = genderBiasWordResources["masculine-text"];
                    genderWords.push(words[i]);
                    mcount ++;
                }
            }
        }
        count = fcount - mcount;
        if(count === 0){
            result = genderBiasWordResources["neutral-review"];
        }else if(count>0 && count<=3){
            result = genderBiasWordResources["feminine-review"];
        }else if(count>3){
            result = genderBiasWordResources["strongly-feminine-review"];
        }else if(count<0 && count>= -3){
            result = genderBiasWordResources["masculine-review"];
        }else if(count< -3){
            result = genderBiasWordResources["strongly-masculine-review"];
        }

        let list =  "<span style=\"font-weight: bold;\">Gender-biased words found:</span><br>";
        genderWords.forEach( (word) =>{
            // console.log(word);
            if(word.gender === "masculine")
            {
                list += "<span style= \"background-color:"+ genderBiasWordResources["masculine-highlight-color"]+"\">"+ word.text + "</span> " ; 
            }else{
                list += "<span style= \"background-color:"+ genderBiasWordResources["feminine-highlight-color"]+"\">"+ word.text + "</span> "; 
            }
        })
                                                    
        this.setState({
            genderWords: genderWords,
            count: count,
            fcount: fcount,
            mcount: mcount,
            detailWords: list,
        });
        return {genderWords, result};
    }

    //performs gender bias review for the text found under a given node
    doGenderBiasReview = (id, node, updateText, self) => {
      if(!self) {
          self = this;
      }
      let words = [];
      self.traverseNodeTree(id, node, updateText, words, self);
      words = words.filter((word) => {
          return (word.text.search(/^\s+$/) !== 0);
      });
      let {genderWords, result} = self.calculateGenderBiasResult(words);
      self.highlightWords(id, node, genderWords, result, updateText, self);
      self.setState({highlightsCleared: false});

    }

    //clears all the highlighters and the review result
    clearGenderBiasReview = (id, self, keepText) => {
        let list = self.state.highlighters;
        delete list[id];
        self.setState({highlighters: list});
        if(!keepText) {
            self.updateText("", self);
        }
        self.setState({highlightsCleared: true});
    }

    //Traverses the node tree and performs gender bias review on each text node
    traverseNodeTree = (id, node, updateText, words, self) => {
        if(!node) {
            return;
        }
        if(!self) {
            self = this;
        }
        
        if(node.contentEditable == "true") {
            self.processTextNode(id, node, updateText, words, self);
            return;
        } else {
          if(node.childNodes && node.childNodes.length > 0) {
            for(let i = 0; i < node.childNodes.length; i++) {
                this.traverseNodeTree(id, node.childNodes[i], updateText, words, self);
            }
          }
        }
    }

    //performs gender bias review on a given text node
    processTextNode = (id, node, updateText, words, self) => {
        if(node.nodeType == node.TEXT_NODE) {
            
            let tokens = node.textContent.split(/([\s,.!?:;\(\){}\[\]\/\"\'\#]+)/g);
            let index = 0;
            let id = 0;
            tokens.forEach((t) => {
                id = words.length + 1;
                words.push(new GenderBiasWord(node, t, index, id));
                index = index + t.length;
            });
            return;
        } else {
          if(node.childNodes && node.childNodes.length > 0) {
            for(let i = 0; i < node.childNodes.length; i++) {
                this.processTextNode(id, node.childNodes[i], updateText, words, self);
            }
          }
        }
    }

    //Updates the gender bias review result text element with result
    updateText = (text, self) => {
        text = text ? text : genderBiasWordResources.help;
        self.setState({genderBiasReviewResult: {__html: text}});
    }

    //Enables or disables the gender bias review
    toggleGenderBiasReview = (event, self) => {
        event.preventDefault();
        if(self.state.genderBiasReviewEnabled == false) {
            self.doGenderBiasReview(1, self.genderRef.current.parentNode, self.updateText);
            self.setState({genderBiasReviewEnabled: true});
            document.getElementById(this.props.id).style.backgroundColor = "#dedede";
            document.getElementById("gender-bias-show-details-popup"+this.props.id).style.display="block";
        } else {
            self.clearGenderBiasReview(1, self);
            self.setState({genderBiasReviewEnabled: false});
            document.getElementById(this.props.id).style.backgroundColor = "";
            this.popupStateRef.current.state.isOpen= false;
            document.getElementById("gender-bias-show-details-popup"+this.props.id).style.display="none";

        }
    }

    //Enable tooltip for a gender bias highlighter
    handleGenderToolTipOn = (id, highlightId) => {
        document.getElementById(id).style.visibility = "visible";
        document.getElementById(highlightId).style["z-index"] = 2;
    }

    //Disable tooltip for a gender bias highlighter
    handleGenderToolTipOff = (id, highlightId) => {
        document.getElementById(id).style.visibility = "hidden";
        document.getElementById(highlightId).style["z-index"] = 1;
    }

    //Return div highlights (based on what is stored in this.state.highlighters)
    getHighlights = () => {
      let i =0;
      let highlights = Object.keys(this.state.highlighters).map( (id, index) => {
          return this.state.highlighters[id].highlights.map( (highlight) => {
            return (
            <div
               className="gender-bias-highlight" 
               id={"highlight-" + this.props.id + highlight.id}
               key={"highlight-key-" + this.props.id + highlight.id}
               gender={highlight.gender}
               style={{
                  position: "fixed",
                  top: highlight.top ,
                  left: highlight.left,
                  width: highlight.width,
                  height: highlight.height,
                  background: highlight.gender === genderBiasWordResources["masculine-text"]? genderBiasWordResources["masculine-highlight-color"] : genderBiasWordResources["feminine-highlight-color"],
                }}
                onMouseOver={(event) => {this.handleGenderToolTipOn("highlight-tooltip" + this.props.id + highlight.id, "highlight-" + this.props.id + highlight.id)}}    
                onMouseOut={(event) => {this.handleGenderToolTipOff("highlight-tooltip" + this.props.id + highlight.id, "highlight-" + this.props.id + highlight.id)}}           
                >
                    <div 
                      id={"highlight-tooltip" + this.props.id + highlight.id}
                      key={"highlight-tooltip-key-" + this.props.id + highlight.id}
                      className="gender-bias-highlight-tooltip"
                      style={{
                        visibility:"hidden",
                        width: "90px",
                        color: "#fff",
                        padding: "0",
                        position: "fixed",
                        top: highlight.top + highlight.height,
                        left: highlight.left
                    }}>{highlight.gender}</div>
            </div>
            );
          });
         
        
      });
      return highlights;
    }
    
    render(){
        const {classes} = this.props;
        return (
            <Fragment>
                <div id={this.props.id} ref={this.genderRef} className={"gender-bias-check" } style={this.props.style} >
                
                    <PopupState variant="popper" popupId="demo-popup-popper" ref={this.popupStateRef}>
                        {
                            
                        (popupState) =>  {
                            // console.log(popupState);
                            return (
                            
                            <div className="gender-bias-show-details" style={{display:"none"}} id={"gender-bias-show-details-popup"+ this.props.id} >
                            <Button variant="outlined" size="small"  className={`${"gender-bias-show-details-button"} ${classes.sectionDesktop}`} {...bindToggle(popupState)}>
                                Show Details
                            </Button>
                            <Popper {...bindPopper(popupState)} placement="left-start" transition className={` ${classes.sectionDesktop}`}>
                                {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                    <Paper>
                                        <div className="gender-bias-details-popup">
                                           
                                            <a href="" className="gender-bias-details-popup-close" onClick={(event)=>{
                                                event.preventDefault();
                                              popupState.close();  
                                            }}>X</a>
                                            <div className="gender-bias-details-header" style={{"font-size": "1rem"}}><b>Gender Bias Review Details</b></div>
                                            <div className="gender-bias-details-summary" dangerouslySetInnerHTML={{__html:
                                                
                                                
                                                    this.state.count === 0? 
                                                    "<b>Nice work!</b> You have a balance of <span style= \"background-color:"+genderBiasWordResources["masculine-highlight-color"]+"\">male-coded </span>and <span style=\"background-color:"+ genderBiasWordResources["feminine-highlight-color"]+"\">female-coded </span> words." 
                                                    :
                                                    this.state.mcount >= this.state.fcount?
                                                    "You're using more <span style= \"background-color:"+genderBiasWordResources["masculine-highlight-color"]+"\">male-coded </span> words than <span style=\"background-color:"+ genderBiasWordResources["feminine-highlight-color"]+"\">female-coded </span> words." 
                                                    :
                                                    "You're using more <span style= \"background-color:"+genderBiasWordResources["feminine-highlight-color"]+"\">female-coded </span> words than <span style=\"background-color:"+ genderBiasWordResources["masculine-highlight-color"]+"\">male-coded </span> words." 

                                                
                                            }}>
                                            </div>
                                            <div className="gender-bias-details-bar">
                                                <Box width="100%" mr={1}>
                                                    <BorderLinearProgress variant="determinate" value={
                                                        this.state.fcount===0 && this.state.mcount===0 ? 50: (this.state.fcount/(this.state.fcount+this.state.mcount))*100
                                                    } />
                                                </Box>
                                                <Box minWidth={35} style={{float:"left", display:"inline-block"}}>
                                                    <Typography variant="body2" color="textSecondary">{this.state.fcount}</Typography>
                                                </Box>
                                                <Box minWidth={35} style={{float:"right", display:"inline-block"}}>
                                                    <Typography  className="gender-bias-details-bar-male-count" variant="body2" color="textSecondary">{this.state.mcount}</Typography>
                                                </Box>
                                            </div>
                                            <br></br>
                                            <div className="gender-bias-details-words" dangerouslySetInnerHTML={{__html: this.state.detailWords}}>
                                                
                                            </div>
                                        
                                        </div>
                                    </Paper>
                                </Fade>
                                )}
                            </Popper>
                            </div>
                        )}}
                    </PopupState>
                    <a href="" onClick={event => {this.toggleGenderBiasReview(event, this)}} >

                        <div className="gender-bias-text" dangerouslySetInnerHTML={this.state.genderBiasReviewResult}/>
                        <SupervisedUserCircleIcon style={{color: "gray", "marginLeft": "10px"}}/>
                    </a>
                    

                </div>
                {this.getHighlights()}
            </Fragment>
        );
    }
}
    

export default 
    withStyles(useStyles, { withTheme: true })(GenderBiasReview)
  ;
