import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import Authentication from "./components/Authentication/Authentication";
import Confirmation from "./components/Confirmation/Confirmation";
import Contact from "./components/Confirmation/ContactConfirmation";
import Loader from "./components/Utilities/Loader/Loader";
import Employer from "./components/User/Employer/Employer";
import Candidate from "./components/User/Candidate/Candidate";
import Logout from "./components/Authentication/Logout";
import ErrorAlert from "./components/Utilities/Alert/AlertError";
import SuccessAlert from "./components/Utilities/Alert/AlertSuccess";
import MuiThemeProvider from "@material-ui/styles/ThemeProvider";
import { CssBaseline, CircularProgress, Backdrop, Card, Snackbar } from "@material-ui/core";
import theme from "./theme/theme";
import oauthConfig from "./oauthConfig";
import axios from "axios";
import oauth from "axios-oauth-client";

class App extends Component {
  state = {
    isAuthenticated: localStorage.getItem("token"),
    formStepsFinish: localStorage.getItem("isRegistered") !== null ? JSON.parse(localStorage.getItem("isRegistered")) : null,
    token: localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null,
    loading: false,
    uuid: localStorage.getItem("uuid") !== null ? localStorage.getItem("uuid") : null,
    error: {
      isError: false,
      message: "",
    },
    initialData: sessionStorage.getItem("initialData") && JSON.parse(sessionStorage.getItem("initialData")),
    role: localStorage.getItem("role") !== null ? localStorage.getItem("role") : null,
    contactConfirmation: {
      open: false,
      msg: "",
      type: "",
    },
  };

  componentDidMount() {
    if (this.state.token !== null && Math.floor(Date.now() / 1000) > this.state.token.expirationDate) {
      this.handleClear();
      this.getRefreshToken();
    }

    if (this.state.token !== null && Math.floor(Date.now() / 1000) < this.state.token.expirationDate) {
      const noRole = !this.state.role;
      const noInitialData = !this.state.initialData;
      const noUUID = !this.state.uuid;

      if (noUUID) {
        this.getUUID(this.state.token);
      }
      if (noRole) {
        this.getUserRole(this.state.token);
      }
      if (noInitialData) {
        this.getInitialData(this.state.token);
      }
    }
  }

  handleStartLoading = () => {
    this.setState({ loading: true });
  };

  handleStopLoading = () => {
    this.setState({ loading: false });
  };

  handleResponseOpen = (msg, type) => {
    this.setState({
      contactConfirmation: {
        open: true,
        msg: msg,
        type: type,
      },
    });
  };

  handleContactClose = () => {
    this.setState({
      contactConfirmation: {
        open: false,
        msg: "",
        type: "",
      },
    });
  };

  getOauthToken = (sender, username, password) => {
    this.fetchOauthToken(sender, username, password);
  };

  getRefreshToken = () => {
    this.refreshOauthToken();
  };

  fetchOauthToken = (sender, username, password) => {
    this.handleStartLoading();
    const getTokenData = oauth.client(axios.create(), {
      url: oauthConfig.baseUrl + "/oauth/token",
      grant_type: "password",
      client_id: oauthConfig.oauth.client_id,
      client_secret: oauthConfig.oauth.client_secret,
      username: username,
      password: password,
    });

    const tokenData = getTokenData();

    tokenData
      .then((data) => {
        let token = { ...data };
        const headers = {
          headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json",
            Authorization: `${token.token_type} ${token.access_token}`,
          },
        };
        token.date = Math.floor(Date.now() / 1000);
        token.expirationDate = token.date + token.expires_in;
        this.setState({ token: token });
        localStorage.setItem("token", JSON.stringify(token));
        axios.get(`${oauthConfig.baseUrl}/api`, headers).then((res) => {
          const uuid = res.data.meta.links.me.meta.id;
          this.handleUUID(uuid);
          this.dataSetUp(uuid, token);
        });
      })
      .catch((err) => {
        if (err.response) {
          this.handleOpenError(err.response.data.message);
        }
        this.handleStopLoading();
      });
  };

  refreshOauthToken = () => {
    this.handleStartLoading();
    if (this.state.token !== null) {
      const getTokenData = oauth.client(axios.create(), {
        url: oauthConfig.baseUrl + "/oauth/token",
        grant_type: "refresh_token",
        client_id: oauthConfig.oauth.client_id,
        client_secret: oauthConfig.oauth.client_secret,
        refresh_token: this.state.token.refresh_token,
      });

      const tokenData = getTokenData();

      tokenData
        .then((data) => {
          let token = { ...data };
          token.date = Math.floor(Date.now() / 1000);
          token.expirationDate = token.date + token.expires_in;
          const headers = {
            headers: {
              Accept: "application/vnd.api+json",
              "Content-Type": "application/vnd.api+json",
              Authorization: `${token.token_type} ${token.access_token}`,
            },
          };
          this.setState({ token: token });
          localStorage.setItem("token", JSON.stringify(token));
          axios.get(`${oauthConfig.baseUrl}/api`, headers).then((res) => {
            const uuid = res.data.meta.links.me.meta.id;
            this.handleUUID(uuid);
            this.dataSetUp(uuid, token);
          });
        })
        .catch((err) => {
          this.handleLogout();
          this.handleStopLoading();
        });
    }
  };

  handleUUID = (uuid) => {
    localStorage.setItem("uuid", uuid);
    this.setState({
      uuid: uuid,
    });
  };

  handleFormStepsFinsih = () => {
    localStorage.setItem("isRegistered", true);
    this.setState({ formStepsFinish: true });
  };

  handleOpenError = (msg) => {
    this.setState({
      error: {
        isError: true,
        message: msg,
      },
    });
  };

  handleCloseError = () => {
    this.setState({
      error: {
        isError: false,
        message: "",
      },
    });
  };

  handleClear = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("uuid");
    localStorage.setItem("isRegistered", false);
    localStorage.removeItem("role");
  };

  handleLogout = () => {
    this.handleClear();
    this.setState({
      isAuthenticated: false,
      formStepsFinish: false,
      role: null,
    });
    this.props.history.push("/");
  };

  setHeaders = (token) => {
    const headers = {
      headers: {
        Accept: "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
        Authorization: `${token.token_type} ${token.access_token}`,
      },
    };

    return headers;
  };

  getTaxonomyData = async (taxonomyName, number, token) => {
    const headers = this.setHeaders(token);
    let arr = [];
    var i = 0;

    while (i < number) {
      let response = await axios.get(`${oauthConfig.baseUrl}/api/taxonomy_term/${taxonomyName}?page[limit]=50&page[offset]=${i}`, headers);
      arr = [...arr, ...response.data.data];
      i = i + 50;
    }

    return arr;
  };

  getInitialData = async (token) => {
    let initialData;

    if (sessionStorage.getItem("initialData")) {
      initialData = JSON.parse(sessionStorage.getItem("initialData"));
      this.setState({
        initialData,
      });
      return;
    }

    const headers = this.setHeaders(token);

    // Lists
    const interestsResponse = await axios.get(`${oauthConfig.baseUrl}/api/list_field_options/field_involving_interest?_format=json`, headers);
    const experianceResponse = await axios.get(`${oauthConfig.baseUrl}/api/list_field_options/field_professional_experience?_format=json`, headers);
    const companyPositionResponse = await axios.get(`${oauthConfig.baseUrl}/api/list_field_options/field_company_position?_format=json`, headers);
    const typeResponse = await axios.get(`${oauthConfig.baseUrl}/api/list_field_options/field_employment_type?_format=json`, headers);
    const remoteResponse = await axios.get(`${oauthConfig.baseUrl}/api/list_field_options/field_remote_job?_format=json`, headers);
    const methodResponse = await axios.get(`${oauthConfig.baseUrl}/api/list_field_options/field_application_method?_format=json`, headers);
    const companySizeResponse = await axios.get(`${oauthConfig.baseUrl}/api/list_field_options/field_company_size?_format=json`, headers);
    const remoteWork = await axios.get(`${oauthConfig.baseUrl}/api/list_field_options/field_remote_work?_format=json`, headers);
    const visaSponsorship = await axios.get(`${oauthConfig.baseUrl}/api/list_field_options/field_visa_sponsorship_required?_format=json`, headers);
    const rolesResponse = await axios.get(`${oauthConfig.baseUrl}/api/list_field_options/field_roles_interested?_format=json`, headers);
    const languagesResponse = await axios.get(`${oauthConfig.baseUrl}/api/list_field_options/field_languages?_format=json`, headers);
    const languageProficienciesResponse = await axios.get(`${oauthConfig.baseUrl}/api/list_field_options/field_language_proficiencies_def?_format=json`, headers);
    const currencyResponse = await axios.get(`${oauthConfig.baseUrl}/api/list_field_options/field_currency?_format=json`, headers);

    // Taxonomy
    const countriesResponse = await this.getTaxonomyData("country", 300, token);
    const technicalSkillsResponse = await this.getTaxonomyData("technical_skills", 60, token);
    const personalSkillsResponse = await this.getTaxonomyData("personal_skills", 50, token);
    const benefitsResponse = await this.getTaxonomyData("benefits", 50, token);
    const industryResponse = await this.getTaxonomyData("industry", 50, token);
    const sourceResponse = await this.getTaxonomyData("candidate_source", 50, token);

    //Modify taxonomy as react-select format
    const sourceCountriesData = sourceResponse.map((obj) => ({
      label: obj.attributes.name,
      id: obj.id,
      tid: obj.attributes.drupal_internal__tid,
      value: obj.attributes.drupal_internal__tid,
    }));

    const extractedCountriesData = countriesResponse.map((obj) => ({
      label: obj.attributes.name,
      id: obj.id,
      tid: obj.attributes.drupal_internal__tid,
      value: obj.attributes.drupal_internal__tid,
    }));

    const arrPersonalSkillData = personalSkillsResponse.map((obj) => ({
      label: obj.attributes.name,
      id: obj.id,
      value: obj.attributes.name,
    }));

    const arrTechnicalSkillData = technicalSkillsResponse.map((obj) => ({
      label: obj.attributes.name,
      id: obj.id,
      tid: obj.attributes.drupal_internal__tid,
      value: obj.attributes.name,
    }));

    const benefits = benefitsResponse.map((obj) => ({
      label: obj.attributes.name,
      id: obj.id,
      value: obj.attributes.name,
    }));

    const industry = industryResponse.map((obj) => ({
      label: obj.attributes.name,
      id: obj.id,
      value: obj.attributes.name,
    }));

    //Modify list as react-select format
    const role = Object.keys(rolesResponse.data).map((el, index) => ({
      label: rolesResponse.data[el],
      value: el,
    }));

    const currency = Object.keys(currencyResponse.data).map((el, index) => ({
      label: currencyResponse.data[el],
      value: el,
    }));

    const languages = [];
    for (const lang in languagesResponse.data) {
      languages.push({ label: languagesResponse.data[lang], value: lang });
    }

    const languageProficiencies = [];
    for (const lang in languageProficienciesResponse.data) {
      languageProficiencies.push({ label: languageProficienciesResponse.data[lang], value: lang });
    }

    initialData = {
      country: extractedCountriesData,
      personalSkills: arrPersonalSkillData,
      techSkills: arrTechnicalSkillData,
      source: sourceCountriesData,
      benefits: benefits,
      industry: industry,
      interests: interestsResponse.data,
      experience: experianceResponse.data,
      position: companyPositionResponse.data,
      method: methodResponse.data,
      type: typeResponse.data,
      remote: remoteResponse.data,
      remoteWork: remoteWork.data,
      size: companySizeResponse.data,
      visaSponsorship: visaSponsorship.data,
      role,
      languages,
      languageProficiencies,
      currency,
    };

    sessionStorage.setItem("initialData", JSON.stringify(initialData));

    this.setState({
      initialData,
    });
  };

  getUserRole = async (token) => {
    let role;
    if (localStorage.getItem("role")) {
      role = localStorage.getItem("role");
      this.setState({ role });
      return role;
    }

    const headers = this.setHeaders(token);
    const userRole = await axios.get(`${oauthConfig.baseUrl}/api/current-user/roles?_format=json`, headers);
    const arrRole = userRole.data[0].roles_target_id.split(",");

    const isEmployer = arrRole.find((el) => el === "Employer" || el === "Unverified Employer");
    const isCandidate = arrRole.find((el) => el === "Candidate" || el === "Unverified Candidate");

    if (Boolean(isEmployer)) {
      localStorage.setItem("role", "employer");
      this.setState({ role: "employer" });
      return "employer";
    } else if (Boolean(isCandidate)) {
      localStorage.setItem("role", "candidate");
      this.setState({ role: "candidate" });
      return "candidate";
    } else {
      this.handleResponseOpen("There was an error! Your account needs to be configured by an administrator, please contact our support team.", "error");
      this.handleLogout();
    }
  };

  getUUID = async (token) => {
    const headers = this.setHeaders(token);
    axios
      .get(`${oauthConfig.baseUrl}/api`, headers)
      .then((res) => {
        const uuid = res.data.meta.links.me.meta.id;
        this.handleUUID(uuid);
      })
      .catch((err) => {
        this.handleLogout();
        this.handleStopLoading();
      });
  };

  dataSetUp = async (uuid, token) => {
    const headers = this.setHeaders(token);
    const user = await axios.get(`${oauthConfig.baseUrl}/api/user/user/${uuid}`, headers);
    const profile = user.data.data.relationships.field_personal_profile_reference.data;
    localStorage.setItem("email", user.data.data.attributes.mail);
    const noRole = !this.state.role;
    const noInitialData = !this.state.initialData;
    if (noRole) {
      this.getUserRole(token);
    }
    if (noInitialData) {
      this.getInitialData(token);
    }
    if (profile) {
      this.handleFormStepsFinsih();
      this.handleStopLoading();
    } else {
      this.getUserRole(token).then((res) => {
        if (res === "employer") {
          this.handleStopLoading();
          this.props.history.push("/sign-up-employer");
        } else {
          this.handleStopLoading();
          this.props.history.push("/sign-up-candidate");
        }
      });
    }
  };

  render() {
    let content;
    if (this.state.loading) {
      content = (
        <Backdrop open={this.state.loading}>
          <CircularProgress color="secondary" />
        </Backdrop>
      );
    } else if (this.state.formStepsFinish && this.state.uuid) {
      content = this.state.role ? (
        this.state.role === "employer" ? (
          <Employer
            token={this.state.token}
            uuid={this.state.uuid}
            loginFunction={this.getOauthToken}
            logout={this.handleLogout}
            initialData={this.state.initialData}
            refreshToken={this.getRefreshToken}
            responseOpen={this.handleResponseOpen}
          />
        ) : (
          <Candidate
            token={this.state.token}
            uuid={this.state.uuid}
            logout={this.handleLogout}
            initialData={this.state.initialData}
            refreshToken={this.getRefreshToken}
            responseOpen={this.handleResponseOpen}
          />
        )
      ) : (
        <Loader />
      );
    } else if (!this.state.formStepsFinish) {
      content = (
        <Authentication
          initialData={this.state.initialData}
          stratLoading={this.handleStartLoading}
          stopLoading={this.handleStopLoading}
          loginFunction={this.getOauthToken}
          cc={this.handleClear}
          refreshToken={this.getRefreshToken}
          formStepsFinsih={this.handleFormStepsFinsih}
          role={this.state.role}
          token={this.state.token}
          uuid={this.state.uuid}
          handleUUID={this.handleUUID}
          responseOpen={this.handleResponseOpen}
          error={this.state.error}
          openError={this.handleOpenError}
          closeError={this.handleCloseError}
        />
      );
    }
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline>
          {content}
          <Route path="/email-confirm/:hash" render={(props) => <Confirmation {...props} isLogged={this.state.uuid} responseOpen={this.handleResponseOpen} refreshToken={this.getRefreshToken} />} />
          <Route path="/api/candidate/organization-access" render={(props) => <Contact {...props} contactOpen={this.handleResponseOpen} />} />
          <Route path="/logout" render={() => <Logout logout={this.handleLogout} />} />
          <Snackbar autoHideDuration={8000} open={this.state.contactConfirmation.open} onClose={this.handleContactClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
            {this.state.contactConfirmation.type === "success" ? <SuccessAlert>{this.state.contactConfirmation.msg}</SuccessAlert> : <ErrorAlert>{this.state.contactConfirmation.msg}</ErrorAlert>}
          </Snackbar>
        </CssBaseline>
      </MuiThemeProvider>
    );
  }
}

export default withRouter(App);
