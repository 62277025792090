import React from 'react';
import { makeStyles, Tooltip, RadioGroup, Radio, FormControlLabel, Box } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import tooltip from '../../../tooltip';

const useStyles = makeStyles ({
    root: {
        marginRight: 5
    },
    checkboxLabel: {
        fontSize: 14
    },
    controlContainer: {
        marginRight: '20px'
    },
    tooltip: {
        fontSize: 12,
    }
});

const all_skills = tooltip.all_skills.en.string;
const any_skills = tooltip.any_skills.en.string;

const RadioOptions = (props) => {
    const classes = useStyles();
    return (
        <div>
            <RadioGroup onChange={(e)=> props.handleChangeRadio(e.target.value, props.checked)} value={props.value}>
            <Box display="flex">
            {
                props.values && (
                    props.values.map(el => {
                        return   (
                            <Box key={el.label} className={classes.controlContainer} display="flex" alignItems="center">
                                <FormControlLabel
                                    classes={{  
                                        label:classes.checkboxLabel,
                                        root:classes.root
                                    }}
                                    control={<Radio />}  
                                    label={el.label}
                                    value={el.value} 
                                />
                                <Tooltip classes={{tooltip: classes.tooltip}} title={el.label === "Any" ? any_skills : all_skills}><HelpIcon style={{ fontSize: 15 }} /></Tooltip>
                            </Box>
                        )
                    })
                )
            }
            </Box>
            </RadioGroup>  
        </div>
    )
    
}

export default RadioOptions;