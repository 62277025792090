import React, { Component, Fragment } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FormHelperText } from "@material-ui/core";
// import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";

const editorConfiguration = {
  toolbar: ["heading", "|", "bold", "italic", "|", "bulletedList", "numberedList", "|", "indent", "blockQuote"],
  heading: {
    options: [
      { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
      { model: "heading2", view: "h2", title: "Heading 2", class: "ck-heading_heading2" },
      { model: "heading3", view: "h3", title: "Heading 3", class: "ck-heading_heading3" },
      { model: "heading4", view: "h4", title: "Heading 4", class: "ck-heading_heading4" },
      { model: "heading5", view: "h5", title: "Heading 5", class: "ck-heading_heading5" },
      { model: "heading6", view: "h6", title: "Heading 6", class: "ck-heading_heading6" },
    ],
  },
};

class RichTextEditor extends Component {
  componentDidMount() {}

  extractData = (event, editor) => {
    const data = editor.getData();
  };

  render() {
    return (
      <Fragment>
        <div className="App" style={this.props.error ? { border: "1px solid red" } : {}}>
          <CKEditor
            config={editorConfiguration}
            editor={ClassicEditor}
            data={this.props.data ? this.props.data : ""}
            onInit={(editor) => {
              // You can store the "editor" and use when it is needed.
              // console.log( 'Editor is ready to use!', editor );
            }}
            // onChange={ (event, editor) => {} }
            onBlur={(event, editor) => {
              this.props.rteChange(event, editor, this.props.id);
              if (this.props.onBlur) {
                this.props.onBlur({ target: { id: this.props.id } });
              }
            }}
            onFocus={(event, editor) => {
              // console.log( 'Focus.', editor );
            }}
          />
        </div>
        {this.props.error && (
          <FormHelperText error={this.props.error} variant="filled">
            {this.props.helperText}
          </FormHelperText>
        )}
      </Fragment>
    );
  }
}

export default RichTextEditor;
