import React, { Fragment, useState, useRef } from "react";
import Loader from '../Utilities/Loader/Loader';
import { makeStyles, Grid, Typography, Tooltip, Button } from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import ReactCrop ,{ centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import dummyImage from '../../images/placeholder.png';
import oauthConfig from '../../oauthConfig';

const useStyles = makeStyles(theme => ({
    initialData: {
        marginBottom: theme.spacing(2),
        width: '200px',
        height: '130px'
    },
    imagePreview: {
      width: '100%',
      height: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'contain'
    },
    customFileUpload: {
        display: 'inline-block',
        backgroundColor: theme.palette.primary.main,
        borderRadius: 3,
        border: 0,
        color: 'white',
        padding: '4px 16px 0 16px',
        fontSize: '0.875rem',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.secondary.main
        },
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    profileImagePreview: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '150px',
        height: '150px',
        marginBottom: theme.spacing(1),
        borderRadius: '50%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover'
    },
    container: {
        minHeight: '180px'
    },
    cropWindow: {
        marginBottom: theme.spacing(2)
    }
}))

const DropUploader = (props) => {
    const classes = useStyles();
    const dropStyles = {
        dropzone: { 
            overflow: 'hidden',
            backgroundImage: `url(${dummyImage})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            border: '3px dashed #e0e0e0',
            minHeight: '180px',
            width: '100%'
        },
        dropzoneWrap: {
            width: '100%'
        },
        inputLabel: {
            fontSize: '12px',
            color: "#424242"
        },
        preview: {
            padding: '5px',
            border: 'none',
            minHeight: '180px'
        },
        previewImage: {
            maxWidth: '50%',
            maxHeight: 'none',
            position: 'relative',
            left: '50%',
            transform: 'translateX(-50%)' 
        },
        dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
    }

    const canvasStyle = {
        display: 'inlien-block',
        objectFit: 'contain',
        borderRadius: '50%',
        width: '150px',
        height: '150px',
        marginBottom: '20px'
    }

    const [imgSrc, setImgSrc] = useState('');
    const [crop, setCrop] = useState("");
    const imagePreviewCanvasRef = useRef(null);
    const imgElement = useRef(null);

    
    // Convert a Base64-encoded string to a File object
    const base64StringtoFile = (base64String, filename) => {
        var arr = base64String.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }

    // Extract an Base64 Image's File Extension
    const extractImageFileExtensionFromBase64 = (base64Data) => {
        return base64Data.substring("data:image/".length, base64Data.indexOf(";base64"))
    }
    
    // Base64 Image to Canvas with a Crop
    const image64toCanvasRef = (canvasRef, image64, crop) => {
        const canvas = canvasRef;
        const pixelRatio = window.devicePixelRatio;
        const scaleX = imgElement.current.naturalWidth / imgElement.current.width;
        const scaleY = imgElement.current.naturalHeight / imgElement.current.height;
        const ctx = canvas.getContext('2d');

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;
        
        const image = new Image()
        image.src = image64

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";

        image.onload = function() {
            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width * scaleX,
                crop.height * scaleY
            );
        }
    }
    const onImageLoad = (e) => {
        const { width, height } = e.currentTarget
        const crop = centerCrop(
          makeAspectCrop(
            {
              unit: '%',
              width: 100,
            },
            1 / 1,
            width,
            height
          ),
          width,
          height
        )
        setCrop(crop)
        handleCropComplete(crop);
    }

    const handleCropComplete = (crop, percentCrop) => {
        const canvasRef = imagePreviewCanvasRef.current;
        image64toCanvasRef(canvasRef, imgSrc, crop)
    }

    const handleSubmit = () => {
        const canvasRef = imagePreviewCanvasRef.current;
        const fileExtention = extractImageFileExtensionFromBase64(imgSrc)
        const croppedImage = canvasRef.toDataURL('image/' + fileExtention)
        const imageName = Math.random().toString(36).substr(2, 5);
        const fileName = imageName + '.' + fileExtention;
        const file = base64StringtoFile(croppedImage, fileName)
        props.uploadFile(file, props.type, props.index)
    }

    const handleChangeStatus = (fileWithMeta, status) => {
        if(status === 'error_validation'){
            fileWithMeta.remove()
        }

        if(status === 'done'){
            const reader = new FileReader() 
            reader.addEventListener('load', () => {
                const src = reader.result.toString() || ''; 
                setImgSrc(src);
            })
            reader.readAsDataURL(fileWithMeta.file)
        }

        if(status === 'removed'){
            if(!fileWithMeta.meta.validationError){
                props.removeFile(props.type, props.index)
            }
        }
    };

    const bytesToMegaBytes = bytes => bytes / (1024*1024);

    const handleValidation = (fileWithMeta) => {
        if(fileWithMeta.file.name.split('.').pop() === "jfif"){
            return true
        }
        if(props.setSizeLimit && props.setMbLimit){
            const mbSize = bytesToMegaBytes(fileWithMeta.file.size)
            if(fileWithMeta.meta.width < props.setSizeLimit.width || fileWithMeta.meta.height < props.setSizeLimit.height || mbSize > props.setMbLimit.mbLimit){
                props.setSizeLimit.func(true)
                return true
            }else{
                props.setSizeLimit.func(false)
                return false
            }
        }
        if(props.setSizeLimit && !props.setMbLimit){
            if(fileWithMeta.meta.width < props.setSizeLimit.width || fileWithMeta.meta.height < props.setSizeLimit.height){
                props.setSizeLimit.func(true)
                return true
            }else{
                props.setSizeLimit.func(false)
                return false
            }
        }
    }
    
    const theme = useTheme();
    const placeholder = window.innerWidth > theme.breakpoints.values.lg ?  `Drag Files or Click to Browse` : `Click to Browse`

    const handleInputContent = (files, extra) => {
        return extra.reject ? 'jpeg, jpg, png files only' : placeholder;
    }
    const handleRemove = () => {
        setImgSrc("");
        setCrop("")
        props.removeInitialData(props.type, props.index)
    }

    const handleRemovePreview = () => {
        setImgSrc("");
        setCrop("")
    }

    let content;
    if(props.initialData && props.initialData === 'loading'){
        content = <Loader />

    }else if(props.initialData){
        content = (
            <Grid container direction="column" justify="center" alignItems="center">
                { props.type === 'field_profile_picture' ? (
                    <div className={classes.profileImagePreview} style={{backgroundImage: `url(${oauthConfig.baseUrl}/${ props.initialData })`}}></div>
                ):(
                    <div className={classes.initialData}>
                        <div className={classes.imagePreview} style={{backgroundImage: `url(${oauthConfig.baseUrl}/${ props.initialData })`}}></div>
                    </div>
                )} 
                <button className={classes.customFileUpload} type="button" onClick={handleRemove}><DeleteIcon /></button>
            </Grid>
        )

    }else{
        content = (
            <Tooltip title={props.tooltip ? props.tooltip : "" }>
                <Grid container direction="column" justify="center" alignItems="center">
                    {imgSrc ? (
                        <Fragment>
                            <canvas
                                ref={imagePreviewCanvasRef}
                                style={canvasStyle} 
                            />
                            <ReactCrop 
                                crop = {crop}
                                onChange={(crop, percentCrop) => setCrop(crop)}
                                aspect={1}
                                onComplete={(crop, percentCrop) => handleCropComplete(crop, percentCrop)}
                                circularCrop
                                className={classes.cropWindow}
                            >
                                <img src={imgSrc} ref={imgElement} onLoad={onImageLoad}/>
                            </ReactCrop>
                            <Grid container justify="space-around">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                >
                                    Crop
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleRemovePreview}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Fragment>
                    ):(
                        <Dropzone 
                            maxFiles={1}
                            multiple={false} 
                            onChangeStatus={handleChangeStatus}
                            validate={handleValidation} 
                            accept='image/png, image/jpeg, image/jpg'
                            inputContent={(files, extra) => handleInputContent(files, extra)}  
                            styles={dropStyles}
                        />
                    )}
                </Grid>
            </Tooltip>
        )
    }
    return (
        <Fragment>
            <Typography variant="subtitle2" align="center" color={props.color}>{props.title}</Typography>
            <Typography variant="subtitle2" align="center" color={props.color}>{props.subtitle}</Typography>
                {content}
            {!props.initialData && <Typography variant="caption" color={props.color}>{props.caption}</Typography>}
        </Fragment>
    )
}

export default DropUploader;
