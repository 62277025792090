import React, { Fragment, useState } from 'react';
import { Paper, Table, TableRow, TableCell, TableBody, IconButton, Box, CircularProgress, Dialog, DialogTitle, DialogActions, Button, Tooltip, makeStyles } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import StarsIcon from '@material-ui/icons/Stars';

const useStyles = makeStyles(theme => ({
    tableTitle: {
        marginBottom: '0',
        [theme.breakpoints.down('xs')]: {
            fontSize: '16px',
        }
    },
    tableSubtitle: {
        fontSize: '14px',
        fontStyle: 'italic',
        [theme.breakpoints.down('xs')]: {
            fontSize: '12px',
        }
    },
    tableEmail: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '14px',
        }
    }
}));


const AccessList  = (props) => {
    const classes = useStyles();
    const { loadings, accessUsers, owner, makeOwner,  removeAccess, isOwner, currentOrg} = props;
    const [open, setOpen] = useState({user: false, action: false});
    const dialogAction = (user, action) => {
        setOpen({user, action})
    }
    const handleClose = () => {
        setOpen({user: false, action: false});
    }
    return (
        <Fragment>
            <Paper>
                <Table>
                    <TableBody>
                        {accessUsers.map((user, index) => (
                            <TableRow key={user.uid}>
                                <TableCell style={{width: '80%'}}>
                                    { index == 0 && <div><h2 className={classes.tableTitle}>{`${owner.name}`}</h2><span className={classes.tableSubtitle}><strong>{`${currentOrg}`}</strong> profile admin</span></div>}
                                    { index == 0  && <h3 className={classes.tableEmail}>{user.email}</h3> }
                                    { index > 0  && <h2 className={classes.tableTitle}>{user.name}</h2> }
                                    { index > 0  && <h3 className={classes.tableEmail}>{user.email}</h3> }
                                </TableCell>
                                <TableCell style={{width: '20%'}}>
                                    { index == 0 ? (
                                            <Box display="flex" alignItems="center" justifyContent="flex-end" m="12px"><StarsIcon color="primary"/></Box>
                                        ) : (
                                            <Box display="flex" alignItems="center" justifyContent="flex-end" flexWrap="wrap">
                                                { loadings.loadingOwner ===  user.uid ?  <CircularProgress size={20}/> : isOwner && <Tooltip title="Transfer organization ownership"><IconButton onClick={()=>{ dialogAction(user, "owner") }} children={<StarsIcon />}/></Tooltip>}
                                                { loadings.loadingRemove ===  user.uid ?  <CircularProgress size={20}/> : isOwner && <Tooltip title="Remove user from this organization"><IconButton onClick={()=>{ dialogAction(user, "remove") }} children={<CancelIcon/>}/></Tooltip>} 
                                            </Box>
                                    ) }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
            {open.user && (
                <Dialog open={Boolean(open.user)} onClose={handleClose} onClick={handleClose}>
                    <DialogTitle>
                        { open.action == "owner" ? `Are you sure you want to transfer the organization's ownership to ${open.user.email}?` : `Are you sure you want to remove ${open.user.email} from the organization?` }
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={open.action == "owner" ? () => makeOwner(open.user.uid) : () => removeAccess(open.user.uid) } color="primary" variant="contained">
                            Yes
                        </Button>
                        <Button onClick={handleClose} color="primary" variant="contained">
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Fragment>
    );
}

export default AccessList;