import React, { Fragment } from "react";
import oauthConfig from "../../../oauthConfig";
import { withStyles, Modal, Slide, Paper, TextField, Button, Grid, Menu, MenuItem, Tooltip, FormControlLabel, Checkbox, FormGroup } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import AddIcon from "@material-ui/icons/Add";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import axios from "axios";
import Column from "./Column";
import SearchBar from "./SearchBar";
import Filters from "./Filters";
import Loader from "../../Utilities/Loader/Loader";
import queryString from "query-string";
import { Scrollbars } from "react-custom-scrollbars";
import Message from "../../Utilities/Error/ErrorText";
import tooltip from "../../../tooltip";

const styles = (theme) => ({
  container: {
    display: "inline-flex",
    position: "relative",
  },
  modal: {
    paddingTop: "100px",
    "&:focus": {
      outline: "none",
    },
  },
  modalContent: {
    width: "400px",
    height: "200px",
    position: "absolute",
    left: 0,
    right: 0,
    margin: "auto",
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    "&:focus": {
      outline: "none",
    },
  },
  filter: {
    margin: `${theme.spacing(2)}px 0`,
    [theme.breakpoints.between("xs", "sm")]: {
      padding: `${theme.spacing(2)}px 0`,
      overflowX: "scroll",
      overflowY: "hidden",
    },
  },
  search: {
    marginRight: `${theme.spacing(2)}px`,
  },
  dndContainer: {
    position: "relative",
  },
  dnd: {
    // display: "inline-block",
    marginBottom: `${theme.spacing(2)}px`,
    position: "relative",
  },
  addBtn: {
    position: "absolute",
    top: "0",
    right: "-40px",
    borderRadius: "50%",
    background: theme.palette.primary.main,
    color: "#ffff",
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold",
    cursor: "pointer",
    zIndex: 1000,
  },
  menuItem: {
    textTransform: "capitalize",
  },
  tooltip: {
    fontSize: 12,
  },
  checkboxes: {
    padding: `0 ${theme.spacing(2)}px`,
  },
});

class Pipelines extends React.Component {
  state = {
    modal: false,
    newColumnTitle: "",
    searchValue: "",
    selectedValues: "",
    query: "",
    initilaFilterData: {},
    hiddenColumns: ["rejected", "archived"],
    anchorEl: null,
    dragProps: {
      dragStart: null,
      ownership: null,
    },
  };

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    const queryKeys = Object.keys(query);
    this.fetchData()
      .then(() => {
        if (queryKeys.length > 0) {
          queryKeys.forEach((key) => {
            if (key === "search") {
              this.onChangeSearchBar(query[key]);
              this.setState({ searchValue: query[key] });
            }
          });
        }
      })
      .catch((err) => console.log(err));
  }

  fetchData = async () => {
    let token = this.props.token;
    const nid = this.props.currentOrg.nid;
    let headers = {
      headers: {
        Accept: "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
        Authorization: `${token.token_type} ${token.access_token}`,
      },
    };

    const tables = await axios.get(`${oauthConfig.baseUrl}/api/organization/${nid}/candidate_table/dashboard_table_${nid}`, headers);
    const columns = { ...tables.data.columns };
    let columnsOrder;
    let hiddenColumns;

    //Add archived column
    if (!columns["archived"]) {
      const archived = { id: "archived", title: "ARCHIVED", candidates: [] };
      columns["archived"] = archived;
    }

    //Check for available order of columns
    if (tables.data.columnsOrder) {
      columnsOrder = tables.data.columnsOrder;
    } else {
      columnsOrder = Object.keys(columns);
    }

    if (tables.data.hiddenColumns) {
      hiddenColumns = tables.data.hiddenColumns;
    }
    //Add Visible prop to all users
    Object.keys(columns).forEach((col) => {
      columns[col].candidates.forEach((user) => {
        user.visible = true;
        user.col_own = col;
      });
    });

    //Get all unique skills from users in array
    const uniqueSkills = this.getDataFromUsers(columnsOrder, columns, "field_technical_skills_name", ";");
    const uniqueCountries = this.getDataFromUsers(columnsOrder, columns, "field_candidate_country");
    const uniqueCity = this.getDataFromUsers(columnsOrder, columns, "field_city_text");
    const uniqueExperience = this.getDataFromUsers(columnsOrder, columns, "field_professional_experience");
    const skillsOptions = uniqueSkills.map((skill) => {
      return { label: skill, value: skill };
    });
    const countriesOptions = uniqueCountries.map((country) => {
      return { label: country, value: country };
    });
    const citiesOptions = uniqueCity.map((city) => {
      return { label: city, value: city };
    });
    const experienceOptions = uniqueExperience.map((experience) => {
      return { label: `${experience} years`, value: experience };
    });
    this.setState({
      columns,
      columnsOrder,
      hiddenColumns: hiddenColumns ? hiddenColumns : this.state.hiddenColumns,
      initilaFilterData: {
        skillsOptions,
        countriesOptions,
        citiesOptions,
        experienceOptions,
      },
    });
  };

  handlePost = (post) => {
    let token = this.props.token;
    const nid = this.props.currentOrg.nid;

    let headers = {
      headers: {
        Accept: "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
        Authorization: `${token.token_type} ${token.access_token}`,
      },
    };

    const copyOfColumns = post.columns;
    const allColumnNames = Object.keys(copyOfColumns);
    const columnsOrder = post.columnsOrder;
    const hiddenColumns = post.hiddenColumns;
    let columns;
    allColumnNames.forEach((name) => {
      const candidatesIDs = copyOfColumns[name].candidates.map((candidate) => candidate.node_id);
      const column = { title: copyOfColumns[name].title.toUpperCase(), id: name, candidates: candidatesIDs };
      columns = {
        ...columns,
        [name]: column,
      };
    });

    const body = JSON.stringify({ hiddenColumns, columnsOrder, columns });
    const data = {
      body: body,
    };
    axios
      .patch(`${oauthConfig.baseUrl}/api/organization/${nid}/candidate_table/dashboard_table_${nid}`, data, headers)
      .then((res) => {})
      .catch((err) => {});
  };

  getDataFromUsers = (colsVariation, columns, field, splitBy) => {
    const userFieldData = [];
    colsVariation.forEach((column) => {
      columns[column].candidates.forEach((user) => {
        if (user[field] && typeof user[field] === "string") {
          user[field].split(splitBy).forEach((el) => {
            userFieldData.push(el);
          });
        }
      });
    });
    const unique = userFieldData.reduce((accumulator, current) => {
      return accumulator.includes(current) ? accumulator : [...accumulator, current];
    }, []);
    return unique;
  };

  onDragStart = (start) => {
    if (start.type !== "column") {
      const colOwnsUser = this.state.columns[start.source.droppableId].candidates.find((user) => user.node_id === start.draggableId);
      this.setState({
        dragProps: {
          dragStart: start.source.droppableId,
          ownership: colOwnsUser.col_own,
        },
      });
    }
  };

  onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;

    //If out of Dropable context stop
    if (!destination) {
      return;
    }

    const start = this.state.columns[source.droppableId];
    const finish = this.state.columns[destination.droppableId];

    //If dropped on the same place stop
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    if (type === "column") {
      const newColumnsOrder = Array.from(this.state.columnsOrder);
      newColumnsOrder.splice(source.index, 1);
      newColumnsOrder.splice(destination.index, 0, draggableId);

      const newState = {
        ...this.state,
        columnsOrder: newColumnsOrder,
      };

      this.handlePost(newState);
      this.setState(newState);
      return;
    }

    //Move to same column
    if (start === finish) {
      const newUsers = Array.from(start.candidates);
      const movedUser = newUsers.find((user) => user.node_id === draggableId);
      newUsers.splice(source.index, 1);
      newUsers.splice(destination.index, 0, movedUser);

      const newColumn = {
        ...start,
        candidates: newUsers,
      };

      const newState = {
        ...this.state,
        columns: {
          ...this.state.columns,
          [newColumn.id]: newColumn,
        },
      };

      this.handlePost(newState);
      this.setState(newState);
      return;
    }

    //Move to different column
    const startUsers = Array.from(start.candidates);
    const movedUser = startUsers.find((user) => user.node_id === draggableId);
    startUsers.splice(source.index, 1);
    const newStart = {
      ...start,
      candidates: startUsers,
    };

    const finishUsers = Array.from(finish.candidates);
    finishUsers.splice(destination.index, 0, movedUser);
    const newFinish = {
      ...finish,
      candidates: finishUsers,
    };

    const newState = {
      ...this.state,
      columns: {
        ...this.state.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };
    this.handlePost(newState);
    this.setState(newState);
  };

  onChangeSearchBar = (value) => {
    const valLower = value.toLowerCase();
    const columns = { ...this.state.columns };
    Object.keys(columns).forEach((col) => {
      columns[col].candidates.forEach((user) => {
        if (!Boolean(value) && this.state.selectedValues.length === 0) {
          user.visible = true;
          return;
        } else {
          const name = (user.field_first_name + user.field_last_name).toLowerCase();
          const skills = user.field_technical_skills_name.toLowerCase();
          const country = user.field_candidate_country.toLowerCase();
          const city = user.field_city_text.toLowerCase();
          const experience = user.field_professional_experience;
          const userValues = [skills, name, country, city, experience];
          if (this.state.selectedValues.length > 0) {
            const selectedValuesLowerCase = this.state.selectedValues.map((el) => el.toLowerCase());
            const foundValue = userValues.find((el) => selectedValuesLowerCase.includes(el));
            const found = userValues.find((el) => el.includes(valLower));
            found && foundValue ? (user.visible = true) : (user.visible = false);
            return;
          }
          const found = userValues.find((el) => el.includes(valLower));
          found ? (user.visible = true) : (user.visible = false);
        }
      });
    });

    this.setState({
      searchValue: value,
      columns,
    });

    setTimeout(() => {
      this.manageQueryFilters([value], "search");
    }, 1000);
  };

  onApplyFilter = (checkedObj, field, arr) => {
    this.manageQueryFilters(arr, field);
    let selectedValues = [];
    for (const property in checkedObj) {
      const nested = checkedObj[property];
      if (Object.keys(nested).length > 0) {
        for (const key in nested) {
          if (nested[key]) {
            selectedValues.push(key);
          }
        }
      }
    }
    const columns = { ...this.state.columns };
    Object.keys(columns).forEach((col) => {
      columns[col].candidates.forEach((user) => {
        if (selectedValues.length === 0 && !this.state.searchValue) {
          user.visible = true;
          return;
        } else {
          const skills = user.field_technical_skills_name.split(";");
          const country = user.field_candidate_country;
          const city = user.field_city_text;
          const experience = user.field_professional_experience;
          const userValues = [...skills, country, city, experience];
          if (this.state.searchValue) {
            const foundSearchVal = userValues.find((value) => value === this.state.searchValue);
            const found = userValues.find((el) => selectedValues.includes(el));
            found && foundSearchVal ? (user.visible = true) : (user.visible = false);
            return;
          }
          const found = userValues.find((el) => selectedValues.includes(el));
          found ? (user.visible = true) : (user.visible = false);
        }
      });
    });
    this.setState({
      columns,
      selectedValues,
    });
  };

  manageQueryFilters = (arr, field) => {
    let constructQuery;
    if (this.state.query) {
      //if there is already a query in state
      let exist = false;
      let newQueryProp = [field, arr.join(",")];
      let prevQuery = this.state.query.split("&");
      let prevQueryProps = prevQuery.map((el) => {
        return el.split("=");
      });
      prevQueryProps.forEach((el) => {
        if (el[0] === newQueryProp[0]) {
          exist = true;
        }
      });
      if (exist) {
        //add new ids to the existing field or remove filed if there are no ids
        let replaceQuery = prevQueryProps.map((el) => {
          const oldFiled = el[0];
          const newField = newQueryProp[0];
          if (oldFiled === newField) {
            if (!newQueryProp[1]) {
              el[0] = null;
              el[1] = null;
            } else {
              el[1] = newQueryProp[1];
            }
          }
          return el[0] && `&${el.join("=")}`;
        });
        constructQuery = `${replaceQuery.join("")}`;
      } else {
        //if there is query in state but we have not existing filed
        constructQuery = `${this.state.query}&${field}=${arr}`;
      }
    } else {
      //if there is no query in state
      constructQuery = `&${field}=${arr}`;
    }

    this.props.history.push({
      search: constructQuery ? `q=1${constructQuery}` : "",
    });

    this.setState({ query: constructQuery });
  };

  onAddColumn = () => {
    const currentColumnsOrder = [...this.state.columnsOrder];
    const newColumnId = this.state.newColumnTitle.split(" ").join("_");
    const columnsOrder = [...currentColumnsOrder, newColumnId];
    const newColumn = {
      id: newColumnId,
      title: this.state.newColumnTitle.toUpperCase(),
      candidates: [],
    };

    const newState = {
      ...this.state,
      newColumnTitle: "",
      columnsOrder,
      columns: {
        ...this.state.columns,
        [newColumnId]: newColumn,
      },
    };
    this.handlePost(newState);
    this.setState(newState);
    this.onOpenModal();
  };

  hideColumns = (column) => {
    const hiddenColumns = [...this.state.hiddenColumns];
    hiddenColumns.push(column);
    const newState = {
      ...this.state,
      hiddenColumns,
    };

    this.handlePost(newState);
    this.setState(newState);
  };

  showColumns = (column) => {
    const hiddenColumns = [...this.state.hiddenColumns];
    const result = hiddenColumns.filter((el) => el !== column);
    const newState = {
      ...this.state,
      anchorEl: null,
      hiddenColumns: result,
    };

    this.handlePost(newState);
    this.setState(newState);
  };

  deleteColumns = (column) => {
    const columns = { ...this.state.columns };
    const columnsOrder = [...this.state.columnsOrder];
    const merged = [...columns.accepted.candidates, ...columns[column].candidates];
    delete columns[column];
    const filteredColumnsOrder = columnsOrder.filter((el) => el !== column);
    const newState = {
      ...this.state,
      columns: {
        ...columns,
        accepted: {
          ...columns["accepted"],
          candidates: merged,
        },
      },
      columnsOrder: filteredColumnsOrder,
    };
    this.handlePost(newState);
    this.setState(newState);
  };

  moveTo = (candidate, column, direction) => {
    const candidatesArr = [...this.state.columns[column].candidates];
    const indexCandidate = candidatesArr.findIndex((el) => el.node_id === candidate);
    if (direction === "top") {
      candidatesArr.unshift(candidatesArr.splice(indexCandidate, 1)[0]);
    }
    if (direction === "bottom") {
      candidatesArr.push(candidatesArr.splice(indexCandidate, 1)[0]);
    }

    const newState = {
      ...this.state,
      columns: {
        ...this.state.columns,
        [column]: {
          ...this.state.columns[column],
          candidates: candidatesArr,
        },
      },
    };
    this.handlePost(newState);
    this.setState(newState);
  };

  deleteCandidate = (candidate, column) => {
    let token = this.props.token;
    const nid = this.props.currentOrg.nid;

    let headers = {
      headers: {
        Accept: "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
        Authorization: `${token.token_type} ${token.access_token}`,
      },
    };

    const candidatesArr = [...this.state.columns[column].candidates];
    const indexCandidate = candidatesArr.findIndex((el) => el.node_id === candidate);
    candidatesArr.splice(indexCandidate, 1);
    const newState = {
      ...this.state,
      columns: {
        ...this.state.columns,
        [column]: {
          ...this.state.columns[column],
          candidates: candidatesArr,
        },
      },
    };

    axios
      .patch(`${oauthConfig.baseUrl}/api/organization/${nid}/disassociate_candidate/${candidate}`, {}, headers)
      .then((res) => {
        this.setState(newState);
      })
      .catch((err) => {
        // console.log(err)
      });
  };

  archived = (candidate, column) => {
    const candidatesArr = [...this.state.columns[column].candidates];
    const archiveCandidatesArr = [...this.state.columns["archived"].candidates];
    const user = candidatesArr.find((user) => user.node_id === candidate);
    const indexing = candidatesArr.findIndex((el) => el.node_id === candidate);
    candidatesArr.splice(indexing, 1);
    archiveCandidatesArr.push(user);
    const newState = {
      ...this.state,
      columns: {
        ...this.state.columns,
        [column]: {
          ...this.state.columns[column],
          candidates: candidatesArr,
        },
        ["archived"]: {
          ...this.state.columns["archived"],
          candidates: archiveCandidatesArr,
        },
      },
    };

    this.handlePost(newState);
    this.setState(newState);
  };

  handleChange = (e) => {
    e.target.checked ? this.showColumns(e.target.name) : this.hideColumns(e.target.name);
  };

  onOpenModal = () => {
    this.setState((prevState) => ({
      anchorEl: null,
      modal: !prevState.modal,
    }));
  };

  emptyBoard = () => {
    if (this.state.initilaFilterData.skillsOptions) {
      const allColumns = this.state.columnsOrder.map((columnId, index) => {
        return this.state.columns[columnId].candidates.length;
      });
      const sum = allColumns.reduce((acc, item) => acc + item);
      return sum === 0;
    }
  };

  downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  handleDownload = () => {
    // Headers for each column
    let headers = ["Name,Country,City,Technical Skills,Experience(years),Email,Phone Number"];
    const candidates = [...this.state.columns.accepted.candidates];
    // Convert candidates data to a csv
    let candidatesCsv = candidates.reduce((acc, candidate) => {
      const { field_first_name, field_last_name, field_candidate_country, field_city_text, field_technical_skills_name, field_professional_experience, field_contact_email, field_phone_number } =
        candidate;
      acc.push(
        [`${field_first_name} ${field_last_name}`, field_candidate_country, field_city_text, field_technical_skills_name, field_professional_experience, field_contact_email, field_phone_number].join(
          ","
        )
      );
      return acc;
    }, []);

    this.downloadFile({
      data: [...headers, ...candidatesCsv].join("\n"),
      fileName: "Candidates.csv",
      fileType: "text/csv",
    });
  };

  render() {
    const { classes } = this.props;
    const tooltipSettings = tooltip.boardSettings.en.string;
    const isEmpty = this.emptyBoard();
    if (isEmpty) {
      return <Message>Please search for candidates and request an introduction. Once candidates accept your introduction they will appear on the candidate board.</Message>;
    }
    return (
      <Fragment>
        {this.state.initilaFilterData.skillsOptions ? (
          <Fragment>
            <Grid container className={classes.filter} alignItems="center" wrap="nowrap">
              <div className={classes.search}>
                <SearchBar value={this.state.searchValue} onChange={this.onChangeSearchBar} />
              </div>
              <Filters {...this.props} initilaFilterData={this.state.initilaFilterData} onApplyFilter={this.onApplyFilter} />
            </Grid>
            <div className={classes.dndContainer}>
              <Scrollbars style={{ height: `70vh`, borderRadius: `5px` }} className={classes.dnd}>
                <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
                  <Droppable droppableId="all-columns" direction="horizontal" type="column">
                    {(provided, snapshot) => (
                      <div className={classes.container} {...provided.droppableProps} ref={provided.innerRef}>
                        {this.state.columnsOrder.map((columnId, index) => {
                          const column = this.state.columns[columnId];
                          return (
                            <Column
                              index={index}
                              dragProps={this.state.dragProps}
                              key={column.id}
                              column={column}
                              users={column.candidates}
                              hidden={this.state.hiddenColumns.find((col) => col === columnId)}
                              hideColumns={this.hideColumns}
                              deleteColumns={this.deleteColumns}
                              currentOrg={this.props.currentOrg}
                              token={this.props.token}
                              initialData={this.props.initialData}
                              archived={this.archived}
                              moveTo={this.moveTo}
                              delete={this.deleteCandidate}
                              onDownload={this.handleDownload}
                            />
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Scrollbars>
              <Tooltip classes={{ tooltip: classes.tooltip }} title={tooltipSettings}>
                <div
                  className={classes.addBtn}
                  onClick={(e) => {
                    this.setState({ anchorEl: e.currentTarget });
                  }}
                >
                  <SettingsIcon />
                </div>
              </Tooltip>
              <Menu
                open={Boolean(this.state.anchorEl)}
                anchorEl={this.state.anchorEl}
                onClose={() => {
                  this.setState({ anchorEl: null });
                }}
              >
                <MenuItem onClick={this.onOpenModal}>
                  <AddIcon /> Add Column
                </MenuItem>
                <FormGroup className={classes.checkboxes}>
                  {Object.keys(this.state.columns).map((col) => {
                    return (
                      <FormControlLabel
                        key={col}
                        control={<Checkbox checked={this.state.hiddenColumns.indexOf(col) === -1} onChange={(e) => this.handleChange(e)} name={col} />}
                        label={col === "rejected" ? "Declined by candidate".toUpperCase() : col.toUpperCase()}
                      />
                    );
                  })}
                </FormGroup>
              </Menu>
            </div>
            <Modal open={this.state.modal} onClose={this.onOpenModal} className={classes.modal}>
              <Slide in={this.state.modal} direction="down">
                <Paper className={classes.modalContent}>
                  <TextField fullWidth={true} variant="outlined" label="Column Title" onChange={(e) => this.setState({ newColumnTitle: e.target.value })} />
                  <Grid container justify="flex-end">
                    <Button onClick={this.onAddColumn} disabled={Boolean(this.state.columnsOrder.find((el) => el === this.state.newColumnTitle)) || Boolean(!this.state.newColumnTitle)}>
                      Add
                    </Button>
                    <Button onClick={this.onOpenModal}>Cancel</Button>
                  </Grid>
                </Paper>
              </Slide>
            </Modal>
          </Fragment>
        ) : (
          <Loader />
        )}
      </Fragment>
    );
  }
}

export default withStyles(styles)(Pipelines);
