import React, { Fragment } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import Script from 'react-load-script';
import config from '../../../oauthConfig';
import { withStyles, FormHelperText } from '@material-ui/core';
import abbreviations from '../../../abbreviations';

const styles = theme => ({
    wrapper: {
        width: '100%',
        position: 'relative', 
        marginTop: '8px',
        marginBottom: '4px',
        '& input': {
            width: '100%',
            border: '1px solid',
            padding: '2px 8px',
            borderRadius: '4px',
            minHeight: '38px',
            outline: '0 !important'
        },
        '& .location-search-input' : {
            borderColor: 'hsl(0,0%,80%)'
        },
        '& .location-search-input-error' : {
            borderColor: theme.palette.error.main
        },
        '& .suggestion-item': {
            fontSize: '14px',
            padding: '5px'
        },
        '& .autocomplete-dropdown-container': {
            position: 'absolute',
            top: '38px',
            width: '100%',
            zIndex: '100',
            border: '1px solid hsl(0,0%,80%)',
            borderRadius: '4px',
            backgroundColor: '#fff'
        }
    }
});


const searchOptions = {
    types: ['(cities)'],
}

class SearchLocation extends React.Component {
    state = { 
        address: '',
        scriptLoaded: false
    }
    componentDidMount () {
        if(this.props.initialCountry){
            const city = this.props.initialCity
            const state = this.props.initialState
            const country = this.props.initialCountry
            if(state){
                let address = [city, state, country]
                this.setState({ address: address.join(", ") });
                
            }else{
                let address = [city, country]
                this.setState({ address: address.join(", ") });
            }
        }
    }

    handleScriptLoad = () => this.setState({scriptLoaded: true})
  
    handleChange = address => {
        this.setState({ address });
    };
    
    handleBlur = e => { 
        if(this.state.address.includes(",") || this.state.address.includes("-")){
            const location = this.state.address.replace(" - ", ", ")
            const locationArr = location.split(", ")
            let country;
            switch(locationArr.length){
                case 2:
                    country = locationArr[1]
                    break;
                case 3:
                    country = locationArr[2]
            }
            abbreviations.forEach(el => {
                if(el.short === country){
                    country = el.long
                }
            })
            const countryTaxonomy = this.props.initialData.filter(el => el.label === country);
            if(countryTaxonomy[0]){
                this.handleSelect(this.state.address)
                this.props.onBlur(e)
            }else{
                this.props.onBlur(e)
                this.props.handleRemoveLocation()
            }
            
        }else if(!this.state.address.includes(",") || this.state.address.includes("-")){
            this.props.onBlur(e)
            this.props.handleRemoveLocation()
            // this.setState({ address: "" });  
        }
    }
  
    handleSelect = address => {
        this.setState({ address });
        const location = address.replace(" - ", ", ")
        const locationArr = location.split(", ")
        let country,state,city;
        switch(locationArr.length){
            case 2: 
                city = locationArr[0]
                country = locationArr[1]
                break;
            case 3:
                city = locationArr[0]
                state = locationArr[1]
                country = locationArr[2]
        }
        
        abbreviations.forEach(el => {
            if(el.short === country){
                country = el.long
            }
        })
        const countryTaxonomy = this.props.initialData.filter(el => el.label === country);
        if(countryTaxonomy[0]){
            const countryObj = {type: `taxonomy_term--country` , id: countryTaxonomy[0].id}
            this.props.handleLocation(city, state, countryObj, country) 
        }
    };
  
    render() {
        
        const { classes } = this.props;

        const  auto = this.props.autocompleteFix ? {} : {autoComplete: "no"};
        return (
            <div className={classes.wrapper}> 
                <Script
                    url={`https://maps.googleapis.com/maps/api/js?key=${config.googleMapKey}&libraries=places&language=en`}
                    onLoad={this.handleScriptLoad}
                />
                {this.state.scriptLoaded && (
                    <Fragment>
                        <PlacesAutocomplete
                        value={this.state.address}
                        onChange={this.handleChange}
                        onSelect={this.handleSelect}
                        searchOptions={searchOptions}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                                return (
                                    <div>
                                        <input
                                            className={classes.inputError}
                                            id={this.props.id}
                                            {...getInputProps({
                                                placeholder: this.props.placeholder ? this.props.placeholder : 'Search Location',
                                                className: this.props.error ? 'location-search-input-error' : 'location-search-input',
                                                ...auto,
                                                onBlur: (e) => {
                                                    if(this.props.onBlur){ 
                                                        this.handleBlur(e)
                                                        this.handleChange(e.target.value)       
                                                    }
                                                },
                                                onFocus: () => {
                                                    if(this.props.error){
                                                        this.handleChange("")
                                                    }
                                                }
                                            })}
                                        />
                                        
                                        {/* {loading && <div>Loading...</div>} */}
                                        { suggestions.length > 0 && (
                                            <div className="autocomplete-dropdown-container">
                                            {suggestions.map(suggestion => {
                                            const className = suggestion.active
                                                ? 'suggestion-item suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div 
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                    key={suggestion.placeId}
                                                    >
                                                    <div className="suggestion-item">{suggestion.description}</div>
                                                </div>
                                            );
                                            })}
                                            </div>

                                        )}
                                    </div>
                                )
                            }}
                        </PlacesAutocomplete>
                        {this.props.error && <FormHelperText error={this.props.error} variant="filled">{this.props.helperText}</FormHelperText>}
                    </Fragment>
                )}
            </div>
        );
    }
}

export default withStyles(styles)(SearchLocation);