import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import blueGrey from '@material-ui/core/colors/blueGrey';


let theme = createMuiTheme({
    
    typography: {
        fontSize: 13, 
    },
    palette: {
        primary: {
            main: "#58a3b3",
            contrastText: '#ffffff'
        },
        secondary: {
            main: "#69379c", 
            contrastText: '#ffffff'
        },
        background: {
            darkBlueGrey: blueGrey[900], 
            darkBlueGreyWhiter: blueGrey[800]
        }
        
    },
    text: {
        primary: "#000000",
        secondary: "#ffffff",
        grey: grey[800]
    },
    overrides: {
        MuiButton: {
            root: { 
                // background: '#58a3b3',
                // color: '#ffffff' 
            },
            contained: {
                backgroundColor: '#58a3b3',
                color: '#ffffff',
                '&:hover': {
                    backgroundColor: "#69379c",
                 }, 
            },
            outlined: {
                color: '#58a3b3',
                background: 'transparent',
                borderColor: '#58a3b3',
                '&:hover': {
                    color: '#69379c',
                    borderColor: '#69379c',
                 } 
            }
        },
        MuiStepConnector: {
            line: {
                // borderColor: '#69379c'
            } 
        },
        MuiDrawer: {
            paper: {
                top: "80px"
                // position: 'absolute' 
            },
            paperAnchorLeft: {
                left: '84px',
                '@media (max-width:960px)': {
                    left: 0
                },
            }
        }
    } 
});

export default theme;