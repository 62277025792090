import React, { Component, Fragment } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { CircularProgress , Grid, Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core';
import axios from 'axios';
import oauthConfig from '../../../oauthConfig';
import OrganizationItems from './OrganizationItems';
import OrganizationItem from './OrganizationItem';
import EditOrganization from '../EditOrganization/EditOrganization';


class OrganizationList extends Component {
    
    state = {
        included: null,
        open: false,
        deleteId: null
    }

    componentDidUpdate(prevProps) {
        if(this.props.organizations && prevProps.organizations){
            // console.log(prevProps)
            // console.log(this.props.organizations.length)
            // if(this.props.organizations.length != prevProps.organizations.length){
            //     this.props.fetchOrg()
            // }
        }
        if(this.props.orgID !== prevProps.orgID){
            this.props.fetchOrg() 
        }
    } 

    setHeadrs = () => {
        let token = this.props.token;

        let headers = {
            headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
            'Authorization': `${token.token_type} ${token.access_token}`
            }
        }

        return headers
    }

    handleDlete = () => {
        if(this.state.deleteId){
            let headers = this.setHeadrs();
            axios.delete(`${oauthConfig.baseUrl}/api/node/organization_profile/${this.state.deleteId}`, headers)
            .then(response => {
                if(this.state.deleteId === this.props.orgID){
                    localStorage.removeItem('organizationLogo');
                    this.props.setLogo(null)
                    localStorage.removeItem('organization');
                    this.props.orgActive(null)
                }
                this.props.fetchOrg()
            })
            .catch(err => {
                if(err.response.status === 401){
                  this.props.logout()
                }
            });
        }
    }

    handleClickOpen = (id) => {
        this.setState({deleteId: id})
        this.setState({open: true});
    };
    
    handleClose = () => {
        // this.setState({deleteId: null})
        this.setState({open: false});
    };

    render(){
        return (
            this.props.organizations ? (
                <Fragment>
                    <Switch>
                        <Route exact path="/organization/all/:id/edit" render={(props)=> <EditOrganization all organizations={this.props.organizations}  fetchOrg = {this.props.fetchOrg} {...this.props} {...props}/> }/>
                        <Route exact path="/organization/all/:id" render={(props)=> <OrganizationItem organizations={this.props.organizations} {...props}/> }/>
                        <Route exact path="/organization/all" render={(props)=> <OrganizationItems organizations={this.props.organizations} handleClickOpen={this.handleClickOpen} {...props} {...this.props} menu={false} /> } />
                    </Switch>
                    <Dialog         
                        open={this.state.open}
                        onClose={this.handleClose}
                        onClick={this.handleClose}
                    >
                                
                        <DialogTitle>{`Are you sure you want to delete this organization?`}</DialogTitle>
                        <DialogActions>
                            <Button onClick={() => this.handleDlete()} variant="contained">
                                Yes
                            </Button>
                            <Button onClick={this.handleClose} variant="contained">
                                No
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Fragment>
            ) :
            (
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <CircularProgress color="secondary" />
                </Grid>
            )

        )

        
    } 
}

export default OrganizationList;