import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const errorText = (props) => {
    return (
        <Box mb={1}>
            <Grid container direction="column" justify="center" alignItems="center">
                <ErrorOutlineIcon color="error" fontSize="large"/>
                <Typography color="error" align="center">
                    {props.children}
                </Typography>
            </Grid>
        </Box>
    )
}

export default errorText;