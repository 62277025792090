import React from 'react';
import AsideNavigationItem from './AsideNavigationItem/AsideNavigationItem';
import WorkRoundedIcon from '@material-ui/icons/WorkRounded';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import BusinessIcon from '@material-ui/icons/Business';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
    root: {
        background: theme.palette.background.darkBlueGrey,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        zIndex: '100',
        [theme.breakpoints.up('md')]: {
            position: "fixed",
            width: '84px'
        }
    }
  });

const AsideNavigation = (props) => {
    const { classes } = props;
    return (
        <aside className={classes.root}>
            
            <AsideNavigationItem to={'/jobs'}>
                <WorkRoundedIcon /> 
                Job Search 
            </AsideNavigationItem>
            <AsideNavigationItem to={'/organizations'}>
                <BusinessIcon /> 
                Organizations 
            </AsideNavigationItem>
            <AsideNavigationItem to={'/profile'}>
                <AccountCircleIcon /> 
                Profile 
            </AsideNavigationItem>
        </aside>
    )
}

export default withStyles(styles)(AsideNavigation);