export default {
	hostname: window.location.hostname,
    baseUrl:'https://www.womentech.net',
    oauth: {
      grant_type: 'password',
      client_id: 'f77d374b-0a8c-443a-867d-563a9761371f',
      client_secret: 'Ek!PLaNu5}Tf9rVNy'
    },
	googleMapKey: 'AIzaSyDcgCDj79v554WobtexD95ih0odV6GgBeI',
  };
