import React, { useState } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import { 
  withStyles, 
  Card, 
  TextField, 
  Button,
  Grid
} from "@material-ui/core";

import ImageUploader from '../../../Utilities/ImageUploader';

const styles = (theme) => ({
  card: {
    marginBottom: 50,
    padding: 20
  },
  container: {
    display: "Flex",
    justifyContent: "center"
  },
  input: {
    height: '200px'
  },
  imagePreview: {
    display: 'inline-block',
    width: '150px',
    height: '150px',
    borderRadius: '50%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover'
  },
  actions: {
    padding: `${theme.spacing(2)}px 0`
  }
});

const FormContent = props => {
  const { 
    classes,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    profileImage,
    createImageEntity,
    deleteImageEntity
  } = props;

  const [mbLimit, setMbLimit] = useState(false);
  const [sizeLimitLogo, setSizeLimitLogo] = useState(false);

  const uploadFile = (file, type) => {
    createImageEntity(file, type)
  }

  const removeFile = (type) => {
    deleteImageEntity(type)
  }

  const setTextValues = (e) => {
    const field = e.target.id;
    const value = e.target.value;
    handleBlur(e);
    if(Object.keys(errors).length && props.isEdit){
      return
    } 
    props.mergeFormData( "attributes", {[field]: value});    
  }
  
  return (
      <form className={classes.container} onSubmit={handleSubmit}>
        <Card className={classes.card}>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
              <ImageUploader
                title={"User Profile Picture"}
                caption={"Please upload profile picture in .jpg .png or .gif format (min 200X200px and less than 15MB)"}
                color={mbLimit || sizeLimitLogo ?  "error" : "textPrimary"}
                setSizeLimit={{func: setSizeLimitLogo, width: 200, height: 200 }}
                setMbLimit={{func: setMbLimit, mbLimit: 15 }}   
                uploadFile={uploadFile}
                removeFile={removeFile}
                type="field_profile_picture"
                initialData={profileImage}
                removeInitialData={removeFile}  
              />
            </Grid>
            {props.isEdit && ( 
              <Grid item xs={12}>
               <TextField
                 id="field_company_name"
                 label="Company Name"
                 type="text"
                 value={values.field_company_name}
                 onChange={handleChange}
                 onBlur={(e) => setTextValues(e)}
                 margin="dense"
                 variant="outlined"
                 fullWidth
               />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                  id="field_first_name"
                  label="First Name*"
                  value={values.field_first_name}
                  onChange={handleChange}
                  onBlur={(e) => setTextValues(e)}
                  helperText={touched.field_first_name ? errors.field_first_name : ""}
                  error={touched.field_first_name && Boolean(errors.field_first_name)}
                  margin="dense"
                  variant="outlined"
                  fullWidth
                />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="field_last_name"
                label="Last Name*"
                value={values.field_last_name}
                onChange={handleChange}
                onBlur={(e) => setTextValues(e)}
                helperText={touched.field_last_name ? errors.field_last_name : ""}
                error={touched.field_last_name && Boolean(errors.field_last_name)}
                margin="dense"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="field_phone_number"
                label="Phone Number"
                value={values.field_phone_number}
                onChange={handleChange}
                onBlur={(e) => setTextValues(e)}
                helperText={touched.field_phone_number ? errors.field_phone_number : ""}
                error={touched.field_phone_number && Boolean(errors.field_phone_number)}
                margin="dense"
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
          {!props.isEdit ? (
              <div className={classes.actions}>
                <Button type="submit" variant="contained" fullWidth>Continue</Button>
              </div>
            ):(
              <div className={classes.actions}>
                <Button variant="contained" fullWidth onClick={() => props.next()}>Next</Button>
              </div>
          )}
        </Card>
      </form>
  );
};
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const Form = withFormik({
  mapPropsToValues: ({
    defaultVal
  }) => {
    return {
     field_first_name: defaultVal.attributes.field_first_name ? defaultVal.attributes.field_first_name : "",
     field_last_name: defaultVal.attributes.field_last_name ? defaultVal.attributes.field_last_name : "",
     field_phone_number: defaultVal.attributes.field_phone_number ? defaultVal.attributes.field_phone_number : "",
     field_company_name: defaultVal.attributes.field_company_name ? defaultVal.attributes.field_company_name : "",
     field_profile_picture: defaultVal.relationships.field_profile_picture.data ?  defaultVal.relationships.field_profile_picture.data : "",
    };
  },
  
  validationSchema: Yup.object().shape({
    field_first_name: Yup.string().required("Required"),
    field_last_name: Yup.string().required("Required"),
    field_phone_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid')
  }),

  handleSubmit: (values, { props, setSubmitting, setValues, setFieldValue }) => {

    let companyName = props.isEdit ? values.field_company_name : (localStorage.getItem("companyName") ? localStorage.getItem("companyName") : "Company Name");
    let email = localStorage.getItem("email");

    let atributes = {
      title: `${values.field_first_name} ${values.field_last_name}`,
      field_company_name: companyName,
      field_contact_email: email,
    }
    if(!props.isEdit) {
      props.mergeFormData('attributes', atributes);
    }
    props.next()
  }
})(FormContent);

export default withStyles(styles)(Form);
