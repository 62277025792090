import React, { Component, Fragment } from 'react';
import { Typography, TextField, Checkbox, IconButton, Paper, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress  } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import config from '../../../../oauthConfig';
import axios from 'axios';

const TableHeadStyle = withStyles(({ breakpoints }) => ({
    root: {
        fontWeight: 'bold',
          [breakpoints.down('xs')]: { 
              fontSize: '0.6rem',
          }
      }
  }))(Typography);


class Greenhouse extends Component {

    state = {
        deleteID: null,
        loading: false,
        boardToken: "",
        editBoardToken: "",
        checked: true,
        editChecked: true,
        integrations: null,
        editMode: null,
        error: null
    }

    componentDidMount(){
        this.fetchData()
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentOrg !== prevProps.currentOrg) {
            this.fetchData()
        }
    }

    fetchData = async () => {
        let token = this.props.token;
        const headers = {
            headers: {
              'Accept': 'application/vnd.api+json',
              'Content-Type': 'application/vnd.api+json',
              'Authorization': `${token.token_type} ${token.access_token}`
            }
        }
        const integrations = await axios.get(`${config.baseUrl}/api/organization/${this.props.currentOrg}/greenhouse_integration`, headers)
        this.setState({
            deleteID: null,
            loading: false, 
            integrations: integrations.data,
            boardToken: "",
            checked: true,
            editMode: null 
        })
    }

    editMode = (id, token, enabled) => {
        this.setState({
            editMode: id,
            editBoardToken: token,
            editChecked: enabled,
        })
    }

    save = async() => {
        this.setState({ loading: true })
        let token = this.props.token;
        const headers = {
            headers: {
              'Accept': 'application/vnd.api+json',
              'Content-Type': 'application/vnd.api+json',
              'Authorization': `${token.token_type} ${token.access_token}`
            }
        }

        await axios.post(`${config.baseUrl}/api/organization/${this.props.currentOrg}/greenhouse_integration`, {greenhouse_board_token: this.state.boardToken, enabled: this.state.checked ? 1 : 0 }, headers)
        .then((res)=>{
            this.fetchData()
        }).
        catch((err)=>{ 
            this.setState({
                error: err.response.data,
                loading: false
            })
        })
    }

    update = async(integrationId) => {
        this.setState({ loading: true })
        let token = this.props.token;
        const headers = {
            headers: {
              'Accept': 'application/vnd.api+json',
              'Content-Type': 'application/vnd.api+json',
              'Authorization': `${token.token_type} ${token.access_token}`
            }
        }
        
        await axios.patch(`${config.baseUrl}/api/organization/${this.props.currentOrg}/greenhouse_integration/${integrationId}`, {greenhouse_board_token: this.state.editBoardToken, enabled: this.state.editChecked ? 1 : 0 }, headers)
        .then((res)=>{
            this.fetchData()
        }).
        catch((err)=>{
            
        })
    }

    delete = async(integrationId) => {
        this.setState({ 
            loading: true,
            deleteID:  integrationId
        })
        let token = this.props.token;
        const headers = {
            headers: {
              'Accept': 'application/vnd.api+json',
              'Content-Type': 'application/vnd.api+json',
              'Authorization': `${token.token_type} ${token.access_token}`
            }
        }

        await axios.delete(`${config.baseUrl}/api/organization/${this.props.currentOrg}/greenhouse_integration/${integrationId}`, headers)
        .then((res)=>{
            this.fetchData()
        }).
        catch((err)=>{
            
        })
    }

    render(){  
        return ( 
            <Fragment>
                <Paper style={{overflowY:"hidden" }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                { ['Greenhouse Board Token', 'Enabled', 'Actions'].map( el => <TableCell key={el}><TableHeadStyle variant="subtitle1">{el}</TableHeadStyle></TableCell>) }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { this.state.integrations && (
                                this.state.integrations.map((el) => {
                                    return (
                                        this.state.editMode == el.id ? (
                                            <TableRow key={el.id}>
                                                <TableCell>
                                                    <TextField
                                                        onChange={(e) => { this.setState({ editBoardToken: e.target.value, error: null}) }}
                                                        value={this.state.editBoardToken}
                                                        error={Boolean(this.state.error)}
                                                        helperText={this.state.error}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={this.state.editChecked}
                                                        onChange={(e => { this.setState({editChecked: e.target.checked})})}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    {(this.state.loading && this.state.editMode == el.id) ?  <CircularProgress size={20} /> : <IconButton disabled={!Boolean(this.state.editBoardToken && this.state.editBoardToken.trim().length > 0 )} children={<SaveIcon/>} onClick={()=> {this.update(el.id)} }/>}   
                                                    <IconButton children={<CloseIcon/>} onClick={()=>{this.editMode(null)}}/>
                                                </TableCell>
                                            </TableRow>
                                        ):(
                                            <TableRow key={el.id}>
                                                <TableCell>{el.greenhouse_board_token}</TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={el.enabled == 1}
                                                        disabled
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton onClick={()=>{ this.editMode(el.id, el.greenhouse_board_token, el.enabled == 1) }} children={<EditIcon/>}/>
                                                    {(this.state.loading && this.state.deleteID == el.id) ?  <CircularProgress size={20} /> : <IconButton onClick={()=>{ this.delete(el.id) }} children={<DeleteIcon/>}/>}   
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )
                                })
                            )}
                            <TableRow>
                                    <TableCell>
                                        <TextField
                                            autoFocus
                                            onChange={(e) => { this.setState({ boardToken: e.target.value, error: null}) }}
                                            value={this.state.boardToken}
                                            error={Boolean(this.state.error)}
                                            helperText={this.state.error}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox
                                            checked={this.state.checked}
                                            onChange={(e => { this.setState({checked: e.target.checked})})}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {(this.state.loading && !this.state.editMode && !this.state.deleteID) ?  <CircularProgress size={20} /> : <IconButton disabled={!Boolean(this.state.boardToken && this.state.boardToken.trim().length > 0 )} children={<SaveIcon/> } onClick={()=> this.save()} />}
                                    </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>
            </Fragment>
        )
    }
}

export default Greenhouse;