import React, { Fragment } from 'react';
import { Typography, RadioGroup, Radio, FormControlLabel, Box } from '@material-ui/core';

const RadioFilter = (props) => {
    return (
        <Fragment>
            <Typography variant="body1" gutterBottom={true}>{props.title}</Typography>
            <RadioGroup onChange={(e)=> props.handleChangeRadio(e.target.value, props.checked)} value={props.value}>
                <Box display="flex" flexWrap="wrap">
                    {
                        props.values && (
                            props.values.map(el => {
                                return   (
                                    <div key={el.label}>
                                        <FormControlLabel
                                            control={<Radio  />} 
                                            label={el.label}
                                            value={el.value} 
                                        />
                                    </div>
                                )
                            })
                        )
                    }
                </Box>
            </RadioGroup>  
        </Fragment>
    )
    
}

export default RadioFilter;