import React, { useState } from "react";
import config from "../../../../oauthConfig";
import { withRouter } from "react-router-dom";
import { withFormik } from "formik";
import * as Yup from "yup";
import { makeStyles, Card, CardContent, CardActions, TextField, Button, InputAdornment, IconButton } from "@material-ui/core";
import axios from "axios";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 420,
    marginTop: 50,
  },
  container: {
    display: "Flex",
    justifyContent: "center",
  },
}));

const FormContent = (props) => {
  const { values, touched, errors, handleChange, handleBlur, handleSubmit } = props;

  const classes = useStyles();

  const [visibility, setVisibility] = useState({ pass: false, confirmPassword: false });
  const handleClickShowPassword = (value) => {
    setVisibility({ ...visibility, [value]: !visibility[value] });
  };

  const preventSpace = (e) => {
    e.target.value = e.target.value.trim();
    handleChange(e);
  };

  return (
    <div className={classes.container}>
      <form onSubmit={handleSubmit}>
        <Card className={classes.card}>
          <CardContent>
            <TextField
              id="mail"
              label="Email"
              type="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.mail ? errors.mail : ""}
              error={touched.mail && Boolean(errors.mail)}
              margin="dense"
              variant="outlined"
              fullWidth
            />
            <TextField
              id="pass"
              label="Password"
              type={visibility.pass ? "text" : "password"}
              value={values.pass}
              onChange={(e) => preventSpace(e)}
              onBlur={handleBlur}
              helperText={touched.pass ? errors.pass : "Password must contain at least 8 characters"}
              error={touched.pass && Boolean(errors.pass)}
              margin="dense"
              variant="outlined"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleClickShowPassword("pass")}>{visibility.pass ? <Visibility /> : <VisibilityOff />}</IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              id="confirmPassword"
              label="Confirm Password"
              type={visibility.confirmPassword ? "text" : "password"}
              value={values.confirmPassword}
              onChange={(e) => preventSpace(e)}
              onBlur={handleBlur}
              helperText={touched.confirmPassword ? errors.confirmPassword : ""}
              error={touched.confirmPassword && Boolean(errors.confirmPassword)}
              margin="dense"
              variant="outlined"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleClickShowPassword("confirmPassword")}>{visibility.confirmPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </CardContent>
          <CardActions>
            <Button type="submit" variant="contained" fullWidth={true}>
              Save and Continue
            </Button>
          </CardActions>
        </Card>
      </form>
    </div>
  );
};

const Form = withFormik({
  mapPropsToValues: ({ mail, pass, confirmPassword }) => {
    return {
      mail: mail || "",
      pass: pass || "",
      confirmPassword: confirmPassword || "",
    };
  },

  validationSchema: Yup.object().shape({
    mail: Yup.string().email("Enter a valid email").required("Email is required"),
    pass: Yup.string().trim().min(8, "Password must contain at least 8 characters").required("Enter your password"),
    confirmPassword: Yup.string()
      .required("Confirm your password")
      .oneOf([Yup.ref("pass")], "Password does not match"),
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    let data = {
      mail: values.mail,
      pass: values.pass,
    };

    props.stratLoading();
    localStorage.setItem("email", values.mail);

    axios
      .post(`${config.baseUrl}/api/user/register/candidate?_format=json`, data)
      .then((res) => {
        props.loginFunction("signUp", values.mail, values.pass);
        props.history.push("/sign-up-candidate");
        if (res.data.uuid) {
          props.handleUUID(res.data.uuid);
        }
        // props.handleUUID(res.data.uuid[0].value)
        // localStorage.setItem('uuid', res.data.uuid[0].value);
      })
      .catch((error) => {
        props.history.push("/sign-up");
        props.stopLoading();
        if (error.response.status === 422) {
          props.openError("There is a user already registered with this email");
        } else {
          props.openError("Something went wrong");
        }
      });
  },
})(FormContent);

export default withRouter(Form);
