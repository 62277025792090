import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import OrganizationForm from "./OrganizationForm";
import axios from "axios";
import oauthConfig from "../../../oauthConfig";

class AddOrganization extends Component {
  state = {
    field_logo: { path: null },
    field_page_title_background: { path: null },
    field_culture_images: [{ path: null }],
    videos: [{ input: "", video_id: null }],
    orgData: {
      type: "node--employer_profile",
      attributes: {
        field_video: [],
      },
      relationships: {},
    },
  };

  handleData = (key, data) => {
    this.setState((prevState) => ({
      orgData: {
        ...prevState.orgData,
        [key]: {
          ...prevState.orgData[key],
          ...data,
        },
      },
    }));
  };

  setHeaders = () => {
    let token = this.props.token;
    let headers = {
      headers: {
        Accept: "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
        Authorization: `${token.token_type} ${token.access_token}`,
      },
    };

    return headers;
  };

  handleProfilePreview = (path, field) => {
    this.setState({
      [field]: { path },
    });
  };

  handleMultiplePreview = (path, field, index) => {
    const fileArr = [...this.state[field]];
    fileArr[index].path = path;
    if (path !== "loading") {
      fileArr.push({ path: null });
    }
    const newState = {
      ...this.state,
      [field]: fileArr,
    };
    this.setState(newState);
  };

  handleMultipleDelete = (path, field, index) => {
    const fileArr = [...this.state[field]];
    fileArr.splice(index, 1);
    const newState = {
      ...this.state,
      [field]: fileArr,
    };
    this.setState(newState);
  };

  createImageEntity = (file, field, index) => {
    index >= 0 ? this.handleMultiplePreview("loading", field, index) : this.handleProfilePreview("loading", field);
    var imagefile = file;
    let token = this.props.token;
    fetch(`${oauthConfig.baseUrl}/api/node/organization_profile/${field}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/octet-stream",
        Accept: "application/vnd.api+json",
        "Content-Disposition": `file; filename="${imagefile.name}"`,
        Authorization: `${token.token_type} ${token.access_token}`,
      },
      body: imagefile,
    })
      .then((response) => response.json())
      .then((success) => {
        index >= 0 ? this.handleMultiplePreview(success.data.attributes.uri.url, field, index) : this.handleProfilePreview(success.data.attributes.uri.url, field);
        let selectedID = { type: success.data.type, id: success.data.id };
        let data = index >= 0 ? { [field]: { data: [selectedID] } } : { [field]: { data: selectedID } };
        if (this.state.orgData.relationships[field] && index >= 0) {
          data = { [field]: { data: [...this.state.orgData.relationships[field].data, selectedID] } };
        }
        this.handleData("relationships", data);
      })
      .catch((error) => console.log(error, "error"));
  };

  deleteImageEntity = (field, index) => {
    index >= 0 ? this.handleMultipleDelete(null, field, index) : this.handleProfilePreview(null, field);
    let data = { [field]: { data: null } };
    if (this.state.orgData.relationships[field] && index >= 0) {
      data = { [field]: { data: [...this.state.orgData.relationships[field].data.splice(index, 1)] } };
    }
    this.handleData("relationships", data);
  };

  handleFormSubmit = () => {
    let headers = this.setHeaders();
    axios
      .post(`${oauthConfig.baseUrl}/api/node/organization_profile`, { data: this.state.orgData }, headers)
      .then((res) => {
        this.props.fetchOrg();
        this.props.history.push("/organization/all");
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            this.props.logout();
          }
        }
      });
  };
  render() {
    return (
      <OrganizationForm
        initialData={this.props.initialData}
        defaultValues={this.state.orgData}
        createImageEntity={this.createImageEntity}
        createMultipleImageEntity={this.createMultipleImageEntity}
        deleteImageEntity={this.deleteImageEntity}
        logo={this.state.field_logo}
        background={this.state.field_page_title_background}
        culture={this.state.field_culture_images}
        videos={this.state.videos}
        handleData={this.handleData}
        handleFormSubmit={this.handleFormSubmit}
        formEdit={false}
      />
    );
  }
}

export default withRouter(AddOrganization);
