import React, { useState } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import { 
  withStyles, 
  Card,
  CardActions,
  TextField , 
  Button, 
  CircularProgress,
  Grid,
  InputAdornment,
  IconButton

} from "@material-ui/core";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import oauthConfig from '../../../../oauthConfig';
import axios from 'axios';

const styles = (theme) => ({
    card: {
      padding: 20,
      maxWidth: 500,
      [theme.breakpoints.up('sm')]: {
        marginTop: 50,
        marginBottom: 50
      }
    },
    container: {
      display: "Flex",
      justifyContent: "center"
    },
    linkText: {
      textAlign: 'center',
      marginTop: theme.spacing(2),
      color: theme.palette.grey[800]
     },
     link: {
       color: theme.palette.secondary.main,
       fontWeight: 'bold'
     },
     actions: {
      '& > * + *' : {
        marginLeft: `${theme.spacing(1)}px`
      }
    }
});

const ChangePasswordForm = props => {
    
    const {
      classes,
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      isError,
    } = props;

    const [visibility, setVisibility] = useState({current: false, pass: false, confirm: false});

    const handleClickShowPassword = (value) => {
      setVisibility({ ...visibility, [value]: !visibility[value] });
    };

    const handleFocus = () => {
        if(isError){
            props.handleIsError()
        }
    }
        
    const preventSpace = (e) => {
      e.target.value = e.target.value.trim()
      handleChange(e)
    };
    
    return (
        <form onSubmit={handleSubmit} className={classes.container}>
          <Card className={classes.card}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="current"
                  label="Current Password"
                  type={visibility.current ? 'text' : 'password'}
                  value={values.current}
                  onChange={(e) => preventSpace(e)}
                  onFocus={handleFocus}
                  helperText={ isError ? "Please insert correct current password" : "" }
                  error={isError}
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                        onClick={() => handleClickShowPassword('current')}
                      >
                          {visibility.current ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="pass"
                  label="New Password"
                  type={visibility.pass ? 'text' : 'password'}
                  value={values.pass}
                  onChange={(e) => preventSpace(e)}
                  onBlur={handleBlur}
                  helperText={touched.pass ? errors.pass : ""}
                  error={touched.pass && Boolean(errors.pass)}
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                        onClick={() => handleClickShowPassword('pass')}
                      >
                          {visibility.pass ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="confirmPassword"
                  label="Confirm New Password"
                  type={visibility.confirm ? 'text' : 'password'}
                  value={values.confirmPassword}
                  onChange={(e) => preventSpace(e)}
                  onBlur={handleBlur}
                  helperText={touched.confirmPassword ? errors.confirmPassword : ""}
                  error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                        onClick={() => handleClickShowPassword('confirm')}
                      >
                          {visibility.confirm ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>
            <CardActions className={classes.actions}>
              <Button onClick={()=>{ props.history.goBack() }} variant="contained"> Back </Button>
              <Button type="submit" variant="contained" fullWidth> {isSubmitting ? <CircularProgress color="secondary" size={25} /> :  "Change"}</Button>
            </CardActions>
          </Card>
        </form>
    );
  };
  
  const Form = withFormik({
    mapPropsToValues: ({
        pass,
        confirmPassword,
        current 
    }) => {
      return { 
        pass: pass || "",
        confirmPassword: confirmPassword || "",
        current: current || ""
      };
    },
  
    validationSchema: Yup.object().shape({
        current:  Yup.string()
            .required("Enter your password"),
        pass: Yup.string()
            .min(8, "Password must contain at least 8 characters")
            .required("Enter your password"),
        confirmPassword: Yup.string()
            .required("Confirm your password")
            .oneOf([Yup.ref("pass")], "Password does not match")
    }),
  
    handleSubmit: (values, { props, setSubmitting }) => { 
      setSubmitting(true)
      const token = props.token;

      const headers = {
          headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
            'Authorization': `${token.token_type} ${token.access_token}`
          }
      }

      let data = {
          data: {
              type: "user--user",
              id: props.uuid,
              attributes: {
                  pass: {
                      existing: values.current,
                      value: values.confirmPassword
                  }
              }
          }
      }


      axios.patch(`${oauthConfig.baseUrl}/api/user/user/${props.uuid}`, data, headers)
      .then((res)=> {
          setSubmitting(false)
          props.refreshToken()
      })
      .catch(err => {
          setSubmitting(false)
          props.handleIsError()
      })
    }
  })(ChangePasswordForm); 
  
  export default withStyles(styles)(Form);
