import React, { Fragment, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { AppBar, makeStyles, Card, Snackbar } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Logo from "../Logo/Logo";
import Login from "./Login";
import SignUp from "./SignUp/SignUp";
import SignUpStepsTalent from "../Authentication/SignUp/SignUpTalent/SignUpStepsTalent";
import SignUpStepsEmployer from "../Authentication/SignUp/SignUpEmployer/SignUpStepsEmployer";
import ResetPassword from "../Settings/Profile/ResetPassword/ResetPassword";
import ForgottenPassword from "./ForgottenPassword/ForgottenPassword";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.grey[100],
    alignItems: "center",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(3),
    height: "100px",
  },
  errorCard: {
    textAlign: "center",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.error.contrastText,
  },
}));

const Authentication = (props) => {
  const classes = useStyles();

  const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={() =>
          props.uuid ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/sign-up",
              }}
            />
          )
        }
      />
    );
  };

  return (
    <Fragment>
      <AppBar className={classes.appBar} position="static">
        <Logo logoType="color" width={"280px"} />
      </AppBar>
      <Switch>
        <PrivateRoute path="/sign-up-candidate" component={SignUpStepsTalent} />
        <PrivateRoute path="/sign-up-employer" component={SignUpStepsEmployer} />
        <Route path="/sign-up" render={(routeProps) => <SignUp {...routeProps} {...props} />} />
        <Route path="/user/reset/:hash" render={(routeProps) => <ResetPassword {...routeProps} {...props} />} />
        {/* <Route path="/sign-up-candidate" render= { () => <SignUpStepsTalent {...props} />} />
                <Route path="/sign-up-employer" render= { () => <SignUpStepsEmployer {...props} />} /> */}
        <Route path="/forgotten-password" render={(props) => <ForgottenPassword {...props} />} />
        {/* <Route path="/email-confirm/:hash" render={(routeProps) => <Confirmation  {...routeProps} {...props}/> } /> */}
        <Route path="/" render={() => <Login {...props} />} />
      </Switch>
      <Snackbar autoHideDuration={5000} open={props.error.isError} onClose={props.closeError}>
        <Card className={classes.errorCard}>
          <div>
            <ErrorOutlineIcon fontSize="large" />
          </div>
          {props.error.message}
        </Card>
      </Snackbar>
    </Fragment>
  );
};

export default Authentication;
