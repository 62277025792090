import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import Popover from '../../Filters/Popover/Popover';
import SelectCheckbox from '../../Filters/SelectCheckbox/SelectCheckbox';
import CheckboxFilter from '../../Filters/CheckboxFilter/CheckboxFilter';
import queryString from 'query-string';

// global query value
let query;

class Filters extends Component {
    
    state = {
        predefined: false,
        checked: {
            checkedSkills: {},
            checkedCountries: {},
            checkedCities: {}
        }
    }

    componentDidMount(){
        const experienceList = this.props.initilaFilterData.experienceOptions;
        const checkedExperience = {}
        experienceList.forEach(el => {
            Object.assign(checkedExperience, {[el.value]: false});
        });
        this.setState(prevState => ({
            skillsList: this.props.initilaFilterData.skillsOptions,
            countriesList: this.props.initilaFilterData.countriesOptions,
            citiesList: this.props.initilaFilterData.citiesOptions,
            experienceList,
            checked: {
                ...prevState.checked,
                checkedExperience
            }
        }))

        query = {...queryString.parse(this.props.location.search)}
        const queryKeys = Object.keys(query)
        if(queryKeys.length  > 0){ 
            this.setFilterValues() 
        }
        
    }

    componentDidUpdate(prevProps, prevState) {
        const queryKeys = Object.keys(query)
        if (prevState.predefined !== this.state.predefined) {
            queryKeys.forEach(key => {
                if(key !== "q" && key !== "search"){
                    this.props.onApplyFilter({...this.state.checked}, key, query[key].split(","))
                }
            })
        }
    }

    setFilterValues = () => {
        const experienceList = this.props.initilaFilterData.experienceOptions;
        const checkedExperience = {}
        experienceList.forEach(el => {
            Object.assign(checkedExperience, {[el.value]: false});
        });
        const skills = query['field_technical_skills_name'];
        const city = query['field_city_text'];
        const country = query['field_candidate_country'];
        const experience = query['field_professional_experience'];
        this.predefinedSelectCheckbox(skills, this.props.initilaFilterData.skillsOptions, "checkedSkills", "skills", this.handleSelect)
        this.predefinedSelectCheckbox(city, this.props.initilaFilterData.citiesOptions, "checkedCities", "cities", this.handleSelect)
        this.predefinedSelectCheckbox(country, this.props.initilaFilterData.countriesOptions, "checkedCountries", "countries", this.handleSelect)
        this.predefinedCheckbox(experience, experienceList, "checkedExperience", this.handleChange)
        this.setState({predefined: true})
    }

    predefinedSelectCheckbox = (predefinedVals, allList, checkedProps, selectedProps, callBack) => {
        if(predefinedVals){
            const preDefined = predefinedVals.split(",")
            const result = allList.filter(el => preDefined.indexOf(el.value) >= 0);
            callBack(result, checkedProps, selectedProps)
        }
    }

    predefinedCheckbox = (predefinedVals, allList, checkedProps, callBack) => {
        if(predefinedVals){
            const preDefined = predefinedVals.split(",")
            const result = allList.filter(el => preDefined.indexOf(el.value) >= 0);
            result.forEach(el => {
                callBack(el.value, true, checkedProps)
            })
        }
    }

    handleChange = (name, checked, checkedObj) => {
        this.setState(prevState => ({
            checked:{
                ...prevState.checked,
                [checkedObj]: {
                    ...prevState.checked[checkedObj],
                    [name]: checked 
                }
            }
        }))
    }

    handleSelect = (selectedOption, checkedObj, statePropName) => {
        let obj = {}
        let elVal;
        selectedOption.forEach(el => {
            if(this.state.checked[checkedObj][el.value] === undefined || this.state.checked[checkedObj][el.value] === false){ 
                elVal = el.value
                Object.assign(obj, {[el.value]: true});
            }
        });

        if(this.state.checked[checkedObj][elVal] === undefined || this.state.checked[checkedObj][elVal] === false){
            this.setState(prevState => ({
                checked: {
                    ...prevState.checked,
                    [checkedObj]: {
                        ...prevState.checked[checkedObj],
                        ...obj
                    } 
                }
            }))
        }

        this.setState({
            [statePropName]: selectedOption
        })
    }

    handleSingleApply = (type, field) => {
        const checkedObj = {...this.state.checked[type]}
        const queryArr = [];
        for (const property in checkedObj) {
            if(checkedObj[property]){
                queryArr.push(property)
            } 
        }
        this.props.onApplyFilter({...this.state.checked}, field, queryArr)
    }

    handleClear = (checkedObj, defaultVal, clearContent, query) => {
        let checkedVals = {...this.state.checked[checkedObj]}
        for (const property in checkedVals) {
            checkedVals[property] = false 
        }
        const queryArr = [];
        for (const property in checkedVals) {
            if(checkedVals[property]){
                queryArr.push(property)
            } 
        }
        const newState = {
            ...this.state,
            [clearContent]: [],
            checked: {
                ...this.state.checked,
                [checkedObj]: checkedVals
            }
        };
        this.setState(newState);
        this.props.onApplyFilter({...newState.checked}, query, queryArr)
    }

    findActive = (checkedFilters) => {
        for (const item in checkedFilters) {
            if(checkedFilters[item]){
                return true
            }
        }
    }

    render () {
        return (
            <div>
                <Grid container wrap="nowrap" spacing={1}>
                    <Grid item>
                        <Popover 
                            id={"Skills"} 
                            type="checkedSkills"  
                            query={"field_technical_skills_name"}
                            clearContent = {"skills"}  
                            handleSingleApply={this.handleSingleApply} 
                            handleClear={this.handleClear}
                            active={this.findActive(this.state.checked.checkedSkills)}
                        >
                            <SelectCheckbox 
                                checked="checkedSkills"
                                statePropName="skills"
                                placeholder="Add Skills"
                                values = {this.state.skills}
                                selectOptions = {this.state.skillsList}
                                checkedObj = {this.state.checked.checkedSkills} 
                                handleSelect={this.handleSelect}
                                handleChange={this.handleChange}
                                handleClear={this.handleClear} 
                            />
                        </Popover>
                    </Grid>
                    <Grid item>
                        <Popover 
                            id={"Countries"} 
                            type="checkedCountries" 
                            query={"field_candidate_country"}
                            clearContent = {"countries"} 
                            handleSingleApply={this.handleSingleApply} 
                            handleClear={this.handleClear}
                            active={this.findActive(this.state.checked.checkedCountries)}
                        >
                            <SelectCheckbox 
                                checked="checkedCountries"
                                statePropName="countries"
                                placeholder="Add Countries"
                                values = {this.state.countries}
                                selectOptions = {this.state.countriesList}
                                checkedObj = {this.state.checked.checkedCountries} 
                                handleSelect={this.handleSelect}
                                handleChange={this.handleChange}
                                handleClear={this.handleClear}
                            />
                        </Popover>
                    </Grid>
                    <Grid item>
                        <Popover 
                            id={"Cities"} 
                            type="checkedCities" 
                            query={"field_city_text"}
                            clearContent = {"cities"}  
                            handleSingleApply={this.handleSingleApply} 
                            handleClear={this.handleClear}
                            active={this.findActive(this.state.checked.checkedCities)}
                        >
                            <SelectCheckbox 
                                checked="checkedCities"
                                statePropName="cities"
                                placeholder="Add Cities"
                                values = {this.state.cities}
                                selectOptions = {this.state.citiesList}
                                checkedObj = {this.state.checked.checkedCities} 
                                handleSelect={this.handleSelect}
                                handleChange={this.handleChange}
                                handleClear={this.handleClear}
                            />
                        </Popover>
                    </Grid>
                    <Grid item>
                        <Popover 
                            id={"Experience"} 
                            type="checkedExperience" 
                            query={"field_professional_experience"} 
                            handleSingleApply={this.handleSingleApply} 
                            handleClear={this.handleClear}
                            active={this.findActive(this.state.checked.checkedExperience)}
                        >
                            <CheckboxFilter
                                title="Experience" 
                                checked="checkedExperience"
                                values={this.state.experienceList}
                                checkedObj = {this.state.checked.checkedExperience}
                                handleChange={this.handleChange} 
                                handleClear={this.handleClear}   
                            />
                        </Popover>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default Filters;
