import React from 'react';
import { CircularProgress, Grid, Box } from '@material-ui/core';

const Loader = () => {
    return (
        <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
    >
        <Box my={1}><CircularProgress color="secondary" /></Box>
    </Grid>
    )
}

export default Loader;