import { useEffect } from 'react';
import axios from 'axios';
import oauthConfig from '../../oauthConfig';

const Confirmation = (props) => {
    useEffect (()=>{
        axios.get(`${oauthConfig.baseUrl}/api/user/confirm/${props.match.params.hash}`)
        .then(res => {
            if(props.isLogged){
                props.responseOpen(res.data.message, "success")
                props.refreshToken()
            }else{
                props.responseOpen(res.data.message, "success")
                props.history.push('/')
            }
        })
        .catch(err => {
            if(props.isLogged){
                props.responseOpen(err.response.data.message, "error")
            }else{
                props.responseOpen(err.response.data.message, "error")
                props.history.push('/')
            }
        })
    }, [])
    
    return null; 
}

export default Confirmation;