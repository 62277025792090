import React, { useState } from 'react';
import { withFormik } from "formik";
import { BlockPicker } from 'react-color';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, CircularProgress, Grid, Button, Typography } from "@material-ui/core";
import oauthConfig from '../../../../oauthConfig';
import axios from 'axios';


const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: '700px',
        minHeight: '500px',
        margin: '0 auto',
        padding: `0, ${theme.spacing(1)}px, 0 ,${theme.spacing(1)}px `
    },
    colorWrapper: {
        position: "relative"
    },
    swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
    },
    color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
    },
    popover: {
        width: '170px',
        position: 'absolute',
        zIndex: '2',
        top: '35px',
        left: '0',
        right: '-200px'
    },
    close: {
        position: "absolute",
        zIndex: '3',
        right: '0',
        cursor: 'pointer'
    },
    submitBtn: {
        minWidth: "150px"
    }
}));

const FormContent = props => { 
    const { 
        handleSubmit,
        isSubmitting,
        setFieldValue,
        appBarColor,
        setColor
    } = props;

    const classes = useStyles(); 

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const handleColorPicker = (color) => {
        setColor(color.hex)
        setFieldValue('color_picker' , color.hex); 
    };

    return (
        <div className={classes.container}>
            <form onSubmit={handleSubmit} className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <Grid container  
                            justify="space-between"
                            alignItems="center"
                            className={classes.colorWrapper}
                        >
                            <Typography variant="subtitle2">
                                App Bar Color:
                            </Typography>
                            <div>
                                <div onClick={toggle} className={classes.swatch}>
                                    <div className={classes.color} style={{backgroundColor: appBarColor}}></div>
                                </div>
                                {isOpen ? (
                                    <div className={classes.popover}>
                                        <span onClick={toggle} className={classes.close}><CloseIcon/></span>
                                        <BlockPicker triangle="hide"  color={appBarColor} onChangeComplete={handleColorPicker} />
                                    </div> 
                                )
                                : null}
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} >
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Button className={classes.submitBtn} type="submit" variant="contained" disabled={isSubmitting}>
                            {isSubmitting ? <CircularProgress color="secondary" size={25} /> :  "Save" }
                        </Button>
                    </Grid> 
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

const Form = withFormik({

    handleSubmit: (values, {props, setSubmitting} ) => {
        
        const data = {
            type: "node--organization_profile",
            id: props.id,
            attributes: {
                field_color_picker: values.color_picker
            }
        }
        
        
        let token = props.token;

        let headers = {
            headers: {
                'Accept': 'application/vnd.api+json',
                'Content-Type': 'application/vnd.api+json',
                'Authorization': `${token.token_type} ${token.access_token}`
            }
        }
        
        axios.patch(`${oauthConfig.baseUrl}/api/node/organization_profile/${props.id}`, {data: data}, headers)
        .then(res => {
            setSubmitting(false)
            props.fetchOrg()
        })
        .catch(err => {
            setSubmitting(false)
            props.history.push('/')
        });
    }
  })(FormContent);

export default Form;
