import React from "react";
import { Link } from 'react-router-dom'
import { withFormik } from "formik";
import * as Yup from "yup";
import { 
  withStyles, 
  Card, 
  CardContent, 
  CardActions, 
  TextField , 
  Button, 
  Typography,
} from "@material-ui/core";
import oauthConfig from '../../../../oauthConfig';
import axios from 'axios';

const styles = (theme) => ({
    card: {
      maxWidth: 420,
      marginTop: 50
    },
    container: {
      display: "Flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    linkText: {
      textAlign: 'center',
      marginTop: theme.spacing(2),
      color: theme.palette.grey[800]
     },
     link: {
       color: theme.palette.secondary.main,
       fontWeight: 'bold'
     }
});

const ResetPasswordForm = props => {
    
    const {
      classes,
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit
    } = props;

    // console.log(props.match.params.hash)
    // console.log(props) 

    const isDisabled = () => {
      return values.pass.length > 0 && values.confirmPassword.length > 0
    }

    // const handleFocus = () => {
    //     if(isError){
    //         props.handleIsError()
    //     }
    // }
        

    return (
      <div className={classes.container}>
        <form onSubmit={handleSubmit}>
          <Card className={classes.card}>
            <CardContent>
                <TextField
                    id="pass"
                    label="New Password"
                    type="password"
                    value={values.pass}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.pass ? errors.pass : ""}
                    error={touched.pass && Boolean(errors.pass)}
                    margin="dense"
                    variant="outlined"
                    fullWidth
                />
                <TextField
                    name="confirmPassword"
                    label="Confirm New Password"
                    type="password"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.confirmPassword ? errors.confirmPassword : ""}
                    error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                    margin="dense"
                    variant="outlined"
                    fullWidth
                />
            </CardContent>
            <CardActions>
              <Button type="submit" variant="contained" fullWidth={true} disabled={!isDisabled()}>
                Change
              </Button>
            </CardActions>
          </Card>
        </form>
      </div>
    );
  };
  
  const Form = withFormik({
    mapPropsToValues: ({
        pass,
        confirmPassword
    }) => {
      return { 
        pass: pass || "",
        confirmPassword: confirmPassword || ""
      };
    },
  
    validationSchema: Yup.object().shape({
        pass: Yup.string()
            .min(8, "Password must contain at least 8 characters")
            .required("Enter your password"),
        confirmPassword: Yup.string()
            .required("Confirm your password")
            .oneOf([Yup.ref("pass")], "Password does not match")
    }),
  
    handleSubmit: (values, { props, setSubmitting }) => { 

        let data = {
          hash: props.hash,
          new_password: values.confirmPassword
        }

        axios.patch(`${oauthConfig.baseUrl}/api/user/password-reset?_format=json`, data)
        .then((res)=> {
          props.loginFunction("login", res.data.name, values.pass)
          
        })
        .catch(err => {
          props.setMsg(err.response.data.error)
          props.handleOpen()
        })
    }
  })(ResetPasswordForm); 
  
  export default withStyles(styles)(Form);
