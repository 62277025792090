import React, { Component } from 'react';
import ChangePasswordForm from './ChangePasswordForm';
import axios from 'axios';

class ChangePassword extends Component {

    state = {
        isError: false
    }

    handleIsError = () => {
        this.setState(prevState => ({
            isError: !prevState.isError
        }));
    }

    render(){
        return (
            <ChangePasswordForm
                {...this.props}  
                isError={this.state.isError}
                handleIsError={this.handleIsError} 
            />
        )
    }
}

export default ChangePassword;