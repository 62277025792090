import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles, Typography, Grid} from '@material-ui/core';
import oauthConfig from '../../oauthConfig';
import parse from 'html-react-parser';
import placeholder from '../../images/placeholder.png';

const useStyles = makeStyles(theme => ({
    menu: {
        borderRight: "1px solid #D3D3D3"
    },
    titleLink: {
        color: "black"
    },
    navLink: {
        textDecoration: "none",
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
        color: "#505050",
        borderBottom: "1px solid #D3D3D3",
        display: "block"
    },
    logoLink: {
        width: '40px',
        height: '40px',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        marginRight: `${theme.spacing(2)}px` 
    }
}));

const JobsMenu = (props) => {
    const classes = useStyles();
        let query;
        if(props.query){
            query = `?page=${props.page}&q=1${props.query}`
        }else{
            query = `?page=${props.page}`
        }
        return (
            <div>
                {   
                    props.jobs.map(el => {
                        let logo;
                        if(el.logo){
                            logo = `${oauthConfig.baseUrl}/${el.logo}`
                        }else {
                            logo = placeholder
                        }
                        return (
                            <NavLink  
                                to={`/jobs/${el.nid}${query}`}  
                                key={el.nid}
                                activeStyle={{background:'#efefef'}}
                                className={classes.navLink}
                            >
                                <Grid container>
                                    <Grid item xs={2}>
                                    <div className={classes.logoLink} style={{backgroundImage: `url(${logo})`}}></div>
                                    </Grid>
                                    <Grid item xs={10}>
                                    <div>
                                        <Typography variant="h6" className={classes.titleLink}>{parse(el.title)}</Typography>
                                        <Typography variant="body1">{parse(el.field_hiring_organization_1)}</Typography>
                                        <Typography variant="body1">{`${el.field_job_country}, ${el.field_job_city_name}`}</Typography>
                                        <Typography variant="caption">Valid until {el.field_valid_until}</Typography>
                                    </div>
                                    </Grid>
                                    
                                </Grid>
                            </NavLink>
                        )
                    })
                }
            </div>
        )
    
}

export default JobsMenu;
