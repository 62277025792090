import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import Drawer from '../Drawer/Drawer';
import DashboardOverview from './DashbordComponents/Overview/Overview';

const styles = theme => ({
    root: {
        padding: `${theme.spacing(2)}px`, 
        [theme.breakpoints.up('md')]: {
            padding: `${theme.spacing(4)}px`,
        }
    }
});

const drawerItems = [
    {
        text: "Overview",
        icon: "assignment",
        pathTo: "/dashboard/overview"
    },
    // {
    //     text: "Calendar",
    //     icon: "event_note",
    //     pathTo: "/dashboard/calendar"
    // }
]

const Dashboard = (props) => {
    const { classes, ...other } = props;
    return (
        <Drawer drawerItems={drawerItems} orgID={other.orgID}>
            <div className={classes.root}>
                <Switch>
                    <Route path="/dashboard/overview" render={()=> <DashboardOverview {...other} />} /> 
                    <Redirect to={ other.location.pathname === "/sign-up-employer" ? `/organization/edit/${other.nid}` : "/dashboard/overview"} />
                </Switch>
            </div>
        </Drawer>
    )
}

export default withStyles(styles)(Dashboard);