import React, { useState } from 'react';
import { withFormik } from "formik";
import * as Yup from "yup";
import { 
    makeStyles,
    CircularProgress,
    TextField,
    Grid,
    Button,
    Typography
} from "@material-ui/core";
import axios from 'axios';
import Select from 'react-select';
import oauthConfig from '../../../oauthConfig';


const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: '700px',
        margin:`${theme.spacing(4)}px auto 0 auto`,
        padding: `0 ${theme.spacing(1)}px`
    },
    input: {
        backgroundColor: "white"
    },
    colorWrapper: {
        position: "relative"
    },
    swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
    },
    color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
    },
    popover: {
        position: 'absolute',
        zIndex: '2',
        bottom: '6px',
        right: '-200px'
    },
    close: {
        position: "absolute",
        zIndex: '3',
        right: '0',
        cursor: 'pointer'
    },
    cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px', 
    },
    inputNumber: {
        backgroundColor: "white",
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0
        },
        "& input[type=number]" : {
            "-moz-appearance" : "textfield"
        }
    },
    submitBtn: {
        minWidth: "150px"
    },
    topForm: {
        marginBottom: `${theme.spacing(3)}px`
    }
}));

const selectStyles = {
    menu: base => ({
      ...base,
      zIndex: 100
    }),
    container: (base, state) => ({
        ...base,
        margin: '8px 0 4px 0'
    }),
    control: (base, state) => ({
        ...base,
        minHeight: '40px'
    })
};

const FormContent = props => { 
    const { 
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        defaultValues,
        token,
        country,
        selectedCountry,
    } = props;

    const classes = useStyles(); 

    const inputStyle = {
        className: classes.input
    }

    const selectStyles = {
        menu: base => ({
          ...base,
          zIndex: 100
        }),
        container: (base, state) => ({
            ...base,
            margin: '8px 0 4px 0'
        }),
        control: (base, state) => ({
            ...base,
            minHeight: '40px'
        })
    };
    
    const handleSelect = (selectedOption, type ) => {
        setFieldValue(type , selectedOption.label);
    }

    return (
        <div className={classes.container}>
            <form onSubmit={handleSubmit} className={classes.container}>
                <Typography variant="h6">Billing Contact</Typography>
                <Grid container spacing={2} className={classes.topForm}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="field_billing_first_name"
                            label="Billing First Name"
                            type="text"
                            value={values.field_billing_first_name}
                            onChange={handleChange}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            InputProps={inputStyle}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="field_billing_last_name"
                            label="Billing Last Name"
                            type="text"
                            value={values.field_billing_last_name}
                            onChange={handleChange}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            InputProps={inputStyle}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="field_billing_email"
                            label="Billing Email"
                            type="text"
                            value={values.field_billing_email}
                            onChange={handleChange}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            InputProps={inputStyle}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="field_billing_phone"
                            label="Billing Phone"
                            type="text"
                            value={values.field_billing_phone}
                            onChange={handleChange}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            InputProps={inputStyle}
                        />
                    </Grid>
                </Grid>
                <Typography variant="h6">Company Data</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}> 
                        <TextField
                            id="field_company_data_company"
                            label="Company / Organization"
                            type="text"
                            value={values.field_company_data_company}
                            onChange={handleChange}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            InputProps={inputStyle}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="field_company_data_vat_ein"
                            label="VAT/EIN"
                            type="text"
                            value={values.field_company_data_vat_ein}
                            onChange={handleChange}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            InputProps={inputStyle}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} >
                        <Select 
                            placeholder="Select country"
                            defaultValue={selectedCountry ? selectedCountry : null}
                            options={country}  
                            onChange={ (selectedOption) => handleSelect(selectedOption , 'country') } 
                            styles={selectStyles} 
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="field_company_data_city"
                            label="City"
                            type="text"
                            value={values.field_company_data_city}
                            onChange={handleChange}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            InputProps={inputStyle}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="field_company_data_address_1"
                            label="Address 1"
                            type="text"
                            value={values.field_company_data_address_1}
                            onChange={handleChange}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            InputProps={inputStyle}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="field_company_data_address_2"
                            label="Address 2"
                            type="text"
                            value={values.field_company_data_address_2}
                            onChange={handleChange}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            InputProps={inputStyle}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="field_company_data_state"
                            label="State/Province"
                            type="text"
                            value={values.field_company_data_state}
                            onChange={handleChange}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            InputProps={inputStyle}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="field_company_data_postal_code"
                            label="Postal Code"
                            type="text"
                            value={values.field_company_data_postal_code}
                            onChange={handleChange}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            InputProps={inputStyle}
                        />
                    </Grid>
                    <Grid item xs={12} >
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Button className={classes.submitBtn} type="submit" variant="contained" disabled={isSubmitting}>
                            {isSubmitting ? <CircularProgress color="secondary" size={25} /> : "Save" }
                        </Button>
                    </Grid> 
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

const Form = withFormik({
    mapPropsToValues: ({
      defaultValues,
    }) => {
        return {
            field_billing_first_name: defaultValues.field_billing_first_name || "",
            field_billing_last_name: defaultValues.field_billing_last_name || "",
            field_billing_email: defaultValues.field_billing_email || "",
            field_billing_phone: defaultValues.field_billing_phone || "",
            field_company_data_company: defaultValues.field_company_data_company || "",
            field_company_data_vat_ein: defaultValues.field_company_data_vat_ein || "",
            field_company_data_address_1: defaultValues.field_company_data_address_1 || "",
            field_company_data_address_2: defaultValues.field_company_data_address_2 || "",
            field_company_data_postal_code: defaultValues.field_company_data_postal_code || "",
            field_company_data_city: defaultValues.field_company_data_city || "",
            field_company_data_state: defaultValues.field_company_data_state || "",
        }
    },
  
    handleSubmit: (values, {props, setSubmitting} ) => {

        console.log(values);

        setSubmitting(true)
        const first_name_trim = values.field_billing_first_name.trim();
        const last_name_trim = values.field_billing_last_name.trim();
        const email_trim = values.field_billing_email.trim();
        const phone_trim = values.field_billing_phone.trim();
        const company_trim = values.field_company_data_company.trim();
        const vat_trim = values.field_company_data_vat_ein.trim();
        const address_1_trim = values.field_company_data_address_1.trim();
        const address_2_trim = values.field_company_data_address_2.trim();
        const postal_trim = values.field_company_data_postal_code.trim();
        const city_trim = values.field_company_data_city.trim();
        const state_trim = values.field_company_data_state.trim();

        let attributes = {
            field_billing_first_name: first_name_trim.length > 0 ? values.field_billing_first_name : "",
            field_billing_last_name: last_name_trim.length > 0 ? values.field_billing_last_name : "",
            field_billing_email: email_trim.length > 0 ? values.field_billing_email : "",
            field_billing_phone: phone_trim.length > 0 ? values.field_billing_phone : "",
            field_company_data_company: company_trim.length > 0 ? values.field_company_data_company : "",
            field_company_data_vat_ein: vat_trim.length > 0 ? values.field_company_data_vat_ein : "",
            field_company_data_address_1: address_1_trim.length > 0 ? values.field_company_data_address_1 : "",
            field_company_data_address_2: address_2_trim.length > 0 ? values.field_company_data_address_2 : "",
            field_company_data_postal_code: postal_trim.length > 0 ? values.field_company_data_postal_code : "",
            field_company_data_city: city_trim.length > 0 ? values.field_company_data_city : "",
            field_company_data_state: state_trim.length > 0 ? values.field_company_data_state : "",
            field_company_data_country: values.country
        }

        let data;

        data = {
            type: "node--organization_profile",
            id: props.defaultValues.uuid,
            attributes: attributes,
        }

        let token = props.token;

        let headers = {
            headers: {
              'Accept': 'application/vnd.api+json',
              'Content-Type': 'application/vnd.api+json',
              'Authorization': `${token.token_type} ${token.access_token}`
            }
        }
        
        axios.patch(`${oauthConfig.baseUrl}/api/node/organization_profile/${props.defaultValues.uuid}`, {data: data}, headers)
        .then(res => {
            setSubmitting(false)
            props.fetchOrg()
            // this.props.history.push('/organization/all')
        })
        .catch(err => {
            if(err.response.status === 401){
            //   this.props.logout()
            }
        });
        
    }
  })(FormContent);

export default Form;
