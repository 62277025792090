import React, { Component, Fragment } from 'react';
import { Snackbar, Card, withStyles } from '@material-ui/core';
import ResetPasswordForm from './ResetPasswordForm';

const styles = theme => ({
    errorCard: {
        textAlign: "center",
        padding: theme.spacing(2),
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.error.contrastText
    }
});


class ResetPassword extends Component {

    state = {
        msg: "",
        open: false 
    }
    setMsg = (msg) => {
        this.setState({msg: msg});
    }

    handleClose = () => {
        this.setState({open: false});
    };

    handleOpen = () => {
        this.setState({open: true});
    };

    render(){
        
        const { classes } = this.props;

        return (
            <Fragment>
                <ResetPasswordForm
                    hash = { this.props.match.params.hash }
                    setMsg={this.setMsg}
                    handleOpen={this.handleOpen}
                    loginFunction = {this.props.loginFunction}
                />
                <Snackbar 
                    autoHideDuration={3000} 
                    open={this.state.open}  
                    onClose={this.handleClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
                >
                    <Card className={classes.errorCard}>
                        <h3>{this.state.msg}</h3>
                    </Card>
                </Snackbar>
            </Fragment>
        )
    }
}

export default withStyles(styles)(ResetPassword);