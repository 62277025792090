import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';

const router = ( 
    <BrowserRouter>
        <App />
    </BrowserRouter>
)


ReactDOM.render(router, document.getElementById('root'));

const host = window.location.host
if(host === "app.tallocate.com" || host === "app-stage.tallocate.com" ){
    const favicon = document.getElementById("favicon")
    const description = "Connect and engage with Tallocate community members to share opportunities."
    favicon.setAttribute("href", `/favicon.ico`);
    document.title = "Tallocate - Job & Career Platform"
    document.querySelector('meta[name="description"]').setAttribute("content", description)
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
