import React, { Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Grid, Paper, makeStyles, Typography, Box, Dialog, DialogTitle, DialogActions, Divider, Tooltip, Badge, InputAdornment, TextField, CircularProgress } from '@material-ui/core';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EditIcon from '@material-ui/icons/Edit';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import CloseIcon from '@material-ui/icons/Close';
import Loader  from '../../../Utilities/Loader/Loader';
import ReactPaginate from 'react-paginate';
import { Html5Entities } from 'html-entities';
import SearchIcon from '@material-ui/icons/Search';
import JobItemsFilter from '../../../Filters/JobItemsFilter/JobItemsFilter';
import axios from 'axios';
import config from '../../../../oauthConfig';
import { DomainPropTypes } from '@material-ui/pickers/constants/prop-types';

const htmlEntities = new Html5Entities();

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 800,
        margin: "0 auto 10px auto",
        padding: theme.spacing(2),
    },
    searchRoot: {
        maxWidth: 800,
        margin: "0 auto 30px auto",
        // padding: theme.spacing(2),
    },
    button: {
        minWidth: '55px',
        margin: `0 ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px 0`,
    },
    buttons: {
        paddingTop: '10px',
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'left',
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
        },
    },
    pagination: {
        display: "flex",
        listStyle: "none",
        justifyContent: "center",
        alignItems: 'center',
        '& li':{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 5px',
            width: '30px',
            height: '30px',
            cursor: 'pointer'
        },
        '& a': {
            height: '20px',
            '&:focus': {
                outline: "0"
            }
        }
    },
    active: {
        background: '#eeeeee', 
        borderRadius: '50%' 
    },
    notVisible: {
        visibility: 'hidden'
    },
    valid: {
        paddingRight: '5px'
    },
    search: {
        background: '#ffffff',
    },
}));

const JobItems = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null); 
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false); 
    const open = Boolean(anchorEl);
    const perPage = 50;
    const pageCount = Math.ceil(props.jobsCount / perPage);

    const handleClick = event => {
        const id = event.currentTarget.id;
        const type = event.currentTarget.dataset.type;
        setAnchorEl({id, type});   
    };

    const handleClose = () => { 
        setAnchorEl(null);
    };

    const handlePageClick = (e) => {
        props.handleSetPage(e.selected)
    }
    
    const setFetured = (nid, val) => {
        if(parseInt(props.feturedQuota) === 0 && val !== 0 ){
            setAnchorEl({id: nid, type: "featured"}); 
            return
        }
        setLoading(nid)
        let token = props.token;
        const headers = {
          headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
            'Authorization': `${token.token_type} ${token.access_token}`
          }
        }
        axios.patch(`${config.baseUrl}/api/job/${nid}/sticky/${val}`, {} , headers)
        .then((res) => {
            setLoading(false)
            let page = props.page ? props.page : 0;
            props.fetchAll(page); 
        })  
    }
    let page = `?page=${props.page}`;
    let jobs;
    if(props.jobList){
        jobs = props.jobList.map((el, index) => {
            return (
                <Fragment key={el.uuid}>
                    <Paper className={classes.root}>
                        <Grid container direction="row" alignItems="flex-start" wrap="wrap">
                            <Grid item container xs={12} md={7}>
                                <Typography component="h1" variant="h6">
                                    <Box lineHeight="normal" css={{textTransform: 'uppercase'}}>
                                        <span dangerouslySetInnerHTML={{__html: el.title }} />
                                    </Box>
                                </Typography>
                                <Grid item container xs={12}>
                                    <Typography variant="caption" className={classes.valid}>
                                        Valid until:
                                    </Typography>
                                    <Typography variant="caption">
                                        {el.field_valid_until}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={5} className={classes.buttons}>
                                { el.sticky === "0" ? (
                                    <Tooltip title="Feature this job post">
                                        <Badge color="primary" badgeContent={props.feturedQuota} anchorOrigin={{ vertical: 'top',horizontal: 'left' }} showZero>
                                            <Button  size="small" className={classes.button} data-type="featured" variant="outlined" onClick={()=>{ setFetured(el.nid, 1) }} children={loading && loading === el.nid ? <CircularProgress size={10}/> : <VerticalAlignTopIcon fontSize="small"/>}></Button>
                                        </Badge>
                                    </Tooltip>
                                ):(
                                    <Tooltip title="Remove from the feature jobs list">
                                        <Button className={classes.button} variant="outlined" onClick={()=> { setFetured(el.nid, 0)}} children={loading && loading === el.nid ? <CircularProgress size={10}/> : <VerticalAlignBottomIcon fontSize="small"/>}></Button>
                                    </Tooltip>
                                )}
                                <Tooltip title="View">
                                    <Button  className={classes.button} variant="outlined" onClick={()=>{props.history.push(`${props.match.path}/${el.nid}`)}} children={<VisibilityIcon fontSize="small"/>}></Button>
                                </Tooltip>
                                <Tooltip title="Edit">
                                    <Button  className={classes.button} variant="outlined" onClick={()=>{props.history.push(`${props.match.path}/${el.nid}/edit${page}`)}} children={<EditIcon fontSize="small"/>}></Button>
                                </Tooltip>
                                <Tooltip title="Delete">
                                    <Button  id={el.nid} className={classes.button} data-type="delete" variant="outlined" onClick={handleClick} children={<DeleteForeverIcon fontSize="small"/>}></Button>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}><Box mt={1} mb={1}><Divider/></Box></Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={3}>
                                <Typography component="h2" variant="subtitle2" className={classes.title}>
                                    Employment Type
                                </Typography>
                                <Typography variant="body2">
                                    {el.field_employment_type}
                                </Typography> 
                            </Grid>
                            {
                                el.field_basesalary_year ? (
                                    <Grid item xs={12} sm={6} md={3}>
                                        <Typography component="h2" variant="subtitle2">
                                            Basesalary Year
                                        </Typography>
                                        <Box display="flex" alignItems="center">
                                            <Typography variant="body2">{el.field_basesalary_year}</Typography> 
                                            <AttachMoneyIcon style={{ fontSize: 14 }}/>
                                        </Box>
                                    </Grid>
                                ) : null
                            }
                            <Grid item xs={12} sm={6} md={3}>
                                <Typography component="h2" variant="subtitle2">
                                    Job Location
                                </Typography>
                                <Typography variant="body2" noWrap>
                                    {htmlEntities.decode(el.field_job_country)}
                                    {el.field_job_state ? `, ${el.field_job_state}` : null}
                                    {el.field_job_city_name ? `, ${el.field_job_city_name}` : null}
                                </Typography>  
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <Typography component="h2" variant="subtitle2">
                                    Remote Job?
                                </Typography>
                                <Typography variant="body2">
                                    {el.field_remote_job}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Dialog
                        open={open && anchorEl.id === el.nid}
                        onClose={handleClose}
                    >
                        {open && (
                            <Fragment>
                                <DialogTitle>
                                    {anchorEl.type === "delete" && "Are you sure you want to delete this job?"}
                                    {anchorEl.type === "featured" && "Please remove one or more from the feature jobs list to add new ones or contact sales"}
                                </DialogTitle>
                                <DialogActions>
                                    {anchorEl.type === "delete" ? (
                                        <Button onClick={() => props.handleDlete(el.uuid)} variant="contained">
                                         Yes
                                        </Button>
                                    ):(
                                        <Button target="_blank" rel="noopener" href="https://www.womentech.net/women-tech-conference/partner?product=NetworkHiring" color="primary" variant="contained">Contact Sales</Button>
                                    )}
                                    <Button onClick={handleClose} variant="contained">
                                        No
                                    </Button>
                                </DialogActions>
                            </Fragment>
                        )}
                    </Dialog>
                </Fragment>
            )
        })
    }else{
        jobs = <Loader />
    }

    const handleChange = (e) => {
        setSearch(e.target.value);
    }

    const handleKeyPress = (e) => {
        if(e.key === 'Enter'){
            props.handleSearch(`&search=${search.replace(/\s+/g, '+')}` , 0)
        }
    }

    const {...other} = props;
    return (
        
        <div>
            {props.jobList && props.jobList.length === 0 ? (
                <div>
                    <Box align="center" mb={2}>
                        <NavLink to="new" style={{textDecoration: 'none'}}>
                            <Button variant="contained">Add New Job<NoteAddIcon fontSize="small"/></Button>
                        </NavLink>
                    </Box>
                </div>
            ): null }
            <div>
                <div className={classes.searchRoot}>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField 
                            className={classes.search} 
                            fullWidth={true} 
                            variant="outlined"
                            onChange={handleChange}
                            value={search}
                            onKeyPress={handleKeyPress}
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                        <Box color="#757575" display="flex">
                                            <SearchIcon fontSize="small"/><Box fontSize={12}>Search</Box>
                                        </Box>
                                </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                            <Button 
                                                type="submit" 
                                                variant="contained" 
                                                fullWidth
                                                disabled = { !search } 
                                                onClick={() => props.handleSearch(`&search=${search.replace(/\s+/g, '+')}` , 0)}
                                            >
                                                Search
                                            </Button>
                                    </InputAdornment>
                                )
                            }} 
                        />
                    </Grid>
                    <JobItemsFilter 
                        {...other} 
                    />
                </Grid>
                </div>
                {jobs}
                <div className={props.jobList && props.jobList.length > 0  ?  null : `${classes.notVisible}`}>
                    {props.page || props.page === 0 ? (
                        <ReactPaginate
                        previousLabel={<NavigateBeforeIcon />}
                        nextLabel={<NavigateNextIcon />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        initialPage={props.page}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={4}
                        disableInitialCallback={true}
                        onPageChange={handlePageClick}
                        containerClassName={classes.pagination}
                        activeClassName={classes.active}
                    />
                    ) : null}
                </div>
            </div>
        </div>
        
    )

}

export default JobItems;