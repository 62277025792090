import React from 'react';
import { 
  AppBar, 
  withStyles,
  Avatar,
  CircularProgress,
  Badge 
} from '@material-ui/core';
import Logo from '../../Logo/Logo';
import MenuPopup from '../../Settings/MenuPopup/MenuPopup';
import oauthConfig from '../../../oauthConfig';

const styles = (theme) => ({
  root: {
    // background: 'linear-gradient(to right, #58a3b3, #1d90b9, #0079bd, #3c5db5, #69379c)',
    // borderBottom: '10px solid #69379C',
    flexDirection: 'row',
    padding: '10px 15px 10px 15px',
    zIndex: '1201',
    height: '80px'
  },
  button:{
    marginLeft: 'auto',
    alignSelf: 'center'
  },
  mobileBtn:{
    marginRight: 'auto',
  },
  avatar: {
    cursor: 'pointer'
  },
  userLogo: {
    width: '200px',
    backgroundSize: 'contain',
    backgroundPosition: 'left center',
    backgroundRepeat: 'no-repeat'
  }
});



const Toolbar = (props) => {
  const { classes } = props;
  let avatar = () => {
    if(props.userData.includes.picture){
      return (
        <Badge color="secondary" badgeContent="!" invisible={(typeof props.orgID) === 'string'}>
          <Avatar  className={classes.avatar} src={`${oauthConfig.baseUrl}/${props.userData.includes.picture}`}></Avatar>
        </Badge>
      )
    }else {
      let firstLetter = props.userData.attributes.field_first_name.charAt(0)
      let secondLetter = props.userData.attributes.field_last_name.charAt(0)
      return (
        <Badge color="secondary" badgeContent="!" invisible={(typeof props.orgID) === 'string'}>
          <Avatar  className={classes.avatar}>{`${firstLetter}${secondLetter}`}</Avatar>
        </Badge>
      )
    }
  }
  
  return (
    <div>
      <AppBar className={classes.root} position={'fixed'}  style={{backgroundColor: props.appBarColor }}> 
        { props.logo ? (
            <div className={classes.userLogo} style={{backgroundImage: `url(${oauthConfig.baseUrl}${props.logo})` }}></div>
          )
            : (
            <Logo logoType='color' width='150px'/>
          ) 
        }
        <div className={classes.button}>
        {props.userData && props.organizations ? (
              <MenuPopup 
                logout={props.logout} 
                userData={props.userData} 
                organizations={props.organizations} 
                setLogo={props.setLogo}
                uuid={props.uuid} 
                token={props.token}
                orgID = {props.orgID}
                orgActive={props.orgActive}
                setOrgNid = {props.setOrgNid}
                setAppBarColor={props.setAppBarColor}>
                { avatar() }
              </MenuPopup>
        ) : <CircularProgress color="primary" size={20} />}
        </div>
      </AppBar>
    </div>
  );
}



export default withStyles(styles)(Toolbar);
