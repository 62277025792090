import React from 'react';
import tallocateLogo from '../../images/tallocate.png';
import womentechLogo from '../../images/WomenTech.png'
import oauthConfig from '../../oauthConfig';

const logo = (props) => {
    const hostname = oauthConfig.hostname
    let logoPath = (hostname === 'app.tallocate' || hostname === 'app-stage.tallocate.com') ? tallocateLogo : womentechLogo; 

    return (
        <div style={{
            backgroundImage: `url(${logoPath})`, 
            width: props.width,
            height: '100%',
            backgroundSize: 'contain',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat' 
        }}>    
        </div>
        // <div style={{width: props.width , height: '100%', textAlign: 'center'}}> 
        //     <img alt="logo" src={logoPath} style={{height: '100%'}}/>
        // </div>
    )
}

export default logo;