import React, { useState } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { makeStyles, CircularProgress, TextField, Grid, Button, FormControl, FormControlLabel, Radio, RadioGroup, Typography, InputAdornment, FormHelperText } from "@material-ui/core";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";
import DropUploader from "../../Utilities/DropUploader";
import RichTextEditor from "../../RichTextEditor/RichTextEditor";
import YoutubeEmbed from "../../YoutubeEmbed/YoutubeEmbed";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "700px",
    margin: `${theme.spacing(4)}px auto 0 auto`,
    padding: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
  input: {
    backgroundColor: "white",
  },
  colorWrapper: {
    position: "relative",
  },
  swatch: {
    padding: "5px",
    background: "#fff",
    borderRadius: "1px",
    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
    display: "inline-block",
    cursor: "pointer",
  },
  color: {
    width: "36px",
    height: "14px",
    borderRadius: "2px",
  },
  popover: {
    position: "absolute",
    zIndex: "2",
    bottom: "6px",
    right: "-200px",
  },
  close: {
    position: "absolute",
    zIndex: "3",
    right: "0",
    cursor: "pointer",
  },
  cover: {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
  inputNumber: {
    backgroundColor: "white",
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
  icon: {
    color: theme.palette.grey[500],
  },
  submitBtn: {
    minWidth: "150px",
  },
}));

const selectStyles = {
  menu: (base) => ({
    ...base,
    zIndex: 100,
  }),
  container: (base, state) => ({
    ...base,
    margin: "8px 0 4px 0",
  }),
  control: (base, state) => ({
    ...base,
    minHeight: "40px",
  }),
};

const backGroundTooltip =
  "This background image is shown on the company profile page. It should not include any logos or text, otherwise those will overlap with the company name which is shown automatically above the background image";

const FormContent = (props) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    initialData,
    createImageEntity,
    deleteImageEntity,
    formEdit,
    defaultValues,
    logo,
    background,
    culture,
    techSkillsSelected,
    benefitsSelected,
    industrySelected,
    selectedCountry,
    selectedCurrency,
  } = props;

  const classes = useStyles();
  const [sizeLimitLogo, setSizeLimitLogo] = useState(false);
  const [sizeLimitBg, setSizeLimitBg] = useState(false);
  const videoHelperText = "Please add a single Video URL (example: https://www.youtube.com/watch?v=yF-VkhOo9ok) Channel URLs & Playlists won't work here";

  const inputStyle = {
    className: classes.input,
  };

  const size = Object.keys(initialData.size).map((el, index) => {
    return (
      <FormControlLabel
        key={index}
        value={el}
        control={<Radio />}
        label={
          <Typography variant="caption" color="textPrimary">
            {initialData.size[el]}
          </Typography>
        }
      />
    );
  });

  const handleSingleSelect = (selectedOption, field, type) => {
    let selectedID = { type: `taxonomy_term--${type}`, id: selectedOption.id };
    let relationships = { [field]: { data: selectedID } };
    // setFieldValue(field , selectedID);
    props.handleData("relationships", relationships);
  };

  const handleMultiSelect = (selectedOption, field, type) => {
    let options;
    if (selectedOption) {
      options = selectedOption.map((el) => {
        return { type: `taxonomy_term--${type}`, id: el.id };
      });
      let relationships = { [field]: { data: options } };
      //   setFieldValue(field , options);
      props.handleData("relationships", relationships);
    } else {
      let relationships = { [field]: { data: [] } };
      // setFieldValue(field , options);
      props.handleData("relationships", relationships);
    }
  };

  const handleListSelect = (selectedOption, field, type) => {
    props.handleData("attributes", { [field]: selectedOption.value });
  };

  const handleRadio = (e, field) => {
    props.handleData("attributes", { [field]: e.target.value });
    // setFieldValue('field_company_size' , e.target.value);
  };

  const uploadFile = (file, type, index) => {
    if (type === "field_logo" && file) {
      setFieldValue(type, file.name);
    }
    createImageEntity(file, type, index);
  };

  const removeFile = (type, index) => {
    if (type === "field_logo") {
      setFieldValue(type, null);
    }
    deleteImageEntity(type, index);
  };

  const rteChange = (event, editor, id) => {
    const data = editor.getData();
    const formatedValue = { value: data, format: "basic_html" };
    props.handleData("attributes", { [id]: formatedValue });
    handleBlur({ target: { id } });
    setFieldValue(id, data);
  };

  const extractVideoId = (url) => {
    let match = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?([a-zA-Z0-9_-]{11})(?![a-zA-Z0-9_-])/);
    if (match) {
      return match[1];
    } else {
      return null;
    }
  };

  const setTextValues = (e) => {
    const field = e.target.id;
    let value;
    value = e.target.value;
    if (field === "field_video") {
      const id = extractVideoId(e.target.value);
      value = {
        input: e.target.value,
        video_id: id,
      };
    }
    props.handleData("attributes", { [field]: value });
    handleBlur(e);
  };

  const addtVideo = (value) => {
    const id = extractVideoId(value);
    value = {
      input: value,
      video_id: id,
    };

    props.handleData("attributes", { field_video: [...defaultValues.attributes.field_video, value] });
  };

  const removeVideo = (id) => {
    const arr = [...defaultValues.attributes.field_video];
    const newArray = arr.filter((video) => video.video_id !== id);
    props.handleData("attributes", { field_video: newArray });
  };

  return (
    <div className={classes.container}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              id="title"
              label="Organization Name*"
              type="text"
              value={values.title}
              helperText={touched.title ? errors.title : ""}
              error={touched.title && Boolean(errors.title)}
              onChange={handleChange}
              onBlur={(e) => setTextValues(e)}
              margin="dense"
              variant="outlined"
              fullWidth
              InputProps={inputStyle}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="field_contact_email"
              type="email"
              label="Contact Email*"
              value={values.field_contact_email}
              helperText={touched.field_contact_email ? errors.field_contact_email : ""}
              error={touched.field_contact_email && Boolean(errors.field_contact_email)}
              onChange={handleChange}
              onBlur={(e) => setTextValues(e)}
              margin="dense"
              variant="outlined"
              fullWidth
              InputProps={inputStyle}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="field_hires_needed"
              type="number"
              inputProps={{ min: 0 }}
              label="Number of Hires Needed"
              value={values.field_hires_needed}
              onChange={handleChange}
              onBlur={(e) => setTextValues(e)}
              margin="dense"
              variant="outlined"
              fullWidth
              InputProps={inputStyle}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="field_phone_number"
              label="Phone"
              type="tel"
              value={values.field_phone_number}
              onChange={handleChange}
              onBlur={(e) => setTextValues(e)}
              margin="dense"
              variant="outlined"
              fullWidth
              InputProps={inputStyle}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">Short company description *</Typography>
            <Typography variant="caption">(Recommended length is 1-2 paragraphs at ~100 words)</Typography>
            <RichTextEditor
              id="field_company_description"
              data={defaultValues.attributes.field_company_description && defaultValues.attributes.field_company_description.value}
              rteChange={(event, editor, id) => rteChange(event, editor, id)}
              helperText={errors.field_company_description ? errors.field_company_description : ""}
              error={errors.field_company_description && Boolean(errors.field_company_description)}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">What makes your company unique?</Typography>
            <RichTextEditor
              id="field_what_makes_your_company_un"
              data={defaultValues.attributes.field_what_makes_your_company_un && defaultValues.attributes.field_what_makes_your_company_un.value}
              rteChange={(event, editor, id) => rteChange(event, editor, id)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="field_website_url"
              label="Website URL"
              type="url"
              helperText={touched.field_website_url ? errors.field_website_url : ""}
              error={touched.field_website_url && Boolean(errors.field_website_url)}
              value={values.field_website_url}
              onChange={handleChange}
              onBlur={(e) => setTextValues(e)}
              margin="dense"
              variant="outlined"
              fullWidth
              InputProps={inputStyle}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="field_linkedin_profile"
              label="LinkedIn"
              helperText={touched.field_linkedin_profile ? errors.field_linkedin_profile : ""}
              error={touched.field_linkedin_profile && Boolean(errors.field_linkedin_profile)}
              value={values.field_linkedin_profile}
              onChange={handleChange}
              onBlur={(e) => setTextValues(e)}
              margin="dense"
              variant="outlined"
              fullWidth
              InputProps={{
                className: classes.input,
                startAdornment: (
                  <InputAdornment position="start">
                    <LinkedInIcon className={classes.icon} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="field_twitter_profile"
              label="Twitter"
              helperText={touched.field_twitter_profile ? errors.field_twitter_profile : ""}
              error={touched.field_twitter_profile && Boolean(errors.field_twitter_profile)}
              value={values.field_twitter_profile}
              onChange={handleChange}
              onBlur={(e) => setTextValues(e)}
              margin="dense"
              variant="outlined"
              fullWidth
              InputProps={{
                className: classes.input,
                startAdornment: (
                  <InputAdornment position="start">
                    <TwitterIcon className={classes.icon} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="field_facebook_profile"
              label="Facebook"
              helperText={touched.field_facebook_profile ? errors.field_facebook_profile : ""}
              error={touched.field_facebook_profile && Boolean(errors.field_facebook_profile)}
              value={values.field_facebook_profile}
              onChange={handleChange}
              onBlur={(e) => setTextValues(e)}
              margin="dense"
              variant="outlined"
              fullWidth
              InputProps={{
                className: classes.input,
                startAdornment: (
                  <InputAdornment position="start">
                    <FacebookIcon className={classes.icon} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">Youtube videos</Typography>
            <TextField
              id="field_video"
              label="Video URL"
              value={values.field_video}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={values.field_video.length && errors.field_video ? errors.field_video : videoHelperText}
              error={Boolean(values.field_video.length && errors.field_video)}
              margin="dense"
              variant="outlined"
              fullWidth
              InputProps={{
                className: classes.input,
                startAdornment: (
                  <InputAdornment position="start">
                    <YouTubeIcon className={classes.icon} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <Button position="start" variant="contained" color="primary" disabled={!(values.field_video && !errors.field_video)} onClick={() => addtVideo(values.field_video)}>
                    Add
                  </Button>
                ),
              }}
            />
            {defaultValues.attributes.field_video.length > 0 && (
              <div>
                {defaultValues.attributes.field_video.map((el, i) => (
                  <Grid container key={i} spacing={3} alignItems="center">
                    <Grid item xs={6}>
                      <YoutubeEmbed embedId={el.video_id} />
                    </Grid>
                    <Grid item xs={4}>
                      <Button onClick={() => removeVideo(el.video_id)}>Remove</Button>
                    </Grid>
                  </Grid>
                ))}
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">Company Video Description</Typography>
            <RichTextEditor
              id="field_video_text"
              data={defaultValues.attributes.field_video_text && defaultValues.attributes.field_video_text.value}
              rteChange={(event, editor, id) => rteChange(event, editor, id)}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              isClearable={false}
              isMulti
              placeholder="Technical skills"
              defaultValue={techSkillsSelected}
              options={initialData.techSkills}
              onChange={(selectedOption) => handleMultiSelect(selectedOption, "field_technical_skills", "technical_skills")}
              styles={selectStyles}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">{"Describe Your Current Tech Challenges or Describe Your Hiring & Employer Branding Challenges"}</Typography>
            <RichTextEditor
              id="field_tech_challenges"
              data={defaultValues.attributes.field_tech_challenges && defaultValues.attributes.field_tech_challenges.value}
              rteChange={(event, editor, id) => rteChange(event, editor, id)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              isMulti
              placeholder="Select All the Benefits That Apply"
              defaultValue={benefitsSelected}
              options={initialData.benefits}
              onChange={(selectedOption) => handleMultiSelect(selectedOption, "field_benefits", "benefits")}
              styles={selectStyles}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              isMulti
              placeholder="Industry"
              defaultValue={industrySelected}
              options={initialData.industry}
              onChange={(selectedOption) => handleMultiSelect(selectedOption, "field_industry", "industry")}
              styles={selectStyles}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              placeholder="Select Headquarters Country"
              defaultValue={selectedCountry}
              options={initialData.country}
              onChange={(selectedOption) => handleSingleSelect(selectedOption, "field_hiring_location", "country")}
              styles={selectStyles}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              placeholder="Currency"
              defaultValue={selectedCurrency}
              options={initialData.currency}
              onChange={(selectedOption) => handleListSelect(selectedOption, "field_currency", "currency")}
              styles={selectStyles}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <Typography variant="subtitle2">Company Size</Typography>
              <RadioGroup
                name="Comany Size"
                defaultValue={formEdit ? defaultValues.attributes.field_company_size : null}
                onChange={(e) => {
                  handleRadio(e, "field_company_size");
                }}
              >
                <Grid container direction="row" justify="flex-start" alignItems="center">
                  {size}
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <DropUploader
              title={"Upload Company Logo*"}
              subtitle={"(Min 400x400 px)"}
              color={sizeLimitLogo || errors.field_logo ? "error" : "textPrimary"}
              setSizeLimit={{ func: setSizeLimitLogo, width: 400, height: 400 }}
              uploadFile={uploadFile}
              removeFile={removeFile}
              type="field_logo"
              initialData={logo && logo.path}
              removeInitialData={removeFile}
            />
            {errors.field_logo && (
              <FormHelperText error={Boolean(errors.field_logo)} variant="filled">
                Required
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <DropUploader
              title={"Upload Title Background"}
              subtitle={"(Min 1920x600 px)"}
              tooltip={backGroundTooltip}
              color={sizeLimitBg ? "error" : "textPrimary"}
              setSizeLimit={{ func: setSizeLimitBg, width: 1920, height: 600 }}
              uploadFile={uploadFile}
              removeFile={removeFile}
              type="field_page_title_background"
              initialData={background && background.path}
              removeInitialData={removeFile}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">Culture description</Typography>
            <RichTextEditor
              id="field_culture_description"
              data={defaultValues.attributes.field_culture_description && defaultValues.attributes.field_culture_description.value}
              rteChange={(event, editor, id) => rteChange(event, editor, id)}
            />
          </Grid>
          <Grid item xs={12} container justify="center">
            {culture.map((el, i) => (
              <Grid item xs={12} md={4} key={i}>
                <DropUploader
                  title={"Images for Company Culture Gallery"}
                  // subtitle={"(Min 1920x600 px)"}
                  // color={sizeLimitBg ?  "error" : "textPrimary"}
                  // setSizeLimit={{func: setSizeLimitBg, width: 1920, height: 600 }}
                  uploadFile={uploadFile}
                  removeFile={removeFile}
                  type="field_culture_images"
                  initialData={el && el.path}
                  index={i}
                  removeInitialData={removeFile}
                />
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" justify="center" alignItems="center">
              <Button className={classes.submitBtn} type="submit" variant="contained">
                {isSubmitting ? <CircularProgress color="secondary" size={25} /> : formEdit ? "Save" : "Save and Create"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const Form = withFormik({
  mapPropsToValues: ({ defaultValues, logo }) => {
    return {
      title: defaultValues.attributes.title ? defaultValues.attributes.title : "",
      field_contact_email: defaultValues.attributes.field_contact_email ? defaultValues.attributes.field_contact_email : "",
      field_hires_needed: defaultValues.attributes.field_hires_needed ? defaultValues.attributes.field_hires_needed : "",
      field_phone_number: defaultValues.attributes.field_phone_number ? defaultValues.attributes.field_phone_number : "",
      field_website_url: defaultValues.attributes.field_website_url ? defaultValues.attributes.field_website_url : "",
      field_linkedin_profile: defaultValues.attributes.field_linkedin_profile ? defaultValues.attributes.field_linkedin_profile : "",
      field_twitter_profile: defaultValues.attributes.field_twitter_profile ? defaultValues.attributes.field_twitter_profile : "",
      field_facebook_profile: defaultValues.attributes.field_facebook_profile ? defaultValues.attributes.field_facebook_profile : "",
      field_video: "",
      field_company_size: defaultValues.attributes.field_company_size ? defaultValues.attributes.field_company_size : "",
      field_company_description: defaultValues.attributes.field_company_description ? defaultValues.attributes.field_company_description : "",
      field_what_makes_your_company_un: defaultValues.attributes.field_what_makes_your_company_un ? defaultValues.attributes.field_what_makes_your_company_un : "",
      field_video_text: defaultValues.attributes.field_video_text ? defaultValues.attributes.field_video_text : "",
      field_culture_description: defaultValues.attributes.field_culture_description ? defaultValues.attributes.field_culture_description : "",
      field_tech_challenges: defaultValues.attributes.field_tech_challenges ? defaultValues.attributes.field_tech_challenges : "",
      field_hiring_location: defaultValues.relationships.field_hiring_location ? defaultValues.relationships.field_hiring_location.data : "",
      field_logo: logo.path ? logo.path : "",
      field_page_title_background: defaultValues.relationships.field_page_title_background ? defaultValues.relationships.field_page_title_background.data : "",
      field_technical_skills: defaultValues.relationships.field_technical_skills ? defaultValues.relationships.field_technical_skills.data : "",
      field_benefits: defaultValues.relationships.field_benefits ? defaultValues.relationships.field_benefits.data : "",
      field_industry: defaultValues.relationships.field_industry ? defaultValues.relationships.field_industry.data : "",
    };
  },

  validationSchema: Yup.object().shape({
    title: Yup.string().trim().required("Required"),
    field_company_description: Yup.string().required("Required"),
    field_contact_email: Yup.string().email("Enter a valid email").required("Required"),
    field_logo: Yup.string().required("Required"),
    field_video: Yup.string().matches(/^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/, "Enter correct Youtube video url!"),
    field_website_url: Yup.string().url("Please provide a valid url"),
    field_linkedin_profile: Yup.string().url("Please provide a valid url"),
    field_twitter_profile: Yup.string().url("Please provide a valid url"),
    field_facebook_profile: Yup.string().url("Please provide a valid url"),
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    props.handleFormSubmit();
  },
})(FormContent);

export default Form;
