import React, { Fragment, useState } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import PhoneIcon from '@material-ui/icons/Phone';
import oauthConfig from '../../oauthConfig';
import parse from 'html-react-parser';
import { Html5Entities } from 'html-entities';
import dummyPicture from '../../images/placeholder.png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const htmlEntities = new Html5Entities();

const useStyles = makeStyles(theme => ({
    header: {
        height: "200px",
        position: "relative",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover'
    },
    container: {
        maxWidth: '1200px',
        margin: '60px auto 60px auto',
        position: "relative",
        zIndex: 1,
        padding: `0 ${theme.spacing(1)}px`
    },
    row: {
        padding: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px 0`
    },
    links: {
        display: "inline-block",
        textDecoration: "none",
        color: theme.palette.grey[800],
        transition: " 0.5s ease",
        marginRight: `${theme.spacing(1)}px`,
        marginBottom: `${theme.spacing(1)}px`,
        '&:hover': {
            color: theme.palette.grey[600],
        }
    },
    logo: {
        width: '110px',
        height: '110px',
        border: '3px solid #f5f5f5',
        borderRadius: '10px',
        backgroundColor: '#fff',
        marginTop: '-130px',
        marginBottom: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0 8px'
    },
    videoContainer: {
        overflow: "hidden",
        position: "relative",
        paddingBottom: '56.25%',
        height: '0',
        '& > iframe' : {
            border: "0",
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%"
        }
    },
    title: {
        width: '100%',
        textAlign: "center",
        fontWeight: 'bold',
        padding: `${theme.spacing(4)}px 0`,
        color: '#fff',
        marginBottom: '0',
        background: "linear-gradient(90deg,rgba(103,55,150,1) 0,rgba(103,55,150,1) 0,rgba(88,163,179,1) 100%)"
    },
    slide: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        minHeight: '360px'
    },
    skills: {
        border: "2px solid #efefef",
        borderRadius: "0 10px 0 10px",
        padding: "2px 10px",
        margin: "0 10px 10px 0",
        fontWeight: "500"
    },
    text: {
        fontSize: '16px'
    },
    col: {
        padding: `0 ${theme.spacing(1)}px`,
    },
    icon: {
        '& img': {
            width: '40px'
        }
    }
}));

const youtube_parser = (url) => {
    var regExp = /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
    var match = url.match(regExp);
    return (match && match[1].length==11)? match[1] : false;
}

const OrganizationItem = (props) => { 
    const classes = useStyles();
    const view = props.organizations.filter(el => el.nid == props.match.params.id)[0];
    const benefits = view.field_benefits_name && view.field_benefits_name.split(';');

    if (benefits) {
        benefits.sort((firstEl, secondEl) => { 
            if(firstEl.localeCompare(secondEl) < 0)
                return -1;
            else if(firstEl.localeCompare(secondEl) > 0)
                return 1;
            else if(firstEl.localeCompare(secondEl) == 0)
                return 0;
        })
    }

    const benefitsImages = view.field_benefits_image ? view.field_benefits_image.split(', ').map(i => oauthConfig.baseUrl + i) : [];
    const bgPath =  view.field_page_title_background && view.field_page_title_background;
    const logoPath =  view.field_logo && view.field_logo;
    const Img = {
        backgroundImage: `url(${oauthConfig.baseUrl}${ bgPath })`
    }
    const noImg = {
        backgroundColor: "#efefef"
    }
    const cultureImages = view.field_culture_images && view.field_culture_images.split(';');
    const youtubeId = view.field_video && youtube_parser(view.field_video);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        accessibility: false,
        arrows: false,
        autoplay: true
    };
    return (
        <Fragment>
            <div className={classes.header} style={ bgPath ? Img : noImg }></div>
            <Grid 
                container
                className={classes.container}
            >
                <Grid item xs={12} md={3}>
                    <div className={classes.logo}><img src={logoPath ? `${oauthConfig.baseUrl}${logoPath}` : dummyPicture}/></div>
                    <Typography variant="h4" component="h1">
                        {view.field_website_url ? <a className={classes.links} href={view.field_website_url} target="_blank">{view.title}</a> : view.title }
                    </Typography>
                    {view.field_company_size && (
                        <div className={classes.row}>
                            <Typography variant="subtitle2" component="h2">
                                Company Size:
                            </Typography>
                            <div>{view.field_company_size} employees</div>
                        </div>
                    )}
                    {view.field_hiring_location && (
                            <div className={classes.row}>
                                <Typography variant="subtitle2" component="h2">
                                    Hiring Location:
                                </Typography>
                                <div>{htmlEntities.decode(view.field_hiring_location)}</div>
                            </div>
                    )}
                    {view.field_industry && (
                        <div className={classes.row}>
                            <Typography variant="subtitle2" component="h2">
                                Industry:
                            </Typography>
                            <div>{view.field_industry.split(';').map((el) => el).join(", ")}</div>
                        </div>
                    )}
                    {view.field_phone_number && (
                        <Typography variant="subtitle1">
                            <a className={classes.links} target="_blank" href={`tel:${view.field_phone_number}`} ><Box display="flex" alignItems="center"><PhoneIcon style={{ fontSize: 16 }}/>{view.field_phone_number}</Box></a>
                        </Typography>
                    )}
                    <Box display="flex">
                        {view.field_linkedin_profile && (
                            <Typography variant="subtitle1">
                                <a className={classes.links} target="_blank" href={view.field_linkedin_profile} ><LinkedInIcon /></a>
                            </Typography>
                        )}
                        {view.field_facebook_profile && (
                            <Typography variant="subtitle1">
                                <a className={classes.links} target="_blank" href={view.field_facebook_profile} ><FacebookIcon /></a>
                            </Typography>
                        )}
                        {view.field_twitter_profile && (
                            <Typography variant="subtitle1">
                                <a className={classes.links} target="_blank" href={view.field_twitter_profile} ><TwitterIcon /></a>
                            </Typography>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12} md={9}>
                    { view.field_company_description && (
                        <div className={classes.text}>{parse(view.field_company_description)}</div>
                    )}
                    {view.field_what_makes_your_company_un && (
                        <div className={classes.row}>
                            <Typography variant="h6" component="h2">
                                What makes your company unique? 
                            </Typography>
                            <div className={classes.text}>{parse(view.field_what_makes_your_company_un)}</div>
                        </div>
                    )}
                </Grid>        
            </Grid>
                {
                    view.field_technical_skills && (
                        <Fragment>
                            <Typography variant="h5" component="h2" className={classes.title}>
                                Technology at {view.title}
                            </Typography>
                            <Grid container justify="center" alignItems="center" className={classes.container}>
                                {view.field_tech_challenges && <Grid item xs={6} className={classes.col}><div className={classes.text}>{parse(view.field_tech_challenges)}</div></Grid>}
                                <Grid item xs={view.field_tech_challenges ? 6 : 12} className={classes.col}>
                                    <Typography variant="h6" component="h2">
                                        Tech Stack & Skills
                                    </Typography>
                                    <Box display="flex" flexWrap="wrap">
                                        {view.field_technical_skills.split(';').map((el) => {
                                            return <div className={classes.skills} key={el}>{el}</div>
                                        })}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Fragment>
                    )
                }
                {benefits && ( 
                    <Fragment>
                        <Typography variant="h5" component="h2" className={classes.title}>
                            Benefits at {view.title}
                        </Typography>
                        <Grid container justify="center" alignItems="flex-start" className={classes.container}>
                            {benefits.map((el, index) => {
                                return (
                                    <Grid item xs={2} key={el} container direction="column" alignItems="center">
                                        <div className={classes.icon}><img src={benefitsImages[index]}/></div>
                                        <Typography variant="subtitle2" component="h2" align="center">{el}</Typography>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Fragment>
                )}
                {youtubeId && (
                    <Fragment>
                        <Typography variant="h5" component="h2" className={classes.title}>
                            Video
                        </Typography>
                        <Grid container alignItems="center" className={classes.container}>
                            <Grid item xs={view.field_video_text ? 6 : 12 } className={classes.col}>
                                <div className={classes.videoContainer}>
                                    <iframe src={`https://www.youtube.com/embed/${youtubeId}`}></iframe>
                                </div>
                            </Grid>
                            <Grid item xs={6} className={classes.col}>
                                <div className={classes.text}>{view.field_video_text && parse(view.field_video_text)}</div>
                            </Grid>
                        </Grid>
                    </Fragment>
                )}
                {cultureImages && (
                    <Fragment>
                        <Typography variant="h5" component="h2" className={classes.title}>
                            Culture at {view.title}
                        </Typography>
                        <Grid container justify="center" alignItems="center" className={classes.container}>
                            {view.field_culture_description && <Grid item xs={6} className={classes.col}><div className={classes.text}>{parse(view.field_culture_description)}</div></Grid>}
                            <Grid item xs={6} className={classes.col}>
                                <Slider {...settings}>
                                    {cultureImages.map((el, index) => <div key={index}><div className={classes.slide} style={{backgroundImage: `url(${oauthConfig.baseUrl}/${el})`}}></div></div>)}
                                </Slider>
                            </Grid>
                        </Grid>
                    </Fragment>
                )}
        </Fragment>
    )

}

export default OrganizationItem;
