import React, { useState } from 'react';
import { Paper, makeStyles, Avatar, Grid, Typography, Button } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import oauthConfig from '../../oauthConfig';
import { Html5Entities } from 'html-entities';
const htmlEntities = new Html5Entities();

const useStyles = makeStyles(theme => ({
    container: {
        height: "280px",
        position: "relative",
        // padding: `${theme.spacing(1)}px`,
        overflow: 'hidden',
        cursor: 'pointer',
        '&:hover $body' : {
            transform: 'translateY(0) !important'
        }
    },
    content: {
        position: "relative",
        padding: "40px 10px 10px 10px"
    },
    logo: {
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        position: 'absolute',
        top: '-40px',
        left: '10px ',
        width: '70px',
        height: '70px',
        border: '10px solid #fff',
        background: '#fff',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    header: {
        height: "130px",
        position: "relative",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover'
    },
    body: {
        display: 'block',
        position: 'absolute',
        top: '0',
        height: '100%',
        width: '100%',
        transform: 'translateY(100%)',
        transition: 'all .3s ease',
        background: 'linear-gradient(90deg ,rgba(103,55,150,1) 0,rgba(103,55,150,1) 0,rgba(88,163,179,1) 100%)',
        padding: '20px',
        color: '#fff'
    },
    action: {
        color: '#fff',
        borderColor: '#fff',
        position: 'absolute',
        bottom: '20px',
        left: '0',
        right: '0',
        margin: 'auto',
        width: '200px',
        '&:hover' : {
            color: '#fff',
            borderColor: '#fff'
        }
    }
}));

const OrganizationsItem = (props) => { 
    const classes = useStyles();
    const bgPath =  props.data.field_page_title_background  && props.data.field_page_title_background
    const Img = { backgroundImage: `url(${oauthConfig.baseUrl}${ bgPath })`}
    const noImg = { backgroundColor: "#efefef" }
    let logo;
    if(props.data.field_logo){
        logo = <div className={classes.logo} style={{backgroundImage: `url(${oauthConfig.baseUrl}${props.data.field_logo})`}}></div>
    }else{
        logo = <Grid container justify="center" alignItems="center" className={classes.logo}><Avatar> <BusinessIcon /></Avatar></Grid>
    }
    const truncate = (str, max, suffix) => str.length < max ? str : `${str.substring(0, str.substring(0, max - suffix.length).lastIndexOf(' '))}${suffix}`;
    const industry = props.data.field_industry && props.data.field_industry.split(';').map(el => el).join(", ");
    const bodyRaw = props.data.field_company_description && props.data.field_company_description.replace(/<[^>]*>?/gm, '');
    let field_company_description;
    if(bodyRaw){
        field_company_description = htmlEntities.decode(truncate(bodyRaw, 200, "..."))
    }
    
    return (
        <Paper className={classes.container}>
            <div className={classes.header} style={ bgPath ? Img : noImg }></div>
            <div className={classes.content}>
                {logo}
                <Typography variant="h6">{props.data.title}</Typography>
                {industry}
            </div>
            <div className={classes.field_company_description}>
                {field_company_description}
                <Button variant="outlined" className={classes.action}>Read more</Button>
            </div>
        </Paper>
    ) 
}

export default OrganizationsItem;
