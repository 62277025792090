import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ColorForm from './ColorForm';


class Color extends Component {

    state = {
        appBarColor: this.props.currentOrg.field_color_picker ? this.props.currentOrg.field_color_picker : '#ffffff'
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentOrg.nid !== prevProps.currentOrg.nid) {
            const color = this.props.currentOrg.field_color_picker ? this.props.currentOrg.field_color_picker : '#ffffff'
            this.setColor(color)
        }
    }

    setColor = (color) => {
        this.setState({appBarColor: color})
    }

    render(){
        return (
            <ColorForm
                appBarColor= {this.state.appBarColor}
                setColor={this.setColor}
                id={this.props.currentOrg.uuid}
                {...this.props}
            />
        )
    }

}

export default withRouter(Color);