import React , { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import config from '../../../../oauthConfig';
import { withStyles } from '@material-ui/core/styles';
import { Stepper, Step, StepLabel, Container, CircularProgress, Typography } from '@material-ui/core';
import SignUpEmployerStepOne from './SignUpEmployerStepOne';
import SignUpEmployerStepTwo from './SignUpEmployerStepTwo';
import axios from 'axios';

const styles = theme => ({
  wrapper: {
    maxWidth: 600,
    margin: 'auto',
    [theme.breakpoints.up('sm')]: {
      padding: `0 ${theme.spacing(3)}px`,
    }
  },
  stepper: {
    backgroundColor: 'transparent'
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    position: "absolute",
    left: 0,
    top: 0
  },
  stepWrapper: {
    position: "relative",
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(3),
    }
  },
  loader: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  }
});

const defaultUserData = 
{
  type: "node--employer_profile",
  attributes: {},
  relationships: {
    field_profile_picture: {
      data: {}
    }
  } 
}

class HorizontalLabelPositionBelowStepper extends Component {
  state = {
    open: false,
    msg: '',
    navigation: {
      activeStep : 0,
      steps: ['Step 1', 'Step 2']
    },
    initialData: {
      position: this.props.initialData && this.props.initialData.position
    },
    profileImage: this.props.profileImage ? this.props.profileImage : null,
    idForDelete: {},
    userData: this.props.userData ? this.props.userData : {...defaultUserData}
  }

  onUnload = e => {
    e = e || window.event;
    if(!this.props.isEdit){  
      e.preventDefault();
      e.returnValue = '';
    } 
  }

  componentDidMount() {
    if(this.props.isEdit){
      this.setState( prevState => ({
        navigation: {
          ...prevState.navigation,
          activeStep: parseInt(this.props.match.params.id) 
        }
      }));
    }
    window.addEventListener("beforeunload", this.onUnload);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.match.params.id !== this.props.match.params.id && this.props.isEdit) {
      this.setState( prevState => ({
        navigation: {
          ...prevState.navigation,
          activeStep: parseInt(this.props.match.params.id) 
        }
      }));
    }
  }
  
  getStepContent  = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <SignUpEmployerStepOne 
            mergeFormData={this.props.isEdit ? this.mergeAndSubmit : this.mergingStepsData} 
            next={this.props.isEdit ?  this.handleNextId : this.handleNext} 
            defaultVal={this.state.userData}
            profileImage={this.state.profileImage}
            handleProfilePreview= {this.handleProfilePreview}
            createImageEntity={this.createImageEntity}
            deleteImageEntity={this.deleteImmediately}
            isEdit={this.props.isEdit}
          />
        )
      case 1:
        return (
          <SignUpEmployerStepTwo 
            mergeFormData={this.props.isEdit ? this.mergeAndSubmit : this.mergingStepsData} 
            handleBack={this.props.isEdit ?  this.handleBackId : this.handleBack} 
            deleteBeforeSubmit={this.deleteBeforeSubmit}
            positionData = {this.state.initialData.position}
            defaultVal={this.state.userData}
            isEdit={this.props.isEdit}
            token={this.props.token}
            uuid={this.props.uuid}
            refreshToken={this.props.refreshToken}
          />
        )
      default:
        return 'Uknown stepIndex';
    }
  }
  
  mergingStepsData = (dataType, newData, step) => {
    this.setState( prevState => ({
        userData: {
          ...prevState.userData,
          [dataType]: {
            ...prevState.userData[dataType],
            ...newData
          }
        }
    }));
  }

  mergeAndSubmit = (dataType, newData, step) => {
    const newState = {
      ...this.state,
      userData: {
        ...this.state.userData,
        [dataType]: {
          ...this.state.userData[dataType],
          ...newData
        }
      }
    }
    this.setState(newState);
    this.submit(newState.userData)
  }

  submit = (userData, haveFile) => {
    this.setState({open: true, msg:"Saving"})
    let profileURL = userData.links.self.href.replace(/\?.*$/g,"");
    let token = this.props.token;
    const headers = {
      headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'Authorization': `${token.token_type} ${token.access_token}`
      }
    }
    delete userData.attributes.revision_timestamp
    delete userData.attributes.changed
    const data = {data: {...userData}}
    axios.patch(profileURL, data, headers)
    .then(() => {
      this.submitMsg()
      this.props.fetchProfileData()
    })
    .catch(err => {
      console.log(err)
    });
  }

  handleNext = () => {
    this.setState( prevState => ({
      navigation: {
        ...prevState.navigation,
        activeStep: prevState.navigation.activeStep + 1
      }
    }));
  };

  handleNextId = () => {
    this.props.history.push(`/profile/step/${this.state.navigation.activeStep + 1}`);
    this.setState( prevState => ({
      navigation: {
        ...prevState.navigation,
        activeStep: parseInt(this.props.match.params.id) + 1
      }
    }));
  };

  handleBack = () => {
    this.setState( prevState => ({
      navigation: {
        ...prevState.navigation,
        activeStep: prevState.navigation.activeStep - 1
      }
    }));
  };

  handleBackId = () => {
    this.props.history.push(`/profile/step/${this.state.navigation.activeStep - 1}`);
    this.setState( prevState => ({
      navigation: {
        ...prevState.navigation,
        activeStep: parseInt(this.props.match.params.id) - 1
      }
    }));
  };

  handleReset = () => {
    this.setState({
      navigation: {
        activeStep: 0
      }
    })
  };

  
  handleProfilePreview = (preview) => {
    this.setState({
      profileImage: preview
    })
  }

  createImageEntity = (file, field) => {
    this.handleProfilePreview("loading")
    var imagefile = file;
    let token = this.props.token;
    fetch(`${config.baseUrl}/api/node/employer_profile/${field}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/octet-stream',
        'Accept': 'application/vnd.api+json',
        'Content-Disposition': `file; filename="${imagefile.name}"`,
        'Authorization': `${token.token_type} ${token.access_token}`
      },
      body: imagefile
      }).then(
        response => response.json()
      ).then(success => {
          this.handleProfilePreview(success.data.attributes.uri.url)
          let selectedID = {type: success.data.type, id:success.data.id}
          const data = {[field]: {data: selectedID}}
          this.props.isEdit ? this.mergeAndSubmit('relationships', data) : this.mergingStepsData('relationships', data);
        }
      ).catch(
        error => console.log(error,'error')
    );
  }

  deleteImmediately = (field) => {
    field === "field_curriculum_vitae" ? this.handlePdfPreview("loading") : this.handleProfilePreview("loading");
    let token = this.props.token;
    const headers = {
      headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'Authorization': `${token.token_type} ${token.access_token}`
      }
    }
    const id = this.state.userData.relationships[field].data.id
    axios.delete(`${config.baseUrl}/api/file/file/${id}`, headers)
    .then(res => {
      field === "field_curriculum_vitae" ? this.handlePdfPreview(null) : this.handleProfilePreview(null);
      const data = {[field]: {data: null}}
      this.props.isEdit ? this.mergeAndSubmit('relationships', data) : this.mergingStepsData('relationships', data);
    })
  }

  submitMsg = () => {
    this.setState({msg:"Profile updated!"})
    setTimeout(
      () => this.setState({open:false , msg:""}), 
      1000
    );
  }

  render(){
    const { classes, isEdit } = this.props;
    return (
      <div className={classes.wrapper}>
          <div className={classes.root}>
            {!isEdit && (
              <Stepper activeStep={this.state.navigation.activeStep} className={classes.stepper}>
               {this.state.navigation.steps.map(label => (
                 <Step key={label}>
                   <StepLabel></StepLabel>
                 </Step>
               ))}
              </Stepper> 
            )}
            { this.props.initialData ? (
                <div className={classes.stepWrapper}>
                  {this.state.open && <div className={classes.instructions}>{this.state.msg} {this.state.msg === "Saving" && <CircularProgress color="secondary" size={15}/>}</div>}
                  {this.getStepContent(isEdit ? parseInt(this.props.match.params.id) : this.state.navigation.activeStep)}
                </div>
              ) : (
                <div className={classes.loader}><CircularProgress color="secondary" /></div>
            )}
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(HorizontalLabelPositionBelowStepper)); 
