import React , { Component } from 'react';
import { withStyles, Modal, Button, IconButton, Grid, Typography, Box, Hidden } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Popover from '../Popover/Popover';
import SelectCheckbox from '../SelectCheckbox/SelectCheckbox';
import CheckboxFilter from '../CheckboxFilter/CheckboxFilter';
import RadioFilter from '../RadioFilter/RadioFilter';
import SearchLocation from '../SearchLocation/SearchLocation';

const styles = theme => ({
    modal: {
        '&:focus':{
            outline: "none" 
        }
    },
    modalContent: {
        padding: `${theme.spacing(2)}px`,
        backgroundColor: '#ffffff',
        height: "100%",
        overflowY: "scroll",
        '&:focus':{
            outline: "none"
        }
    },
    filter: {
        padding: `${theme.spacing(2)}px 0`,
        width: '100%',
        overflowX: 'auto'
    },
    allFilterContainer: {
        width: '100%',
        maxWidth: '920px',
        margin: 'auto',
        paddingTop: `70px`,
    },
    filterHeader: {
        top: 0,
        left: 0,
        position: 'absolute',
        width: '100%',
        background: '#ffff',
        zIndex: 2,
        padding:  `${theme.spacing(2)}px 0`,
        boxShadow: '0px 6px 9px 0px rgb(163 163 163 / 75%)',
        position: 'absolute'
    },
    close: {
        position: 'absolute',
        top: 10,
        right: 30
    },
    allBtn: {
        width: '100px'
    },
    allBtnMobile: {
        margin: '20px 0'
    }
});

const datePosted = [
    { label: "Past 24 hours", value: "&created=-24 hours"},
    { label: "Past Week", value: "&created=-1 week"},
    { label: "Past Month", value: "&created=-1 month"},
    { label: "Any Time", value: ""} 
]

const predefinedSelectCheckbox = (predefinedVals, allList, checkedProps, selectedProps, callBack) => {
    if(predefinedVals && selectedProps !== "location"){
        const preDefined = predefinedVals.split(" ")
        const result = allList.filter(el => preDefined.indexOf(el.value) >= 0);
        callBack(result, checkedProps, selectedProps)
    }else if(predefinedVals && selectedProps == "location"){
        const preDefined = predefinedVals.split(" ")
        const result = preDefined.map(el => {
            const replace = el.replaceAll("--", ", ").replaceAll( "-", " ").replaceAll("--", " - " )
            return {label: replace, value: replace}
        })
        callBack(result, checkedProps, selectedProps)
    }
}

const predefinedCheckbox = (predefinedVals, allList, checkedProps, callBack) => {
    if(predefinedVals){
        const preDefined = predefinedVals.split(" ")
        const result = allList.filter(el => preDefined.indexOf(el.value) >= 0);
        result.forEach(el => {
            callBack(el.value, true, checkedProps)
        })
        
    }
}

const predefinedRadio = (predefinedVals, serachProp, property, callBack) => {
    if(predefinedVals){
        const preDefined = serachProp + predefinedVals
        callBack(preDefined, property)
    }
}

const defaultState = (initialData, companies) => {
   //Experience
   const experienceRaw = {...initialData.experience}
   const experienceList = []
   for (const property in experienceRaw) {
       experienceList.push({label: experienceRaw[property] , value: property})
   }
   const checkedExperience = {}
   experienceList.forEach(el => {
       Object.assign(checkedExperience, {[el.value]: false});
   });

   //Type
   const type = {...initialData.type}
   const employmentType = []
   for (const property in type) {
       employmentType.push({label: type[property] , value: property})
   }
   const checkedType = {}
   employmentType.forEach(el => {
       Object.assign(checkedType, {[el.value]: false});
   });
   //Remote
   const remoteRaw = {...initialData.remote}
   const remoteList = []
   for (const property in remoteRaw) {
       remoteList.push({label: remoteRaw[property] , value: property})
   }
   const checkedRemote = {}
   remoteList.forEach(el => {
       Object.assign(checkedRemote, {[el.value]: false});
   });

   //Companies
   const companiesList = companies.map(el => (
       {
           label: el.title,
           value: `${el.nid}`
       }
   ))

   //Skills
   const skillsList = initialData.techSkills.map(el => (
       {
           label: el.label,
           value: `${el.tid}`
       }
   ))
   //country
   const countriesList = initialData.country.map(el => (
       {
           label: el.label, 
           value: `${el.tid}`
       }
   ))
    
    return {
        open: false,
        companies: null,
        skills: null,
        location: [],
        countries: null,
        employmentType: null, 
        companiesList,
        skillsList,
        countriesList,
        employmentType,
        remoteList,
        experienceList, 
        checked: {
            checkedType,
            checkedRemote,
            checkedExperience,
            checkedCompany: {},
            checkedSkills: {},
            checkedLocation: {},
            checkedCountries: {},
        },
        radio: {
            checkedDate: {
                checkedDate: "",
                checkedDateDefault: ""
            }
        }
    }
}

class CandidateJobFilter extends Component {
    state = defaultState(this.props.initialData, this.props.companies);

    componentDidMount(){
        const hiring_organization = this.props.predefinedVals['hiring_organization'];
        const skills = this.props.predefinedVals['skills'];
        const country = this.props.predefinedVals['country'];
        const location = this.props.predefinedVals['location'];
        const employment_type = this.props.predefinedVals['employment_type']
        const experience = this.props.predefinedVals['experience']
        const remote = this.props.predefinedVals['remote']
        const created = this.props.predefinedVals['created']
        //Predefined SelectCheckBox
        predefinedSelectCheckbox(hiring_organization, this.state.companiesList, "checkedCompany", "companies", this.handleSelect)
        predefinedSelectCheckbox(skills, this.state.skillsList, "checkedSkills", "skills", this.handleSelect)
        predefinedSelectCheckbox(country, this.state.countriesList, "checkedCountries", "countries", this.handleSelect)
        predefinedSelectCheckbox(location, null, "checkedLocation", "location", this.handleSelect)
        //Predefined CheckBox
        predefinedCheckbox(employment_type, this.state.employmentType, "checkedType", this.handleChange)
        predefinedCheckbox(experience, this.state.experienceList, "checkedExperience", this.handleChange)
        predefinedCheckbox(remote, this.state.remoteList, "checkedRemote", this.handleChange)
        //Predefined Radio
        predefinedRadio(created, '&created=', "checkedDate", this.handleChangeRadio)
    }

    handleOpen = () => {
        this.setState({
            open: true
        })
    };

    handleClose = () => {
        this.setState({
            open: false
        })
    };

    handleSelect = (selectedOption, checkedObj, statePropName) => {
        let obj = {}
        let elVal;
        selectedOption.forEach(el => {
            if(this.state.checked[checkedObj][el.value] === undefined || this.state.checked[checkedObj][el.value] === false){ 
                elVal = el.value
                Object.assign(obj, {[el.value]: true});
            }
        });
        
        //If value already exist
        if(Object.keys(obj).length === 0){
            return
        }
        
        if(this.state.checked[checkedObj][elVal] === undefined || this.state.checked[checkedObj][elVal] === false){
            this.setState(prevState => ({
                checked: {
                    ...prevState.checked,
                    [checkedObj]: {
                        ...prevState.checked[checkedObj],
                        ...obj
                    } 
                }
            }))
        }
        
        this.setState({
            [statePropName]: selectedOption
        })
    }

    handleChange = (name, checked, checkedObj) => {
        this.setState(prevState => ({
            checked:{
                ...prevState.checked,
                [checkedObj]: {
                    ...prevState.checked[checkedObj],
                    [name]: checked 
                }
            }
        }))
    }

    handleChangeRadio = (value, property) => {
        this.setState(prevState => ({
            radio: {
                [property]: {
                    ...prevState.radio[property],
                    [property] : value
                }
            }
        }))
    }

    handleSingleApply = (type, queryString) => { 
        const checkedObj = {...this.state.checked[type]}
        const arrOfKeys = Object.keys(checkedObj)
        if (arrOfKeys.length === 0){
            return;
        }
        const queryArr = [];
        for (const property in checkedObj) {
            if(checkedObj[property]){
                queryArr.push(property)
            } 
        }
        if(queryString !== "&location="){
            this.props.handleSearch(`${queryString}${queryArr.join("+")}`, 0)
        }else if(queryString === "&location="){
            const replaced = queryArr.map(el => {
                return el.replaceAll(", ", "--").replaceAll(" - ", "--").replaceAll(" ", "-")
            })
            this.props.handleSearch(`${queryString}${replaced.join("+")}`, 0)
        }
    }

    handleApplyRadio = () => {
        const radioObj = {...this.state.radio}
        let date = radioObj.checkedDate.checkedDate;
        this.props.handleSearch(date, 0)
    }

    handleApplyAll = () => {
        //Merge & Apply Checkboxes
        const checkedObj = {...this.state.checked}
        let innerObj;
        let arr = [];
        let queryString = "";
        for (const checkedObjProp in checkedObj) {
            innerObj = checkedObj[checkedObjProp]
            let innerArr = []
            for(const innerObjProp in innerObj){
                if(innerObj[innerObjProp]){
                    innerArr.push(innerObjProp)
                }
            }
            if(checkedObjProp === "checkedType"){
                if(innerArr.length > 0){
                    queryString = "&employment_type="
                    arr.push([`${queryString}${innerArr.join('+')}`])
                }
            }
            if(checkedObjProp === "checkedRemote"){
                if(innerArr.length > 0){
                    queryString = "&remote="
                    arr.push([`${queryString}${innerArr.join('+')}`])
                }
            }
            if(checkedObjProp === "checkedCompany"){
                if(innerArr.length > 0){
                    queryString = "&hiring_organization="
                    arr.push([`${queryString}${innerArr.join('+')}`])
                }
            }
            if(checkedObjProp === "checkedExperience"){
                if(innerArr.length > 0){
                    queryString = "&experience="
                    arr.push([`${queryString}${innerArr.join('+')}`])
                }
            }
            if(checkedObjProp === "checkedSkills"){
                if(innerArr.length > 0){
                    queryString = "&skills="
                    arr.push([`${queryString}${innerArr.join('+')}`])
                }
            }
            if(checkedObjProp === "checkedLocation"){
                if(innerArr.length > 0){
                    queryString = "&location="
                    const replaced = innerArr.map(el => {
                        return el.replace(", ", "--").replace(" ", "-")
                    })
                    arr.push([`${queryString}${replaced.join('+')}`])
                }
            }
            if(checkedObjProp === "checkedCountries"){
                if(innerArr.length > 0){
                    queryString = "&country="
                    arr.push([`${queryString}${innerArr.join('+')}`])
                }
            }
            
        }
        //Apply Radio
        const radioObj = {...this.state.radio}
        let date = radioObj.checkedDate.checkedDate;
        arr.push([date])
        this.props.handleSearch(arr.join(""), 0, true, "allFilter")
        this.handleClose()
    }

    handleClear = (checkedObj, defaultVal, clearContent, query) => {
        if(this.props.query === null){
            return
        } 
        const queryArr = this.props.query.split("&");
        const remove = queryArr.filter(el =>  (!`&${el}`.includes(query)));
        const mergeArr = remove.join('&');
        let checkedVals = {...this.state.checked[checkedObj]}
        for (const property in checkedVals) {
            checkedVals[property] = false 
        }
        
        this.setState(prevState => ({
            [clearContent]: [],
            checked: {
                ...prevState.checked,
                [checkedObj]: checkedVals
            } 
        }))
        this.props.handleSearch(mergeArr, this.props.page, true)
    }

    handleClearRadio = (property , defaultVal) => {
        let mergeArr;
        const checkedVal = this.state.radio[property][property];
        let queryArr = this.props.query.split("&");
        mergeArr = queryArr.filter(el =>  (!`&${el}`.includes(checkedVal))).join('&');
        
        this.setState(prevState => ({
            radio: {
                ...prevState.radio,
                [property]: {
                    ...prevState.radio[property],
                    [property] : defaultVal
                }
            }
        }))
        this.props.handleSearch(mergeArr, this.props.page, true)
    }

    handleClearAll = () => {
        localStorage.removeItem('jobFilterQuery');
        this.setState(defaultState(this.props.initialData, this.props.companies))
        this.props.handleSearch("", 0, true)
    }

    findActive = (checkedFilters) => {
        if(checkedFilters === ""){
            return false
        }
        for (const item in checkedFilters) {
            if(checkedFilters[item]){
                return true
            }
        }
    }
    render(){
        const { classes } = this.props;
        return (
            <div>
                <Hidden xsDown>
                    <Grid container wrap="nowrap" spacing={1} className={classes.filter}>
                        <Grid item>
                            <Popover 
                                id={"Company"} 
                                type="checkedCompany" 
                                query={"&hiring_organization="}
                                clearContent = {"companies"} 
                                handleSingleApply={this.handleSingleApply} 
                                handleClear={this.handleClear}
                                active={this.findActive(this.state.checked.checkedCompany)}
                            >
                                <SelectCheckbox 
                                    checked="checkedCompany"
                                    statePropName="companies"
                                    placeholder="Add Company"
                                    values = {this.state.companies}
                                    selectOptions = {this.state.companiesList}
                                    checkedObj = {this.state.checked.checkedCompany} 
                                    handleSelect={this.handleSelect}
                                    handleChange={this.handleChange}
                                    handleClear={this.handleClear}
                                />
                            </Popover>
                        </Grid>
                        <Grid item>
                            <Popover 
                                id={"Skills"} 
                                type="checkedSkills" 
                                query={"&skills="}
                                clearContent = {"skills"}
                                handleSingleApply={this.handleSingleApply} 
                                handleClear={this.handleClear}
                                active={this.findActive(this.state.checked.checkedSkills)}
                            >
                                <SelectCheckbox 
                                    checked="checkedSkills"
                                    statePropName="skills"
                                    placeholder="Add Skills"
                                    values = {this.state.skills}
                                    selectOptions = {this.state.skillsList}
                                    checkedObj = {this.state.checked.checkedSkills} 
                                    handleSelect={this.handleSelect}
                                    handleChange={this.handleChange}
                                    handleClear={this.handleClear}
                                />
                            </Popover>
                        </Grid>
                        <Grid item>
                            <Popover 
                                    id={"Countries"} 
                                    type="checkedCountries" 
                                    query={"&country="}
                                    clearContent = {"countries"} 
                                    handleSingleApply={this.handleSingleApply} 
                                    handleClear={this.handleClear}
                                    active={this.findActive(this.state.checked.checkedCountries)}
                            >
                                <SelectCheckbox 
                                    checked="checkedCountries"
                                    statePropName="countries"
                                    placeholder="Add Countries"
                                    values = {this.state.countries}
                                    selectOptions = {this.state.countriesList}
                                    checkedObj = {this.state.checked.checkedCountries} 
                                    handleSelect={this.handleSelect}
                                    handleChange={this.handleChange}
                                    handleClear={this.handleClear}
                                />
                            </Popover>
                        </Grid>
                        <Grid item>
                            <Popover 
                                id={"Location"} 
                                type="checkedLocation" 
                                query={"&location="}
                                clearContent = {"location"} 
                                handleSingleApply={this.handleSingleApply} 
                                handleClear={this.handleClear}
                                active={this.findActive(this.state.checked.checkedLocation)}
                            >
                                <SearchLocation
                                    statePropName="location"
                                    checked="checkedLocation"
                                    values = {this.state.location}
                                    checkedObj = {this.state.checked.checkedLocation}
                                    handleSelect={this.handleSelect}
                                    handleChange={this.handleChange}
                                />
                            </Popover>
                        </Grid>
                        <Grid item>
                            <Popover 
                                id={"Employment"}
                                type="checkedType" 
                                query={"&employment_type="}
                                handleSingleApply={this.handleSingleApply} 
                                handleClear={this.handleClear}
                                active={this.findActive(this.state.checked.checkedType)}
                            >
                                <CheckboxFilter 
                                    checked="checkedType"
                                    values={this.state.employmentType}
                                    checkedObj = {this.state.checked.checkedType} 
                                    handleSelect={this.handleSelect}
                                    handleChange={this.handleChange} 
                                    handleClear={this.handleClear} 
                                />
                            </Popover>
                        </Grid>
                        <Grid item >
                            <Popover 
                                id={"Remote"}
                                type="checkedRemote" 
                                query={"&remote="}
                                handleSingleApply={this.handleSingleApply} 
                                handleClear={this.handleClear}
                                active={this.findActive(this.state.checked.checkedRemote)}
                            >
                                <CheckboxFilter 
                                    checked="checkedRemote"
                                    values={this.state.remoteList}
                                    checkedObj = {this.state.checked.checkedRemote}
                                    handleSelect={this.handleSelect}
                                    handleChange={this.handleChange} 
                                    handleClear={this.handleClear}  
                                />
                            </Popover>
                        </Grid>
                        <Grid item>
                            <Popover 
                                id={"Date"}
                                type="checkedDate"
                                default={this.state.radio.checkedDate.checkedDateDefault}
                                handleSingleApply={this.handleApplyRadio}
                                handleClear={this.handleClearRadio}
                                active={this.findActive(this.state.radio.checkedDate.checkedDate)}
                            >
                                <RadioFilter 
                                    values={datePosted}
                                    checked="checkedDate"
                                    value={this.state.radio.checkedDate.checkedDate}
                                    handleChangeRadio={this.handleChangeRadio}
                                />
                            </Popover>
                        </Grid>
                        <Grid item>
                            <Button  className={classes.allBtn} onClick={this.handleOpen}> 
                                All Filters
                            </Button>
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden smUp>
                    <Button className={classes.allBtnMobile} onClick={this.handleOpen} variant="contained" color="primary" fullWidth> 
                        All Filters
                    </Button>
                </Hidden>
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="caption" className={classes.result}>
                        {this.props.totalJobs} results found 
                    </Typography>
                    {this.props.query && <Button  onClick={()=>{ this.handleClearAll()}}>Reset filters </Button>}
                </Box>
                <Modal
                    open={this.state.open}
                    onClose={this.handleClose}
                    className={classes.modal}
                >
                    <div className={classes.modalContent}>
                        <div className={classes.filterHeader}>
                            <Grid container justify="center" spacing={2}>
                                <Grid item>
                                    <Button variant="contained" color="primary" onClick={this.handleApplyAll}>
                                        Apply
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={this.handleClearAll}>
                                        Clear
                                    </Button>
                                </Grid>
                            </Grid>
                            <IconButton  className={classes.close} onClick={this.handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <Grid container spacing={2} className={classes.allFilterContainer}>
                            <Grid item xs={12} md={3}>
                            <SearchLocation
                                title="Location"
                                statePropName="location"
                                checked="checkedLocation"
                                stateArr = {this.state.location}
                                values = {this.state.location}
                                checkedObj = {this.state.checked.checkedLocation}
                                handleSelect={this.handleSelect}
                                handleChange={this.handleChange}
                            />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <SelectCheckbox
                                    title="Company"
                                    checked="checkedCompany"
                                    statePropName="companies"
                                    placeholder="Add Company"
                                    values = {this.state.companies}
                                    selectOptions = {this.state.companiesList}
                                    checkedObj = {this.state.checked.checkedCompany} 
                                    handleSelect={this.handleSelect}
                                    handleChange={this.handleChange}
                                    handleClear={this.handleClear}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <SelectCheckbox
                                    title="Skills" 
                                    checked="checkedSkills"
                                    statePropName="skills"
                                    placeholder="Add Skills"
                                    values = {this.state.skills}
                                    selectOptions = {this.state.skillsList}
                                    checkedObj = {this.state.checked.checkedSkills} 
                                    handleSelect={this.handleSelect}
                                    handleChange={this.handleChange}
                                    handleClear={this.handleClear}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <SelectCheckbox
                                    title="Countries" 
                                    checked="checkedCountries"
                                    statePropName="countries"
                                    placeholder="Add Countries"
                                    values = {this.state.countries}
                                    selectOptions = {this.state.countriesList}
                                    checkedObj = {this.state.checked.checkedCountries} 
                                    handleSelect={this.handleSelect}
                                    handleChange={this.handleChange}
                                    handleClear={this.handleClear}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <RadioFilter
                                    title="Date Posted" 
                                    values={datePosted}
                                    checked="checkedDate"
                                    value={this.state.radio.checkedDate.checkedDate}
                                    handleChangeRadio={this.handleChangeRadio}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <CheckboxFilter
                                    title="Remote" 
                                    checked="checkedRemote"
                                    values={this.state.remoteList}
                                    checkedObj = {this.state.checked.checkedRemote}
                                    handleChange={this.handleChange} 
                                    handleClear={this.handleClear} 
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <CheckboxFilter
                                    title="Employment type" 
                                    checked="checkedType"
                                    values={this.state.employmentType}
                                    checkedObj = {this.state.checked.checkedType}
                                    handleChange={this.handleChange} 
                                    handleClear={this.handleClear} 
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <CheckboxFilter
                                    title="Experience" 
                                    checked="checkedExperience"
                                    values={this.state.experienceList}
                                    checkedObj = {this.state.checked.checkedExperience}
                                    handleChange={this.handleChange} 
                                    handleClear={this.handleClear} 
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Modal>
                
            </div>
        )
    }
}

export default withStyles(styles)(CandidateJobFilter);