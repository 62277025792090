import React from "react";
import { Redirect } from 'react-router';
import  {Tab, Tabs } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Greenhouse from './Integrations/Greenhouse';
import Color from './Color/Color';
import Access from './Access/Access';

function TabContainer(props) {
  return (
    <div>
      {props.children}
    </div>
  );
}

const styles = theme => ({
  root: {
    maxWidth: '700px',
    margin:`${theme.spacing(4)}px auto 0 auto`,
    padding: `0 ${theme.spacing(1)}px`
  },
  tabs: {
    marginBottom: theme.spacing(4),
  },
  linkText: {
   textAlign: 'center',
   marginTop: theme.spacing(2),
   color: theme.palette.grey[800]
  },
  link: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold'
  }
});

class ScrollableTabsButtonAuto extends React.Component {
  state = {
    value: 0
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };
  
  render() {
    const { classes, organizations, token, fetchOrg, refreshToken, currentUser, theme } = this.props;
    const currentOrg = organizations.filter(el => el.nid == this.props.match.params.id)[0];
    const { value } = this.state;
    const positionData  = this.props.initialData.position;
    const isMobile = document.body.clientWidth < theme.breakpoints.values.sm;
    if(!currentOrg){
      return <Redirect to='/organization/all'/>
    }
    return (
        <div className={classes.root}>
            <Tabs
                className={classes.tabs}
                value={value}
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
                scrollButtons="auto"
                variant={isMobile ? "scrollable" : 'fullWidth'}
                centered={isMobile ? false : true}
            >
                <Tab label="Users & Access" component="div" style={{width: '160px'}}/>
                <Tab label="Integrations" component="div" style={{width: '160px'}}/>
                <Tab label="Color settings" component="div"/>
            </Tabs>
            {value === 0 && <TabContainer><Access currentOrg={currentOrg} currentUser={currentUser} positionData={positionData} token={token} fetchOrg={fetchOrg} refreshToken={refreshToken}/></TabContainer>}
            {value === 1 && <TabContainer><Greenhouse currentOrg={currentOrg.nid} token={token}/></TabContainer>}
            {value === 2 && <TabContainer><Color currentOrg={currentOrg} token={token} fetchOrg={fetchOrg}/></TabContainer>}
        </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ScrollableTabsButtonAuto);
