import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Layout from "../../Layout/Layout";
import Dashboard from "../../Dashboard/Dashboard";
import Jobs from "../../Jobs/Jobs";
import EditProfile from "../../Settings/Profile/EditProfile";
import Organization from "../../Organization/Organization";
import Candidates from "../../Candidates/Candidates";
import Settings from "../../Settings/MenuSettings/Settings";
import Loader from "../../Utilities/Loader/Loader";
import ResetPassword from "../../Settings/Profile/ResetPassword/ResetPassword";
import ChangePassword from "../../Settings/Profile/ChangePassword/ChangePassword";
import Confirmation from "../../Confirmation/Confirmation";
import Profile from "../../Profile/Employer/EmployerProfile";
import oauthConfig from "../../../oauthConfig";
import axios from "axios";

class Employer extends Component {
  state = {
    storageData: localStorage.getItem("userOrgData") ? JSON.parse(localStorage.getItem("userOrgData")) : null,
    logo: localStorage.getItem("userOrgData")
      ? JSON.parse(localStorage.getItem("userOrgData"))[this.props.uuid]
        ? JSON.parse(localStorage.getItem("userOrgData"))[this.props.uuid].organizationLogo
        : null
      : null,
    orgID: localStorage.getItem("userOrgData")
      ? JSON.parse(localStorage.getItem("userOrgData"))[this.props.uuid]
        ? JSON.parse(localStorage.getItem("userOrgData"))[this.props.uuid].organization
        : null
      : null,
    appBarColor: localStorage.getItem("userOrgData")
      ? JSON.parse(localStorage.getItem("userOrgData"))[this.props.uuid]
        ? JSON.parse(localStorage.getItem("userOrgData"))[this.props.uuid].appBarColor
        : "#ffffff"
      : "#ffffff",
    nid: localStorage.getItem("userOrgData") ? (JSON.parse(localStorage.getItem("userOrgData"))[this.props.uuid] ? JSON.parse(localStorage.getItem("userOrgData"))[this.props.uuid].nid : null) : null,
    organizations: null,
    userData: null,
  };

  componentDidMount() {
    this.handleGetOrganizations();
    this.fetchProfileData();
  }

  setHeaders = () => {
    if (!this.props.token) {
      this.props.logout();
      return;
    }
    const token = this.props.token;

    const headers = {
      headers: {
        Accept: "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
        Authorization: `${token.token_type} ${token.access_token}`,
      },
    };

    return headers;
  };

  setLogo = (path) => {
    this.setState({ logo: path });
  };

  setOrgActive = (id) => {
    this.setState({ orgID: id });
    this.getOrganizations();
  };

  setAppBarColor = (color) => {
    this.setState({ appBarColor: color });
  };

  setOrgNid = (nid) => {
    this.setState({ nid: nid });
  };

  handleGetOrganizations = (editId) => {
    this.getOrganizations().catch((err) => {
      this.props.logout();
    });
  };

  fetchProfileData = async () => {
    const headers = this.setHeaders();
    const result = await axios(`${oauthConfig.baseUrl}/api/node/employer_profile?filter[uid.id][value]=${this.props.uuid}&include=field_profile_picture`, headers);
    let includesObj = {};
    let technicalArr = [];
    let personalArr = [];
    if (result.data.included) {
      result.data.included.forEach((el) => {
        if (el.type === "taxonomy_term--country") {
          includesObj["country"] = el.attributes.name;
        }
        if (el.type === "file--file") {
          if (el.attributes.filemime === "application/pdf") {
            includesObj["pdf"] = el.attributes.uri.url;
          } else {
            includesObj["picture"] = el.attributes.uri.url;
          }
        }
        if (el.type === "taxonomy_term--personal_skills") {
          personalArr.push(el.attributes.name);
          includesObj["personal"] = personalArr;
        }
        if (el.type === "taxonomy_term--technical_skills") {
          technicalArr.push(el.attributes.name);
          includesObj["technical"] = technicalArr;
        }
      });
    }
    this.setState({
      userData: {
        attributes: { ...result.data.data[0].attributes },
        includes: { ...includesObj },
      },
    });
  };

  getOrganizations = async () => {
    this.setState({ organizations: null });

    const headers = this.setHeaders();
    const currentUserOrganization = await axios.get(`${oauthConfig.baseUrl}/api/organization/get_organizations_for_current_user`, headers);
    //Set organizations alphabetically
    if (currentUserOrganization.data.length > 0) {
      currentUserOrganization.data.sort((firstEl, secondEl) => {
        if (firstEl.title.localeCompare(secondEl.title) < 0) return -1;
        else if (firstEl.title.localeCompare(secondEl.title) > 0) return 1;
        else if (firstEl.title.localeCompare(secondEl.title) == 0) return 0;
      });
      //Set active first
      const index = currentUserOrganization.data.findIndex((el) => el.uuid === this.state.orgID);
      currentUserOrganization.data.unshift(currentUserOrganization.data.splice(index, 1)[0]);
    }
    //Define user org object with key uuid
    const userOrgData = {
      [this.props.uuid]: {},
    };

    //If no organizatiom
    if (currentUserOrganization.data.length === 0) {
      this.setState({ orgID: null });
      this.setAppBarColor("#ffffff");
      this.setLogo(null);
      this.setState({ organizations: [] });
    }

    const setDefault = () => {
      //Set first as deafault
      const nid = currentUserOrganization.data[0].nid;
      const color = currentUserOrganization.data[0].field_color_picker ? currentUserOrganization.data[0].field_color_picker : "#ffffff";

      this.setState({
        orgID: currentUserOrganization.data[0].uuid,
        appBarColor: color,
        nid: nid,
      });

      userOrgData[this.props.uuid].organization = currentUserOrganization.data[0].uuid;
      userOrgData[this.props.uuid].appBarColor = color;
      userOrgData[this.props.uuid].nid = nid;
      // Check for logo path
      if (currentUserOrganization.data[0].field_logo) {
        let path = currentUserOrganization.data[0].field_logo;
        this.setLogo(path);
        userOrgData[this.props.uuid].organizationLogo = path;
      } else {
        this.setLogo(null);
        userOrgData[this.props.uuid].organizationLogo = null;
      }
    };

    // If single org
    if (currentUserOrganization.data.length === 1) {
      //Set first as deafault
      setDefault();
      //Check if they are any includes
      this.setState({ organizations: currentUserOrganization.data });

      //Add User Org Data to local storage
      const storageOrgData = JSON.parse(localStorage.getItem("userOrgData"));
      const mergeStorage = { ...storageOrgData, ...userOrgData };
      localStorage.setItem("userOrgData", JSON.stringify(mergeStorage));
    }

    // If they are more than one Org and there is not include property
    if (currentUserOrganization.data.length > 1) {
      const items = [...currentUserOrganization.data];
      this.setLogo(null);

      // if there is selected Org
      if (this.state.orgID) {
        const current = items.find((el) => {
          return el.uuid === this.state.orgID;
        });
        if (current.field_color_picker) {
          const storageOrgData = { ...JSON.parse(localStorage.getItem("userOrgData")) };
          storageOrgData[this.props.uuid].appBarColor = current.field_color_picker;
          localStorage.setItem("userOrgData", JSON.stringify(storageOrgData));
          this.setState({ appBarColor: current.field_color_picker });
        }

        const storageOrgData = { ...JSON.parse(localStorage.getItem("userOrgData")) };
        storageOrgData[this.props.uuid].nid = current.nid;
        localStorage.setItem("userOrgData", JSON.stringify(storageOrgData));
        this.setState({ nid: current.nid });
      }

      this.setState({ organizations: items });
    }
  };

  render() {
    let candidateDB;
    if (this.state.organizations && this.state.organizations.length > 0 && this.state.orgID) {
      const currentOrg = this.state.organizations.find((el) => el.uuid === this.state.orgID);
      const crrentTime = Math.floor(Date.now() / 1000);
      const currentOrgTime = currentOrg.field_candidate_database_date_ro;
      candidateDB = currentOrgTime - crrentTime > 0;
    }

    return (
      <Layout
        {...this.props}
        role="employer"
        userData={this.state.userData}
        logo={this.state.logo}
        setLogo={this.setLogo}
        orgID={this.state.orgID}
        orgActive={this.setOrgActive}
        organizations={this.state.organizations}
        appBarColor={this.state.appBarColor}
        setAppBarColor={this.setAppBarColor}
        setOrgNid={this.setOrgNid}
        candidateDB={candidateDB}
      >
        <Switch>
          <Route
            path="/profile"
            render={(props) =>
              this.props.initialData && this.state.userData ? <Profile {...props} {...this.props} {...this.state} fetchProfileData={this.fetchProfileData} role="employer" /> : <Loader />
            }
          />
          <Route
            path="/job"
            render={(props) =>
              this.state.orgID ? (
                this.state.organizations && this.props.initialData ? (
                  <Jobs {...props} {...this.props} currentOrg={this.state.organizations.find((el) => el.uuid === this.state.orgID)} />
                ) : (
                  <Loader />
                )
              ) : (
                <Redirect to="/dashboard" />
              )
            }
          />
          <Route
            path="/organization"
            render={() =>
              this.state.organizations && this.props.initialData ? (
                <Organization
                  {...this.props}
                  organizations={this.state.organizations}
                  fetchOrg={this.handleGetOrganizations}
                  setLogo={this.setLogo}
                  orgID={this.state.orgID}
                  orgActive={this.setOrgActive}
                  setAppBarColor={this.setAppBarColor}
                  setOrgNid={this.setOrgNid}
                  currentUser={this.state.userData.attributes.field_contact_email}
                />
              ) : (
                <Loader />
              )
            }
          />

          <Route
            path="/candidates"
            render={(props) => {
              if (this.state.orgID) {
                return this.state.organizations && this.props.initialData ? (
                  <Candidates {...props} {...this.props} candidateDB={candidateDB} currentOrg={this.state.organizations.find((el) => el.uuid === this.state.orgID)} />
                ) : (
                  <Loader />
                );
              } else {
                return <Redirect to="/dashboard" />;
              }
            }}
          />
          <Route
            path="/settings"
            render={(props) => (this.state.organizations ? <Settings {...props} {...this.props} currentOrg={this.state.organizations.find((el) => el.id === this.state.orgID)} /> : <Loader />)}
          />
          <Route path="/user/edit" render={(props) => (this.props.initialData ? <EditProfile {...props} {...this.props} fetchProfileData={this.fetchProfileData} role="employer" /> : <Loader />)} />
          <Route path="/password" render={(props) => <ChangePassword {...this.props} {...props} />} />
          <Route path="/user/reset/:hash" render={(props) => <ResetPassword {...this.props} {...props} />} />
          {/* <Route path="/email-confirm/:hash" render={(props) => <Confirmation  {...this.props} {...props}/> } /> */}
          <Route
            path="/"
            render={(props) =>
              this.state.organizations ? <Dashboard {...props} {...this.props} organizations={this.state.organizations} orgID={this.state.orgID} nid={this.state.nid} /> : <Loader />
            }
          />
          {/* <Redirect to='/dashboard'/> */}
          {/* <Route component={NotFound} /> */}
        </Switch>
      </Layout>
    );
  }
}

export default Employer;
