import React, { useState, Fragment } from 'react';
import { makeStyles, IconButton, Menu, MenuItem, Dialog, DialogTitle, DialogActions, Button, Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import tooltip from '../../../tooltip';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Droppable, Draggable } from "react-beautiful-dnd";
import clsx from 'clsx';
import User from './User';

const useStyles = makeStyles(theme => ({
    container: {
        background: '#f2f2f2',
        borderRadius: "5px",
        marginRight: "20px",
        width: "270px",
        display: "flex",
        flexDirection: "column",
        flexShrink: 0,
        "&.hide": {
            display: "none"
        } 
    },
    usersList: {
        padding: "0 10px 10px 10px", 
        flexGrow: "1",
        minHeight: "200px", 
        background: '#f2f2f2',
        transition: "background-color 0.3s linear",
        "&.active" : {
            backgroundColor: "#EAEAEA"
        }
    },
    title: {
        display: "flex",
        margin: "0",
        background: '#f2f2f2',
        padding: `${theme.spacing(1)}px ${theme.spacing(4)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
        borderRadius: "5px 5px 0 0",
        textTransform: "capitalize" 
    },
    titleContainer: {
        background: '#fafafa', 
        color: theme.palette.grey[600],
        position: "sticky",
        top: 0,
        zIndex: 1000
    },
    colMenu: {
        position: 'absolute',
        top: "4px",
        right: "10px",
        borderRadius: "5px"
    },
    tooltip: {
        fontSize: 12
    }
}));

const Column = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [dialog, setDialog] = useState(false);

    const handleDialogOpen = () => {
        setDialog(true);
    };
    const handleDialogClose = () => {
        setDialog(false);
        setAnchorEl(null);
    };
    const openMenu = (target) => {
        setAnchorEl(target);
    };
    const closeMenu = () => {
        setAnchorEl(null);
    };
    const hideColumn = (id) => {
        props.hideColumns(id)
        setAnchorEl(null); 
    };
    const deleteColumn = (id) => {
        props.deleteColumns(id);
        setAnchorEl(null);
    }
    const handleDownload = () => {
        props.onDownload();
        setAnchorEl(null);
    }

    
    const deleteButton = props.column.id !== "requested" && props.column.id !== "rejected" && props.column.id !== "accepted" && props.column.id !== "archived";
    const isDropableDisabled = () => {
        if(props.dragProps.dragStart === "requested" || props.dragProps.dragStart === "rejected" || props.dragProps.dragStart === "accepted"){
            if(props.dragProps.dragStart === "requested"){
                return !(props.column.id === "requested")
            }
            if(props.dragProps.dragStart === "rejected"){
                return !(props.column.id === "rejected")
            }
            if(props.dragProps.dragStart === "accepted"){
                return props.column.id === "requested" || props.column.id === "rejected"
            }
        }else{
            if(props.dragProps.ownership === "requested"){
                return !(props.column.id === "requested") 
            }
            if(props.dragProps.ownership === "accepted" || props.dragProps.ownership !== "rejected" || props.dragProps.ownership !== "requested"){
                return props.column.id === "requested" || props.column.id === "rejected"
            }
            if(props.dragProps.ownership === "rejected"){
                return !(props.column.id === "rejected")
            }
        }
        
    }

    const requested = tooltip.requestedCol.en.string;
    const accepted = tooltip.acceptedCol.en.string;
    const rejected  = tooltip.rejectedCol.en.string;
    let tooltipTitle;
    switch (props.column.id) {
        case 'requested':
            tooltipTitle = requested;
          break;
        case 'accepted':
            tooltipTitle = accepted
          break;
        case 'rejected':
            tooltipTitle = rejected;
        break;
        default:
            tooltipTitle = "";
    }
    return (
        <Fragment>
            <Draggable draggableId={props.column.id} index={props.index}>   
                {(provided, snapshot) => (
                    <div className={clsx(classes.container, {['hide']: props.hidden})} {...provided.draggableProps} ref={provided.innerRef}>
                        <div className={classes.titleContainer}>
                            <h4 className={classes.title} {...provided.dragHandleProps}> 
                                {props.column.title === "REJECTED" ? "DECLINED BY CANDIDATE" : props.column.title}
                                {tooltipTitle && <Tooltip classes={{tooltip: classes.tooltip}} title={tooltipTitle}><HelpIcon style={{ fontSize: 15 }} /></Tooltip>}
                            </h4>
                            <IconButton className={classes.colMenu} onClick={(e) => openMenu(e.currentTarget)} size="small" children={<MoreHorizIcon />}></IconButton>
                            <Menu
                                open={Boolean(anchorEl)}
                                anchorEl={anchorEl}
                                onClose={closeMenu}
                            >
                                <MenuItem onClick={() => hideColumn(props.column.id) }>Hide</MenuItem>
                                {props.column.id === "accepted" && <MenuItem onClick={() => handleDownload() }>Download</MenuItem>}
                                {deleteButton && <MenuItem onClick={() => handleDialogOpen() }>Delete</MenuItem>}
                            </Menu>
                        </div>
                        <Dialog
                            open={dialog && Boolean(anchorEl)}
                        >
                            <DialogTitle>{"Are you sure you want to delete this column? Candidates in this column will move back to the ACCEPTED column."}</DialogTitle>
                            <DialogActions>
                                <Button onClick={()=> deleteColumn(props.column.id)} variant="contained">
                                    Yes
                                </Button>
                                <Button onClick={handleDialogClose} variant="contained">
                                    No
                                </Button>
                            </DialogActions> 
                        </Dialog>
                        <Droppable droppableId={props.column.id} isDropDisabled={isDropableDisabled()}>
                            {(provided, snapshot)=> (
                                <div 
                                    className={clsx(classes.usersList, {['active']: snapshot.isDraggingOver})}  
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {props.users.map((user, index) => {  
                                        return user.visible && (
                                            <User 
                                                key={user.node_id} 
                                                user={user} 
                                                index={index} 
                                                col={props.column.id} 
                                                currentOrg={props.currentOrg} 
                                                token={props.token} 
                                                archived={props.archived} 
                                                moveTo={props.moveTo} 
                                                delete={props.delete}
                                                countUsers = {props.users.length}
                                                initialData = {props.initialData}
                                            />
                                        )
                                    })}
                                    {provided.placeholder} 
                                </div>
                            )}
                        </Droppable>
                    </div>
                )}
            </Draggable>
        </Fragment>
    )
} 

export default Column;