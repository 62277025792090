import React , { Component } from 'react';
import { withRouter } from 'react-router-dom';
import config from '../../../../oauthConfig';
import { withStyles } from '@material-ui/core/styles';
import { Stepper, Step, StepLabel, Button, Typography, Container, CircularProgress, Snackbar } from '@material-ui/core';
import SignUpTalentStepOne from './SignUpTalentStepOne'
import SignUpTalentStepTwo from './SignUpTalentStepTwo'
import SignUpTalentStepThree from './SignUpTalentStepThree'
import SignUpTalentStepFour from './SignUpTalentStepFour' 
import axios from 'axios';

const styles = theme => ({
  wrapper: {
    maxWidth: 600,
    margin: 'auto',
    [theme.breakpoints.up('sm')]: {
      padding: `0 ${theme.spacing(3)}px`,
    }
  },
  stepper: {
    backgroundColor: 'transparent'
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    position: "absolute",
    left: 0,
    top: 0
  },
  stepWrapper: {
    position: "relative",
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(3),
    }
  },
  loader: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  },
  snackbarContent: {
    backgroundColor: theme.palette.secondary.main
  }
});

const defaultUserData = {
  type: "node--candidate_profile",
  attributes: {},
  relationships: {
    field_candidate_country: {
      data: []
    },
    field_profile_picture: {
      data: {}
    }
  }
}

class HorizontalLabelPositionBelowStepper extends Component {
  state = {
    open: false,
    msg: "",
    loadingFile: false,
    navigation: {
      activeStep : 0,
      steps: ['Step 1', 'Step 2', 'Step 3', 'Step 4']
    },
    initialData: {
      selectedCountry: this.props.selectedCountry  && this.props.selectedCountry,
      selectedTechRoles: this.props.selectedRole && this.props.selectedRole,
      selectedLanguages: this.props.selectedLanguages ? this.props.selectedLanguages : [{lang:[], level:[]}],
      selectedProgramingLang: this.props.selectedProgramingLang ? this.props.selectedProgramingLang : [{lang: [], level:[]}],
      selectedPersonal: this.props.selectedPersonal ? this.props.selectedPersonal : [{lang: [], level:[]}],
      selectedCurrency:  this.props.selectedCurrency && this.props.selectedCurrency,
      employmnetType: {
        content: this.props.initialData && this.props.initialData.type, 
        checked: this.props.initialData && (this.props.initialData.type && this.props.checkedType ? this.props.checkedType : Object.keys(this.props.initialData.type).reduce((acc, item) => {acc[item] = false; return acc;}, {})),
      },
      visaSponsorship: {
        content: this.props.initialData && this.props.initialData.visaSponsorship,
        checked: this.props.initialData && (this.props.checkedVisa ? this.props.checkedVisa : Object.keys(this.props.initialData.visaSponsorship).reduce((acc, item) => {acc[item] = false; return acc;}, {})),
      },
      country: this.props.initialData && this.props.initialData.country,
      personalSkills: this.props.initialData && this.props.initialData.personalSkills,
      techSkills: this.props.initialData && this.props.initialData.techSkills,
      interests: this.props.initialData && this.props.initialData.interests,
      experience: this.props.initialData && this.props.initialData.experience,
      remoteWork: this.props.initialData && this.props.initialData.remoteWork,
      thirdParty: {1: "Yes", 0: "Require email confirmation"},
      visible: {1: "Visible", 0: "Not Visible (Show only my Initials)"},
      techRoles: this.props.initialData && this.props.initialData.role,
      languages: this.props.initialData && this.props.initialData.languages,
      languageProficiencies: this.props.initialData && this.props.initialData.languageProficiencies,
      currency: this.props.initialData && this.props.initialData.currency
    },
    profileImage: this.props.profileImage && this.props.profileImage,
    pdfPreview: this.props.pdfPreview && this.props.pdfPreview,
    idForDelete: {},
    userData: this.props.userData ? this.props.userData : {...defaultUserData}
  }

  onUnload = e => {
    e = e || window.event;
    if(!this.props.isEdit){  
      e.preventDefault();
      e.returnValue = '';
    } 
  }

  componentDidMount() {
    if(this.props.isEdit){
      this.setState( prevState => ({
        navigation: {
          ...prevState.navigation,
          activeStep: parseInt(this.props.match.params.id) 
        }
      }));
    }
    window.addEventListener("beforeunload", this.onUnload);
  }

componentDidUpdate(prevProps) {
  if(prevProps.match.params.id !== this.props.match.params.id && this.props.isEdit) {
    this.setState( prevState => ({
      navigation: {
        ...prevState.navigation,
        activeStep: parseInt(this.props.match.params.id) 
      }
    }));
  }
}

 componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
 }
  
  getStepContent  = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <SignUpTalentStepOne 
            mergeFormData={this.props.isEdit ? this.mergeAndSubmit : this.mergingStepsData}
            mergeAndSubmitLocation={this.mergeAndSubmitLocation}
            next={this.props.isEdit ?  this.handleNextId : this.handleNext} 
            defaultVal={this.state.userData}
            initialData={this.state.initialData}
            profileImage={this.state.profileImage} 
            countries={this.state.initialData.country}
            selectedCountry={this.state.initialData.selectedCountry}
            handleSelected={this.handleSelected}
            handleProfilePreview= {this.handleProfilePreview}
            createImageEntity={this.createImageEntity}
            deleteImageEntity={this.deleteImmediately}
            isEdit={this.props.isEdit}
          />
        )
      case 1:
        return (
          <SignUpTalentStepTwo 
            mergeFormData={this.props.isEdit ? this.mergeAndSubmit : this.mergingStepsData}
            next={this.props.isEdit ?  this.handleNextId : this.handleNext}
            handleBack={this.props.isEdit ?  this.handleBackId : this.handleBack}  
            defaultVal={this.state.userData}
            createImageEntity={this.createImageEntity}
            deleteImageEntity={this.deleteImmediately}
            pdfPreview={this.state.pdfPreview}
            isEdit={this.props.isEdit}
          />
        ) 
      case 2:
        return (
          <SignUpTalentStepThree  
            mergeFormData={this.props.isEdit ? this.mergeAndSubmit : this.mergingStepsData}
            next={this.props.isEdit ?  this.handleNextId : this.handleNext} 
            handleBack={this.props.isEdit ?  this.handleBackId : this.handleBack}  
            initialData={this.state.initialData}
            defaultVal={this.state.userData}
            handleSelected={this.handleSelected}
            isEdit={this.props.isEdit} 
          />
        )
      case 3:
        return (
          <SignUpTalentStepFour  
            mergeFormData={this.props.isEdit ? this.mergeAndSubmit : this.mergingStepsData}
            handleBack={this.props.isEdit ?  this.handleBackId : this.handleBack}  
            handleChecked={this.handleChecked}
            handleSelected={this.handleSelected}
            deleteBeforeSubmit={this.deleteBeforeSubmit}
            idForDelete={this.state.idForDelete} 
            initialData={this.state.initialData}
            defaultVal={this.state.userData}
            isEdit={this.props.isEdit}
            token={this.props.token}
            uuid={this.props.uuid}
            refreshToken={this.props.refreshToken}
          />
        )
      default:
        return 'Uknown stepIndex';
    }
  }

  handleSelected = (dataType, data) => {
    this.setState(prevState => ({
      initialData: {
        ...prevState.initialData,
        [dataType]: data
      }
    }))
  }

  handleChecked = (dataType, data) => {
    this.setState(prevState => ({
      initialData: {
        ...prevState.initialData,
        [dataType]: {
          ...prevState.initialData[dataType],
          checked: data
        }
      }
    }))
  }
  
  mergingStepsData = (dataType, newData, step) => {
    this.setState( prevState => ({
        userData: {
          ...prevState.userData,
          [dataType]: {
            ...prevState.userData[dataType],
            ...newData
          }
        }
    }));
  }

  mergeAndSubmit = (dataType, newData, step) => {
    const newState = {
      ...this.state,
      userData: {
        ...this.state.userData,
        [dataType]: {
          ...this.state.userData[dataType],
          ...newData
        }
      }
    }
    newState.userData.attributes.title = `${newState.userData.attributes.field_first_name} ${newState.userData.attributes.field_last_name}`;
    this.setState(newState);
    this.submit(newState.userData);
  }

  mergeAndSubmitLocation = (attributes, relationships) => {
    const newState = {
      ...this.state,
      userData: {
        ...this.state.userData,
        attributes: {
          ...this.state.userData.attributes,
          ...attributes
        },
        relationships: {
          ...this.state.userData.relationships,
          ...relationships
        }
      }
    }
    this.setState(newState);
    this.submit(newState.userData);
  }

  submit = (userData) => {
    this.setState({open: true, msg:"Saving"})
    let profileURL = userData.links.self.href.replace(/\?.*$/g,"");
    let token = this.props.token;
    const headers = {
      headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'Authorization': `${token.token_type} ${token.access_token}`
      }
    }
    delete userData.attributes.revision_timestamp
    delete userData.attributes.changed
    const data = {data: {...userData}}
    axios.patch(profileURL, data, headers)
    .then(() => {
      this.submitMsg()
      this.props.fetchProfileData()
    })
    .catch(err => {
      console.log(err)
    });
  }

  handleNext = () => {
    this.setState( prevState => ({
      navigation: {
        ...prevState.navigation,
        activeStep: prevState.navigation.activeStep + 1
      }
    }));
  };

  handleNextId = () => {
    this.props.history.push(`/profile/step/${this.state.navigation.activeStep + 1}`);
    this.setState( prevState => ({
      navigation: {
        ...prevState.navigation,
        activeStep: parseInt(this.props.match.params.id) + 1
      }
    }));
  };

  handleBack = () => {
    this.setState( prevState => ({
      navigation: {
        ...prevState.navigation,
        activeStep: prevState.navigation.activeStep - 1
      }
    }));
  };

  
  handleBackId = () => {
    this.props.history.push(`/profile/step/${this.state.navigation.activeStep - 1}`);
    this.setState( prevState => ({
      navigation: {
        ...prevState.navigation,
        activeStep: parseInt(this.props.match.params.id) - 1
      }
    }));
  };

  handleReset = () => {
    this.setState({
      navigation: {
        activeStep: 0
      }
    })
  };

  handleProfilePreview = (preview) => {
      this.setState({
        profileImage: preview
      })
  }

  handlePdfPreview = (preview) => {
    this.setState({
      pdfPreview: preview
    })
  }

  createImageEntity = (file, field) => {
    const fileType = file.type.split('/')[1];
    fileType === 'pdf' ? this.handlePdfPreview("loading") : this.handleProfilePreview("loading")
    var imagefile = file;
    let token = this.props.token;
    fetch(`${config.baseUrl}/api/node/candidate_profile/${field}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/octet-stream',
        'Accept': 'application/vnd.api+json',
        'Content-Disposition': `file; filename="${imagefile.name}"`,
        'Authorization': `${token.token_type} ${token.access_token}`
      },
      body: imagefile
      }).then(
        response => response.json()
      ).then(success => {
        if(fileType === "pdf"){
          this.handlePdfPreview(file.name)
        }else{
          this.handleProfilePreview(success.data.attributes.uri.url)
        }
          let selectedID = {type: success.data.type, id:success.data.id}
          const data = {[field]: {data: selectedID}}
          this.props.isEdit ? this.mergeAndSubmit('relationships', data) : this.mergingStepsData('relationships', data);
        }
      ).catch(
        error => console.log(error,'error')
      );
  }

  deleteImmediately = (field) => {
    field === "field_curriculum_vitae" ? this.handlePdfPreview("loading") : this.handleProfilePreview("loading");
    let token = this.props.token;
    const headers = {
      headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'Authorization': `${token.token_type} ${token.access_token}`
      }
    }

    const id = this.state.userData.relationships[field].data.id
    axios.delete(`${config.baseUrl}/api/file/file/${id}`, headers)
    .then(res => {
      field === "field_curriculum_vitae" ? this.handlePdfPreview(null) : this.handleProfilePreview(null);
      const data = {[field]: {data: null}}
      this.props.isEdit ? this.mergeAndSubmit('relationships', data) : this.mergingStepsData('relationships', data);
    })
  }

  submitMsg = () => {
    this.setState({msg:"Profile updated!"})
    setTimeout(
      () => this.setState({open:false , msg:""}), 
      5000
    );
  }
  
  render(){
    const { classes, isEdit } = this.props;
    return (
      <div className={classes.wrapper}>
        {!isEdit && (
          <Stepper activeStep={this.state.navigation.activeStep} className={classes.stepper}>
            {this.state.navigation.steps.map(label => (
              <Step key={label}>
                <StepLabel></StepLabel>
              </Step>
            ))}
          </Stepper> 
        )}
        {this.state.activeStep === this.state.navigation.steps.length ? (
          <div>
            <Typography className={classes.instructions}>All steps completed</Typography>
            <Button onClick={this.handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            { this.props.initialData ? (  
              <div className={classes.stepWrapper}>
                {/* {this.state.open && <div className={classes.instructions}>{this.state.msg} {this.state.msg === "Saving" && <CircularProgress color="secondary" size={15}/>}</div>} */}
                {this.getStepContent(isEdit ? parseInt(this.props.match.params.id) : this.state.navigation.activeStep)}
              </div>
            ) : (
              <div className={classes.loader}><CircularProgress color="secondary" /></div>
            )}
          </div>
        )}
         <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={this.state.open}
          message={this.state.msg}
          ContentProps={{
            className: classes.snackbarContent
          }}
        />
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(HorizontalLabelPositionBelowStepper)); 
