import React from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import { 
  withStyles, 
  Card, 
  CardContent, 
  Grid, 
  TextField , 
  Button,
  CircularProgress,
  CardActions
} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import oauthConfig from '../../../oauthConfig';
import axios from 'axios';

const styles = (theme) => ({
  card: {
    maxWidth: 420,
    margin: '0 auto'
  },
  container: {
    padding: 10,

  },
  link: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold'
  },
  // actions: {
  //   padding: `${theme.spacing(2)}px 0`
  // }
});

const SendEmailForm = props => {
    
    const {
      classes,
      values,
      touched,
      errors,
      isSubmitting,
      handleChange,
      handleBlur,
      handleSubmit
    } = props;

    // console.log(props.match.params.hash)
    // console.log(props) 

    const isDisabled = () => {
      return values.email.length > 0
    }

    // const handleFocus = () => {
    //     if(isError){
    //         props.handleIsError()
    //     }
    // }
        

    return (
      <div className={classes.container}>
        <form onSubmit={handleSubmit}>
          <Card className={classes.card}>
            <CardContent>
              <TextField
                id="email"
                label="Email"
                type="text"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.email ? errors.email : ""}
                error={touched.email && Boolean(errors.email)}
                margin="dense"
                variant="outlined"
                fullWidth
              />
            </CardContent>
            <CardActions>
              <Button type="submit" variant="contained" onClick={()=>{ props.history.push('/')}}>Back</Button>
              <Button type="submit" variant="contained" fullWidth={true} disabled={!isDisabled()}>
                {isSubmitting ? <CircularProgress color="secondary" size={25} /> : "Send"}
              </Button>
              </CardActions>
          </Card>
        </form>
      </div>
    );
  };
  
  const Form = withFormik({
    mapPropsToValues: ({
        email,
    }) => {
      return { 
        email: email || ""
      };
    },
  
    validationSchema: Yup.object().shape({
        email: Yup.string()
            .email("Please add valid email")
            .required("Required")
    }),
  
    handleSubmit: (values, { props, setSubmitting }) => {  
      setSubmitting(true)
      let data = {
        email: values.email,
      }
        
      axios.post(`${oauthConfig.baseUrl}/api/user/password-reset`, data)
      .then((res)=> {
        setSubmitting(false)
        props.setMsg(res.data.msg)
      })
      .catch(err => {
        setSubmitting(false)
        if(err && err.response && err.response.data){
          props.setMsg(err.response.data.error, true)
        }
      })
    }
  })(SendEmailForm); 
  
  export default withStyles(styles)(Form);
