import React, { Fragment } from "react";
import Select from 'react-select'
import * as Yup from "yup";
import { withFormik } from "formik";
import { 
  withStyles, 
  Card,
  CardActions, 
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  IconButton,
  FormControl,
  Grid,
  Typography,
  TextField,
  FormHelperText
} from "@material-ui/core";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';

const styles = (theme) => ({
  card: {
    padding: 20,
    marginBottom: 50,
  },
  container: {
    display: "Flex",
    justifyContent: "center"
  },
  actions: {
    '& > * + *' : {
      marginLeft: `${theme.spacing(1)}px`
    }
  },
  removeBtn: {
    color: theme.palette.error.light
  }
});

const form = props => {
  const { 
    classes,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleBack,
    handleSubmit,
    setFieldValue,
    initialData,
    defaultVal,
    handleSelected
  } = props;

  const selectStyles = (field, index) => {
    const styles = {
      menu: base => ({
        ...base,
        zIndex: 100
      }),
      control: (base, state) => ({
          ...base,
          minHeight: '40px',
          boxShadow: 'none',
          '&:hover': {
              border: '1px solid', 
              borderColor: (index >= 0 && touched[field] ? !values[field][index] : touched[field] ) && Boolean(errors[field]) ? "red" : `hsl(0,0%,70%)`
          },
          borderColor:(index >= 0 && touched[field] ? !values[field][index] : touched[field] ) && Boolean(errors[field]) ? "red" : `hsl(0,0%,70%)`
      })
    }
    return styles;
  }

  const experienceLevel =  Object.keys(initialData.experience).map((el,index) => {
      return { label : initialData.experience[el], value: el }
  })

  const experience = Object.keys(initialData.experience).map((el,index) => {
    return  <FormControlLabel
              key={index} 
              value={el} 
              control={<Radio />} 
              label={<Typography variant="caption" color="textPrimary">{initialData.experience[el]}</Typography>} 
            />
  })

  const handleRadio = (e, field) => {
      setFieldValue(field , e.target.value);
      props.mergeFormData( "attributes", {[field]: e.target.value}); 
  }

  const handleSelectList = (selectedOption, field, selected ) => {
    let options;
    if(selectedOption){
        options = selectedOption.map( el => {
        return el.value
      })
      props.mergeFormData( "attributes", {[field]: options});
      handleSelected(selected, selectedOption)
    }
  }
  
  const handleLanguage = (selectedData, selectedOption, innerArray, index, field) => {
    const languages = [...initialData[selectedData]];
    // const isExisting = languages.find(el => el.lang ===  selectedOption.value);
    const current = {...languages[index]};
    current[innerArray] = selectedOption.value;
    languages.splice(index, 1, current);
    const fieldValues = (innerArray === "lang") ?  languages.map(el => el.lang) : languages.map(el => el.level);
    setFieldValue(field , fieldValues);
    props.mergeFormData( "attributes", {[field]: fieldValues}); 
    handleSelected(selectedData, languages);
    // handleSelected("languages", removeSelectedLang);
  }
  
  const handleLanguageTaxonomy = (selectedData, selectedOption, innerArray, index, name ) => {
    const languages = [...initialData[selectedData]];
    const current = {...languages[index]};
    let option; 
    if(selectedOption){
      option = {type: `taxonomy_term--${name}` , id: selectedOption.id, value: selectedOption.value};
      current[innerArray] = option;
      languages.splice(index, 1, current);
      const fieldValues = (innerArray === "lang") ?  languages.map(el => el.lang) : languages.map(el => el.level);
      let relationships = {[`field_${name}`]: {data: fieldValues}};
      setFieldValue(`field_${name}` , fieldValues);
      props.mergeFormData( "relationships", relationships);
      handleSelected(selectedData, languages)
    }else {
      // let relationships = {[field]: {data: []}};
      setFieldValue(`field_${name}` , option);
      // props.mergeFormData( "relationships", relationships);
      // handleSelected(selected, null)
    }
  }

  const addLanguage = (selectedData) => {
    const mergedLanguage = [...initialData[selectedData],{lang:"", level:""}]
    handleSelected(selectedData, mergedLanguage)
  }

  const removeLanguage = (index, selectedData, fields) => {
    const languages = [...initialData[selectedData]]
    languages.splice(index, 1);
    fields.forEach(field => {
      values[field].splice(index, 1);
      if(defaultVal.attributes[field]){
        const  value = values[field];
        props.mergeFormData( "attributes", { [field]: value });
      }
      if(defaultVal.relationships[field]){
        const  value = [...defaultVal.relationships[field].data];
        value.splice(index, 1);
        props.mergeFormData( "relationships", { [field]: {data: value} });
      }
    })
    handleSelected(selectedData, languages)
  }

  const setTextValues = (e) => {
    const field = e.target.id;
    const value = e.target.value;
    handleBlur(e)
    if(Object.keys(errors).length && props.isEdit){
      return
    }
    props.mergeFormData( "attributes", {[field]: value});  
  }
  
  const removeOptions = (options, selectedData) => {
    const res = initialData[options].reduce((acc, curr) => {
      const index = initialData[selectedData].findIndex(item => {
        const value = item.lang.value ? item.lang.value : item.lang
        return value === curr.value
      });
      if(index === -1) {
        acc.push(curr)
      }
      return acc;
    }, []);
    return res
  }
  
  return (
    <form onSubmit={handleSubmit} className={classes.container}>
      <Card className={classes.card}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
                id="field_current_role"
                label="Current Role"
                value={values.field_current_role}
                onChange={handleChange}
                onBlur={(e) => setTextValues(e)}
                helperText={touched.field_current_role ? errors.field_current_role : ""}
                error={touched.field_current_role && Boolean(errors.field_current_role)}
                margin="dense"
                variant="outlined"
                fullWidth
            />
          </Grid>
          <Grid item xs={12}>
              <Select 
                isMulti
                placeholder="What roles are you interested in?"
                defaultValue={(defaultVal.attributes.field_roles_interested && initialData.selectedTechRoles )  ? initialData.selectedTechRoles : null} 
                options={initialData.techRoles} 
                onChange={ (selectedOption) => handleSelectList(selectedOption , 'field_roles_interested', 'selectedTechRoles') } 
              />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">Programming Languages</Typography>
            {initialData.selectedProgramingLang.map((el, index) =>
              <Grid key={index} container alignItems="center" spacing={2}>
                <Grid item xs={7}> 
                  <Select 
                    placeholder="Programming Language"
                    defaultValue={defaultVal.relationships.field_technical_skills ? initialData.techSkills.find((el) => el.id ===  initialData.selectedProgramingLang[index]['lang'].id) : null} 
                    options={removeOptions('techSkills', 'selectedProgramingLang')}
                    onChange={ (selectedOption) => handleLanguageTaxonomy('selectedProgramingLang', selectedOption , 'lang', index, 'technical_skills') }
                    onBlur={ () => handleBlur({target:{id: "field_technical_skills"}}) }
                    styles={selectStyles("field_technical_skills")} 
                  />
                  {touched.field_technical_skills && Boolean(errors.field_technical_skills) && <FormHelperText error={true} variant="filled">{errors.field_technical_skills}</FormHelperText>}
                </Grid>
                <Grid item xs={4}>
                  <Select 
                    placeholder="Skill Level"
                    defaultValue={defaultVal.attributes.field_technical_skills_exp ? experienceLevel.find((el) => el.value ===  initialData.selectedProgramingLang[index]['level']) : null}
                    isDisabled={!initialData.selectedProgramingLang[index]['lang']} 
                    options={experienceLevel} 
                    onChange={ (selectedOption) => handleLanguage('selectedProgramingLang', selectedOption , 'level', index, 'field_technical_skills_exp') }
                    styles={selectStyles( "field_technical_skills_exp", index)} 
                  />
                  {(touched["field_technical_skills_exp"] && !values["field_technical_skills_exp"][index]) && Boolean(errors.field_technical_skills_exp) && <FormHelperText error={true} variant="filled">{errors.field_technical_skills_exp}</FormHelperText>}
                </Grid>
                <Grid item xs={1}>
                  {initialData.selectedProgramingLang.length > 1 && (
                    <IconButton size="small" className={classes.removeBtn} onClick={() => removeLanguage(index, 'selectedProgramingLang', ['field_technical_skills', 'field_technical_skills_exp'])}>
                      <CancelIcon fontSize="small"/>
                    </IconButton>
                  )}
                  <IconButton onClick={() => addLanguage('selectedProgramingLang')} color="primary" size="small" disabled={initialData.selectedProgramingLang[index]['level'].length < 1}>
                    <AddCircleIcon fontSize="small"/>
                  </IconButton>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">Personal skills</Typography>
            {initialData.selectedPersonal.map((el, index) =>
              <Grid key={index} container alignItems="center" spacing={2}>
                <Grid item xs={7}> 
                  <Select 
                    placeholder="Personal skills*"
                    defaultValue={defaultVal.relationships.field_personal_skills ? initialData.personalSkills.find((el) => el.id ===  initialData.selectedPersonal[index]['lang'].id) : null} 
                    options={removeOptions('personalSkills', 'selectedPersonal')}
                    onChange={ (selectedOption) => handleLanguageTaxonomy('selectedPersonal', selectedOption , 'lang', index, 'personal_skills') }
                    onBlur={ () => handleBlur({target:{id: "field_personal_skills"}}) }
                    styles={selectStyles("field_personal_skills")} 
                  />
                  {touched.field_personal_skills && Boolean(errors.field_personal_skills) && <FormHelperText error={true} variant="filled">{errors.field_personal_skills}</FormHelperText>}
                </Grid>
                <Grid item xs={4}>
                  <Select 
                    placeholder="Skill Level"
                    defaultValue={defaultVal.attributes.field_personal_skills_exp ? experienceLevel.find((el) => el.value ===  initialData.selectedPersonal[index]['level']) : null}
                    isDisabled={!initialData.selectedPersonal[index]['lang']} 
                    options={experienceLevel} 
                    onChange={ (selectedOption) => handleLanguage('selectedPersonal', selectedOption , 'level', index, 'field_personal_skills_exp') }
                    styles={selectStyles( "field_personal_skills_exp", index)} 
                  />
                  {(touched["field_personal_skills_exp"] && !values["field_personal_skills_exp"][index]) && Boolean(errors.field_personal_skills_exp) && <FormHelperText error={true} variant="filled">{errors.field_personal_skills_exp}</FormHelperText>}
                </Grid>
                <Grid item xs={1}>
                  {initialData.selectedPersonal.length > 1 && (
                    <IconButton size="small" className={classes.removeBtn} onClick={() => removeLanguage(index, 'selectedPersonal', ['field_personal_skills', 'field_personal_skills_exp'])}>
                      <CancelIcon fontSize="small"/>
                    </IconButton>
                  )}
                  <IconButton onClick={() => addLanguage('selectedPersonal')} color="primary" size="small" disabled={initialData.selectedPersonal[index]['level'].length < 1}>
                    <AddCircleIcon fontSize="small"/>
                  </IconButton>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">Languages</Typography>
            {initialData.selectedLanguages.map((el, index) =>
              <Grid key={index} container alignItems="center" spacing={2}>
                <Grid item xs={4}> 
                  <Select 
                    placeholder="Language"
                    defaultValue={defaultVal.attributes.field_languages ? initialData.languages.find((el) => el.value ===  initialData.selectedLanguages[index]['lang']) : null} 
                    options={removeOptions('languages', 'selectedLanguages')} 
                    onChange={ (selectedOption) => handleLanguage('selectedLanguages', selectedOption, 'lang', index, 'field_languages' ) }
                  />
                </Grid>
                <Grid item xs={7}>
                  <Select 
                    placeholder="Language Proficiencies"
                    defaultValue={defaultVal.attributes.field_language_proficiencies ? initialData.languageProficiencies.find((el) => el.value ===  initialData.selectedLanguages[index]['level']) : null}
                    isDisabled={!initialData.selectedLanguages[index]['lang'].length > 0} 
                    options={initialData.languageProficiencies} 
                    onChange={ (selectedOption) => handleLanguage('selectedLanguages', selectedOption , 'level', index, 'field_language_proficiencies') }
                    styles={selectStyles( "field_language_proficiencies", index)} 
                  />
                  {(touched["field_language_proficiencies"] && !values["field_language_proficiencies"][index]) && Boolean(errors.field_language_proficiencies) && <FormHelperText error={true} variant="filled">{errors.field_language_proficiencies}</FormHelperText>}
                </Grid>
                <Grid item xs={1}>
                  {initialData.selectedLanguages.length > 1 && (
                    <IconButton size="small" className={classes.removeBtn} onClick={() => removeLanguage(index, 'selectedLanguages', ['field_languages', 'field_language_proficiencies'])}>
                      <CancelIcon fontSize="small"/>
                    </IconButton>
                  )}
                  <IconButton onClick={() => addLanguage("selectedLanguages")} color="primary" size="small" disabled={initialData.selectedLanguages[index]['level'].length < 1}>
                    <AddCircleIcon fontSize="small"/>
                  </IconButton>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <Typography variant="subtitle2" color={(touched.field_professional_experience && errors.field_professional_experience) && "error"}>Professional Experience*</Typography>
              <RadioGroup aria-label="experience" name="experience" value={values.field_professional_experience} onChange={(e)=>{handleRadio(e,'field_professional_experience')}}> 
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                {experience}
              </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
          {!props.isEdit ? (
            <CardActions className={classes.actions}>
              <Button onClick={handleBack} variant="contained"> Back </Button>
              <Button type="submit" variant="contained" fullWidth >Continue</Button>
            </CardActions>
          ) : (
            <CardActions className={classes.actions}>
              <Button onClick={handleBack} variant="contained"> Back </Button>
              <Button variant="contained" fullWidth onClick={() => props.next()}>Next</Button>
            </CardActions>
          )}
      </Card>
    </form>
  );
};

const Form = withFormik({
  mapPropsToValues: ({defaultVal}) => {
    
    return {
      field_personal_skills: defaultVal.relationships.field_personal_skills ? defaultVal.relationships.field_personal_skills.data : [],
      field_personal_skills_exp: defaultVal.attributes.field_personal_skills_exp ? defaultVal.attributes.field_personal_skills_exp : [],
      field_technical_skills: defaultVal.relationships.field_technical_skills ? defaultVal.relationships.field_technical_skills.data : [],
      field_technical_skills_exp: defaultVal.attributes.field_technical_skills_exp ? defaultVal.attributes.field_technical_skills_exp : [],  
      field_professional_experience : defaultVal.attributes.field_professional_experience ? defaultVal.attributes.field_professional_experience : '',
      field_current_role:  defaultVal.attributes.field_current_role ? defaultVal.attributes.field_current_role : "",
      field_roles_interested:  defaultVal.attributes.field_roles_interested ? defaultVal.attributes.field_roles_interested : "",
      field_languages: defaultVal.attributes.field_languages ? defaultVal.attributes.field_languages : [],
      field_language_proficiencies: defaultVal.attributes.field_language_proficiencies ? defaultVal.attributes.field_language_proficiencies : [],
    }
  },

  validationSchema: Yup.object().shape({
    field_technical_skills: Yup.array().min(1, "Required").required("Required"),
    field_personal_skills: Yup.array().min(1, "Required").required("Required"),
    field_professional_experience: Yup.string().required("Required"),
    field_language_proficiencies: Yup.array().test('matchLanguage', 'Required', function(item){
      if(this.parent.field_languages.length !== 0){
        if(this.parent.field_languages.length === 1){
          return this.parent.field_language_proficiencies.length > 0 && this.parent.field_language_proficiencies.length > 0
        } 
        else if(this.parent.field_languages.length > 1){
          return (this.parent.field_languages.length === this.parent.field_language_proficiencies.length) && this.parent.field_language_proficiencies.length > 0
        }
      }
      else return []
    }),
    field_technical_skills_exp: Yup.array().test('matchProgramingLanguage', 'Required', function(item){
      if(this.parent.field_technical_skills.length !== 0){
        if(this.parent.field_technical_skills.length === 1){
          return this.parent.field_technical_skills_exp.length > 0 && this.parent.field_technical_skills_exp.length > 0
        } 
        else if(this.parent.field_technical_skills.length > 1){
          return (this.parent.field_technical_skills.length === this.parent.field_technical_skills_exp.length) && this.parent.field_technical_skills_exp.length > 0
        }
      }
      else return []
    }),
    field_personal_skills_exp: Yup.array().test('matchPersonalSkills', 'Required', function(item){
      if(this.parent.field_personal_skills.length !== 0){
        if(this.parent.field_personal_skills.length === 1){
          return this.parent.field_personal_skills_exp.length > 0 && this.parent.field_personal_skills_exp.length > 0
        } 
        else if(this.parent.field_personal_skills.length > 1){
          return (this.parent.field_personal_skills.length === this.parent.field_personal_skills_exp.length) && this.parent.field_personal_skills_exp.length > 0
        }
      }
      else return []
    })
  }),
  handleSubmit: (values, { props, setSubmitting, setValues, setFieldValue }) => {
    props.next()
  }
})(form);

export default withStyles(styles)(Form);
