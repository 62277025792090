import React from "react";
import { RadioGroup, FormControl, Grid, Typography } from "@material-ui/core";

const RadioButtons = props => {
    return (
        <FormControl component="fieldset">
            <Typography color={props.error && "error"} variant="subtitle2">{props.title}</Typography>
            <RadioGroup 
                id={props.field}
                value={props.value}
                onChange={(e)=>{props.handleChange(e, props.field)}}
            > 
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                >
                    {props.children}
                </Grid>
            </RadioGroup>
        </FormControl>
    )
}

export default RadioButtons;