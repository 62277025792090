import React from "react";
import { FormGroup, FormControlLabel, Checkbox, Grid, Typography } from "@material-ui/core";

const CheckboxButtons = props => {
    const { data, type, handleChange, field } = props
    const content = Object.keys(data[type].content).map((el,index) => {
        return (
          <FormControlLabel
            key={index}
            value={el} 
            control={<Checkbox checked={data[type].checked[el]} id="field_employment_type"/>} 
            label={<Typography variant="caption" color="textPrimary">{data[type].content[el]}</Typography>}
            onChange={(e)=>{handleChange(type, e.target.value, field)}} 
          />
        ) 
    })

    return (
        <FormGroup>
            <Typography variant="subtitle2" color={props.error && "error"}>{props.title}</Typography>
            <Typography variant="caption">{props.subtitle}</Typography>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
            >
                {content}
            </Grid>
        </FormGroup>
    )
}

export default CheckboxButtons;