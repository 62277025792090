import React, { useState, Fragment } from 'react';
import { 
    Typography,
    Button, 
    Grid, 
    Box,
    Dialog, 
    DialogActions,
    DialogTitle,
    Paper,
    Divider,
    Tooltip
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Loader from '../../../Utilities/Loader/Loader';
import { makeStyles } from '@material-ui/core/styles';
import { Html5Entities } from 'html-entities';

const htmlEntities = new Html5Entities();

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 800,
        margin: "0 auto 10px auto",
        padding: theme.spacing(2),
    },
    button: {
        margin: `0 ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px 0`,
    },
    buttons: {
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
        },
    },
    valid: {
        paddingRight: '5px'
    }
}));

const JobItem = (props) => {
    const classes = useStyles();
    const [openDelete, setOpenDelete] = useState(false);
    let content;
    let item;

    const handleDeleteOpen = () => {
        setOpenDelete(true);
    };
    
    const handleDeleteClose = () => {
        setOpenDelete(false);
    };

    const currency = props.currentOrg.field_currency;
    
    if(props.jobList){
        item = props.jobList.filter(el => el.nid == props.match.params.id);
        content = (
            <Fragment>
                <Paper className={classes.root}>
                    <Grid container direction="row" alignItems="flex-start" wrap="wrap">
                        <Grid item container xs={12} md={8}>
                            <Typography component="h1" variant="h6">
                                <Box lineHeight="normal" css={{textTransform: 'uppercase'}}>
                                    {Html5Entities.decode(item[0].title)}
                                </Box>
                            </Typography>
                            <Grid item container xs={12}>
                                <Typography component="h2" variant="caption" className={classes.valid}>
                                    Valid until:
                                </Typography>
                                <Typography variant="caption">
                                    {item[0].field_valid_until}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} md={4} className={classes.buttons}>
                            <Tooltip title="Back">
                                <Button 
                                    className={classes.button} 
                                    variant="outlined" 
                                    onClick={()=>{props.history.goBack()}}> 
                                    <KeyboardBackspaceIcon fontSize="small"/>
                                </Button>
                            </Tooltip>
                            <Tooltip title="Edit">
                                <Button 
                                    className={classes.button} 
                                    variant="outlined" 
                                    onClick={()=>{props.history.push(`${props.match.url}/edit`)}}> 
                                    <EditIcon fontSize="small"/>
                                </Button>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <Button 
                                    className={classes.button} 
                                    variant="outlined" 
                                    onClick={()=>{handleDeleteOpen()}}>
                                    <DeleteIcon fontSize="small"/>
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}><Box mt={1} mb={1}><Divider/></Box></Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography component="h2" variant="subtitle2">
                                Job Description:
                            </Typography>
                            <Typography variant="body1">
                                    
                            </Typography>
                            <div dangerouslySetInnerHTML={{__html: item[0].field_description}}></div>
                        </Grid>
                        { item[0].field_experience_requirements ? (
                            <Grid item xs={12}>
                                <Typography component="h2" variant="subtitle2">
                                    Experience Requirements:
                                </Typography>
                                <div dangerouslySetInnerHTML={{__html: item[0].field_experience_requirements}}></div>
                            </Grid>
                        ) : null }
                        <Grid item xs={12}>
                            <Typography component="h2" variant="subtitle2">
                                Technical Skills:
                            </Typography>
                            <Typography variant="body2">
                                {item[0].field_technical_skills.split(";").join(", ")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography component="h2" variant="subtitle2">
                                Employment Type
                            </Typography>
                            <Typography variant="body2">
                                {item[0].field_employment_type}
                            </Typography> 
                        </Grid>
                        {
                            item[0].field_base_salary_year_from || item[0].field_base_salary_year_to ? (
                                <Grid item xs={12} md={3}>
                                    <Typography component="h2" variant="subtitle2">
                                        Net Base Salary (Annual)
                                    </Typography>
                                    
                                        <Typography variant="body2">
                                            {`From ${item[0].field_base_salary_year_from} ${item[0].field_currency}`}
                                        </Typography>
                                        <Typography variant="body2">
                                            {`To ${item[0].field_base_salary_year_to} ${item[0].field_currency}`}
                                        </Typography>  
                                    
                                </Grid>
                            ) : null
                        }   
                        <Grid item xs={12} md={3}>
                            <Typography component="h2" variant="subtitle2">
                                Job Location
                            </Typography>
                            <Typography variant="body2">
                                {htmlEntities.decode(item[0].field_job_country)}
                                {item[0].field_job_state ? `, ${item[0].field_job_state}` : null}
                                {item[0].field_job_city_name ? `, ${item[0].field_job_city_name}` : null}
                            </Typography>  
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography component="h2" variant="subtitle2">
                                Remote Job?
                            </Typography>
                            <Typography variant="body2">
                                {item[0].field_remote_job}
                            </Typography>
                        </Grid>
                    </Grid> 
                </Paper>   
            
                <Dialog
                    open={openDelete}
                    onClose={handleDeleteClose}
                >
                    <DialogTitle>{"Are you sure you want to delete this job?"}</DialogTitle>
                    <DialogActions>
                        <Button onClick={() => props.handleDlete(item[0].uuid)} variant="contained">
                            Yes
                        </Button>
                        <Button onClick={handleDeleteClose} variant="contained">
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        )
    }else{
        content = <Loader/>
    }
    
    return (
        <Fragment>{content}</Fragment>
    )

}

export default JobItem;