import React, { Fragment } from 'react';
import { Typography, Checkbox, FormControlLabel, Box } from '@material-ui/core';

const CheckboxFilter = (props) => {
    return (
        <Fragment>
            <Typography variant="body1" gutterBottom={true}>{props.title}</Typography>
            <Box display="flex" flexWrap="wrap">
                {
                    props.values && (
                        props.values.map(el => {
                            return   (
                                <div key={el.label}> 
                                    <FormControlLabel
                                        control={<Checkbox  checked={props.checkedObj[el.value]} onChange={(e)=> props.handleChange(e.target.name, e.target.checked, props.checked)} name={el.value} />}
                                        label={el.label}
                                    />
                                </div>
                            )
                        })
                    )
                }   
            </Box>
        </Fragment>
    )
    
}

export default CheckboxFilter;