import React, { useState, useEffect } from 'react';

import { makeStyles, InputAdornment, TextField, Box } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
    search: {
        width: "200px",
        background: '#ffffff'
    },
    input: {
        height: "34.4px"
    }
}));

const SearchBar = (props) => {
 
    const classes = useStyles()
    const [searchVal, setSearchVal] = useState("")

    useEffect(()=> {
        setSearchVal(props.value)
    }, [props.value])
    
    const onChangeBar = (value) => {
        setSearchVal(value)
        props.onChange(value)
    }

    return (
        <div>
        <TextField 
            className={classes.search}
            variant="outlined"
            onChange={(e) => onChangeBar( e.target.value )}
            value={searchVal}
            // onKeyPress={this.handleKeyPress}
            InputProps={{
                className: classes.input,
                startAdornment: (
                    <InputAdornment position="start">
                            <Box color="#757575" display="flex">
                                <SearchIcon fontSize="small"/>
                            </Box>
                    </InputAdornment>
                )
            }} 
        />
        </div>
    )
}

export default SearchBar;