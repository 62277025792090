const tooltip = {
    any_skills: {
        en: {
            string: "Include any of the selected skills"
        }
    },
    all_skills: {
        en: {
            string: "Include all of the selected skills"
        }
    },
    requestedCol: {
        en: {
            string: "These candidates have not accepted your connection request yet and therefore are not accessible or moveable"
        }
    },
    acceptedCol: {
        en: {
            string: " These candidates have accepted your connection request or have been added to the board and can be moved to custom lists"        }
    },
    rejectedCol: {
        en: {
            string: "These candidates have declined your connection request and therefore are not accessible or moveable"
        }
    },
    boardSettings: {
        en: {
            string: "Add, show or hide columns"
        }
    }
}

export default tooltip;