import React from 'react';
import { Typography, Checkbox, FormControlLabel, Box } from '@material-ui/core';
import Select from 'react-select';

const styles = {
    multiValue: base => ({
        ...base,
        display: "none"
    }),
    option: base => ({  
        ...base,
        zIndex: 'red'
    }),
}

const SelectCheckbox = (props) => {
    return (
        <div>
            <Typography variant="body1" gutterBottom={true}>{props.title}</Typography>
            <Select
                isClearable={false} 
                isMulti
                placeholder={props.placeholder}
                onChange={ (selectedOption) => props.handleSelect(selectedOption, props.checked, props.statePropName) }
                value={props.values}
                options={props.selectOptions} 
                styles={styles} 
            />
            {props.children}
            <Box display="flex" flexWrap="wrap">
                {props.values && (
                    props.values.map(el => {
                        return   (
                            <div key={el.value}>
                                <FormControlLabel
                                    control={<Checkbox checked={props.checkedObj[el.value]} onChange={(e)=> props.handleChange(e.target.name, e.target.checked, props.checked)} name={el.value} />}
                                    label={el.label}
                                />
                            </div>
                        )
                    })
                )}
            </Box>
        </div>
    )
}

export default SelectCheckbox;