import React, { Component, Fragment } from 'react';
import { Snackbar } from '@material-ui/core';
import SendEmailForm from './SendEmailForm';
import ErrorCard from '../../Utilities/Alert/AlertError';
import SuccessCard from '../../Utilities/Alert/AlertSuccess';


class ForgottenPassword extends Component {

    state = {
        msg: "",
        error: false,
        open: false 
    }
    setMsg = (msg, isError) => {
        if(isError){
            this.setState({msg: msg, error: true, open: true });
        }else{
            this.setState({msg: msg, open: true});
        }
    }

    handleClose = () => {
        const isError = this.state.error;
        this.setState({open: false, error: false});
        if(!isError){
            this.props.history.push("/") 
        }
    };

    render(){
        const { history } = this.props
        return (
            <Fragment>
                <SendEmailForm setMsg={this.setMsg} history={history}/>
                <Snackbar 
                    autoHideDuration={3000} 
                    open={this.state.open}
                    onClose={this.handleClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
                >
                    {this.state.error ? (
                        <ErrorCard>{this.state.msg}</ErrorCard>
                    ):(
                        <SuccessCard>{this.state.msg}</SuccessCard>
                    )}
                </Snackbar>
            </Fragment>
        )
    }
}

export default ForgottenPassword;